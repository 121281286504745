import React, { useState } from "react";
import type { IconName } from "@blueprintjs/icons";
import type { MaybeElement } from "@blueprintjs/core";
import { Button, Icon } from "@blueprintjs/core";
import { usePostHog } from "posthog-js/react";
import SdTheme from "../../styles/theme";
import type { EventProps } from "../../@types/sd/event";

interface Props extends EventProps {
  icon?: IconName | MaybeElement;
  onClick?: React.MouseEventHandler<HTMLElement>;
  danger?: boolean;
  disabled?: boolean;
  className?: string;
  type?: "submit" | "reset" | "button";
  minimal?: boolean;
  children?: React.ReactNode;
}

const SdButton: React.FunctionComponent<Props> = ({
  danger,
  disabled,
  icon,
  onClick,
  className,
  minimal,
  type,
  children,
  eventName,
}) => {
  const posthog = usePostHog();
  const [isHovered, setHovered] = useState(false);
  let backgroundColor = "#2e77ff"; // SIGNADOT-BLUE400
  let textColor = SdTheme.Button.Normal.Neutral.text;
  if (danger) {
    backgroundColor = SdTheme.Button.Danger.Neutral.background;
    textColor = SdTheme.Button.Danger.Neutral.text;
  } else if (minimal) {
    backgroundColor = SdTheme.Button.Minimal.Neutral.background;
    textColor = SdTheme.Button.Minimal.Neutral.text;
  }
  if (disabled) {
    if (danger) {
      backgroundColor = SdTheme.Button.Danger.Disabled.background;
      textColor = SdTheme.Button.Danger.Disabled.text;
    } else if (minimal) {
      backgroundColor = SdTheme.Button.Minimal.Disabled.background;
      textColor = SdTheme.Button.Minimal.Disabled.text;
    } else {
      backgroundColor = SdTheme.Button.Normal.Disabled.background;
      textColor = SdTheme.Button.Normal.Disabled.text;
    }
  } else if (isHovered) {
    if (danger) {
      backgroundColor = SdTheme.Button.Danger.Hover.background;
      textColor = SdTheme.Button.Danger.Hover.text;
    } else if (minimal) {
      backgroundColor = SdTheme.Button.Minimal.Hover.background;
      textColor = SdTheme.Button.Minimal.Hover.text;
    } else {
      backgroundColor = "#0049d1"; // SIGNADOT-BLUE600
      textColor = SdTheme.Button.Normal.Hover.text;
    }
  }
  let iconEl: MaybeElement;
  if (icon) {
    iconEl = <Icon icon={icon} color={SdTheme.Button.icon} />;
  }
  return (
    <Button
      className={className}
      icon={iconEl}
      minimal
      style={{
        color: textColor,
        backgroundColor,
      }}
      onClick={(event) => {
        if (eventName) {
          posthog?.capture(eventName, {
            component: "Button",
          });
        }
        onClick?.(event);
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      disabled={disabled}
      type={type}
    >
      {children}
    </Button>
  );
};

export default SdButton;
