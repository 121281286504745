import React, { useEffect, useState, useMemo } from "react";
import type { Job } from "../@types/sd/job";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { AttemptPhaseEnum } from "../@types/sd/job.d.ts";
import type { LogItem, LogResponseItem } from "../components/Logs/Logs";
import type { StandardStreamType } from "../pages/Testing/Jobs/JobDetail/tabs/Logs/constants";
import {
  SIGNAL_RESTART,
  STDERR,
  STDOUT,
} from "../pages/Testing/Jobs/JobDetail/tabs/Logs/constants";
import useSSE from "./UseSSE";

type UseGetJobLogsProps = {
  job?: Job;
};

const useGetJobLogs = ({ job }: UseGetJobLogsProps) => {
  const [stdoutLogs, setStdoutLogs] = useState<LogItem[]>();
  const [stderrLogs, setStderrLogs] = useState<LogItem[]>();
  const [mergedLogs, setMergedLogs] = useState<LogItem[]>();

  useEffect(() => {
    setStderrLogs([]);
    setStdoutLogs([]);
    setMergedLogs([]);
  }, [job]);

  // To switch between stdout and stderr logs
  const [selectedType, setSelectedType] =
    React.useState<StandardStreamType>(STDOUT);

  // Handle Messages sent by the server
  const onMessage = (
    message: LogResponseItem,
    setLogs: React.Dispatch<React.SetStateAction<LogItem[] | undefined>>
  ) =>
    setLogs((prevLogs) => [
      ...(prevLogs || []),
      {
        message: message.message,
      } as LogItem,
    ]);

  // Handle Signals sent by the server
  const onSignal = (
    signal: string,
    setLogs: React.Dispatch<React.SetStateAction<LogItem[] | undefined>>
  ) => {
    if (signal === SIGNAL_RESTART) {
      setLogs([] as LogItem[]);
    }
  };

  // Handle Error messages sent by the server
  const onError = (error: string) => {
    console.error("ERROR:", error);
  };

  const getStreamEndpoint = () =>
    `/api/v2/orgs/:orgName/jobs/${job?.name}/attempts/${job?.status.attempts[0]?.id}/logs/stream`;

  const doDebounce = (phase?: AttemptPhaseEnum): boolean =>
    phase === AttemptPhaseEnum.SUCCEEDED || phase === AttemptPhaseEnum.FAILED;

  // Handle SSE events for stdout logs
  useSSE<LogResponseItem>(
    job ? `${getStreamEndpoint()}?type=${STDOUT}` : undefined,
    (message) => {
      onMessage(message, setStdoutLogs);
      onMessage(message, setMergedLogs);
    },
    (message) => onSignal(message, setStdoutLogs),
    onError,
    doDebounce(job?.status.attempts[0]?.phase)
  );

  // Handle SSE events for stderr logs
  useSSE<LogResponseItem>(
    job ? `${getStreamEndpoint()}?type=${STDERR}` : undefined,
    (message) => {
      onMessage(message, setStderrLogs);
      onMessage(message, setMergedLogs);
    },
    (message) => onSignal(message, setStderrLogs),
    onError,
    doDebounce(job?.status.attempts[0]?.phase)
  );

  const logs = useMemo(
    () => (selectedType === STDOUT ? stdoutLogs : stderrLogs),
    [stdoutLogs, stderrLogs, selectedType]
  );

  return {
    stderrLogs: job ? stderrLogs ?? [] : [],
    stdoutLogs: job ? stdoutLogs ?? [] : [],
    mergedLogs: mergedLogs ?? [],
    selectedType,
    setSelectedType,
    logs: job ? logs ?? [] : [],
  };
};

export default useGetJobLogs;
