import React from "react";
import { Icon } from "@blueprintjs/core";
import type { SandboxStatusV2 } from "../../../@types/sd/sandboxv2";
import styles from "./SandboxStatus.module.css";

interface SandboxStatusProps {
  status?: SandboxStatusV2;
}

const getStatusStyle = (status?: {
  progressing?: boolean;
  ready?: boolean;
}) => {
  if (!status) return styles.unknown;
  if (status.progressing) return styles.notReady;
  if (status.ready) return styles.ready;
  return styles.notReady;
};

const SandboxStatus: React.FC<SandboxStatusProps> = ({ status }) => (
  <Icon icon="dot" size={32} className={getStatusStyle(status)} />
);

export default SandboxStatus;
