import type { UsageBannerConfig } from "../utils";
import { INFO_THRESHOLD } from "../utils";

// eslint-disable-next-line import/prefer-default-export
export const constructBannerMessage = (
  bannerConfig: UsageBannerConfig
): string => {
  if (!bannerConfig.displayBanner) {
    // This function is expected to be called only when a banner is to be displayed.
    // So this should never happen, but still returning an empty string to be safe.
    return "";
  }
  const usages = [];
  if (
    bannerConfig.sandboxUsagePercentage &&
    bannerConfig.sandboxUsagePercentage! >= INFO_THRESHOLD
  ) {
    usages.push(`${bannerConfig.sandboxUsagePercentage}% of sandbox quota`);
  }
  if (
    bannerConfig.jobUsagePercentage &&
    bannerConfig.jobUsagePercentage! >= INFO_THRESHOLD
  ) {
    usages.push(`${bannerConfig.jobUsagePercentage}% of job quota`);
  }
  if (usages.length > 0) {
    return `Your organization has used up ${usages.join(", ")}.`;
  }
  return "";
};
