import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import React from "react";
import { formatDateTime, getTimeAgoFromNow } from "../../util/Util";

type TTLDeletionDescriptionProps = {
  deletionTime: string;
  description: string;
  templateKey?: string;
  inheritedFromCluster?: boolean;
};

// eslint-disable-next-line import/prefer-default-export
export const TTLDeletionDescription = ({
  deletionTime,
  description,
  templateKey = ":deletionTime",
  inheritedFromCluster,
}: TTLDeletionDescriptionProps) => {
  const formattedDeletionTime = formatDateTime(deletionTime);

  const templateStart = description.indexOf(templateKey);
  const templateEnd = templateStart + templateKey.length;

  const beforeTemplate = description.substring(0, templateStart);
  const afterTemplate = description.substring(templateEnd);

  return (
    <Popover2>
      {templateStart !== -1 ? (
        <>
          {beforeTemplate}
          <Tooltip2
            content={formattedDeletionTime}
            intent="none"
            hoverOpenDelay={250}
          >
            <span style={{ textDecoration: "underline", cursor: "pointer" }}>
              {getTimeAgoFromNow(deletionTime)}
            </span>
          </Tooltip2>
          {afterTemplate}
          {inheritedFromCluster && (
            <span className="ml-1">(cluster-wide default)</span>
          )}
        </>
      ) : (
        <span>{description}</span>
      )}
    </Popover2>
  );
};
