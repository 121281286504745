import React from "react";
import SdTheme from "../../styles/theme";

interface Props {
  title: string;
  value: React.ReactNode;
  fill?: true;
}

const DetailItem: React.FunctionComponent<Props> = ({ title, value, fill }) => (
  <p
    style={{ flex: fill ? "100%" : "50%", color: SdTheme.Text.lightBackground }}
  >
    <b>{title}</b>: {value}
  </p>
);

export default DetailItem;
