// eslint-disable-next-line import/prefer-default-export
export const TTL_POLICIES = {
  CREATED_AT: {
    value: "createdAt",
    label: "Created At",
  },
  UPDATED_AT: {
    value: "updatedAt",
    label: "Updated At",
  },
};
