import React from "react";
import { Icon } from "@blueprintjs/core";
import styles from "./BaseWidget.module.css";
import Status from "./Status/Status";

type BaseWidgetProps = {
  children: React.ReactNode | React.ReactNode[];
  title: string;
  iconName?: React.ComponentProps<typeof Icon>["icon"];
};

const BaseWidget = ({ title, iconName, children }: BaseWidgetProps) => (
  <div className={styles.container}>
    <div className={styles.title}>
      {iconName && <Icon icon={iconName} size={20} />}
      {title}
    </div>
    <div className={styles.body}>{children}</div>
  </div>
);

BaseWidget.Status = Status;

export default BaseWidget;
