import { useCallback } from "react";
import moment from "moment";
import useApiMutation from "../hooks/UseApiMutation";

export const ANALYTICS_API_NAME = "api:analytics";
export const EVENTS_API_NAME = "api:events";

type SubmitFunction = () => void;

interface AnalyticsApiProps {
  eventTypes: string[];
  startTime: Date;
  endTime: Date;
  filters?: string;
  interval?: string;
}

interface AnalyticsApiRequest {
  eventTypes: string[];
  startTime: string;
  endTime: string;
  filters?: string;
  interval?: string;
}

interface EventCount {
  eventType: string;
  count: number;
}

interface AnalyticsApiResponse {
  groups: {
    dimensions: Record<string, string>;
    entries: {
      start: string;
      end: string;
      counts: EventCount[];
    }[];
  }[];
  error: undefined;
}

export type AnalyticsApi =
  | {
      isLoading: true;
      error: undefined;
      data: undefined;
      submit: SubmitFunction;
    }
  | {
      isLoading: false;
      error: string;
      data: undefined;
      submit: SubmitFunction;
    }
  | {
      isLoading: false;
      error: undefined;
      data: AnalyticsApiResponse;
      submit: SubmitFunction;
    };

export const useAnalyticsApi = ({
  eventTypes,
  startTime,
  endTime,
  filters,
  interval,
}: AnalyticsApiProps): AnalyticsApi => {
  const api = useApiMutation<AnalyticsApiResponse, AnalyticsApiRequest>(
    ANALYTICS_API_NAME,
    "POST",
    []
  );
  const submit = useCallback(() => {
    api.mutate({
      url: `/api/v1/orgs/:orgName/analytics/events/query`,
      data: {
        eventTypes,
        interval,
        filters,
        // HACK: Javascript Date objects make it very hard to get an RFC3339 string out of them
        // that isn't automatically changing the day/hour fields in it based on the offset of the
        // local time zone to the UTC time zone. So we use moment here to take the literal
        // values for each field out of the Date object and present it as if it were in the UTC
        // time zone all along.
        startTime: moment(startTime).format("YYYY-MM-DD[T]HH:mm:00.000[Z]"),
        endTime: moment(endTime).format("YYYY-MM-DD[T]HH:mm:00.000[Z]"),
      },
    });
  }, [eventTypes, startTime, endTime, filters, interval]);

  if (api.isError) {
    return {
      isLoading: false,
      error: api.error?.response?.data?.error ?? "No response from server",
      data: undefined,
      submit,
    };
  }
  if (api.isLoading || !api.data) {
    return {
      isLoading: true,
      error: undefined,
      data: undefined,
      submit,
    };
  }
  return {
    isLoading: false,
    error: undefined,
    data: api.data.data,
    submit,
  };
};

interface EventsApiProps {
  eventTypes: string[];
  startTime: Date;
  endTime: Date;
  filters?: string;
  limit?: number;
}

interface EventsApiRequest {
  eventTypes: string[];
  startTime: string;
  endTime: string;
  filters?: string;
  limit?: number;
}

interface EventsApiResponse {
  events: {
    timestamp: string;
    eventType: string;
    data: {
      apiUsed: string;
      clusterID: string;
      clusterName: string;
      sandboxID: string;
      sandboxName: string;
      labels?: Record<string, string>;
    };
  }[];
}

export type EventsApi =
  | {
      isLoading: true;
      error: undefined;
      data: undefined;
      submit: SubmitFunction;
    }
  | {
      isLoading: false;
      error: string;
      data: undefined;
      submit: SubmitFunction;
    }
  | {
      isLoading: false;
      error: undefined;
      data: EventsApiResponse;
      submit: SubmitFunction;
    };

export const useEventsApi = ({
  eventTypes,
  startTime,
  endTime,
  filters,
  limit,
}: EventsApiProps): EventsApi => {
  const api = useApiMutation<EventsApiResponse, EventsApiRequest>(
    EVENTS_API_NAME,
    "POST",
    []
  );
  const submit = useCallback(() => {
    api.mutate({
      url: `/api/v1/orgs/:orgName/events/query`,
      data: {
        eventTypes,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        filters,
        limit,
      },
    });
  }, [eventTypes, startTime, endTime, filters, limit]);

  if (api.isError) {
    return {
      isLoading: false,
      error: api.error?.response?.data?.error ?? "No response from server",
      data: undefined,
      submit,
    };
  }
  if (api.isLoading || !api.data) {
    return {
      isLoading: true,
      error: undefined,
      data: undefined,
      submit,
    };
  }
  return {
    isLoading: false,
    error: undefined,
    data: api.data.data,
    submit,
  };
};
