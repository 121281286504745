import React from "react";
import { Card, Dialog, Elevation, FormGroup } from "@blueprintjs/core";
import { SdInputGroup } from "../../../../components/theming/SdInput";
import SdButton from "../../../../components/theming/SdButton";
import styles from "./SaveRequestDialog.module.css";

interface Props {
  name: string;
  setName: (name: string) => void;
  showDialog: boolean;
  setShowDialog: (show: boolean) => void;
  handleSaveRequest: () => void;
}

const SaveRequestDialog: React.FC<Props> = ({
  name,
  setName,
  showDialog,
  setShowDialog,
  handleSaveRequest,
}) => (
  <Dialog
    icon="info-sign"
    title="Save Request"
    isOpen={showDialog}
    onClose={() => {
      setName("");
      setShowDialog(false);
    }}
  >
    <Card className={styles.card} interactive elevation={Elevation.TWO}>
      <div>
        <p>Provide name for the request.</p>
        <FormGroup>
          <SdInputGroup
            id="name"
            value={name}
            onChange={(evt) => setName(evt.target.value)}
            className={styles.inputName}
          />
          <SdButton
            icon="plus"
            onClick={() => {
              handleSaveRequest();
              setShowDialog(false);
            }}
          >
            Save
          </SdButton>
        </FormGroup>
      </div>
    </Card>
  </Dialog>
);

export default SaveRequestDialog;
