import type { ApiMutationResult } from "../hooks/UseApiMutation";
import useApiMutation from "../hooks/UseApiMutation";
import useApi from "../hooks/UseApi";
import type { PlaygroundStatus } from "../@types/sd/playground";

export const Healthy = "Healthy";
export const Progressing = "Progressing";
export const Missing = "Missing";
export const Degraded = "Degraded";

export const CREATE_PLAYGROUND_API_NAME = "api_mutation:create_playground";
export const GET_PLAYGROUND_KUBECONFIG = "api:get_playground_kubeconfig";
export const GET_PLAYGROUND_STATUS = "api:get_playground_status";

const standardQueryConfig = {
  retry: 1,
  refetchInterval: 30000,
};

// types
export type Empty = Record<string, unknown>;

export interface CreatePlaygroundResponse {
  clusterID: string;
}

type CreatePlaygroundApiReturn = [
  () => void,
  ApiMutationResult<CreatePlaygroundResponse, Empty>
];

export const useGetPlaygroundApi = (name: string, options = {}) =>
  useApi(
    GET_PLAYGROUND_KUBECONFIG,
    `/api/v2/orgs/:orgName/clusters/playground/${name}/kubeconfig`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );

export const useGetPlaygroundStatusApi = (name: string, options = {}) =>
  useApi<PlaygroundStatus>(
    GET_PLAYGROUND_STATUS,
    `/api/v2/orgs/:orgName/clusters/playground/${name}/status`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );

export const useCreatePlaygroundApi = (): CreatePlaygroundApiReturn => {
  const mutation = useApiMutation<CreatePlaygroundResponse, Empty>(
    CREATE_PLAYGROUND_API_NAME,
    "POST",
    ["clusters"]
  );
  return [
    () => {
      mutation.mutate({
        url: `/api/v2/orgs/:orgName/clusters/playground`,
        data: {},
      });
    },
    mutation,
  ];
};
