import React from "react";
import type { TrafficDiffFinding } from "../../../../../@types/sd/findings";
import Finding from "./Finding";

interface Props {
  findings: TrafficDiffFinding[];
}

const FlatView: React.FC<Props> = ({ findings }) => {
  if (findings.length > 0) {
    return (
      <div>
        {findings.map((finding, idx) => (
          <Finding
            key={`finding-${finding.pairingID}-${idx}`}
            finding={finding}
          />
        ))}
      </div>
    );
  }
  return <div>No findings</div>;
};

export default FlatView;
