import yaml from "js-yaml";
import React from "react";
import type { RouteGroup } from "../../../../@types/sd/routegroup";
import ViewSpec from "../../../../components/theming/ViewSpec/ViewSpec";

type RouteGroupSpecProps = {
  routeGroup: RouteGroup;
};

const RouteGroupSpec: React.FC<RouteGroupSpecProps> = ({ routeGroup }) => (
  <ViewSpec
    yaml={yaml.dump({
      name: routeGroup.name,
      spec: routeGroup.spec,
    })}
  />
);

export default RouteGroupSpec;
