import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { GrDocumentTest } from "react-icons/gr";
import styles from "./EmptyExecutions.module.css";
import CreateFirst from "../../../../../components/CreateFirst";

const ContainerMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const EmptyExecutions = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <CreateFirst
        icon={<GrDocumentTest size={80} />}
        buttonLabel="Go to Tests"
        onClick={() => navigate("/testing/tests")}
        message={
          <ContainerMessage>
            <p>
              No Tests were run. For more detailed information about how tests
              works visit the
              <a
                href="https://www.signadot.com/docs/reference/smart-tests"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                official documentation.
              </a>
            </p>
          </ContainerMessage>
        }
      />
    </div>
  );
};

export default EmptyExecutions;
