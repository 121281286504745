import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  ButtonGroup,
  Intent,
  OverlayToaster,
} from "@blueprintjs/core";
import _ from "lodash";
import { GiPlug } from "react-icons/gi";
import {
  useDeleteResourcePlugin,
  useResourcePlugins,
} from "../../api/ResourcePluginsApi";
import PaginatedTable from "../../components/PaginatedTable/PaginatedTable";
import CreateFirst from "../../components/CreateFirst";
import { SdHeading1 } from "../../components/theming/SdHeading";

const toaster = OverlayToaster.create();

const columns = [
  {
    Header: "Name",
    accessor: "resourcePluginName",
    search: true,
  },
  {
    Header: "Resource count",
    accessor: "resourceCount",
  },
  {
    Header: "Action",
    accessor: "actions",
  },
];

interface FormattedResourcePlugin {
  resourcePluginName: string;
  resourceCount: number;
}

const ResourcePlugins = () => {
  const deleteResourcePluginApi = useDeleteResourcePlugin();
  const [selectedResourcePluginName, setSelectedResourcePluginName] =
    useState("");
  const [isDeleteResourcePluginOpen, setDeleteResourcePluginOpen] =
    useState(false);

  const navigate = useNavigate();
  const pluginsInfo = useResourcePlugins({});

  // Deletion handlers.
  const handleConfirmDeleteResourcePlugin = useCallback((name: string) => {
    setSelectedResourcePluginName(name);
    setDeleteResourcePluginOpen(true);
  }, []);
  const handleCancelDeleteResourcePlugin = useCallback(() => {
    setSelectedResourcePluginName("");
    setDeleteResourcePluginOpen(false);
  }, []);
  const handleDeleteResourcePlugin = useCallback(() => {
    deleteResourcePluginApi.mutate({
      url: `/api/v2/orgs/:orgName/resource-plugins/${selectedResourcePluginName}`,
    });
    setSelectedResourcePluginName("");
    setDeleteResourcePluginOpen(false);
  }, [selectedResourcePluginName]);

  if (deleteResourcePluginApi.error) {
    toaster.show({
      message: _.get(deleteResourcePluginApi.error, "response.data.error"),
      intent: Intent.DANGER,
    });
    deleteResourcePluginApi.reset();
  } else if (deleteResourcePluginApi.isSuccess) {
    toaster.show({
      message: "Resource Plugin deleted",
      intent: Intent.SUCCESS,
    });
    deleteResourcePluginApi.reset();
  }

  const plugins: FormattedResourcePlugin[] = useMemo(() => {
    const res = pluginsInfo.data;
    if (!Array.isArray(res) || res.length === 0) {
      return [];
    }
    return res.map((rp) => {
      let count = 0;
      if (rp.status && Array.isArray(rp.status.resources)) {
        count = rp.status.resources.length;
      }
      return {
        resourcePluginName: rp.name,
        resourceCount: count,
        actions: (
          <ButtonGroup>
            <Button
              minimal
              icon="trash"
              onClick={() => {
                handleConfirmDeleteResourcePlugin(rp.name);
              }}
            />
          </ButtonGroup>
        ),
      };
    });
  }, [pluginsInfo.data]);

  if (pluginsInfo.isLoading) {
    return (
      <SdHeading1 small lightBackground>
        Resource Plugins
      </SdHeading1>
    );
  }

  const onRowClick = (row: FormattedResourcePlugin) => {
    navigate(`/resource-plugins/name/${row.resourcePluginName}`);
  };

  return (
    <>
      <SdHeading1 small lightBackground>
        Resource Plugins
      </SdHeading1>
      {plugins.length === 0 ? (
        <CreateFirst
          icon={<GiPlug size={80} />}
          message="No Resource Plugins created. Check the documentation on how to create it."
          buttonLabel="Resource Plugin Documentation"
          onClick={() => {
            window.location.href =
              "https://www.signadot.com/docs/reference/resource-plugins";
          }}
        />
      ) : (
        <PaginatedTable
          columns={columns}
          data={plugins}
          onRowClickCallback={onRowClick}
        />
      )}

      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Delete Resource Plugin"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={isDeleteResourcePluginOpen}
        onCancel={handleCancelDeleteResourcePlugin}
        onConfirm={handleDeleteResourcePlugin}
      >
        <div>
          <p>
            Are you sure you want to delete <b>{selectedResourcePluginName}</b>
          </p>
        </div>
      </Alert>
    </>
  );
};

export default ResourcePlugins;
