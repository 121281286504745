import React, { useState } from "react";
import { Icon } from "@blueprintjs/core";
import styles from "./ButtonGroup.module.css";
import SdButton from "../../SdButton";
import type { EventName } from "../../../../Constants";

interface ButtonProps {
  id: string;
  label: string;
  onClick: () => void;
  eventName: EventName;
  disabled?: boolean;
}

interface Props {
  allButtonProps: ButtonProps[];
}

const ButtonGroup: React.FC<Props> = ({ allButtonProps }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [lastSelectedButtonId, setLastSelectedButtonId] = useState<
    string | undefined
  >(undefined);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleButtonClick = (clickedButtonProps: ButtonProps) => {
    setIsOpen(false);
    setLastSelectedButtonId(clickedButtonProps.id);
    clickedButtonProps.onClick();
  };

  const stickyButton: ButtonProps | null = React.useMemo(() => {
    if (!allButtonProps || allButtonProps.length === 0) return null;
    if (!lastSelectedButtonId) return allButtonProps[0];
    const selectedButton = allButtonProps.find(
      (buttonProps) => buttonProps.id === lastSelectedButtonId
    );
    return selectedButton || allButtonProps[0];
  }, [allButtonProps, lastSelectedButtonId]);

  return (
    <div className={styles.container}>
      {stickyButton && (
        <SdButton
          eventName={stickyButton.eventName}
          onClick={() => handleButtonClick(stickyButton)}
          disabled={stickyButton.disabled}
          className={styles.btn}
        >
          {stickyButton.label}
        </SdButton>
      )}
      <button
        type="button"
        onClick={toggleDropdown}
        className={styles.dropdownButton}
      >
        <Icon icon="chevron-down" />
      </button>
      {isOpen && (
        <ul className={styles.dropdownMenu}>
          {allButtonProps.map((button) => (
            <li key={button.id} className={styles.item}>
              <SdButton
                eventName={button.eventName}
                onClick={() => handleButtonClick(button)}
                disabled={button.disabled}
              >
                {button.label}
              </SdButton>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ButtonGroup;
