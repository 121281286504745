import { Button, Icon, Tag as BaseTag } from "@blueprintjs/core";
import React from "react";
import SdColors from "../../../styles/colors";

interface Props {
  text: React.ReactNode;
  onClose?: () => void;
  position?: "left" | "right";
}

const Tag: React.FunctionComponent<Props> = ({
  text,
  onClose,
  position = "right",
}) => (
  <BaseTag
    round
    style={{
      backgroundColor: SdColors.PRIMARY700,
      paddingLeft: position === "right" ? 17 : 0,
      paddingRight: position === "left" ? 17 : 0,
    }}
  >
    {position === "left" && (
      <Button minimal onClick={onClose}>
        <Icon icon="cross" style={{ color: "white" }} />{" "}
      </Button>
    )}
    {text}
    {position === "right" && (
      <Button minimal onClick={onClose}>
        {" "}
        <Icon icon="cross" style={{ color: "white" }} />
      </Button>
    )}
  </BaseTag>
);

export default Tag;
