import { useMemo } from "react";
import useApi from "../../hooks/UseApi";

export type OperatorVersion = {
  major: number;
  minor: number;
  bugfix: number;
};

type LatestOperatorVersionData = {
  version: OperatorVersion | null;
  releaseNotes: string | null;
  error: string | null;
};

interface LatestOperatorVersionApiResponse {
  version: string;
  releaseNotes: string;
}

const parseOperatorVersion = (operatorVersion: string): OperatorVersion => {
  // eslint-disable-next-line prefer-const
  let [major, minor, bugfix] = operatorVersion.split(".", 3);
  const hashStart = bugfix.indexOf("-");
  // We have no way to determine if any given hash is for an earlier or later operator.
  // So we ignore it and only consider the major, minor, and bugfix numbers.
  if (hashStart > -1) {
    bugfix = bugfix.substring(0, hashStart);
  }
  return {
    major: parseInt(major, 10),
    minor: parseInt(minor, 10),
    bugfix: parseInt(bugfix, 10),
  };
};

const useLatestOperatorVersion = (): LatestOperatorVersionData => {
  const { isSuccess, error, data } = useApi<LatestOperatorVersionApiResponse>(
    "latest_operator_version",
    `/api/v1/vars/latest-operator-version`,
    {
      retry: 1,
    }
  );
  const operatorVersion = useMemo(() => {
    if (!data) {
      return null;
    }
    return parseOperatorVersion(data!.version);
  }, [data]);
  if (error) {
    return {
      version: null,
      releaseNotes: null,
      error: error.message,
    };
  }
  if (!isSuccess) {
    return {
      version: null,
      releaseNotes: null,
      error: null,
    };
  }
  return {
    version: operatorVersion,
    releaseNotes: data!.releaseNotes,
    error: null,
  };
};

const isObservedVersionLatest = (
  observed: OperatorVersion,
  latest: OperatorVersion
): boolean => {
  if (observed.major < latest.major) {
    return false;
  }
  if (observed.minor < latest.minor) {
    return false;
  }
  return observed.bugfix >= latest.bugfix;
};

export {
  useLatestOperatorVersion,
  parseOperatorVersion,
  isObservedVersionLatest,
};
