import type { Monaco } from "@monaco-editor/react";
import type { ILanguageDefinition } from "../language";
import { BASE_DARK_THEME, SIGNADOT_DARK_THEME } from "../../constants";
import { LANGUAGE_NAME } from "./constants";
import language from "./base";

const setCustomTheme = (monaco: Monaco) => {
  monaco.languages.setMonarchTokensProvider(LANGUAGE_NAME, language);

  monaco.editor.defineTheme(SIGNADOT_DARK_THEME, {
    colors: {},
    encodedTokensColors: [],
    base: BASE_DARK_THEME,
    inherit: true,
    rules: [
      {
        token: "http-method",
        foreground: "#ff0000",
        fontStyle: "bold",
      },
    ],
  });
};

const pythonImpl: ILanguageDefinition = {
  beforeMount(monaco) {
    setCustomTheme(monaco);
  },
  // TODO: Enable once we know how to proceed and we have clearer expectations
  // onMonacoAvailable(monaco: Monaco) {
  //   setTestAutocomplete(monaco);
  // },
};

export default pythonImpl;
