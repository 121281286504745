import React from "react";
import DOMPurify from "dompurify";
import { marked } from "marked";
import styles from "./MarkdownViewer.module.css";

interface MarkdownViewerProps {
  title?: string;
  content: string;
}

const MarkdownViewer: React.FC<MarkdownViewerProps> = ({ title, content }) => (
  <div className={styles.container}>
    {title && <div className={styles.title}>{title}</div>}
    <div
      className={styles.markdownViewer}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(marked.parse(content), {
          USE_PROFILES: { html: true },
        }),
      }}
    />
  </div>
);

export default MarkdownViewer;
