import type { ApiMutationResult } from "../../hooks/UseApiMutation";
import useApiMutation from "../../hooks/UseApiMutation";

interface ClusterTokenResponse {
  token: string;
}

export interface MutationResult {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: {
    token: string;
  };
  error: {
    code: number;
    detail: string;
  };
  reset(): void;
}

type TokenCallback = (token: string) => void;
type TokenCreatorResult = [
  ApiMutationResult<ClusterTokenResponse>,
  (clusterName: string) => void
];

const useClusterTokenCreator = (
  callback?: TokenCallback
): TokenCreatorResult => {
  const mutation = useApiMutation<ClusterTokenResponse>(
    "create_cluster_token",
    "POST",
    ["clusterTokens"],
    (response) =>
      callback && response && response.token && callback(response.token)
  );
  return [
    mutation,
    (clusterName: string) => {
      mutation.mutate({
        url: `/api/v1/orgs/:orgName/clusters/${clusterName}/tokens`,
        data: {},
      });
    },
  ];
};

export default useClusterTokenCreator;
