import { useEffect, useState } from "react";

export const PREFERENCES_NAMES = {
  COLLAPSE_SIDEBAR: "collapse-sidebar",
  DEBUG_SMART_TEST: "smart-tests/debug",
} as const;

type CurrentSmartTestDefinition = {
  testName?: string;
  executionName?: string;
  targetJobName?: string;
  ranAt?: Date;
};

type LocalStorageTypeMapping = {
  [PREFERENCES_NAMES.COLLAPSE_SIDEBAR]: boolean;
  [PREFERENCES_NAMES.DEBUG_SMART_TEST]: CurrentSmartTestDefinition;
};

type PreferencesState = {
  [K in keyof typeof PREFERENCES_NAMES as (typeof PREFERENCES_NAMES)[K]]: LocalStorageTypeMapping[(typeof PREFERENCES_NAMES)[K]];
};

const getStringValue = (value: unknown): string => {
  switch (typeof value) {
    case "object":
      return JSON.stringify(value);
    default:
      return String(value);
  }
};

export default () => {
  const [preferences, setPreferences] = useState<PreferencesState>({
    [PREFERENCES_NAMES.COLLAPSE_SIDEBAR]: false,
    [PREFERENCES_NAMES.DEBUG_SMART_TEST]: {},
  });

  useEffect(() => {
    const loadPreferences = () => {
      const collapsedSidebar = localStorage.getItem(
        PREFERENCES_NAMES.COLLAPSE_SIDEBAR
      );

      const rawCurrentDebugSmartTest = localStorage.getItem(
        PREFERENCES_NAMES.DEBUG_SMART_TEST
      );

      let currentDebugSmartTest: CurrentSmartTestDefinition = {};
      if (rawCurrentDebugSmartTest) {
        currentDebugSmartTest = JSON.parse(rawCurrentDebugSmartTest);
      }

      setPreferences((prevState) => ({
        ...prevState,
        [PREFERENCES_NAMES.COLLAPSE_SIDEBAR]: collapsedSidebar === "true",
        [PREFERENCES_NAMES.DEBUG_SMART_TEST]: currentDebugSmartTest,
      }));
    };

    // Initialize preferences from localStorage
    loadPreferences();

    // Set up a listener for storage events if preferences can be changed across tabs
    const onStorageChange = (event: StorageEvent) => {
      if (
        (
          [
            PREFERENCES_NAMES.DEBUG_SMART_TEST,
            PREFERENCES_NAMES.COLLAPSE_SIDEBAR,
          ] as Array<string>
        ).includes(event.key || "")
      ) {
        loadPreferences();
      }
    };

    window.addEventListener("storage", onStorageChange);

    return () => {
      window.removeEventListener("storage", onStorageChange);
    };
  }, []);

  const setPreference = <K extends keyof PreferencesState>(
    name: K,
    value:
      | PreferencesState[K]
      | ((val: PreferencesState[K]) => PreferencesState[K])
  ) => {
    const newValue =
      typeof value === "function" ? value(preferences[name]) : value;
    localStorage.setItem(name, getStringValue(newValue));
    setPreferences((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  return {
    values: preferences,
    setPreference,
  };
};
