import { useMemo } from "react";
import useApi from "../hooks/UseApi";
import type { Patch } from "../@types/sd/patch";

type PatchesSearch = {
  type?: string;
  name?: string;
};

interface PatchSearchResponse {
  patches: Patch[];
}

export const SEARCH_PATCHES_API_NAME = "api:search_patches";

export const usePatchesApi = (
  sandboxId: string,
  searchParams: PatchesSearch,
  options = {}
) => {
  const apiName = useMemo(() => {
    if (!searchParams) {
      return [SEARCH_PATCHES_API_NAME, sandboxId];
    }
    const keys = Object.keys(searchParams);
    keys.sort();
    const name = [SEARCH_PATCHES_API_NAME, sandboxId];
    keys.forEach((key) => name.push(searchParams[key]));
    return name;
  }, [searchParams]);
  return useApi<PatchSearchResponse>(
    apiName,
    `/api/v1/orgs/:orgName/sandboxes/${sandboxId}/patches?${new URLSearchParams(
      searchParams
    )}`,
    options
  );
};
