import type { ReactNode } from "react";
import React from "react";
import ActionLink from "../../../../components/theming/ActionLink";
import styles from "../../../../components/Layout/Layout.module.css";
import {
  OPEN_IN_NEW_TAB,
  SmartTestsAnnouncementClick,
} from "../../../../Constants";

export interface AnnouncementData {
  content: ReactNode;
  featureFlagName?: string;
}

const defaultItems: AnnouncementData[] = [
  {
    content: (
      <div>
        <b>🚀 Run AI-powered </b>{" "}
        <ActionLink
          className={styles.link}
          eventName={SmartTestsAnnouncementClick}
          onClick={() =>
            window.open(
              "https://signadot.com/docs/guides/use-cases/run-smart-tests",
              OPEN_IN_NEW_TAB
            )
          }
        >
          Smart Integration Tests
        </ActionLink>{" "}
        on Pull Requests
      </div>
    ),
  },
];

export default defaultItems;
