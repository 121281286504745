import SdColors from "./colors";

const SdTheme = {
  Background: {
    light: "white",
    dark: SdColors.PRIMARY900,
  },
  Card: {
    Background: {
      light: "white",
      dark: SdColors.PRIMARY900,
    },
  },
  Menu: {
    background: SdColors.PRIMARY500,
    inactive: SdColors.PRIMARY500,
    active: SdColors.PRIMARY300,
  },
  Table: {
    header: SdColors.PRIMARY500,
    background: "white",
    hovered: SdColors.NEUTRAL200,
  },
  Status: {
    good: SdColors.SUCCESS500,
    warning: SdColors.WARNING500,
    bad: SdColors.ERROR700,
    neutral: SdColors.NEUTRAL700,
  },
  Icon: {
    darkBackground: SdColors.NEUTRAL50,
    lightBackground: SdColors.NEUTRAL700,
  },
  Text: {
    Subtle: {
      lightBackground: SdColors.NEUTRAL400,
      darkBackground: SdColors.NEUTRAL500,
    },
    darkBackground: SdColors.NEUTRAL50,
    lightBackground: SdColors.NEUTRAL700,
  },
  Link: {
    darkBackground: SdColors.SECONDARY500,
    lightBackground: SdColors.PRIMARY500,
  },
  Input: {
    text: SdColors.NEUTRAL700,
    background: SdColors.NEUTRAL50,
  },
  Chart: {
    text: SdColors.NEUTRAL700,
    data: SdColors.PRIMARY500,
    color1: SdColors.PRIMARY900,
    color2: SdColors.PRIMARY300,
  },
  Button: {
    Normal: {
      Disabled: {
        background: SdColors.PRIMARY300,
        text: SdColors.NEUTRAL50,
      },
      Neutral: {
        background: SdColors.PRIMARY500,
        text: SdColors.NEUTRAL50,
      },
      Hover: {
        background: SdColors.PRIMARY700,
        text: SdColors.NEUTRAL50,
      },
    },
    Danger: {
      Disabled: {
        background: SdColors.ERROR300,
        text: SdColors.NEUTRAL50,
      },
      Neutral: {
        background: "#cd4246",
        text: SdColors.NEUTRAL50,
      },
      Hover: {
        background: SdColors.ERROR700,
        text: SdColors.NEUTRAL50,
      },
    },
    Minimal: {
      Disabled: {
        background: SdColors.NEUTRAL100,
        text: SdColors.NEUTRAL400,
      },
      Neutral: {
        background: SdColors.NEUTRAL200,
        text: SdColors.NEUTRAL700,
      },
      Hover: {
        background: SdColors.NEUTRAL300,
        text: SdColors.NEUTRAL700,
      },
    },
    text: SdColors.NEUTRAL50,
    icon: SdColors.NEUTRAL50,
  },
};

export default SdTheme;
