import type { ApiMutationResult } from "../hooks/UseApiMutation";
import useApiMutation from "../hooks/UseApiMutation";

export const CREATE_ORG_API_NAME = "api:create_org";

// API: Create Org
interface CreateOrgRequest {
  orgDisplayName: string;
  firstName: string;
  lastName: string;
}

interface CreateOrgResponse {
  publicId: string;
}

type CreateOrgApiReturn = [
  (data: CreateOrgRequest) => void,
  ApiMutationResult<CreateOrgResponse, CreateOrgRequest>
];

export const useCreateOrgApi = (): CreateOrgApiReturn => {
  const mutation = useApiMutation<CreateOrgResponse, CreateOrgRequest>(
    CREATE_ORG_API_NAME,
    "POST",
    []
  );
  return [
    (data: CreateOrgRequest) => {
      mutation.mutate({
        url: `/api/v1/setup/orgs`,
        data,
      });
    },
    mutation,
  ];
};
