import { useMemo } from "react";
import useApi from "../hooks/UseApi";
import type {
  Resource,
  ResourceV1,
  ResourceV1RuntimeInfo,
} from "../@types/sd/resource";
import { convertLegacyResource } from "../util/StatusUtil/StatusUtils";

const V1_RESOURCE_VERSION = "alpha1";
const V2_RESOURCE_VERSION = "v1";

type SearchResourcesParams = {
  sandboxID?: string;
  resourceName?: string;
  clusterName?: string;
  pluginUID?: string;
};

export interface ListResourcesResponse {
  version: string;
  resources?: Resource[] | ResourceV1RuntimeInfo[];
}

export interface TransformedListResourcesResponse {
  version: string;
  resources?: Resource[];
}

interface SearchLegacyResourcesResponse {
  version: string;
  resources: ResourceV1[];
}

const standardQueryConfig = {
  retry: 1,
  refetchInterval: 5000,
};

export const SEARCH_RESOURCES_API_NAME = "api:search_resources";

export const useSearchResources = (
  searchParams: SearchResourcesParams,
  options = {}
) => {
  const apiName = useMemo(() => {
    const keys = Object.keys(searchParams);
    keys.sort();
    const name = [SEARCH_RESOURCES_API_NAME];
    keys.forEach((key) => name.push(searchParams[key]));
    return name;
  }, [searchParams]);
  return useApi<SearchLegacyResourcesResponse>(
    apiName,
    `/api/v1/orgs/:orgName/resources?${new URLSearchParams(
      searchParams
    ).toString()}`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );
};

export const useSandboxResources = (
  sandbox?: string,
  clusterName?: string,
  options = {}
) =>
  useApi<ListResourcesResponse, TransformedListResourcesResponse>(
    "get-sandbox-resources",
    `/api/v2/orgs/:orgName/sandboxes/${sandbox}/resources`,
    {
      ...standardQueryConfig,
      ...options,
    },
    (original: ListResourcesResponse): TransformedListResourcesResponse => {
      if (original.version === V1_RESOURCE_VERSION) {
        const out: TransformedListResourcesResponse = {
          version: V2_RESOURCE_VERSION,
          resources: original.resources?.map((res) =>
            convertLegacyResource(res as ResourceV1RuntimeInfo, clusterName)
          ),
        };
        return out;
      }
      return original as TransformedListResourcesResponse;
    }
  );
