import type { RouteGroup } from "../@types/sd/routegroup";
import useApi from "../hooks/UseApi";
import useApiMutation from "../hooks/UseApiMutation";

export const ROUTE_GROUPS_API_NAME = "api:route_groups";
export const ROUTE_GROUP_BY_NAME_API_NAME = "api:route_group_by_name";
export const SAVE_ROUTE_GROUPS_API_NAME = "api_mutation:route_group";
export const DELETE_ROUTE_GROUP_API_NAME = "api_mutation:delete_route_group";

export type CreateRouteGroupRequest = Omit<
  RouteGroup,
  "createdAt" | "routingKey" | "endpoints" | "status" | "updatedAt"
>;

// There's a possibility that a RouteGroup could have been defined with just the label
// field in the match object being filled out, instead of the all/any arrays. Because
// the label field being filled is the same as a single entry in either the all or any
// arrays, we can move that entry to the all array without any behavior changes on
// the backend. This keeps the UI simple, only having to work with all/any types and
// not having to deal with the label field at all.
const normalizeRouteGroup = (rg: RouteGroup) => {
  if (rg.spec.match && rg.spec.match.label) {
    rg.spec.match.all = [
      {
        label: rg.spec.match.label,
      },
    ];
    rg.spec.match.label = undefined;
  }
};

export const useRouteGroupsApi = () => {
  const api = useApi<RouteGroup[]>(
    ROUTE_GROUPS_API_NAME,
    `/api/v2/orgs/:orgName/routegroups`,
    {
      retry: 1,
      refetchInterval: 30000,
    }
  );
  if (api.data) {
    for (let i = 0; i < api.data.length; i++) {
      normalizeRouteGroup(api.data[i]);
    }
  }
  return api;
};

export const useRouteGroupByNameApi = (name: string) => {
  const api = useApi<RouteGroup>(
    [ROUTE_GROUP_BY_NAME_API_NAME, name],
    `/api/v2/orgs/:orgName/routegroups/${name}`,
    {
      retry: 1,
      refetchInterval: 30000,
    }
  );
  if (api.data) {
    normalizeRouteGroup(api.data);
  }
  return api;
};

export const useSaveRouteGroupApi = () =>
  useApiMutation<unknown, CreateRouteGroupRequest>(
    SAVE_ROUTE_GROUPS_API_NAME,
    "PUT",
    [ROUTE_GROUPS_API_NAME, ROUTE_GROUP_BY_NAME_API_NAME]
  );

export const useDeleteRouteGroupApi = () =>
  useApiMutation(DELETE_ROUTE_GROUP_API_NAME, "DELETE", [
    ROUTE_GROUPS_API_NAME,
    ROUTE_GROUP_BY_NAME_API_NAME,
  ]);
