const INVITE_CODE_KEY = "inviteCode";

interface InstallationsResponse {
  githubOrg?: string;
  signadotOrgCreated?: boolean;
}

export const HOMEPAGE = "/";
export const POST_INSTALLATION_PAGE = "/invitation/post-install";

export const signadotAppInstalled = (data: InstallationsResponse) =>
  data.githubOrg && data.githubOrg.length > 0;

export const signadotOrgCreated = (data: InstallationsResponse) =>
  data.signadotOrgCreated === true;

export const setInviteCodeToLocalStorage = (inviteCode: string) =>
  localStorage.setItem(INVITE_CODE_KEY, inviteCode);

export const getInviteCodeFromLocalStorage = () =>
  localStorage.getItem(INVITE_CODE_KEY);

export const clearInviteCodeFromLocalStorage = () =>
  localStorage.removeItem(INVITE_CODE_KEY);

export const redirectTo = (url: string) => {
  window.location.href = url;
};
