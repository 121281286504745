import React from "react";
import style from "./ExecutionTitle.module.css";

type ExecutionTitleProps = {
  title: string;
};

const ExecutionTitle = ({ title }: ExecutionTitleProps) => (
  <div className={style.container}>
    <p>{title}</p>
  </div>
);

export default ExecutionTitle;
