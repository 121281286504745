import type { FindingDiffOp } from "../../../../@types/sd/findings";
import {
  isPrimaryQueryParametersKind,
  isRequestBodyKind,
  isRequestHeaderKind,
  isRequestMetadataKind,
  isResponseBodyKind,
  isResponseHeaderKind,
  isResponseMetadataKind,
} from "../util";
import type {TrafficDiffSummary} from "../../../../@types/sd/testexecutions";

export interface StructuredDiffOps {
  extra: {
    statusCode?: FindingDiffOp;
    queryParameters?: FindingDiffOp;
  };
  request: {
    header: FindingDiffOp[];
    body: FindingDiffOp[];
    metadata: FindingDiffOp[];
  };
  response: {
    header: FindingDiffOp[];
    body: FindingDiffOp[];
    metadata: FindingDiffOp[];
  };
}

export const restructureDiffs = (diffs: FindingDiffOp[]): StructuredDiffOps => {
  const structuredDiffs: StructuredDiffOps = {
    extra: {},
    request: {
      header: [],
      body: [],
      metadata: [],
    },
    response: {
      header: [],
      body: [],
      metadata: [],
    },
  };

  if (diffs.length === 0) return structuredDiffs;

  const pathsWithBrackets = {
    "/request/message/[body]": structuredDiffs.request.body,
    "/response/message/[body]": structuredDiffs.response.body,
  };

  diffs.forEach((diff) => {
    let set = false;
    Object.entries(pathsWithBrackets).forEach(([pathPrefix]) => {
      if (diff.path.startsWith(pathPrefix)) {
        set = true;

        // Directly amend and push to the target array
        pathsWithBrackets[pathPrefix].push({
          ...diff,
          path: diff.path.replace(
            pathPrefix.slice(0, pathPrefix.indexOf("/[body]")),
            ""
          ),
        });
      }
    });

    if (set) return;

    if (isRequestMetadataKind(diff)) {
      structuredDiffs.request.metadata.push({
        ...diff,
        path: diff.path.replace("/request/message/", ""),
      });
      return;
    }

    if (isRequestHeaderKind(diff)) {
      structuredDiffs.request.header.push({
        ...diff,
        path: diff.path.replace("/request/message/headers", ""),
      });
      return;
    }

    if (isRequestBodyKind(diff)) {
      structuredDiffs.request.body.push({
        ...diff,
        path: diff.path.replace("/request/message/body", ""),
      });
      return;
    }

    if (isResponseMetadataKind(diff)) {
      if (diff.isPrimary && diff.path.startsWith("/response/statusCode")) {
        structuredDiffs.extra.statusCode = {
          ...diff,
          path: diff.path.replace("/response/statusCode", ""),
        };
        return;
      }

      structuredDiffs.response.metadata.push({
        ...diff,
        path: diff.path.replace("/response/message", ""),
      });
      return;
    }

    if (isResponseHeaderKind(diff)) {
      structuredDiffs.response.header.push({
        ...diff,
        path: diff.path.replace("/response/message/headers", ""),
      });
      return;
    }

    if (isResponseBodyKind(diff)) {
      structuredDiffs.response.body.push({
        ...diff,
        path: diff.path.replace("/response/message/body", ""),
      });

      return;
    }

    if (diff.isPrimary && isPrimaryQueryParametersKind(diff)) {
      structuredDiffs.extra.queryParameters = {
        ...diff,
        path: diff.path.replace("/request/query", ""),
      };
    }
  });

  return structuredDiffs;
};

export const getCountOfSummary = (summary?: TrafficDiffSummary): number => {
  if (!summary) return 0;

  return summary.removals + summary.replacements + summary.additions;
};
