import { useMemo } from "react";
import type { ApiError } from "../../../../hooks/UseApi";
import useApi from "../../../../hooks/UseApi";
import {
  isObservedVersionLatest,
  parseOperatorVersion,
  useLatestOperatorVersion,
} from "../../../../api/OperatorVersion";

interface ClusterStatusResponse {
  Statuses: {
    status: {
      healthy: boolean;
      reason: string;
      message: string;
    };
  }[];
}

interface ClustersResponse {
  clusters: {
    operatorVersion?: string;
  }[];
}

type Status =
  | {
      isLoading: true;
      isError: false;
    }
  | {
      error: ApiError | string;
      isError: true;
      isLoading: false;
}
  | {
      isError: false;
      totalClusters: number;
      unhealthy: number;
      pendingUpgrade: number;
      isLoading: false;
    };

const useGetStatus = (): Status => {
  const { isLoading, data, error } = useApi<ClusterStatusResponse>(
    "cluster_status",
    "/api/v1/orgs/:orgName/clusters/status"
  );
  const clustersApi = useApi<ClustersResponse>(
    "clusters",
    "/api/v1/orgs/:orgName/clusters"
  );
  const latestOperatorVersionApi = useLatestOperatorVersion();
  const waitingOnData =
    !data ||
    isLoading ||
    !clustersApi.data ||
    clustersApi.isLoading ||
    !latestOperatorVersionApi.version;

  const unhealthy = useMemo(() => {
    if (waitingOnData || !data) {
      return 0;
    }
    let total = 0;
    for (let i = 0; i < data.Statuses.length; i++) {
      if (!data.Statuses[i].status.healthy) {
        total++;
      }
    }
    return total;
  }, [data, waitingOnData]);
  const pendingUpgrades = useMemo(() => {
    if (
      waitingOnData ||
      !clustersApi.data ||
      !latestOperatorVersionApi.version
    ) {
      return 0;
    }
    let total = 0;
    const { version } = latestOperatorVersionApi;
    for (let i = 0; i < clustersApi.data.clusters?.length; i++) {
      const c = clustersApi.data.clusters[i];
      if (!c.operatorVersion) {
        continue;
      }
      const clusterVersion = parseOperatorVersion(c.operatorVersion);
      if (!isObservedVersionLatest(clusterVersion, version)) {
        total++;
      }
    }
    return total;
  }, [clustersApi, latestOperatorVersionApi, waitingOnData]);

  if (error) {
    return {
      isError: true,
      isLoading: false,
      error,
    };
  }

  if (clustersApi.error) {
    return {
      isError: true,
      isLoading: false,
      error: clustersApi.error,
    };
  }

  if (latestOperatorVersionApi.error) {
    return {
      isError: true,
      isLoading: false,
      error: latestOperatorVersionApi.error,
    };
  }

  if (waitingOnData) {
    return {
      isError: false,
      isLoading: true,
    };
  }

  const totalClusters = waitingOnData
    ? 0
    : data.Statuses.filter((clusterStatus) => clusterStatus.status.healthy)
        .length;

  return {
    isError: false,
    isLoading: false,
    unhealthy: unhealthy,
    pendingUpgrade: pendingUpgrades,
    totalClusters: totalClusters,
  };
};

export default useGetStatus;
