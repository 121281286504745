import type { MouseEventHandler } from "react";
import React from "react";
import { usePostHog } from "posthog-js/react";
import styles from "./ActionLink.module.css";
import type { EventProps } from "../../../@types/sd/event";

interface ActionLinkProps extends EventProps {
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  disabled?: boolean;
}

const ActionLink: React.FC<ActionLinkProps> = ({
  children,
  onClick,
  eventName,
  className = styles.linkStyle,
  disabled,
}) => {
  const posthog = usePostHog();
  let newOnClick = onClick;
  if (onClick) {
    newOnClick = (e) => {
      if (eventName) {
        posthog?.capture(eventName);
      }
      onClick?.(e);
    };
  }
  return (
    <button
      type="button"
      onClick={newOnClick}
      className={`${styles.actionLink} ${className}`}
      disabled={!!disabled}
    >
      {children}
    </button>
  );
};

export default ActionLink;
