import React, { useEffect, useState } from "react";
import { Button } from "@blueprintjs/core";
import Interstitial from "../../../components/Interstitial";
import SetupStep from "./SetupStep";
import {
  getInviteCodeFromLocalStorage,
  HOMEPAGE,
  redirectTo,
} from "../services";
import PostInstallationCheck from "./PostInstallationCheck";
import useApiMutation from "../../../hooks/UseApiMutation";
import InvitationError from "../PreInstall/InvitationError";

const Setup = function () {
  const [orgName, setOrgName] = useState<null | string>(null);
  const [installationComplete, setInstallationComplete] = useState(false);
  const [setupComplete, setSetupComplete] = useState(false);

  const mutation = useApiMutation("setup_org", "POST", []);
  const createOrg = () => {
    const inviteCode = getInviteCodeFromLocalStorage();
    if (inviteCode && orgName && installationComplete) {
      mutation.mutate({
        data: {
          inviteCode: inviteCode,
        },
        url: `/api/v1/setup/orgs`,
      });
    }
  };

  useEffect(() => {
    if (setupComplete) {
      return;
    }
    if (installationComplete) {
      createOrg();
    }
  }, [installationComplete, setupComplete]);

  const inviteCode = getInviteCodeFromLocalStorage();
  if (!inviteCode) {
    return <InvitationError />;
  }

  if (mutation.error) {
    return (
      <InvitationError message="Error linking your Github with Signadot." />
    );
  }

  return (
    <>
      <PostInstallationCheck
        setOrgName={setOrgName}
        setInstallationComplete={setInstallationComplete}
        setSetupComplete={setSetupComplete}
      />
      <Interstitial>
        <div className="m-3">
          <div>
            <SetupStep
              description="Verifiying Signadot app installation on Github"
              complete={installationComplete}
            />
            <SetupStep
              description="Linking your Github account/org with Signadot"
              complete={setupComplete}
            />
          </div>
          {installationComplete && setupComplete && (
            <div>
              <p
                style={{
                  color: "green",
                  fontSize: "1.1em",
                  marginTop: "5em",
                  marginBottom: "2em",
                }}
              >
                Setup complete!
              </p>
              <Button type="button" onClick={() => redirectTo(HOMEPAGE)}>
                Go to Signadot homepage
              </Button>
            </div>
          )}
        </div>
      </Interstitial>
    </>
  );
};

export default Setup;
