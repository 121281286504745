import type { MouseEventHandler } from "react";
import React from "react";
import type { EventProps } from "../../../../@types/sd/event";
import ActionLink from "../../ActionLink";
import styles from "./BorderButton.module.css";

interface BorderButtonProps extends EventProps {
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const BorderButton: React.FC<BorderButtonProps> = ({
  children,
  onClick,
  eventName,
}) => (
  <ActionLink onClick={onClick} eventName={eventName} className={styles.button}>
    {children}
  </ActionLink>
);

export default BorderButton;
