import React, { useState } from "react";
import { Dialog } from "@blueprintjs/core";
import YouTube from "react-youtube";
import BaseWidget from "../BaseWidget";
import styles from "./GettingStarted.module.css";
import type { ResourceProps } from "./resources";
import resources from "./resources";
import InfoCapsule from "../../../../components/theming/InfoCapsule";

const VIDEO_ASPECT_RATIO = 1.6;
const VIDEO_WIDTH = 800;
const VIDEO_HEIGHT = VIDEO_WIDTH / VIDEO_ASPECT_RATIO;

const PLAYER_OPTS = {
  height: VIDEO_HEIGHT,
  width: VIDEO_WIDTH,
  playerVars: {
    autoplay: 1,
  },
};

const GettingStarted = () => {
  const [selectedVideoID, setSelectedVideoID] = useState<string | undefined>(
    undefined
  );
  const handleInfoClick = (resource: ResourceProps) => () => {
    if (resource.type === "docs") {
      window.open(resource.url, "_blank");
      return;
    }

    const url = new URL(resource.url);
    const id = url.searchParams.get("v");
    setSelectedVideoID(id ?? undefined);
  };

  return (
    <BaseWidget title="Getting Started">
      <div className={styles.container}>
        {resources.map((resource) => (
          <InfoCapsule
            key={resource.title}
            type={resource.type}
            label={resource.title}
            onClick={handleInfoClick(resource)}
            eventName={resource.eventName}
          />
        ))}
      </div>
      <Dialog
        isOpen={!!selectedVideoID}
        onClose={() => setSelectedVideoID(undefined)}
        className={styles.video_container}
      >
        <YouTube videoId={selectedVideoID} opts={PLAYER_OPTS} />
      </Dialog>
    </BaseWidget>
  );
};

export default GettingStarted;
