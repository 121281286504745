import React from "react";
import type { RouteGroup } from "../../@types/sd/routegroup";
import PaginatedTable from "../../components/PaginatedTable/PaginatedTable";

interface Props {
  routeGroup: RouteGroup;
}

const columns = [
  {
    Header: "Name",
    accessor: "name",
    search: true,
  },
  {
    Header: "Endpoint",
    accessor: "target",
    search: true,
  },
  {
    Header: "URL",
    accessor: "url",
    search: true,
  },
];

const EndpointsTable: React.FunctionComponent<Props> = React.memo(
  ({ routeGroup }) => {
    if (!routeGroup.endpoints || routeGroup.endpoints.length === 0) {
      return <div>No endpoints configured</div>;
    }
    const data = routeGroup.endpoints.map(({ name, target, url }) => ({
      name,
      target,
      url: {
        text: url,
        component: (
          <a href={url} target="_blank" rel="noreferrer">
            {url}
          </a>
        ),
      },
    }));
    return <PaginatedTable columns={columns} data={data} />;
  },
  (prevProps, nextProps) => {
    const prevEndpoints = prevProps.routeGroup.endpoints;
    const nextEndpoints = nextProps.routeGroup.endpoints;
    if (prevEndpoints === nextEndpoints) {
      return true;
    }
    if (!prevEndpoints || !nextEndpoints) {
      return false;
    }
    if (prevEndpoints.length !== nextEndpoints.length) {
      return false;
    }
    for (let i = 0; i < prevEndpoints.length; i++) {
      if (prevEndpoints[i].name !== nextEndpoints[i].name) {
        return false;
      }
      if (prevEndpoints[i].target !== nextEndpoints[i].target) {
        return false;
      }
      if (prevEndpoints[i].url !== nextEndpoints[i].url) {
        return false;
      }
    }
    return true;
  }
);

EndpointsTable.displayName = "EndpointsTable";

export default EndpointsTable;
