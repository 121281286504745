import type { Cell } from "react-table";
import SdTheme from "../../styles/theme";

export type Size = "small" | "medium";

// eslint-disable-next-line import/prefer-default-export
export const getBaseCellProps = <DataT extends object>(cell: Cell<DataT>) => ({
  style: {
    color: SdTheme.Text.lightBackground,
    ...(cell.column.Header === "" ? { width: "10px" } : {}),
  },
  size: "small" as Size,
});
