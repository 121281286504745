import React from "react";
import styles from "./Status.module.css";
import { getStatusStyle } from "./utils";

export type StatusProps = {
  type: "error" | "warning";
  message: string;
  onClick?: () => void;
};
const Status = ({ type, message, onClick }: StatusProps) => (
  <div
    className={`${styles.status} ${getStatusStyle(type)} ${
      onClick ? styles.action : ""
    }`}
    onClick={() => onClick?.()}
  >
    <p>{message}</p>
  </div>
);

export default Status;
