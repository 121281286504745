import React from "react";
import { Icon } from "@blueprintjs/core";
import styles from "./WorkloadDropdown.module.css";
import type { ForkWL, WL } from "../types";
import { EntityType, isForkWL } from "../types";
import { getWLInfo } from "../utils";
import {
  DropdownForkItem,
  DropdownResourceItem,
} from "./WorkloadDropdownWrapperItem";
import type { Step } from "../../../../../@types/sd/resource";
import useDisclosure from "../../../../../hooks/UseDisclosure";

export type WorkloadDropdownOption = {
  original: WL;
  value: ForkWL | Step;
};

type CustomDropdownProps = {
  workloads: WL[];
  onSelect: (selected: WorkloadDropdownOption) => void;
  selectedWorkload: WorkloadDropdownOption | null;
};
const WorkloadDropdown: React.FC<CustomDropdownProps> = ({
  workloads,
  onSelect,
  selectedWorkload,
}) => {
  const { isOpen, toggle, close } = useDisclosure(false);

  const handleClick = (
    originalWl: WL,
    data: ForkWL | ["create" | "delete", number]
  ) => {
    close();
    if (!Array.isArray(data)) {
      const selectedOption = { original: originalWl, value: data };
      onSelect(selectedOption);

      return;
    }

    if (originalWl.type !== "Resource") return;

    const [type, index] = data;

    const steps =
      type === "create" ? originalWl.data.create : originalWl.data.delete;
    const step = steps && steps.length > 0 ? steps[index] : undefined;

    if (!step) return;

    const selectedOption = { original: originalWl, value: step };
    onSelect(selectedOption);
  };

  const getValue = (data: WorkloadDropdownOption | null) => {
    if (!data) return "No fork/resource selected";

    const wlInfo = getWLInfo(data.original);
    if (isForkWL(data.value)) {
      return `${wlInfo?.name} [${wlInfo?.type}]`;
    }

    return `${wlInfo?.name}:${data.value.name} [${wlInfo?.type}]`;
  };

  return (
    <div>
      <div className={styles.container}>
        <div>
          {/* eslint-disable-next-line react/button-has-type */}
          <button onClick={toggle}>
            {getValue(selectedWorkload)}
            <Icon icon="chevron-down" />
          </button>
          {isOpen && (
            <div className={styles.options}>
              {workloads.map((item) =>
                item.type === EntityType.Fork ? (
                  <DropdownForkItem
                    key={item.uname}
                    {...item}
                    onClick={(workload) => handleClick(item, workload)}
                  />
                ) : (
                  <DropdownResourceItem
                    key={item.uname}
                    {...item}
                    onClick={(...values) => {
                      handleClick(item, values);
                    }}
                  />
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { WorkloadDropdown };
