import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import type { SandboxV2 } from "../../../../@types/sd/sandboxv2";
import {
  FILTER_TEST_EXECUTIONS_API_NAME,
  useQuerySandboxTestExecutions,
} from "../../../../api/TestExecutionsApi";
import TestExecutionDetail from "../../../Testing/TestExecutionDetail";
import SdButton from "../../../../components/theming/SdButton";
import style from "./Tests.module.css";
import Executions from "./Executions";
import Collapse from "../../../Testing/TestExecutionDetail/common/Collapse";
import { groupByTest } from "./utils";
import ExecutionTitle from "./ExecutionTitle/ExecutionTitle";
import EmptyExecutions from "./EmptyExecutions/EmptyExecutions";

interface Props {
  sandbox: SandboxV2;
}

const Tests: React.FC<Props> = ({ sandbox }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { hash } = useLocation();

  useEffect(() => {
    queryClient.invalidateQueries(FILTER_TEST_EXECUTIONS_API_NAME);
  }, []);

  const [selectedTestName, setSelectedTestName] = useState<string | null>(null);
  const [selectedExecutionName, setSelectedExecutionName] = useState<
    string | null
  >(null);
  const { isSuccess, isLoading, data } = useQuerySandboxTestExecutions(
    sandbox.name,
    {
      enabled: !!sandbox?.name,
    }
  );
  const isTestExecutionSelected: boolean = React.useMemo(
    () => !!(selectedTestName && selectedExecutionName),
    [selectedTestName, selectedExecutionName]
  );

  useEffect(() => {
    if (hash) {
      const parts = hash.split("@");
      if (parts.length !== 2) {
        setSelectedTestName(null);
        setSelectedExecutionName(null);

        return;
      }

      const [testName, executionName] = parts;

      setSelectedTestName(testName.substring(1));
      setSelectedExecutionName(executionName);
      return;
    }

    setSelectedTestName(null);
    setSelectedExecutionName(null);
  }, [hash]);

  if (!isSuccess || isLoading || !data) {
    return null;
  }
  if (data.length === 0) {
    return <EmptyExecutions />;
  }

  const groupedExecutions = groupByTest(data);

  return (
    <div>
      {!isTestExecutionSelected ? (
        Object.entries(groupedExecutions).map(([testName, executions]) => (
          <Collapse
            title={<ExecutionTitle title={testName} />}
            show
            key={testName}
          >
            <Executions
              executions={executions}
              onSelect={(testExecution) => {
                setSelectedTestName(testName);
                setSelectedExecutionName(testExecution.name);
                navigate(`#${testName}@${testExecution.name}`);
              }}
            />
          </Collapse>
        ))
      ) : (
        <div>
          <SdButton
            icon={<FaArrowLeft />}
            className={style.gap}
            onClick={() => {
              setSelectedTestName(null);
              setSelectedExecutionName(null);
              navigate(`/sandbox/name/${sandbox.name}/tests`, {
                replace: true,
              });
            }}
          >
            View all Test Executions
          </SdButton>
          <div className={style.content}>
            <TestExecutionDetail
              testName={selectedTestName!}
              executionName={selectedExecutionName!}
              hideBreadcrumbs
              customTitle={(title) => (
                <p className={style.custom_test_title}>{title}</p>
              )}
              onNotFound={() => {
                setSelectedTestName(null);
                setSelectedExecutionName(null);
                navigate(`/sandbox/name/${sandbox.name}/tests`, {
                  replace: true,
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Tests;
