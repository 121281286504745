import React from "react";
import Collapse from "../../../../common/Collapse";
import styles from "./BaseDataDisplay.module.css";

type BaseDataDisplayProps = {
  title: string;
  show: boolean;
  tags?: React.ComponentProps<typeof Collapse>["tags"];
  children?: React.ReactNode;
  defaultOpen?: boolean;
};

const BaseDataDisplay = ({
  title,
  tags,
  show,
  children,
  defaultOpen,
}: BaseDataDisplayProps) => (
  <Collapse
    title={
      <div className={styles.mainHeader}>
        {title.charAt(0).toUpperCase() + title.slice(1)}
      </div>
    }
    tags={tags}
    show={show}
    containerStyle={styles.containerStyleOverride}
    defaultOpen={defaultOpen}
  >
    <div>{children}</div>
  </Collapse>
);
export default BaseDataDisplay;
