import yaml from "js-yaml";
import React from "react";
import type { RunnerGroup } from "../../../../../../@types/sd/runnergroup";
import ViewSpec from "../../../../../../components/theming/ViewSpec/ViewSpec";

type Props = {
  runnerGroup: RunnerGroup;
};

const Specification: React.FC<Props> = ({ runnerGroup }) => (
  <ViewSpec
    yaml={yaml.dump({
      name: runnerGroup.name,
      spec: runnerGroup.spec,
    })}
  />
);

export default Specification;
