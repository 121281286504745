import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import Error from "../../components/Error/Error";
import { useSandboxByIdApi } from "../../api/SandboxesApi";

const SandboxIdRedirect: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { sandboxId } = useParams<{ sandboxId: string }>();
  const sandboxApi = useSandboxByIdApi(sandboxId!);

  useEffect(() => {
    if (!sandboxApi.data) {
      return;
    }
    if (!sandboxApi.data.sandbox.name) {
      return;
    }
    navigate(`/sandbox/name/${sandboxApi.data.sandbox.name}`, {
      replace: true,
    });
  }, [sandboxApi]);

  if (sandboxApi.isError) {
    return <Error text={sandboxApi.error.message} />;
  }
  if (sandboxApi.isLoading) {
    return <Loading />;
  }

  // ShareChat, as well as old sandboxes that might exist, don't have a name. Fallback to our old
  // component that uses the V1 API for rendering by ID.
  if (!sandboxApi.data!.sandbox.name) {
    return <div>Sandbox not found</div>;
  }
  return <div>Redirecting to Sandbox...</div>;
};

export default SandboxIdRedirect;
