import * as React from "react";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Spacer from "../../components/Util/Util";
import { SdHeading1 } from "../../components/theming/SdHeading";
import GettingStarted from "./Widgets/GettingStarted";
import Clusters from "./Widgets/Clusters";
import Sandboxes from "./Widgets/Sandboxes";
import RecentBlogs from "./Widgets/RecentBlogs";
import styles from "./Overview.module.css";
import Announcements from "./Widgets/Announcements/Announcements";

const Overview: React.FunctionComponent = () => (
  <>
    <SdHeading1 small lightBackground>
      Overview
    </SdHeading1>

    <ResponsiveMasonry
      columnsCountBreakPoints={{ 350: 1, 1200: 2, 1600: 3 }}
      style={{ marginTop: "2rem" }}
    >
      <Masonry gutter="2rem" className={styles.masonry_layout}>
        <Announcements />
        <Clusters />
        <Sandboxes />
        <GettingStarted />
        <RecentBlogs />
      </Masonry>
    </ResponsiveMasonry>

    <Spacer />
  </>
);

export default Overview;
