import React from "react";
import { getPlanDisplayNameByType } from "../../Utils";
import type { BillingData } from "../../../../@types/sd/billing";
import SdButton from "../../../../components/theming/SdButton";

interface EnterpriseButtonProps {
  orgName: string;
  billingData: BillingData | null;
  targetPlanType: string;
}

const EnterpriseButton: React.FunctionComponent<EnterpriseButtonProps> =
  React.memo(({ orgName, billingData, targetPlanType }) => {
    const mailtoNewline = "%0D%0A";

    const currentPlan = getPlanDisplayNameByType(billingData!.tier.planType);
    const targetPlan = getPlanDisplayNameByType(targetPlanType);
    const content = `Request for plan change.
  
Organization: ${orgName}
Current Plan: ${currentPlan}
Change To: ${targetPlan}

[Feel free to update the contents of this email]`;
    const body = content.replace(/\n/g, mailtoNewline);
    const mailTo = `mailto:sales@signadot.com?subject=Plan Change&body=${body}`;

    return (
      <SdButton
        onClick={() => {
          window.location.href = mailTo;
        }}
      >
        Contact Us
      </SdButton>
    );
  });
EnterpriseButton.displayName = "EnterpriseButton";

export default EnterpriseButton;
