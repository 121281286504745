import React from "react";
import { useLocation } from "react-router-dom";
import type { ForkWL, QueryParams, WL } from "./types";
import { EntityType } from "./types";
import type { SandboxV2 } from "../../../../@types/sd/sandboxv2";
import type { LogParams } from "../../../DeploymentDetails/LogPanel/LogPanel";
import type { Step } from "../../../../@types/sd/resource";

export const useQuery = () => new URLSearchParams(useLocation().search);

export const useLogParams = (
  sandbox: SandboxV2,
  container: string,
  wl?: WL,
  step?: Step
) =>
  React.useMemo<LogParams | undefined>(() => {
    if (!wl) {
      return undefined;
    }
    if (wl.type === EntityType.Fork && (wl.data as ForkWL | undefined)?.fork) {
      return {
        cluster: sandbox.spec.cluster,
        kind: "Deployment",
        namespace: (wl.data as ForkWL).fork!.metadata.namespace,
        container: container, // * = all containers
        name: (wl.data as ForkWL).fork!.metadata.name,
      } as LogParams;
    }
    if (wl.type === EntityType.Resource && step) {
      return {
        cluster: sandbox.spec.cluster,
        kind: "Pod",
        namespace: step.status.pod.namespace,
        container: container, // * = all containers
        name: step.status.pod.name,
      } as LogParams;
    }
    return undefined;
  }, [sandbox, wl, step, container]);

export const useQueryParams = (
  query: URLSearchParams
): QueryParams | undefined =>
  React.useMemo(() => {
    const typeParam = query.get("type");
    const nameParam = query.get("name");
    const stepParam = query.get("step");

    if (
      !typeParam ||
      !nameParam ||
      !(typeParam === EntityType.Fork || typeParam === EntityType.Resource)
    ) {
      return undefined;
    }
    return { type: typeParam, name: nameParam, step: stepParam } as QueryParams;
  }, [query]);
