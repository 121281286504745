import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon, Intent, Tag } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import DetailItem from "../../../../../../components/DetailItem";
import type { Job } from "../../../../../../@types/sd/job";
import ActionLink from "../../../../../../components/theming/ActionLink";
import { getTimeAgoElement } from "../../../../../../util/Util";
import { getDuration } from "../../../../../../components/JobsList/utils";
import { getRoutingContext } from "../../../Jobs/utils";
import SdColors from "../../../../../../styles/colors";
import StatusCard from "../../../../../../components/ResourceStatus/StatusCard";

interface Props {
  job: Job;
}

const Overview: React.FC<Props> = ({ job }) => {
  const navigate = useNavigate();
  const attempt = job.status.attempts[0];
  const routingContext = getRoutingContext(job);

  const errorMessage = React.useMemo(() => {
    if (attempt.phase !== "failed") {
      return null;
    }
    const messages = [];
    if (attempt.state.failed.message) {
      messages.push(`Reason: ${attempt.state.failed.message}`);
    }
    if (attempt.state.failed.exitCode) {
      messages.push(`(Exit code: ${attempt.state.failed.exitCode})`);
    }
    return messages.join(" ");
  }, [attempt]);

  const brokenJobRunnerErrorMessage = React.useMemo(() => {
    if (!job.status.runnerGroupDeletionCompletedAt) {
      return null;
    }

    const runnerGroup = job.spec.runnerGroup;
    return `The Job Runner Group (${runnerGroup}) was deleted.`;
  }, [job.status]);

  return (
    <div>
      {errorMessage && (
        <div className="mb-5">
          <StatusCard
            title="Job Failed"
            description={errorMessage}
            intent={Intent.DANGER}
          />
        </div>
      )}
      <DetailItem
        title="Job Runner Group"
        value={
          job.status.runnerGroupDeletionCompletedAt ? (
            <Tooltip2 content="This entity has been deleted">
              <>
                {job.spec.runnerGroup}{" "}
                <Icon icon="warning-sign" size={15} intent={Intent.WARNING} />
              </>
            </Tooltip2>
          ) : (
            <ActionLink
              onClick={() =>
                navigate(`/testing/job-runner-groups/${job.spec.runnerGroup}`)
              }
            >
              {job.spec.runnerGroup}
            </ActionLink>
          )
        }
      />
      {routingContext.context !== "baseline" && (
        <DetailItem
          title="Routing context"
          value={`${routingContext.value} (${routingContext.context})`}
        />
      )}
      {attempt?.createdAt && (
        <DetailItem
          title="Created"
          value={getTimeAgoElement(attempt.createdAt)}
        />
      )}
      {attempt?.startedAt && (
        <DetailItem
          title="Started"
          value={getTimeAgoElement(attempt.startedAt)}
        />
      )}
      {attempt?.finishedAt && (
        <DetailItem
          title="Completed"
          value={getTimeAgoElement(attempt.finishedAt)}
        />
      )}
      {attempt?.createdAt && attempt?.finishedAt && (
        <DetailItem
          title="Duration"
          value={getDuration(attempt.createdAt, attempt.finishedAt)}
        />
      )}
      {job.spec.labels ? (
        <DetailItem
          title="Labels"
          value={Object.keys(job.spec.labels).map((key) => (
            <Tag
              key={key}
              className="mr-1 mb-1"
              style={{ backgroundColor: SdColors.NEUTRAL500 }}
            >
              {key}: {job.spec.labels?.[key]}
            </Tag>
          ))}
        />
      ) : null}
    </div>
  );
};

export default Overview;
