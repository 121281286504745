import React from "react";
import ReactDiffViewer from "react-diff-viewer";
import styles from "./ViewDiff.module.css";

interface Props {
  // TODO: As of now, the planned UX only supports showing diff for content
  // without any regard for the response status. In case of an error, we
  // consider the response to be empty. We will need to address this soon
  // so that the status gets surfaced to the user as well.
  baselineBody?: string;
  sandboxBody?: string;
}

const ViewDiff: React.FC<Props> = ({ baselineBody, sandboxBody }) => (
  <div className={styles.diffViewer}>
    <ReactDiffViewer
      oldValue={baselineBody}
      newValue={sandboxBody}
      splitView={false}
    />
  </div>
);

export default ViewDiff;
