import useApi from "../hooks/UseApi";
import type { ResourcePlugin } from "../@types/sd/resourceplugin";
import useApiMutation from "../hooks/UseApiMutation";

const standardQueryConfig = {
  retry: 1,
  refetchInterval: 30000,
};

export const LIST_RESOURCE_PLUGINS_API_NAME = "api:resource_plugins";
export const GET_RESOURCE_PLUGIN_API_NAME = "api:get_resource_plugin";
export const DELETE_RESOURCE_PLUGIN_API_NAME = "api:delete_resource_plugin";

export const useResourcePlugins = (options = {}) =>
  useApi<ResourcePlugin[]>(
    LIST_RESOURCE_PLUGINS_API_NAME,
    `/api/v2/orgs/:orgName/resource-plugins`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );

export const useResourcePlugin = (name: string, options = {}) =>
  useApi<ResourcePlugin>(
    GET_RESOURCE_PLUGIN_API_NAME,
    `/api/v2/orgs/:orgName/resource-plugins/${name}`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );

export const useDeleteResourcePlugin = () =>
  useApiMutation(DELETE_RESOURCE_PLUGIN_API_NAME, "DELETE", [
    LIST_RESOURCE_PLUGINS_API_NAME,
    GET_RESOURCE_PLUGIN_API_NAME,
  ]);
