import type { ReactNode } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import type { RedirectLoginOptions } from "@auth0/auth0-react";
import { Auth0Provider } from "@auth0/auth0-react";

type Auth0ProviderWithHistoryProperties = {
  children: ReactNode | undefined;
};

const Auth0ProviderWithHistory = ({
  children,
}: Auth0ProviderWithHistoryProperties) => {
  const domain = process.env.VITE_AUTH0_DOMAIN!;
  const clientId = process.env.VITE_AUTH0_CLIENT_ID!;
  const audience = process.env.VITE_AUTH0_AUDIENCE!;
  const navigate = useNavigate();
  const onRedirectCallback = (appState: RedirectLoginOptions) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={audience}
      scope="openid profile email"
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
