import React from "react";
import { Button } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";
import Interstitial from "../Interstitial";
import styles from "./Error.module.css";

interface Props {
  message?: string;
}

const AuthError: React.FC<Props> = ({ message }) => {
  const navigate = useNavigate();
  return (
    <Interstitial>
      <div className="mt-4">
        <p className="text-2xl mb-5 font-bold">Error</p>
        {message && <p className={styles.reason}>Reason: {message}</p>}
        <p className="mb-16">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          If you think you ended up here in error, please contact
          info@signadot.com.
        </p>
        <Button
          icon="log-out"
          onClick={() => {
            navigate("/signout");
          }}
        >
          Log Out
        </Button>
      </div>
    </Interstitial>
  );
};

export default AuthError;
