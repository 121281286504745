// eslint-disable-next-line import/prefer-default-export
import type { Test } from "../../../@types/sd/testspec";
import type { ManagedRGData } from "../../../api/ManagedRunnerGroupsApi";

// eslint-disable-next-line import/prefer-default-export
export const filterTestSpecs = (
  filterText: string,
  items: Test[] = []
): Test[] =>
  items.filter((item) =>
    filterText
      .toLowerCase()
      .trim()
      .split(" ")
      .some(
        (token) =>
          item.name.toLowerCase().includes(token) ||
          (item.spec.triggers || []).some((service) =>
            service.sandboxOf.name.toLowerCase().includes(token)
          )
      )
  );

export const canRunSmartTests = (
  managedRgSettings: ManagedRGData | undefined
): boolean => {
  if (!managedRgSettings) return false;

  if (!managedRgSettings.enabled) return false;

  return Object.values(managedRgSettings.clusters).some(
    (rg) => rg.eligible && rg.replicas > 0
  );
};
