import type { MutationKey, QueryKey, UseMutationResult } from "react-query";
import { useMutation, useQueryClient } from "react-query";
import type { AxiosResponse, Method } from "axios";
import axios from "axios";
import { generatePath } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import type { ApiError } from "./UseApi";

export const API_VERSION_V2 = "20211220";

export type MutationVariables<DataT = unknown> = {
  method?: Method;
  url: string;
  data?: DataT;
  headers?: Record<string, string>;
};

export type ApiMutationResult<
  ResponseT = unknown,
  DataT = unknown
> = UseMutationResult<
  AxiosResponse<ResponseT>,
  ApiError,
  MutationVariables<DataT>
>;

const baseURL = process.env.VITE_API_BASE_URL;
axios.defaults.baseURL = baseURL;

const useApiMutation = <ResponseT = unknown, DataT = unknown>(
  name: MutationKey,
  defaultMethod: Method,
  invalidations: QueryKey[],
  onSuccess?: (response: ResponseT, vars: MutationVariables<DataT>) => void,
  onError?: (err: ApiError, variables: MutationVariables<DataT>) => void
): ApiMutationResult<ResponseT, DataT> => {
  const { state } = useAuth();
  const { AuthToken, org } = state;
  const queryClient = useQueryClient();

  // set orgName parameter in URL if needed.
  const mutation = useMutation<
    AxiosResponse<ResponseT>,
    ApiError,
    MutationVariables<DataT>
  >(
    name,
    (arg: MutationVariables<DataT>): Promise<AxiosResponse<ResponseT>> => {
      const method = arg.method || defaultMethod;
      const expandedUrl = generatePath(arg.url, { orgName: org?.name });
      const headers = {
        Authorization: `Bearer ${AuthToken}`,
      };
      if (arg.headers) {
        const headerArgs = arg.headers;
        Object.keys(headerArgs).forEach((key) => {
          const newHeaderValue = headerArgs[key];
          if (headers[key]) {
            headers[key] = `${headers[key]}; ${newHeaderValue}`;
          } else {
            headers[key] = newHeaderValue;
          }
        });
      }
      return axios({
        method,
        headers,
        data: arg.data,
        url: expandedUrl,
      });
    },
    {
      onSuccess: (
        response: AxiosResponse<ResponseT>,
        variables: MutationVariables<DataT>
      ) => {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess(response && response.data, variables);
        }
        invalidations.forEach((item) => queryClient.invalidateQueries(item));
      },
      onError,
    }
  );
  return mutation;
};

export default useApiMutation;
