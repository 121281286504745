import {
  Alert,
  Card,
  Checkbox,
  Intent,
  OverlayToaster,
} from "@blueprintjs/core";
import React, { useEffect, useMemo } from "react";
import { SdHeading5 } from "../../components/theming/SdHeading";
import TTLSelector from "../../components/TTLSelector/TTLSelector";
import { useTTL } from "../../hooks/UseTTL";
import { TTL_POLICIES as RG_POLICIES } from "../RouteGroups/Constants";
import { TTL_POLICIES as SB_POLICIES } from "../Sandboxes/Constants";
import Spacer from "../../components/Util/Util";
import {
  useGetClusterDefaultsApi,
  useRemoveClusterDefaultApi,
  useSaveClusterDefaultApi,
} from "../../api/ClustersApi";
import type { ClusterDefault } from "../../@types/sd/cluster";
import SdButton from "../../components/theming/SdButton";
import useDisclosure from "../../hooks/UseDisclosure";
import { hasRouteGroupTTLChanged, hasSandboxTTLChanged } from "./utils";

type DefaultsTTLProps = {
  clusterName: string;
};

const toaster = OverlayToaster.create();

const DefaultsTTL = ({ clusterName }: DefaultsTTLProps) => {
  const clusterDefaults = useGetClusterDefaultsApi(clusterName as string);
  const routegroupTTL = useTTL({ offsetFrom: "", policies: RG_POLICIES });
  const sandboxTTL = useTTL({ offsetFrom: "", policies: SB_POLICIES });

  const saveTTL = useSaveClusterDefaultApi(
    () => {},
    (error) => {
      toaster.show({ message: error.message, intent: Intent.DANGER });
    }
  );
  const removeTTL = useRemoveClusterDefaultApi(
    () => {},
    (error) => {
      toaster.show({ message: error.message, intent: Intent.DANGER });
    }
  );

  const confirmationAlert = useDisclosure(false);

  const defaultRouteGroupTTL = useMemo(
    () =>
      clusterDefaults?.data?.defaults.find(
        (d) => d.class === "ttl" && d.resourceKind === "routegroup"
      ),
    [clusterDefaults.data]
  );

  const defaultSandboxTTL = useMemo(
    () =>
      clusterDefaults?.data?.defaults.find(
        (d) => d.class === "ttl" && d.resourceKind === "sandbox"
      ),
    [clusterDefaults.data]
  );

  if (clusterDefaults.error) return null;

  useEffect(() => {
    if (defaultRouteGroupTTL) {
      routegroupTTL.recalculate({
        duration: defaultRouteGroupTTL.value.duration,
        offsetFrom: defaultRouteGroupTTL.value.offsetFrom,
      });
    }

    if (defaultSandboxTTL) {
      sandboxTTL.recalculate({
        duration: defaultSandboxTTL.value.duration,
        offsetFrom: defaultSandboxTTL.value.offsetFrom,
      });
    }
  }, [clusterDefaults.data]);

  const hasTTLChanged = () =>
    hasSandboxTTLChanged(defaultSandboxTTL, sandboxTTL.ttl) ||
    hasRouteGroupTTLChanged(defaultRouteGroupTTL, routegroupTTL.ttl);

  const handleDeleteTTL = () => {
    const resourceKinds = [];
    if (!routegroupTTL.ttl.enabled) {
      resourceKinds.push("routegroup");
    }
    if (!sandboxTTL.ttl.enabled) {
      resourceKinds.push("sandbox");
    }

    let url = `/api/v2/orgs/:orgName/clusters/${clusterName}/defaults`;
    if (resourceKinds.length === 1) {
      url += `?resourceKind=${resourceKinds[0]}`;
    }

    if (resourceKinds.length === 0) {
      return;
    }

    removeTTL.mutate({ url });
  };

  const handleSaveTTL = () => {
    const defaults: ClusterDefault[] = [];

    if (routegroupTTL.ttl.enabled) {
      defaults.push({
        class: "ttl",
        resourceKind: "routegroup",
        value: {
          offsetFrom: routegroupTTL.ttl.offsetFrom,
          duration: `${routegroupTTL.ttl.duration}${routegroupTTL.ttl.durationType}`,
        },
      });
    }

    if (sandboxTTL.ttl.enabled) {
      defaults.push({
        class: "ttl",
        resourceKind: "sandbox",
        value: {
          offsetFrom: sandboxTTL.ttl.offsetFrom,
          duration: `${sandboxTTL.ttl.duration}${sandboxTTL.ttl.durationType}`,
        },
      });
    }

    saveTTL.mutate({
      data: {
        defaults,
      },
      url: `/api/v2/orgs/:orgName/clusters/${clusterName}/defaults`,
    });

    toaster.show({ message: "TTL Updated", intent: Intent.SUCCESS });

    handleDeleteTTL();

    confirmationAlert.close();
  };

  return (
    <Card className="pl-4">
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Apply TTL"
        icon="edit"
        intent={Intent.DANGER}
        isOpen={confirmationAlert.isOpen}
        onCancel={confirmationAlert.close}
        onClose={confirmationAlert.close}
        onConfirm={handleSaveTTL}
      >
        <div>
          <p>
            This may have unintended consequences. Please check the{" "}
            <a href="https://www.signadot.com/docs/guides/admin/cluster-configuration">
              documentation
            </a>{" "}
            for further details.
          </p>

          <p>Are you sure you want to proceed?</p>
        </div>
      </Alert>

      <SdHeading5 lightBackground>TTL</SdHeading5>

      <Checkbox
        checked={sandboxTTL.ttl.enabled}
        label="Default Sandbox TTL"
        onChange={() => {
          sandboxTTL.onChange("enabled", (prev) => ({
            ...prev,
            enabled: !prev.enabled,
          }));
        }}
      />

      {sandboxTTL.ttl.enabled && (
        <TTLSelector
          policiesOptions={sandboxTTL.policies}
          onChangeTTL={sandboxTTL.onChange}
          ttl={sandboxTTL.ttl}
        />
      )}

      <Spacer />
      <Checkbox
        checked={routegroupTTL.ttl.enabled}
        label="Default Route Group TTL"
        onChange={() => {
          routegroupTTL.onChange("enabled", (prev) => ({
            ...prev,
            enabled: !prev.enabled,
          }));
        }}
      />

      {routegroupTTL.ttl.enabled && (
        <TTLSelector
          policiesOptions={routegroupTTL.policies}
          onChangeTTL={routegroupTTL.onChange}
          ttl={routegroupTTL.ttl}
        />
      )}

      <SdButton
        onClick={confirmationAlert.open}
        disabled={!hasTTLChanged()}
        className="mt-4"
      >
        Apply
      </SdButton>
    </Card>
  );
};

export default DefaultsTTL;
