import React from "react";
import { Icon } from "@blueprintjs/core";
import SdTheme from "../../../styles/theme";
import type { PreviewEndpoint } from "../../../@types/sd/sandbox";
import type { EndpointV2 } from "../../../@types/sd/sandboxv2";

export interface ServicePreview {
  id?: string;
  host: string;
  port: string;
  protocol: string;
  previewURL: string;
}

interface PreviewURLHelp {
  open: boolean;
  httpURL?: string;
  grpcURL?: string;
}

export type Preview = ServicePreview | PreviewEndpoint;
export type SetPreviewURLHelp = (help: PreviewURLHelp) => void;

export interface FormattedEndpoint {
  name: string;
  target: string;
  previewURL: {
    text: string;
    component: React.ReactNode;
  };
}

const queryIdentifier = "preview_endpoints";

const getHelpIcon = (
  previewURL: string,
  protocol: string | undefined,
  setPreviewURLHelp: SetPreviewURLHelp
) => {
  let helpIconEl = null;
  if (protocol) {
    helpIconEl = setPreviewURLHelp ? (
      <div style={{ position: "relative", bottom: 2, cursor: "pointer" }}>
        <span className="ml-1" />
        <Icon
          icon="help"
          color={SdTheme.Icon.lightBackground}
          iconSize={14}
          onClick={(e) => {
            switch (protocol) {
              case "http":
              case "https":
                e.stopPropagation();
                setPreviewURLHelp({
                  open: true,
                  httpURL: previewURL,
                });
                break;
              case "grpc":
                e.stopPropagation();
                setPreviewURLHelp({
                  open: true,
                  grpcURL: previewURL,
                });
                break;
              default:
                // Shouldn't hit this case.
                break;
            }
          }}
        />
      </div>
    ) : null;
  }
  return helpIconEl;
};

const getHyperlink = (previewURL: string) => {
  if (!previewURL) {
    return null;
  }
  return (
    <a
      style={{ fontSize: "0.9em", color: SdTheme.Link.lightBackground }}
      href={previewURL}
      onClick={(e) => e.stopPropagation()}
      target="_blank"
      rel="noreferrer"
    >
      {previewURL}
    </a>
  );
};

const formatEndpoints = (endpoints: EndpointV2[]): FormattedEndpoint[] =>
  endpoints.map((endpoint) => ({
    name: endpoint.name,
    target: endpoint.target,
    previewURL: {
      text: endpoint.url,
      component: getHyperlink(endpoint.url),
    },
  }));

export { formatEndpoints, queryIdentifier, getHyperlink, getHelpIcon };
