import React from "react";
import { useNavigate } from "react-router-dom";
import type { MaybeElement } from "@blueprintjs/core";
import { Icon, Intent } from "@blueprintjs/core";
import type { IconName } from "@blueprintjs/icons";
import styles from "./TopBarItem.module.css";

interface TopBarItemProps {
  icon?: IconName | MaybeElement | string;
  url: string;
  highlight?: boolean;
  children: React.ReactNode;
}

const TopBarItem: React.FC<TopBarItemProps> = ({
  icon,
  url,
  highlight,
  children,
}) => {
  const navigate = useNavigate();
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={`${styles.container} ${highlight ? styles.highlight : ""}`}
      onClick={() => {
        if (url.startsWith("https")) {
          window.open(url, "_blank")!.focus();
        } else {
          navigate(url);
        }
      }}
    >
      <span className="ml-3" />
      {icon && (
        <Icon
          icon={icon as IconName | MaybeElement}
          size={16}
          intent={Intent.NONE}
        />
      )}
      {children}
    </div>
  );
};

export default TopBarItem;
