import React from "react";
import { Icon } from "@blueprintjs/core";
import styles from "./TimeInfo.module.css";
import { getTimeAgoElement } from "../../../../../../util/Util";
import type { TestExecution } from "../../../../../../@types/sd/testexecutions";

interface Props {
  execution: TestExecution;
}

// eslint-disable-next-line import/prefer-default-export
const TimeInfo: React.FC<Props> = ({ execution }) => (
  <div className={styles.duration}>
    <div>
      <Icon icon="calendar" size={12} />
      <span>Created {getTimeAgoElement(execution.createdAt)}</span>
    </div>
  </div>
);

export default TimeInfo;
