import React from "react";
import type { TrafficDiffFinding } from "../../../../../../@types/sd/findings";
import styles from "./ServiceDescription.module.css";
import { getServiceUrl } from "./util";
import StatusBadge from "./StatusBadge";
import type { FindingDiffOp } from "../../../../../../@types/sd/findings";

type ServiceDescriptionProps = {
  finding: TrafficDiffFinding;
  statusDiffOps?: FindingDiffOp;
  // eslint-disable-next-line react/no-unused-prop-types
  testScript: string;
};

// TODO: Let's rename it's name to PairedTrafficFindingsTitle
// Skipping for now as both David and I (James) are working on different parts of the GroupedView
// and this might lead to merge conflicts.
const ServiceDescription = ({ finding, statusDiffOps }: ServiceDescriptionProps) => {
  const serviceUrl = getServiceUrl(finding);
  // const testCodeModal = useDisclosure(false);

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.requestInfo}>
          <div className={styles.proto_http11}>{finding.proto}</div>
          <div className={styles.url}>{serviceUrl}</div>
          {!statusDiffOps && (
            <StatusBadge status={finding.responseStatus} />
          )}
        </div>
      </div>

      {/* <Tooltip2 */}
      {/*  isOpen={testCodeModal.isOpen} */}
      {/*  // onClose={testCodeModal.close} */}
      {/*  content={ */}
      {/*    <PanelGroup */}
      {/*      direction="horizontal" */}
      {/*      style={{ height: "600px", width: 400 }} */}
      {/*    > */}
      {/*      <Panel> */}
      {/*        <Editor */}
      {/*          language="python" */}
      {/*          value={testScript} */}
      {/*          staticCodeLens={[ */}
      {/*            { */}
      {/*              id: finding.pairingID, */}
      {/*              command: { */}
      {/*                id: finding.pairingID, */}
      {/*                title: `Snippet for ${ */}
      {/*                  finding.source.requestName ?? finding.pairingID */}
      {/*                }`, */}
      {/*              }, */}
      {/*              range: { */}
      {/*                endColumn: finding.source.scriptRef.col ?? 0, */}
      {/*                startColumn: finding.source.scriptRef.col ?? 0, */}
      {/*                endLineNumber: finding.source.scriptRef.line ?? 0, */}
      {/*                startLineNumber: finding.source.scriptRef.line ?? 0, */}
      {/*              }, */}
      {/*            }, */}
      {/*          ]} */}
      {/*        /> */}
      {/*      </Panel> */}
      {/*    </PanelGroup> */}
      {/*  } */}
      {/* > */}
      {/*  <div className={styles.source} onClick={testCodeModal.toggle}> */}
      {/*    <Icon icon="code" color="black" /> */}
      {/*  </div> */}
      {/* </Tooltip2> */}
    </div>
  );
};

export default ServiceDescription;
