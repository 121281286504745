import React from "react";
import type { ClusterResponse } from "../../Clusters";
import styles from "./PlaygroundClusterInfo.module.css";
import {
  Healthy,
  useGetPlaygroundStatusApi,
} from "../../../../api/PlaygroundApi";
import PlaygroundInfoContent from "./PlaygroundInfoContent";

interface PlaygroundClusterInfoProps {
  cluster: ClusterResponse;
}

const PlaygroundClusterInfo: React.FC<PlaygroundClusterInfoProps> = ({
  cluster,
}) => {
  const [isHealthy, setIsHealthy] = React.useState(false);
  const { isLoading, data } = useGetPlaygroundStatusApi(cluster.name, {
    refetchInterval: isHealthy ? false : 5000,
  });

  React.useEffect(() => {
    setIsHealthy(data?.status === Healthy);
  }, [data]);

  return (
    <div className={styles.container}>
      <PlaygroundInfoContent isLoading={isLoading} status={data?.status} />
    </div>
  );
};

export default PlaygroundClusterInfo;
