// Common values and constants
import _ from "lodash";
import React from "react";
import { Button, Icon } from "@blueprintjs/core";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import TimeAgo from "javascript-time-ago";
import moment from "moment";
// @ts-ignore
import en from "javascript-time-ago/locale/en";
import { MdOutlineWatchLater } from "react-icons/md";
import { parseStatus } from "./StatusUtil/StatusUtils";
import StatusIndicator from "./StatusUtil/StatusIndicator";
import type { Sandbox, SandboxStatus } from "../@types/sd/sandbox";
import HoverUnderline from "../components/HoverUnderline";
import SdTheme from "../styles/theme";
import type { SandboxV2 } from "../@types/sd/sandboxv2";
import type { RouteGroup } from "../@types/sd/routegroup";

export interface FormattedSandboxV2
  extends Omit<SandboxV2, "createdAt" | "updatedAt" | "name" | "status"> {
  id: string;
  labels?: string;
  clusterName: {
    text: string | null;
    component: React.ReactNode;
  };
  name: {
    text: string | null;
    component: React.ReactNode;
  };
  description: {
    text: string | null;
    component: React.ReactNode;
  };
  status: {
    text: string | null;
    component: React.ReactNode;
  };
  createdAt: React.ReactNode;
  updatedAt: React.ReactNode;
  icon: React.ReactNode;
}

export interface FormattedSandbox
  extends Omit<
    Sandbox,
    | "createdAt"
    | "updatedAt"
    | "description"
    | "name"
    | "clusterName"
    | "labels"
  > {
  labels?: string;
  clusterName: {
    text: string | null;
    component: React.ReactNode;
  };
  name: {
    text: string | null;
    component: React.ReactNode;
  };
  description: {
    text: string | null;
    component: React.ReactNode;
  };
  status: {
    text: string | null;
    component: React.ReactNode;
  };
  createdAt: React.ReactNode;
  updatedAt: React.ReactNode;
  icon: React.ReactNode;
}

interface TruncateEllipsisProps {
  width?: string | number;
}

interface DeleteIconProps {
  hasTTL: boolean;
  tooltipMessage?: string;
}

interface TTLIconProps {
  tooltipMessage?: string;
}

interface FormattedTTL {
  text: string;
  date: moment.Moment;
}

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-US");

const dateTimeFormat = "dddd, MMMM Do YYYY, h:mm:ss a";

const TruncateEllipsis: React.FunctionComponent<
  TruncateEllipsisProps & { children: React.ReactNode }
> = ({ width, children }) => (
  <span
    style={{
      display: "block",
      width,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
  >
    {children}
  </span>
);

const getTimeAgoFromNow = (date: string) => {
  const deletion = moment(date);
  if (!deletion.isValid()) return "";

  return timeAgo.format(deletion.toDate(), {
    future: deletion.isAfter(moment.now()),
  });
};

const formatTTL = (
  resource: SandboxV2 | RouteGroup,
  customFormat?: (deletionDate: moment.Moment) => string
): FormattedTTL | undefined => {
  if (!resource.spec.ttl) {
    return;
  }

  let offsetFromDate = moment(resource.createdAt);
  if (resource.spec.ttl.offsetFrom === "updatedAt") {
    offsetFromDate = moment(resource.updatedAt);
  }
  const duration = resource.spec.ttl.duration;
  const durationValue = parseInt(
    resource.spec.ttl.duration.substring(0, duration.length - 1),
    10
  );
  const durationUnit = duration[duration.length - 1] as moment.unitOfTime.Base;
  // MomentJS naturally supports the same units we use for TTL as the unit of time to add to
  // a date. (aka: m/h/d/w)
  const deletionDate = offsetFromDate.add(durationValue, durationUnit);
  const content = `Scheduled for deletion ${timeAgo.format(
    deletionDate.toDate(),
    {
      future: deletionDate.isAfter(moment.now()),
    }
  )}`;

  return {
    text: customFormat?.(deletionDate) || content,
    date: deletionDate,
  };
};

export const DeleteIcon = React.memo<DeleteIconProps>(
  ({ hasTTL, tooltipMessage }) => {
    if (!hasTTL) {
      return (
        <Button minimal icon="trash" color={SdTheme.Icon.lightBackground} />
      );
    }

    return (
      <Popover2>
        <Tooltip2 content={tooltipMessage} intent="none" hoverOpenDelay={250}>
          <Button minimal color={SdTheme.Icon.lightBackground}>
            <Icon icon="trash" />
            <div style={{ position: "relative" }}>
              <span
                style={{
                  background: "white",
                  borderRadius: "50%",
                  position: "absolute",
                  bottom: -3,
                  right: -3,
                }}
              >
                <MdOutlineWatchLater size={13} />
              </span>
            </div>
          </Button>
        </Tooltip2>
      </Popover2>
    );
  }
);
DeleteIcon.displayName = "DeleteIcon";

export const TTLIconWithTooltip = React.memo<TTLIconProps>(
  ({ tooltipMessage }) => (
    <Popover2>
      <Tooltip2 content={tooltipMessage} intent="none" hoverOpenDelay={250}>
        <MdOutlineWatchLater size={13} />
      </Tooltip2>
    </Popover2>
  )
);
TTLIconWithTooltip.displayName = "TTLIconWithTooltip";

const formatDateTime = (dt: string): string => {
  const mDate = moment(dt);
  if (mDate.isValid()) {
    return mDate.format(dateTimeFormat);
  }
  return dt;
};

export const getTimeAgo = (
  dt: string | null | undefined
): string | undefined => {
  if (!dt) {
    return undefined;
  }
  const mDate = moment(dt);
  if (mDate.isValid()) {
    return timeAgo.format(mDate.toDate());
  }
  return undefined;
};
const getTimeAgoElement = (dt: string | null | undefined): React.ReactNode => {
  // If Moment is given something such as undefined as the parameter to parse, it will create
  // a value at the current time. So we need to explicitly detect an unset date to be able
  // to return a blank value.
  if (!dt) {
    return undefined;
  }
  const mDate = moment(dt);
  if (mDate.isValid()) {
    const tooltipDate = formatDateTime(dt as string);
    const printDate = timeAgo.format(mDate.toDate());

    return (
      <Popover2>
        <Tooltip2
          content={tooltipDate}
          intent="none"
          placement="top"
          hoverOpenDelay={250}
        >
          <span style={{ whiteSpace: "nowrap" }}>
            <HoverUnderline>{printDate}</HoverUnderline>
          </span>
        </Tooltip2>
      </Popover2>
    );
  }
  return undefined;
};

const formatSandbox = (
  sandbox?: Sandbox,
  sandboxStatus?: SandboxStatus
): FormattedSandbox | null => {
  if (!sandbox) {
    return null;
  }
  const preparedStatus = parseStatus(sandboxStatus);
  // Fallback for old sandboxes that were not created with a name.
  const name = sandbox.name ?? "";
  return {
    ...sandbox,
    labels: sandbox.labels
      ? Object.keys(sandbox.labels)
          .map((key) => `${key} ${sandbox.labels?.[key]}`)
          ?.join(" ")
      : undefined,
    clusterName: {
      text: sandbox.clusterName,
      component: (
        <TruncateEllipsis width={130}>{sandbox.clusterName}</TruncateEllipsis>
      ),
    },
    name: {
      text: name,
      component: <div style={{ whiteSpace: "nowrap" }}>{name}</div>,
    },
    description: {
      text: sandbox.description,
      component: (
        <TruncateEllipsis width={180}>{sandbox.description}</TruncateEllipsis>
      ),
    },
    status: {
      text: preparedStatus && preparedStatus.statusText,
      component: <StatusIndicator status={preparedStatus} placement="right" />,
    },
    createdAt: getTimeAgoElement(sandbox.createdAt),
    updatedAt: getTimeAgoElement(sandbox.updatedAt),
    icon: <Button minimal icon="trash" color={SdTheme.Icon.lightBackground} />,
  };
};

const formatSandboxes = (
  sandboxes: Sandbox[],
  statuses: SandboxStatus[] = []
): FormattedSandbox[] => {
  if (_.isEmpty(sandboxes)) {
    return [];
  }

  const statusMap = statuses.reduce((map, obj) => {
    // eslint-disable-next-line no-param-reassign
    map[obj.id] = obj;
    return map;
  }, {});

  return sandboxes.map((ws) => formatSandbox(ws, statusMap[ws.id])!);
};

const formatSandboxV2 = (
  sandbox?: SandboxV2,
  sandboxStatus?: SandboxStatus
): FormattedSandboxV2 | null => {
  if (!sandbox) {
    return null;
  }
  const preparedStatus = parseStatus(sandboxStatus);
  // Old sandboxes don't have names.
  const name = sandbox.name ?? "";
  return {
    ...sandbox,
    id: sandbox.routingKey,
    labels: sandbox.spec.labels
      ? Object.keys(sandbox.spec.labels)
          .map((key) => `${key} ${sandbox.spec.labels?.[key]}`)
          ?.join(" ")
      : undefined,
    clusterName: {
      text: sandbox.spec.cluster,
      component: (
        <TruncateEllipsis width={130}>{sandbox.spec.cluster}</TruncateEllipsis>
      ),
    },
    name: {
      text: name,
      component: <div style={{ whiteSpace: "nowrap" }}>{name}</div>,
    },
    description: {
      text: sandbox.spec.description,
      component: (
        <TruncateEllipsis width={180}>
          {sandbox.spec.description}
        </TruncateEllipsis>
      ),
    },
    status: {
      text: preparedStatus && preparedStatus.statusText,
      component: <StatusIndicator status={preparedStatus} placement="right" />,
    },
    createdAt: getTimeAgoElement(sandbox.createdAt),
    updatedAt: getTimeAgoElement(sandbox.updatedAt),
    icon: (
      <DeleteIcon
        hasTTL={!!sandbox.spec.ttl}
        tooltipMessage={formatTTL(sandbox)?.text}
      />
    ),
  };
};

const formatSandboxesV2 = (
  sandboxes: SandboxV2[],
  statuses: SandboxStatus[] = []
): FormattedSandboxV2[] => {
  if (_.isEmpty(sandboxes)) {
    return [];
  }

  const statusMap = statuses.reduce((map, obj) => {
    // eslint-disable-next-line no-param-reassign
    map[obj.id] = obj;
    return map;
  }, {});

  return sandboxes.map((ws) => formatSandboxV2(ws, statusMap[ws.routingKey])!);
};

const isProd = () => process.env.SIGNADOT_ENV === "production";

export {
  formatTTL,
  formatSandbox,
  formatSandboxes,
  formatSandboxV2,
  formatSandboxesV2,
  formatDateTime,
  getTimeAgoElement,
  getTimeAgoFromNow,
  isProd,
};
