import React from "react";
import Table from "../../../components/Table/Table";
import { formatEndpoints } from "./Util";
import Spacer from "../../../components/Util/Util";
import SdTheme from "../../../styles/theme";
import type { EndpointV2 } from "../../../@types/sd/sandboxv2";
import { SdHeading2 } from "../../../components/theming/SdHeading";

interface Props {
  endpoints?: EndpointV2[];
}

const columns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Target",
    accessor: "target",
  },
  {
    Header: "URL",
    accessor: "previewURL",
  },
];

const PreviewEndpoints: React.FunctionComponent<Props> = function ({
  endpoints,
}) {
  return (
    <div className="pt-12">
      <SdHeading2 small lightBackground>
        Preview Endpoints
      </SdHeading2>
      <p style={{ color: SdTheme.Text.lightBackground }}>
        Endpoints to services within Kubernetes clusters.&nbsp;
        <a
          style={{ color: SdTheme.Link.lightBackground }}
          href="https://www.signadot.com/docs/reference/route-groups/spec#specendpoints"
          target="_blank"
          rel="noreferrer"
        >
          (docs)
        </a>
      </p>
      <Spacer />
      {Array.isArray(endpoints) && endpoints?.length > 0 ? (
        <Table columns={columns} data={formatEndpoints(endpoints!)} />
      ) : (
        "None"
      )}
    </div>
  );
};

export default PreviewEndpoints;
