import React from "react";
import Banner from "../../../components/theming/banner/Banner";
import { HandWaveIcon } from "../../../components/theming/icons";
import ActionLink from "../../../components/theming/ActionLink";
import { OPEN_IN_NEW_TAB } from "../../../Constants";

const PlaygroundSandboxBanner = () => (
  <Banner
    icon={<HandWaveIcon />}
    title="This sandbox exists in a playground cluster"
  >
    <div>
      {/*
        TODO:
        Ideally, we'd want to take the user to the cluster detail page directly. But the page currently needs passing
        the cluster information in state which isn't a good approach. So, we should first refactor the page to be self
        sufficient, and depend only on the cluster name from the URL path parameter.
      */}
      Tip: For information to access the playground Kubernetes cluster using
      kubectl, click the playground-cluster on the{" "}
      <ActionLink
        onClick={() => window.open("/settings/clusters", OPEN_IN_NEW_TAB)}
      >
        Clusters page
      </ActionLink>
      .
    </div>
  </Banner>
);

export default PlaygroundSandboxBanner;
