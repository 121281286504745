import React from "react";

import { Intent, OverlayToaster } from "@blueprintjs/core";
import style from "./CreatePlayground.module.css";
import { useCreatePlaygroundApi } from "../../../api/PlaygroundApi";
import BorderButton from "../../../components/theming/button/BorderButton";
import Banner from "../../../components/theming/banner/Banner";
import { PlaygroundIcon } from "../../../components/theming/icons";
import type { ClusterResponse } from "../Clusters";
import PlaygroundClusterInfo from "./PlaygroundClusterInfo";
import { ClustersRequestPlaygroundCluster } from "../../../Constants";

const toaster = OverlayToaster.create();

interface PlaygroundClusterBannerProps {
  cluster?: ClusterResponse;
}

const PlaygroundClusterBanner: React.FC<PlaygroundClusterBannerProps> = ({
  cluster,
}) => {
  const [createPlayground, createPlaygroundApi] = useCreatePlaygroundApi();

  if (createPlaygroundApi.isLoading) {
    return <p>Creating a playground. Please wait.</p>;
  }
  if (createPlaygroundApi.isError) {
    toaster.show({
      message: createPlaygroundApi.error.response.data.error,
      intent: Intent.DANGER,
    });
    createPlaygroundApi.reset();
    return <p>There was an error. Please try again after a while.</p>;
  }
  if (createPlaygroundApi.isSuccess) {
    toaster.show({
      message:
        "Playground cluster is being set up. This may take up to 2 minutes.",
      intent: Intent.SUCCESS,
    });
    createPlaygroundApi.reset();
  }
  return (
    <Banner icon={<PlaygroundIcon />} title="Playground Cluster">
      <div className={style.container}>
        <span>
          {cluster ? (
            <PlaygroundClusterInfo cluster={cluster} />
          ) : (
            <div>
              Don&apos;t have a Kubernetes cluster available to connect?
              <BorderButton
                onClick={createPlayground}
                eventName={ClustersRequestPlaygroundCluster}
              >
                Request a playground cluster
              </BorderButton>
            </div>
          )}
        </span>
      </div>
    </Banner>
  );
};

export default PlaygroundClusterBanner;
