import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Intent, OverlayToaster } from "@blueprintjs/core";
import styles from "./Triggers.module.css";
import PaginatedTable from "../../../../../../components/PaginatedTable/PaginatedTable";
import type {
  BaseTestSpec,
  Test,
  TestTrigger,
} from "../../../../../../@types/sd/testspec";
import EmptyTriggers from "./EmptyTriggers/EmptyTriggers";
import useDisclosure from "../../../../../../hooks/UseDisclosure";
import TriggerEditor from "./TriggerEditor";
import { useSaveTest } from "../../../../../../api/TestsApi";
import SdButton from "../../../../../../components/theming/SdButton";

const toaster = OverlayToaster.create();

interface Props {
  test: Test;
}

const columns = [
  {
    Header: "Namespace / Name",
    accessor: "service",
    search: true,
  },
  {
    Header: "Cluster",
    accessor: "cluster",
  },
  {
    Header: "Kind",
    accessor: "kind",
  },
  {
    Header: "Action",
    accessor: "actions",
  },
];

const formatTriggers = (
  triggers: TestTrigger[],
  onDeleteTrigger: (triggerID: string) => void
) =>
  triggers.map((t) => ({
    kind: t.sandboxOf.kind,
    namespace: t.sandboxOf.namespace,
    cluster: t.executionTemplate.cluster,
    service: `${t.sandboxOf.namespace}/${t.sandboxOf.name}`,
    id: t.id,
    actions: (
      <ButtonGroup>
        <Button
          minimal
          icon="trash"
          onClick={() => {
            onDeleteTrigger(t.id);
          }}
        />
      </ButtonGroup>
    ),
  }));

const Triggers: React.FC<Props> = ({ test }) => {
  const createTriggerDrawer = useDisclosure(false);
  const [selectedTrigger, setSelectedTrigger] = useState<TestTrigger>();
  const triggers = test.spec.triggers;

  const noTriggersSet = !triggers || triggers.length === 0;

  const saveTestSpecApi = useSaveTest(
    () => {
      toaster.show({
        message: "Triggers updated",
        intent: Intent.SUCCESS,
      });
    },
    (apiError) => {
      toaster.show({
        message: apiError.response.data.error,
        intent: Intent.DANGER,
      });
    }
  );

  useEffect(() => {
    setSelectedTrigger(undefined);
  }, [test]);

  const onDeleteTrigger = (triggerID: string) => {
    if (!triggers) return;

    const newTriggers = triggers.filter((t) => t.id !== triggerID);
    const updatedTest: BaseTestSpec = {
      ...test.spec,
      triggers: newTriggers,
    };

    saveTestSpecApi.mutate({
      url: `/api/v2/orgs/:orgName/tests/${test?.name}`,
      data: updatedTest,
    });
  };

  const onSaveTrigger = (trigger: TestTrigger) => {
    const newTriggers: TestTrigger[] = [...(triggers || []), trigger];
    const updatedTest: BaseTestSpec = {
      ...test.spec,
      triggers: newTriggers,
    };

    createTriggerDrawer.close();
    saveTestSpecApi.mutate({
      url: `/api/v2/orgs/:orgName/tests/${test?.name}`,
      data: updatedTest,
    });
  };

  return (
    <div className={styles.container}>
      {noTriggersSet ? (
        <EmptyTriggers
          onAddFirstTrigger={createTriggerDrawer.open}
          isShowingNewTrigger={createTriggerDrawer.isOpen}
        />
      ) : (
        <>
          <PaginatedTable
            data={formatTriggers(test.spec.triggers || [], onDeleteTrigger)}
            columns={columns}
          />
        </>
      )}

      {createTriggerDrawer.isOpen ? (
        <TriggerEditor
          isOpen={createTriggerDrawer.isOpen}
          trigger={selectedTrigger}
          onSave={onSaveTrigger}
          onCancel={() => {
            setSelectedTrigger(undefined);
            createTriggerDrawer.close();
          }}
        />
      ) : (
        !noTriggersSet && (
          <SdButton icon="plus" onClick={createTriggerDrawer.open}>
            Add
          </SdButton>
        )
      )}
    </div>
  );
};

export default Triggers;
