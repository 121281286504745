import React from "react";
import { Tag } from "@blueprintjs/core";
import styles from "./JobDetailStatus.module.css";
// @ts-ignore
// eslint-disable-next-line import/extensions
import type { AttemptPhaseEnum } from "../../../../../@types/sd/job.d.ts";

type JobState = AttemptPhaseEnum;

interface JobDetailStatusProps {
  status: JobState;
}

// Not too great an approach, but it works for now.
const jobStatusColorMap: Record<JobState, string> = {
  queued: styles.queued,
  running: styles.running,
  succeeded: styles.succeeded,
  canceled: styles.canceled,
  failed: styles.failed,
};

const JobDetailStatus: React.FC<JobDetailStatusProps> = ({ status }) => (
  <Tag round className={`${styles.icon} ${jobStatusColorMap[status]}`}>
    {status}
  </Tag>
);

export default JobDetailStatus;
