import { HTMLSelect } from "@blueprintjs/core";
import React, { useEffect, useMemo } from "react";
import { useManagedRunnerGroups } from "../../../../../api/ManagedRunnerGroupsApi";

type ClusterSelectorProps = {
  value?: string;
  onChange: (clusterName: string) => void;
  unsetInitial?: boolean;
  fill?: boolean;
};

const ClusterSelector = ({
  value,
  onChange,
  unsetInitial,
  fill,
}: ClusterSelectorProps) => {
  const managedRgsApi = useManagedRunnerGroups();
  const clusterNames = useMemo(() => {
    if (!managedRgsApi.data) {
      return null;
    }

    const clustersMapping = Object.entries(managedRgsApi.data.clusters);

    return clustersMapping
      .filter(([_, settings]) => settings.eligible)
      .map(([clusterName]) => ({
        name: clusterName,
        id: clusterName,
      }));
  }, [managedRgsApi.data]);

  useEffect(() => {
    if (unsetInitial) {
      return;
    }

    if (
      (!value || value.length === 0) &&
      clusterNames &&
      clusterNames.length > 0
    ) {
      onChange(clusterNames[0].name);
    }
  }, [clusterNames]);

  if (!clusterNames) {
    return <HTMLSelect />;
  }

  return (
    <HTMLSelect
      value={value}
      onChange={(item) => onChange(item.target.value)}
      fill={fill}
    >
      {unsetInitial && !value && <option value="@">Select a cluster</option>}

      {clusterNames.map((cluster) => (
        <option key={cluster.id} value={cluster.name}>
          {cluster.name}
        </option>
      ))}
    </HTMLSelect>
  );
};

export default ClusterSelector;
