export interface Source {
  id: string;
  scriptRef: {
    file: string;
    line?: number;
    col?: number;
    callNum: number;
  };
  requestName?: string;
}

// eslint-disable-next-line no-shadow
export enum DiffCat {
  Red = "Red",
  Yellow = "Yellow",
  Green = "Green",
}

// eslint-disable-next-line no-shadow
export enum FindingOp {
  Add = "add",
  Remove = "remove",
  Replace = "replace",
  Copy = "copy",
}

export interface FindingDiffOp {
  op: FindingOp;
  path: string;
  value?: any;
  oldValue?: any;
  relevance?: number;
  isPrimary?: boolean;
}

export interface TrafficDiffFinding {
  pairingID: string;
  host: string;
  port: number;
  proto: string;
  method: string;
  path: string;
  queryParams?: Record<string, any>;
  responseStatus: number;
  source: Source;
  relevance: number;
  category: DiffCat;
  diffOps: FindingDiffOp[];
}

export interface Findings {
  findings: TrafficDiffFinding[];
}

// eslint-disable-next-line no-shadow
export enum FindingKind {
  Header = "Header",
  Body = "Body",
  Metadata = "Metadata",
  Query = "Query",
}
