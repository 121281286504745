import React from "react";
import Wrapper from "../../Wrapper";

const SSONotEnabled: React.FC = () => (
  <Wrapper>
    <div>
      Please{" "}
      <a
        href="mailto:info@signadot.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        contact us
      </a>{" "}
      to enable SSO for your org.
    </div>
  </Wrapper>
);

export default SSONotEnabled;
