import type { Monaco } from "@monaco-editor/react";
import type { ILanguageDefinition } from "../language";
import { LANGUAGE_NAME } from "./constant";
import { BASE_DARK_THEME, SIGNADOT_DARK_THEME } from "../../constants";

const logsImpl: ILanguageDefinition = {
  beforeMount(monaco) {
    monaco.languages.register({ id: LANGUAGE_NAME });

    // Register a tokens provider for the language
    monaco.languages.setMonarchTokensProvider(LANGUAGE_NAME, {
      tokenizer: {
        root: [
          [/^Run finished.*/, "custom-date"],
          [/^Run failed with.*/, "custom-error"],
          [/\[info.*/, "custom-info"],
        ],
      },
    });

    // Define a new theme that contains only rules that match this language
    monaco.editor.defineTheme(SIGNADOT_DARK_THEME, {
      base: BASE_DARK_THEME,
      inherit: true,
      rules: [
        { token: "custom-info", foreground: "808080" },
        { token: "custom-error", foreground: "ff0000", fontStyle: "bold" },
        { token: "custom-notice", foreground: "FFA500" },
        { token: "custom-date", foreground: "008800" },
      ],
      colors: {},
    });

    monaco.languages.registerCompletionItemProvider(LANGUAGE_NAME, {
      provideCompletionItems: (model, position) => {
        const word = model.getWordUntilPosition(position);
        const range = {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn,
        };
        const suggestions = [
          {
            label: "simpleText",
            kind: monaco.languages.CompletionItemKind.Text,
            insertText: "simpleText",
            range: range,
          },
          {
            label: "testing",
            kind: monaco.languages.CompletionItemKind.Keyword,
            // eslint-disable-next-line no-template-curly-in-string
            insertText: "testing(${1:condition})",
            insertTextRules:
              monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            range: range,
          },
          {
            label: "ifelse",
            kind: monaco.languages.CompletionItemKind.Snippet,
            insertText: [
              // eslint-disable-next-line no-template-curly-in-string
              "if (${1:condition}) {",
              "\t$0",
              "} else {",
              "\t",
              "}",
            ].join("\n"),
            insertTextRules:
              monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            documentation: "If-Else Statement",
            range: range,
          },
        ];
        return { suggestions: suggestions };
      },
    });
  },
  onMonacoAvailable(monaco: Monaco) {

  },
};

export default logsImpl;
