import React from "react";
import { useClusterEndpoints } from "../../../RouteGroups/CreateRouteGroup";
import EndpointSelect from "../../../RouteGroups/EndpointSelect";
import { HTTP_PROTOCOL } from "../../SavedRequestCatalog/types";

interface Props {
  clusterName: string;
  onChange: (protocol: string, hostname: string) => void;
  selectedProtocol?: string;
  selectedHost?: string;
}

const SelectService: React.FC<Props> = ({
  clusterName,
  onChange,
  selectedProtocol,
  selectedHost,
}) => {
  // Reusing the function and component created for RouteGroup.
  const clusterEndpoints = useClusterEndpoints(clusterName);
  return (
    <EndpointSelect
      items={clusterEndpoints}
      onChange={onChange}
      supportedProtocols={[HTTP_PROTOCOL]}
      selectedProtocol={selectedProtocol}
      selectedHost={selectedHost}
    />
  );
};

export default SelectService;
