import React from "react";
import styled from "styled-components";
import { MdFindInPage } from "react-icons/md";
import styles from "./EmptyFindings.module.css";
import CreateFirst from "../../../../../components/CreateFirst";

const ContainerMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const EmptyFindings = () => (
  <div className={styles.container}>
    <CreateFirst
      icon={<MdFindInPage size={80} />}
      message={
        <ContainerMessage>
          <p>
            No findings available with the current filters. Try changing the
            filters in the control section.
          </p>
        </ContainerMessage>
      }
    />
  </div>
);

export default EmptyFindings;
