import { Tag } from "@blueprintjs/core";
import React from "react";
import type { RouteGroup } from "../../@types/sd/routegroup";
import DetailItem from "../../components/DetailItem";

interface Props {
  routeGroup: RouteGroup;
}

const MatchesDetailItem: React.FunctionComponent<Props> = ({ routeGroup }) => {
  if (!routeGroup.spec.match) {
    return null;
  }

  let matches = routeGroup.spec.match.all;
  let isAll = true;
  if (!matches) {
    isAll = false;
    matches = routeGroup.spec.match.any;
  }
  if (!matches) {
    return null;
  }

  let title = "Label matches";
  if (matches.length > 1) {
    if (isAll) {
      title += " (all of)";
    } else {
      title += " (any of)";
    }
  }

  const content = (
    <>
      {matches.map((match) => (
        <Tag key={match.label?.key} className="mr-1">
          {match.label?.key}: {match.label?.value}
        </Tag>
      ))}
    </>
  );

  return <DetailItem title={title} value={content} />;
};

export default MatchesDetailItem;
