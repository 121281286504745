import { Intent } from "@blueprintjs/core";
import type { Usage, UsageItem } from "../../../@types/sd/billing";
import { INFO_THRESHOLD, WARN_THRESHOLD } from "../BillingBanner/utils";

export type FormattedItem = {
  label: string;
  title: string;
  subTitle?: string;
  percentageUsage: number;
  color: Intent;
};

const UNLIMITED = -1;
const UNLIMITED_STRING = "unlimited";
const UNLIMITED_PERCENTAGE = 3;

export const LIMIT = "limit";
export const QUOTA = "quota";

const getUsagePercentage = (used: number, allowed: number): number => {
  if (allowed === UNLIMITED) {
    return used === 0 ? 0 : UNLIMITED_PERCENTAGE;
  }
  const percentage = (used * 100.0) / allowed;
  return Math.ceil(Math.min(100, percentage));
};

const getUsageColor = (percentage: number): Intent => {
  if (percentage >= WARN_THRESHOLD) {
    return Intent.DANGER;
  }
  if (percentage >= INFO_THRESHOLD) {
    return Intent.WARNING;
  }
  return Intent.SUCCESS;
};

const formatMessage = (usageItem: UsageItem) => {
  const { used } = usageItem;
  let allowed;
  let suffix;

  if (usageItem.type === QUOTA) {
    // quota
    allowed = usageItem.cap === UNLIMITED ? UNLIMITED_STRING : usageItem.cap;
    suffix = "this month";
  } else if (usageItem.type === LIMIT) {
    // limit
    allowed = usageItem.cap === UNLIMITED ? UNLIMITED_STRING : usageItem.cap;
    suffix = "total";
  }
  return `${used} of ${allowed} ${suffix}`;
};

// eslint-disable-next-line import/prefer-default-export
export const getFormattedUsage = (usage: Usage): FormattedItem[] => {
  const result = [];
  const { job, sandbox, cluster, resourcePlugin } = usage;

  const formatItem = (label: string, item: UsageItem): FormattedItem => {
    const { cap, used } = item;
    const perc = getUsagePercentage(used, cap);
    const color = getUsageColor(perc);
    return {
      label: label,
      title: formatMessage(item),
      subTitle: item.additionalUnits
        ? `${item.cap} = ${item.base} included in plan + ${item.additionalUnits.lotSize} x ${item.additionalUnits.quantity} additional units`
        : undefined,
      percentageUsage: perc,
      color: color,
    };
  };

  result.push(formatItem("Jobs ", job));
  result.push(formatItem("Sandboxes", sandbox));
  result.push(formatItem("Clusters", cluster));
  result.push(formatItem("Resource Plugins", resourcePlugin));

  return result;
};
