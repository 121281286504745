import {
  Callout,
  Card,
  Dialog,
  Elevation,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import React, { useState } from "react";
import Spacer from "../Util/Util";
import SdButton from "../theming/SdButton";

type Props = {
  clusterName: string;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const DeleteClusterDialog = ({
  clusterName,
  isOpen,
  onCancel,
  onConfirm,
}: Props) => {
  const [userInput, setUserInput] = useState("");

  return (
    <Dialog
      style={{ height: "100%" }}
      icon="info-sign"
      title={`Disconnect cluster: ${clusterName}`}
      isOpen={isOpen}
      onClose={onCancel}
    >
      <Card
        style={{ marginBottom: "-20px" }}
        interactive
        elevation={Elevation.TWO}
      >
        <Callout
          intent={Intent.DANGER}
          style={{ color: "red", fontWeight: 700 }}
        >
          Do you really want to DELETE the cluster connection? (this action
          cannot be undone!)
        </Callout>
        <Spacer />

        <div style={{ lineHeight: "1.5em" }}>
          <b>Important:</b> This operation will not clean up resources used by
          running sandboxes in your cluster. If Signadot Operator is still
          installed in the cluster, please uninstall it before proceeding to
          ensure that all resources are cleaned up.
        </div>
        <div className="pt-5 pb-2">
          Enter the name of the cluster (<b>{clusterName}</b>) below to confirm.
        </div>
        <InputGroup
          id="name"
          aria-label="enter-cluster-name"
          placeholder={clusterName}
          onChange={(e) => setUserInput(e.target.value)}
          style={{
            width: "18em",
            marginBottom: "1em",
          }}
        />
        <SdButton
          danger
          onClick={onConfirm}
          disabled={userInput !== clusterName}
        >
          Disconnect the cluster
        </SdButton>
      </Card>
    </Dialog>
  );
};

export default DeleteClusterDialog;
