import { useMemo } from "react";

import { useSandboxStatusesApi } from "../../../../api/SandboxesApi";

type Status =
  | {
      isLoading: true;
      isError: false;
    }
  | {
      error: string;
      isError: true;
      isLoading: false;
    }
  | {
      isError: false;
      totalSandboxes: number;
      notReady: number;
      isLoading: false;
    };

const useGetStatus = (): Status => {
  const { statuses, error } = useSandboxStatusesApi();
  const waitingOnData = !statuses && !error;
  const notReady = useMemo(() => {
    let total = 0;
    if (waitingOnData || !statuses) {
      return total;
    }
    for (let i = 0; i < statuses.length; i++) {
      if (!statuses[i].ready) {
        total++;
      }
    }
    return total;
  }, [statuses]);

  if (error) {
    return {
      isError: true,
      error: error,
      isLoading: false,
    };
  }

  if (waitingOnData) {
    return {
      isError: false,
      isLoading: true,
    };
  }

  const totalSandboxes = waitingOnData
    ? 0
    : statuses!.filter((sandboxStatus) => sandboxStatus.ready).length;
  return {
    isError: false,
    notReady: notReady,
    totalSandboxes: totalSandboxes,
    isLoading: false,
  };
};

export default useGetStatus;
