import React from "react";
import type { Params } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "@blueprintjs/core";
import { useGetRunnerGroup } from "../../../../api/RunnerGroupApi";
import {
  RunnerGroupDetailClickOverviewTab,
  RunnerGroupDetailClickSpecificationTab,
} from "../../../../Constants";
import Overview from "./tabs/Overview";
import Specification from "./tabs/Specification";
import MultiTabsLayout from "../../../../components/MultiTabsLayout/MultiTabsLayout";
import useDisclosure from "../../../../hooks/UseDisclosure";
import DeleteRunnerGroup from "./DeleteRunnerGroup";

const RunnerGroupDetail: React.FC = () => {
  const navigate = useNavigate();
  const deleteJobState = useDisclosure(false);
  const { runnerGroupName } = useParams<Params>();
  const {
    data: runnerGroup,
    isSuccess,
    isError,
    error,
  } = useGetRunnerGroup(runnerGroupName!);
  if (isError) {
    // @ts-ignore
    return <div>Error: {error?.message}</div>;
  }
  if (!isSuccess || !runnerGroup) {
    return null;
  }

  return (
    <>
      <DeleteRunnerGroup
        runnerGroup={runnerGroup}
        onCancel={deleteJobState.close}
        isOpen={deleteJobState.isOpen}
        close={() => deleteJobState.close()}
      />
      <MultiTabsLayout
        tabs={[
          {
            label: "Overview",
            slug: "overview",
            eventName: RunnerGroupDetailClickOverviewTab,
            content: <Overview runnerGroup={runnerGroup} />,
          },
          {
            label: "Specification",
            slug: "specification",
            eventName: RunnerGroupDetailClickSpecificationTab,
            content: <Specification runnerGroup={runnerGroup} />,
          },
        ]}
        title={runnerGroupName!}
        actions={[
          {
            label: "Delete",
            onClick: deleteJobState.open,
            display: true,
            isRiskAction: true,
            icon: "trash",
          },
        ]}
        breadcrumbs={[
          {
            icon: <Icon icon="walk" />,
            text: "Testing: Job Runner Groups",
            onClick: () => navigate(`/testing/job-runner-groups`),
          },
          {
            text: runnerGroupName,
          },
        ]}
      />
    </>
  );
};

export default RunnerGroupDetail;
