import type { RunnerGroup } from "../../../../@types/sd/runnergroup";

// eslint-disable-next-line import/prefer-default-export
export const filterRunnerGroups = (
  runnerGroups: RunnerGroup[],
  filterText: string
): RunnerGroup[] =>
  runnerGroups.filter((runnerGroup) =>
    filterText
      .trim()
      .split(" ")
      .some(
        (f) =>
          runnerGroup.name.toLowerCase().includes(f.toLowerCase()) ||
          (runnerGroup.spec.labels &&
            Object.keys(runnerGroup.spec.labels).some(
              (key) =>
                key.toLowerCase().includes(f.toLowerCase()) ||
                runnerGroup.spec
                  .labels![key].toLowerCase()
                  .includes(f.toLowerCase())
            ))
      )
  );
