import { useState } from "react";

export default (initialValue: boolean | undefined) => {
  const [isActive, setIsActive] = useState<boolean>(!!initialValue);

  const toggle = () => {
    setIsActive((prev) => !prev);
  };

  const close = () => {
    setIsActive(false);
  };

  const open = () => {
    setIsActive(true);
  };

  return {
    isOpen: isActive,
    toggle,
    close,
    open,
  };
};
