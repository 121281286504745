import React from "react";
import { Icon } from "@blueprintjs/core";
import type { Placement } from "@blueprintjs/popover2";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import { STATUS } from "./StatusUtils";
import SdTheme from "../../styles/theme";

interface Status {
  statusText: string;
}

interface Props {
  status: Status | null;
  iconSize?: number;
  className?: string;
  placement?: Placement;
  color?: string;
  tooltipContent?: string;
}

const StatusIndicator: React.FunctionComponent<Props> = ({
  status,
  color,
  iconSize = 14,
  className = "ml-4",
  placement = "top",
  tooltipContent,
}) => {
  if (!status) {
    return null;
  }

  const { statusText } = status;

  if (!color && !tooltipContent) {
    switch (statusText) {
      case STATUS.Unknown:
        color = SdTheme.Status.warning;
        tooltipContent = "Not Ready";
        break;
      case STATUS.Healthy:
        color = SdTheme.Status.good;
        tooltipContent = "Ready";
        break;
      case STATUS.Unhealthy:
        color = SdTheme.Status.bad;
        tooltipContent = "Error";
        break;
      default:
    }
  }

  if (!color) {
    return null;
  }

  return (
    <span className={className}>
      <Popover2>
        <Tooltip2
          content={tooltipContent}
          intent="none"
          placement={placement}
          hoverOpenDelay={200}
          usePortal
        >
          <Icon icon="full-circle" color={color} iconSize={iconSize} />
        </Tooltip2>
      </Popover2>
    </span>
  );
};

export default StatusIndicator;
