import React, { useEffect, useRef, useState } from "react";
import PaginatedTable from "../../../components/PaginatedTable/PaginatedTable";
import ReplicasInput from "./ReplicasInput";
import type { ManagedRGClusterSettings } from "../../../api/ManagedRunnerGroupsApi";

const CLUSTERS_TABLE_COLUMNS = [
  {
    Header: "Cluster",
    accessor: "name",
    show: true,
  },
  {
    Header: "Runner Pods",
    accessor: "replicas",
    show: true,
  },
];

interface Props {
  clusterSettings: Record<string, ManagedRGClusterSettings>;
  onChange: (newSettings: Record<string, ManagedRGClusterSettings>) => void;
}

const ClustersTable: React.FC<Props> = ({ clusterSettings, onChange }) => {
  const [data, setData] = useState<
    Record<string, ManagedRGClusterSettings> | undefined
  >(clusterSettings);

  const isFirstRender = useRef(true); // Track if it's the first render

  // Update local state when clusterSettings prop changes
  useEffect(() => {
    setData(clusterSettings);
  }, [clusterSettings]);

  // Use useEffect to update parent state after the initial render
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // Mark the first render as complete
    } else {
      // Update only if data has changed. Without this check, the onChange
      // function will be called on every render, and the parent state will
      // be updated even when the data hasn't changed, leading to unnecessary
      // re-renders.
      // eslint-disable-next-line no-lonely-if
      if (data && JSON.stringify(data) !== JSON.stringify(clusterSettings)) {
        onChange(data);
      }
    }
  }, [data, clusterSettings, onChange]);

  const updateClusterData = (clusterName: string, replicas: number) => {
    setData((prev) => {
      if (!prev) return;
      return {
        ...prev,
        [clusterName]: {
          ...prev[clusterName],
          replicas,
        },
      };
    });
  };

  if (!data) {
    return null;
  }

  const clusters = Object.entries(data)
    .filter(([, details]) => details.eligible)
    .map(([clusterName, details]) => ({
      name: clusterName,
      replicas: (
        <ReplicasInput
          initialReplicas={details.replicas}
          onChange={(newCount) => updateClusterData(clusterName, newCount)}
        />
      ),
    }));

  return <PaginatedTable columns={CLUSTERS_TABLE_COLUMNS} data={clusters} />;
};

export default ClustersTable;
