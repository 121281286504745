import React from "react";
import styles from "./Badge.module.css";

interface Props {
  label: string;
}

const Badge: React.FC<Props> = ({ label }) => (
  <div className={styles.container}>{label}</div>
);

export default Badge;
