import React from "react";
import HistoryLink from "../../components/HistoryLink";
import PaginatedTable from "../../components/PaginatedTable/PaginatedTable";
import { SdHeading2 } from "../../components/theming/SdHeading";

interface RouteGroup {
  name: string;
}

function MatchingRouteGroups({ routeGroups }: { routeGroups: RouteGroup[] }) {
  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
  ];

  if (routeGroups.length < 1) {
    return null;
  }
  const formattedData = routeGroups.map((rg) => ({
    name: {
      text: rg.name,
      component: (
        <HistoryLink url={`/routegroups/${rg.name}`}>{rg.name}</HistoryLink>
      ),
    },
  }));
  return (
    <div className="pt-12">
      <SdHeading2 small lightBackground>
        Matching Route Groups
      </SdHeading2>
      <PaginatedTable columns={columns} data={formattedData} />
    </div>
  );
}

export default MatchingRouteGroups;
