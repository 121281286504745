import {
  Button,
  Callout,
  Card,
  Collapse,
  Elevation,
  H4,
  Icon,
  Intent,
  Tag,
  Toaster,
} from "@blueprintjs/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SiKubernetes } from "react-icons/si";
import React, { useState } from "react";
import yaml from "js-yaml";
import { useQueryClient } from "react-query";
import ClusterTokens from "../ClusterTokens";
import CopyableText from "../../components/CopyableText";
import useApi from "../../hooks/UseApi";
import { getTimeAgoElement } from "../../util/Util";
import { parseStatus, STATUS } from "../../util/StatusUtil/StatusUtils";
import ResourceStatus from "../../components/ResourceStatus";
import DetailItem from "../../components/DetailItem";
import { useGetPlaygroundApi } from "../../api/PlaygroundApi";
import type { ClusterStatus } from "../../@types/sd/cluster";
import styles from "./ClusterDetails.module.css";
import ActionLink from "../../components/theming/ActionLink";
import { PLAYGROUND_EVICTION_MESSAGE } from "../Clusters/constants";
import Spacer from "../../components/Util/Util";
import { useGetClusterApi } from "../../api/ClustersApi";
import DefaultsTTL from "./DefaultsTTL";
import MultiTabsLayout from "../../components/MultiTabsLayout/MultiTabsLayout";
import DeleteClusterDialog from "../../components/DeleteClusterDialog";
import useDisclosure from "../../hooks/UseDisclosure";
import useApiMutation from "../../hooks/UseApiMutation";
import _ from "lodash";

interface State {
  clusterToken?: string;
  operatorVersion?: string;
  createdAt?: string;
  isPlayground?: boolean;
}

interface Params extends Record<string, string> {
  clusterName: string;
}

interface ClusterStatusResponse {
  Statuses?: ClusterStatus[];
}

const toaster = Toaster.create();

type PlaygroundCardProps = {
  clusterName: string;
  isClusterReady: boolean;
};

const PlaygroundCard: React.FC<PlaygroundCardProps> = ({
  clusterName,
  isClusterReady,
}) => {
  const [expand, setExpand] = React.useState(false);
  const { isSuccess, data } = useGetPlaygroundApi(clusterName, {
    enabled: isClusterReady,
  });
  return (
    <Card className="mt-2 mb-2 text-sm" elevation={Elevation.TWO}>
      <div>
        <Callout intent={Intent.PRIMARY}>
          <span>{PLAYGROUND_EVICTION_MESSAGE}</span>
        </Callout>
        <Spacer />
        <Spacer />

        <div className="leading-6">
          <H4>Playground Cluster</H4>
          <p>
            The playground cluster is a Kubernetes cluster that is provisioned
            to help you test Signadot. The cluster comes preinstalled with the
            demo application in{" "}
            <a
              href="https://github.com/signadot/hotrod"
              target="_blank"
              rel="noreferrer"
            >
              signadot/hotrod
            </a>{" "}
            github repository. This is also the baseline application used by the{" "}
            <a
              href="https://www.signadot.com/docs/tutorials/quickstart/first-sandbox"
              target="_blank"
              rel="noreferrer"
            >
              quickstart guide
            </a>
            .
          </p>
        </div>
        <br />
        <br />

        <div>
          <div className={styles.instruction}>
            Optionally, you can connect to the playground cluster using{" "}
            <pre className={styles.inlineCode}>kubectl</pre>. (
            <ActionLink onClick={() => setExpand(!expand)}>
              {expand ? "Hide" : "View"} instruction
            </ActionLink>
            ).
          </div>
          <Collapse isOpen={expand}>
            <H4>Accessing the cluster using kubectl</H4>
            {isClusterReady ? (
              isSuccess && (
                <>
                  <p>
                    You can get read-only access to your playground cluster by
                    using the below kubeconfig.
                  </p>
                  <CopyableText className="mt-2 mb-2 p-4">
                    <pre
                      style={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        wordBreak: "break-all",
                      }}
                    >
                      {yaml.dump(data, {
                        lineWidth: -1,
                        replacer(key, value) {
                          if (key === "script") {
                            return value.replace(/\t/g, "  ");
                          }
                          return value;
                        },
                      })}
                    </pre>
                  </CopyableText>
                  <p>
                    You can copy the above kubeconfig and save it to your
                    workstation. Once saved, you can access the cluster as shown
                    in the examples below:
                  </p>
                  <CopyableText className="mt-2 mb-2 p-4">
                    <span>
                      kubectl --kubeconfig=/path/to/kubeconfig
                      --namespace=hotrod get services
                    </span>
                  </CopyableText>
                  Refer to the{" "}
                  <a
                    href="https://kubernetes.io/docs/reference/kubectl/cheatsheet/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    kubectl cheatsheet
                  </a>{" "}
                  for more commands you can run.
                </>
              )
            ) : (
              <p>
                Your kubeconfig will be available here. If the cluster was
                created recently, note that this may take a few minutes.
              </p>
            )}
            <br />
            <br />
            <H4>[Optional] Add the above into your default kubeconfig file</H4>
            <p>Save the kubeconfig as a file and run the below command:</p>
            <CopyableText className="mt-2 mb-2 p-4">
              cp ~/.kube/config ~/.kube/config.bak # Keeping a backup as a
              failsafe.
              <br />
              KUBECONFIG=~/.kube/config:/path/to/playground/cluster/config
              kubectl config view --flatten {`>`} ~/.kube/tempconfig && mv
              ~/.kube/tempconfig ~/.kube/config
            </CopyableText>
            <p>
              This will allow you to use kubectl to list contexts, switch
              context etc.
              <CopyableText className="mt-2 mb-2 p-4">
                kubectl config get-contexts # list all contexts
                <br />
                kubectl config use-context &lt;kube-context-name&gt; # switch
                context
              </CopyableText>
            </p>
          </Collapse>
        </div>
      </div>
    </Card>
  );
};

const ClusterDetails: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const deleteClusterAlert = useDisclosure(false);

  const state = (location.state ?? {}) as State;
  const queryClient = useQueryClient();

  const { clusterName } = useParams<Params>();

  const [refreshInProgress, setRefreshInProgress] = useState(false);

  // Clean up the history stack's state object to prevent it from retaining the
  // cluster token longer than the current page is loaded, while also not
  // losing it for re-renders.
  const [localClusterToken, setLocalClusterToken] = useState(
    state.clusterToken
  );
  if (state.clusterToken) {
    delete state.clusterToken;
    navigate(location, { replace: true });
  }

  const clusterDetail = useGetClusterApi(clusterName as string);
  const deleteClusterApi = useApiMutation("delete_cluster", "DELETE", [
    "clusters",
  ]);

  if (clusterDetail.error) {
    if (refreshInProgress) {
      toaster.show({
        message: "Error fetching cluster detail",
        intent: Intent.DANGER,
      });
      setRefreshInProgress(false);
    }
  } else if (clusterDetail.isSuccess) {
    if (refreshInProgress) {
      toaster.show({
        message: "Cluster detail refreshed",
        intent: Intent.SUCCESS,
      });
      setRefreshInProgress(false);
    }
  }

  if (deleteClusterApi.error) {
    toaster.show({
      message: _.get(deleteClusterApi.error, ["response", "data", "error"]),
      intent: Intent.DANGER,
    });
    deleteClusterAlert.close();
  } else if (deleteClusterApi.isSuccess) {
    toaster.show({
      message: "Cluster connection deleted",
      intent: Intent.SUCCESS,
    });

    navigate("/settings/clusters");
  }

  const cStatus = useApi<ClusterStatusResponse>(
    ["cluster_status", clusterName],
    `/api/v1/orgs/:orgName/clusters/status?clusterName=${clusterName}`,
    {
      retry: 1,
      refetchInterval: 5000,
    }
  );
  let clusterStatusData: undefined | ClusterStatus;
  if (!cStatus.isLoading && cStatus.isSuccess) {
    const statusData = cStatus.data || {};
    const statuses = statusData.Statuses;
    if (Array.isArray(statuses) && statuses.length > 0) {
      // in this case, we've asked for a specific cluster by name and the length
      // is guaranteed to be 1. eslint-disable-next-line prefer-destructuring
      clusterStatusData = statuses[0];
    }
  }
  const preparedStatus = parseStatus(clusterStatusData?.status);
  return (
    <>
      <DeleteClusterDialog
        clusterName={clusterName!}
        isOpen={deleteClusterAlert.isOpen}
        onCancel={() => {
          deleteClusterAlert.close();
        }}
        onConfirm={() => {
          deleteClusterApi.mutate({
            url: `/api/v1/orgs/:orgName/clusters/${clusterName}`,
          });
        }}
      />
      <MultiTabsLayout
        tabs={[]}
        title="Cluster Details"
        breadcrumbs={[
          {
            icon: <SiKubernetes className="mr-1" />,
            text: "Clusters",
            onClick: () => navigate(-1),
          },
          { text: clusterName },
        ]}
        actions={[
          {
            label: "Delete",
            onClick: deleteClusterAlert.open,
            display: true,
            isRiskAction: true,
            icon: "trash",
          },
        ]}
      >
        <>
          <Card className="mt-2 mb-2" elevation={Elevation.TWO}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <DetailItem title="Name" value={clusterName} />

              <DetailItem
                title="Created"
                value={getTimeAgoElement(clusterDetail.data?.createdAt)}
              />
              <DetailItem
                title="Operator Version"
                value={
                  <Tag round>
                    {clusterDetail.data?.operator?.version || "unknown"}
                  </Tag>
                }
              />
            </div>
            {clusterDetail.data && !clusterDetail.data.operator?.version ? (
              <ResourceStatus
                title="Cluster setup has not been completed"
                intent={Intent.WARNING}
              />
            ) : (
              <ResourceStatus
                statusText={preparedStatus?.statusText}
                statusDetail={preparedStatus?.statusDetail}
              />
            )}
            <br />
            <br />
            <Button
              icon="refresh"
              onClick={() => {
                setRefreshInProgress(true);
                queryClient.invalidateQueries(["clusterTokens", clusterName]);
                queryClient.invalidateQueries("clusters");
              }}
            >
              Refresh
            </Button>
          </Card>
          <br />

          {localClusterToken && localClusterToken.trim().length > 0 && (
            <>
              <Card className="mt-2 mb-2" elevation={Elevation.TWO}>
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                    }}
                  >
                    <Icon
                      iconSize={25}
                      icon="cross"
                      onClick={() => setLocalClusterToken("")}
                    />
                  </div>
                </div>
                <H4>Connecting Your Cluster</H4>
                <span>
                  A cluster token has been created for you. Be sure to save it
                  as you will not be able to view it again if you leave or
                  refresh this page.
                </span>
                <CopyableText className="mt-2 mb-2 p-4">
                  {localClusterToken}
                </CopyableText>
                <br />
                <br />
                Run the following commands to install Signadot on the cluster
                you are connecting:
                <br />
                <CopyableText bashScriptMode className="mt-2 mb-2 p-4">
                  kubectl create ns signadot
                  <br />
                  kubectl -n signadot create secret generic cluster-agent
                  --from-literal=token=&apos;{localClusterToken}&apos;
                </CopyableText>
                <br />
                <CopyableText bashScriptMode className="mt-2 mb-2 p-4">
                  helm repo add signadot https://charts.signadot.com
                  <br />
                  helm install signadot-operator signadot/operator
                </CopyableText>
                Or see{" "}
                <a
                  href="https://www.signadot.com/docs/installation/signadot-operator"
                  target="_blank"
                  rel="noreferrer"
                >
                  our documentation
                </a>{" "}
                for full details.
              </Card>
              <br />
            </>
          )}

          {clusterDetail.data?.isPlayground ? (
            <PlaygroundCard
              clusterName={clusterName!}
              isClusterReady={preparedStatus?.statusText === STATUS.Healthy}
            />
          ) : (
            <>
              <ClusterTokens
                clusterName={clusterName!}
                setCreatedToken={setLocalClusterToken}
              />

              <h1 className="mt-10">Settings</h1>
              <DefaultsTTL clusterName={clusterName!} />
            </>
          )}
        </>
      </MultiTabsLayout>
    </>
  );
};

export default ClusterDetails;
