// eslint-disable-next-line import/prefer-default-export
import type { TestExecution } from "../../../../../../@types/sd/testexecutions";

// eslint-disable-next-line import/prefer-default-export
export const filterTestExecutions = (
  executions: TestExecution[],
  filterText: string
): TestExecution[] =>
  executions
    .filter((execution) => {
      if (filterText === "") return true;
      if (!execution.spec) return false;
      const searchSpace: string[] = [
        execution.name,
        execution.spec.test,
        execution.spec.executionContext.cluster,
        execution.status.phase,
      ];
      if (execution.spec.executionContext.routing?.sandbox) {
        searchSpace.push(execution.spec.executionContext.routing!.sandbox);
      }
      if (execution.spec.executionContext.routing?.routegroup) {
        searchSpace.push(execution.spec.executionContext.routing!.routegroup);
      }
      if (execution.status.triggeredBy?.trigger.sandboxOf.name) {
        searchSpace.push(execution.status.triggeredBy.trigger.sandboxOf.name);
      }
      if (execution.status.triggeredBy?.trigger.sandboxOf.name) {
        searchSpace.push(execution.status.triggeredBy.trigger.sandboxOf.name);
      }
      return searchSpace.some((text) =>
        text.toLowerCase().includes(filterText.toLowerCase())
      );
    })
    .map((execution) => ({
      ...execution,
      id: execution.name,
    }));
