/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Interstitial from "../../../components/Interstitial";

const InvitationError = function ({
  message = "Error processing the request",
}) {
  return (
    <Interstitial>
      <div className="mt-16">
        {message && <p className="text-2xl mb-5 font-bold">{message}</p>}
        <p className="text-lg leading-7">
          If you feel you've reached this message in error, please reach out to
          Signadot at: <a href="mailto:info@signadot.com">info@signadot.com</a>
        </p>
      </div>
    </Interstitial>
  );
};

export default InvitationError;
