import React from "react";
import type { IconName } from "@blueprintjs/icons";
import type { MaybeElement } from "@blueprintjs/core";
import { Button } from "@blueprintjs/core";
import styles from "./LabeledCard.module.css";

interface ButtonInfo {
  id: string;
  icon: IconName | MaybeElement;
  label: string;
  onClick: () => void;
}

interface LabeledCardProps {
  label: string;
  titleBarContent?: React.ReactNode;
  buttonInfos?: ButtonInfo[];
  children: React.ReactNode;
}

const LabeledCard: React.FC<LabeledCardProps> = ({
  label,
  titleBarContent,
  buttonInfos,
  children,
}) => (
  <div className={styles.container}>
    <div className={styles.titleBar}>
      <div className={styles.title}>{label.toUpperCase()}</div>
      {titleBarContent ? (
        <div className={styles.titleBarContent}>{titleBarContent}</div>
      ) : null}
    </div>
    <div className={styles.content}>{children}</div>
    {Array.isArray(buttonInfos) && buttonInfos.length > 0 ? (
      <ul className={styles.buttons}>
        {buttonInfos.map((buttonInfo) => (
          <li key={buttonInfo.id}>
            <Button
              className={styles.button}
              icon={buttonInfo.icon}
              onClick={buttonInfo.onClick}
            >
              {buttonInfo.label}
            </Button>
          </li>
        ))}
      </ul>
    ) : null}
  </div>
);

export default LabeledCard;
