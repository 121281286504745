import React from "react";
import { Alert, Intent, OverlayToaster } from "@blueprintjs/core";
import _ from "lodash";

import { useNavigate } from "react-router-dom";
import { useDeleteTest } from "../../../../../../api/TestsApi";

const toaster = OverlayToaster.create();

interface DeleteSandboxAlertProps {
  name: string;
  isDeleteModalOpen: boolean;
  onCancel: () => void;
}

const DeleteTestSpecAlert: React.FunctionComponent<DeleteSandboxAlertProps> = ({
  name,
  isDeleteModalOpen,
  onCancel,
}) => {
  const deletionApi = useDeleteTest(
    () => {},
    () => {}
  );

  const navigate = useNavigate();

  if (deletionApi.error) {
    toaster.show({
      message: _.get(deletionApi.error, "response.data.error"),
      intent: Intent.DANGER,
    });
    deletionApi.reset();
  } else if (deletionApi.isSuccess) {
    toaster.show({
      message: "Test deleted",
      intent: Intent.SUCCESS,
    });
    deletionApi.reset();

    navigate("/testing/tests");
  }

  if (!name) {
    return null;
  }

  return (
    <Alert
      cancelButtonText="Cancel"
      confirmButtonText="Delete Test"
      icon="trash"
      intent={Intent.DANGER}
      isOpen={isDeleteModalOpen}
      onCancel={onCancel}
      onConfirm={() => {
        deletionApi.mutate({
          data: {},
          url: `/api/v2/orgs/:orgName/tests/${name}`,
        });
      }}
    >
      <div>
        <p>
          Are you sure you want to delete the test: <b>{name}</b>?
        </p>
      </div>
    </Alert>
  );
};

export default DeleteTestSpecAlert;
