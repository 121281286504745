import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Elevation,
  Intent,
  OverlayToaster,
} from "@blueprintjs/core";
import _ from "lodash";
import type { Method } from "axios";
import Spacer from "../../components/Util/Util";
import useApiMutation from "../../hooks/UseApiMutation";
import { useSandboxByIdApi } from "../../api/SandboxesApi";
import type { Workload } from "../../@types/sd/workload";
import type { ImagePatch, Patch } from "../../@types/sd/patch";
import { getApiVersion } from "../../@types/sd/workload";

interface Props {
  runTimeInfo: Workload;
  sandboxId: string;
  patch: Patch | null;
}

const toaster = OverlayToaster.create();

const ImageEditor: React.FunctionComponent<Props> = function ({
  runTimeInfo,
  sandboxId,
  patch,
}) {
  const sandboxApi = useSandboxByIdApi(sandboxId);

  const baselineName = _.get(runTimeInfo, ["baseline", "metadata", "name"], "");
  const forkName = _.get(runTimeInfo, ["fork", "metadata", "name"], "");
  const baselineNamespace = _.get(
    runTimeInfo,
    ["baseline", "metadata", "namespace"],
    ""
  );
  const forkedContainer = _.get(
    runTimeInfo,
    ["fork", "spec", "template", "spec", "containers", 0],
    {}
  );
  const baselineContainer = _.get(
    runTimeInfo,
    ["baseline", "spec", "template", "spec", "containers", 0],
    {}
  );
  const { kind } = runTimeInfo;
  const apiVersion = getApiVersion(kind);

  const [selectedImage, setSelectedImage] = useState("");
  const [lastSubmittedImage, setLastSubmittedImage] = useState("");

  const queryIdentifier = ["image_patch_info", sandboxId];

  // get patch ID and image associated with an image patch if it already exists.
  const patchId = patch?.id;
  const image =
    (patch?.value as ImagePatch | undefined)?.image ?? forkedContainer.image;

  const replaceImageApi = useApiMutation(
    "image_patch",
    "POST",
    // Invalidate the api queries for the image patch info and the deployment details. Deployment
    // details invalidated in order to cause the callout to update to not ready.
    [queryIdentifier, ["deployment_details", sandboxId, forkName]]
  );

  // show success / failure messages for API calls.
  if (replaceImageApi.error) {
    toaster.show({
      message: _.get(
        replaceImageApi.error,
        ["response", "data", "error"],
        "Unknown error"
      ),
      intent: Intent.DANGER,
    });
    replaceImageApi.reset();
  } else if (replaceImageApi.isSuccess) {
    toaster.show({ message: "Image updated", intent: Intent.SUCCESS });
    replaceImageApi.reset();
  }

  useEffect(() => {
    if (replaceImageApi.isError) {
      setSelectedImage(lastSubmittedImage);
    }
  }, [replaceImageApi, lastSubmittedImage]);

  const applyImagePatch = () => {
    let url = `/api/v1/orgs/:orgName/sandboxes/${sandboxId}/patches`;
    let method: Method = "POST";
    if (patchId) {
      method = "PUT";
      url = `${url}/${patchId}`;
    }
    replaceImageApi.mutate({
      method,
      data: {
        target: {
          apiVersion,
          kind,
          namespace: baselineNamespace,
          name: baselineName,
        },
        type: "signadot/image",
        value: [
          {
            container: baselineContainer.name,
            image: selectedImage,
          },
        ],
      },
      url,
    });
    setLastSubmittedImage(selectedImage);
    setSelectedImage("");
  };

  const onImageInputKey = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.code === "Enter") {
      applyImagePatch();
    }
  };

  // We only want to allow image mutations when this is a sandbox without a valid PR.
  const isNonPRDeployment =
    _.get(sandboxApi.data, ["workspace", "branches", 0, "pullRequest"], 0) ===
    0;

  return (
    <Card elevation={Elevation.TWO}>
      <span className="ml-1 text-sm">
        <b>Forked Workload Image:</b> {image}
      </span>
      {isNonPRDeployment && (
        <>
          <Spacer />
          <input
            name="replacement-image"
            type="text"
            placeholder="Replacement Image (org/imagerepo:tag)"
            className="bp4-input w-4/12 mt-2 mb-2 ml-1 mr-2"
            value={selectedImage}
            onChange={(e) => {
              setSelectedImage(e.target.value);
            }}
            onKeyDown={onImageInputKey}
          />
          <Button icon="saved" onClick={applyImagePatch}>
            Apply
          </Button>
        </>
      )}
    </Card>
  );
};

export default ImageEditor;
