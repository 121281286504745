import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";
import Auth0ProviderWithHistory from "./components/Auth/Auth0ProviderWithHistory";
import App from "./App";

import "./styles/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

const PosthogHostURL = "https://app.posthog.com";
posthog.init(process.env.VITE_POSTHOG_KEY!, {
  api_host: PosthogHostURL,
  disable_session_recording: true,
});

render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <PostHogProvider client={posthog}>
        <App />
      </PostHogProvider>
    </Auth0ProviderWithHistory>
  </BrowserRouter>,
  document.getElementById("root")
);
