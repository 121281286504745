import React, { useMemo } from "react";
import {
  Boundary,
  Breadcrumbs,
  Card,
  Elevation,
  H5,
  Tab,
  Tabs,
} from "@blueprintjs/core";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Spacer from "../../components/Util/Util";
import EnvVarEditor from "./EnvVarEditor";
import ImageEditor from "./ImageEditor";
import PodsPanel from "./PodsPanel";
import DetailItem from "../../components/DetailItem";
import Loading from "../../components/Loading/Loading";
import Error from "../../components/Error/Error";
import {
  useSandboxDeploymentsApi,
  useSandboxNameById,
} from "../../api/SandboxesApi";
import { ArgoWorkloadKind } from "../../@types/sd/workload";
import ResourceStatus from "../../components/ResourceStatus";
import { STATUS } from "../../util/StatusUtil/StatusUtils";
import { SdHeading1 } from "../../components/theming/SdHeading";
import { usePatchesApi } from "../../api/PatchesApi";
import CustomPatchTab from "./CustomPatchTab";

interface Params extends Record<string, string> {
  sandboxId: string;
  name: string;
}

const IMAGE_TAB_ID = "deployment-image-change";
const ENV_VAR_TAB_ID = "deployment-env-vars";
const PODS_TAB_ID = "deployment-pods";
const CUSTOM_PATCH_ID = "deployment-custom-patch";
const LOGS_TAB_ID = "deployment-logs";

const WorkloadDetails = function () {
  const navigate = useNavigate();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const tab = search.get("tab");
  let editImage = false;
  let editEnvironment = false;
  let viewLogs = false;
  let viewPods = false;
  let viewCustomPatch = false;
  switch (tab) {
    case "editImage":
      editImage = true;
      break;
    case "editEnvVars":
      editEnvironment = true;
      break;
    case "viewLogs":
      viewLogs = true;
      break;
    case "viewPods":
      viewPods = true;
      break;
    case "customPatch":
      viewCustomPatch = true;
      break;
    default:
      // nothing
      break;
  }
  let activeTab: string;
  if (editImage) {
    activeTab = IMAGE_TAB_ID;
  } else if (editEnvironment) {
    activeTab = ENV_VAR_TAB_ID;
  } else if (viewPods) {
    activeTab = PODS_TAB_ID;
  } else if (viewLogs) {
    activeTab = LOGS_TAB_ID;
  } else if (viewCustomPatch) {
    activeTab = CUSTOM_PATCH_ID;
  } else {
    activeTab = IMAGE_TAB_ID;
  }

  const { sandboxId, name } = useParams<Params>();
  const sandboxName = useSandboxNameById(sandboxId!);

  const deploymentSearch = useMemo(
    () => ({ workspaceID: sandboxId!, name: name! }),
    [sandboxId, name]
  );
  const deploymentDetailsApi = useSandboxDeploymentsApi(deploymentSearch);

  const workloadName = useMemo(() => {
    if (
      !deploymentDetailsApi.data?.deployments?.[0]?.baseline?.metadata?.name
    ) {
      return "";
    }
    const deploymentInfo = deploymentDetailsApi.data.deployments[0];
    return deploymentInfo.baseline?.metadata.name;
  }, [deploymentDetailsApi.data?.deployments?.[0]?.baseline?.metadata?.name]);
  const patches = usePatchesApi(sandboxId!, {
    name: workloadName,
  });
  const customPatch = useMemo(() => {
    if (!patches.data) {
      return null;
    }
    for (let i = 0; i < patches.data.patches.length; i++) {
      const p = patches.data.patches[i];
      if (p.type === "strategic" || p.type === "merge" || p.type === "json") {
        return p;
      }
    }
    return null;
  }, [patches.data]);
  const envPatch = useMemo(() => {
    if (!patches.data) {
      return null;
    }
    for (let i = 0; i < patches.data.patches.length; i++) {
      const p = patches.data.patches[i];
      if (p.type === "signadot/env") {
        return p;
      }
    }
    return null;
  }, [patches.data]);
  const imagePatch = useMemo(() => {
    if (!patches.data) {
      return null;
    }
    for (let i = 0; i < patches.data.patches.length; i++) {
      const p = patches.data.patches[i];
      if (p.type === "signadot/image") {
        return p;
      }
    }
    return null;
  }, [patches.data]);

  if (
    deploymentDetailsApi.isLoading ||
    deploymentDetailsApi.isIdle ||
    patches.isLoading ||
    !patches.data
  ) {
    return <Loading />;
  }
  if (deploymentDetailsApi.isError) {
    return <Error text={deploymentDetailsApi.error.response.data.error} />;
  }

  const deploymentInfo = deploymentDetailsApi.data.deployments[0];
  const baselineName = deploymentInfo.baseline?.metadata.name;
  const baselineNamespace = deploymentInfo.baseline?.metadata.namespace;
  const forkedFrom = `${baselineNamespace}/${baselineName}`;
  const { kind } = deploymentInfo;

  const forkName = _.get(deploymentInfo, ["fork", "metadata", "name"], "");
  const forkNamespace = _.get(
    deploymentInfo,
    ["fork", "metadata", "namespace"],
    ""
  );
  const baselineContainerImage = _.get(
    deploymentInfo,
    ["baseline", "spec", "template", "spec", "containers", 0, "image"],
    {}
  );
  const replicas = _.get(deploymentInfo, ["fork", "status", "replicas"]);
  const readyReplicas = _.get(deploymentInfo, [
    "fork",
    "status",
    "readyReplicas",
  ]);

  return (
    <>
      <SdHeading1 small lightBackground>
        Workload Details
      </SdHeading1>
      <br />
      <Breadcrumbs
        collapseFrom={Boundary.START}
        items={[
          {
            icon: "layers",
            text: "Sandboxes",
            onClick: () => navigate(`/sandboxes`),
          },
          {
            text: sandboxName,
            onClick: () => navigate(`/sandbox/id/${sandboxId}`),
          },
          { text: name },
        ]}
      />
      <br />
      <Card className="m-2" elevation={Elevation.TWO}>
        <H5>
          <a href="#runtime-info">Runtime Information</a>
        </H5>
        <Spacer />

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <DetailItem title="Namespace" value={forkNamespace} />
          <DetailItem title="Workload" value={forkName} />
          <DetailItem title="Baseline Workload" value={forkedFrom} />
          <DetailItem title="Baseline Image" value={baselineContainerImage} />
          <DetailItem
            title="Type"
            value={kind === ArgoWorkloadKind ? "Argo Rollout" : kind}
          />
        </div>

        <ResourceStatus
          statusText={
            replicas === readyReplicas ? STATUS.Healthy : STATUS.Unhealthy
          }
        />
      </Card>

      <Spacer />
      <Tabs
        className="m-2"
        key="deployment-tabs"
        animate
        id="deployment-navbar"
        selectedTabId={activeTab}
        large
        onChange={(newTab) => {
          switch (newTab) {
            case IMAGE_TAB_ID:
              navigate(`${location.pathname}?tab=editImage`);
              break;
            case ENV_VAR_TAB_ID:
              navigate(`${location.pathname}?tab=editEnvVars`);
              break;
            case PODS_TAB_ID:
              navigate(`${location.pathname}?tab=viewPods`);
              break;
            case CUSTOM_PATCH_ID:
              navigate(`${location.pathname}?tab=customPatch`);
              break;
            default:
            // nothing
          }
        }}
      >
        <Tab
          id={IMAGE_TAB_ID}
          title="Image"
          panel={
            <ImageEditor
              runTimeInfo={deploymentInfo}
              sandboxId={sandboxId!}
              patch={imagePatch}
            />
          }
        />
        <Tab
          id={ENV_VAR_TAB_ID}
          title="Environment Variables"
          panel={
            <EnvVarEditor
              sandboxId={sandboxId!}
              data={deploymentInfo}
              patch={envPatch}
            />
          }
        />
        <Tab
          id={PODS_TAB_ID}
          title="Pods"
          panel={
            <PodsPanel sandboxId={sandboxId!} deployInfo={deploymentInfo} />
          }
        />
        {customPatch !== null && (
          <Tab
            id={CUSTOM_PATCH_ID}
            title="Custom Patch"
            panel={<CustomPatchTab patch={customPatch} />}
          />
        )}
      </Tabs>
    </>
  );
};

export default WorkloadDetails;
