import React, { useMemo } from "react";
import { Tag } from "@blueprintjs/core";
import { useParams } from "react-router-dom";
import { formatSandboxV2 } from "../../../../util/Util";
import { parseStatus } from "../../../../util/StatusUtil/StatusUtils";
import Error from "../../../../components/Error/Error";
import PreviewEndpoints from "../../PreviewEndpoints";
import MatchingRouteGroups from "../../MatchingRouteGroups";
import { getHyperlink } from "../../PreviewEndpoints/Util";
import DetailItem from "../../../../components/DetailItem";
import type { Workload } from "../../../../@types/sd/workload";
import { ArgoWorkloadKind } from "../../../../@types/sd/workload";
import { useSandboxDeploymentsApi } from "../../../../api/SandboxesApi";
import ResourceStatus from "../../../../components/ResourceStatus";
import SdTheme from "../../../../styles/theme";
import { useSandboxResources } from "../../../../api/ResourcesApi";
import type { Resource } from "../../../../@types/sd/resource";
import ResourceCard from "../../ResourceCard";
import SdColors from "../../../../styles/colors";
import ForkCard from "../../ForkCard";
import ListSection from "../../../../components/theming/Section/ListSection";
import type {
  LocalWorkloadStatus,
  LocalWorkloadWithStatus,
  SandboxV2,
} from "../../../../@types/sd/sandboxv2";
import LocalWorkloadCard from "../../LocalWorkloadCard/LocalWorkloadCard";
import styles from "./SandboxOverview.module.css";
import { TTLDeletionDescription } from "../../../../components/TTLDeletionDescription/TTLDeletionDescription";
import { TTL_POLICIES } from "../../../RouteGroups/Constants";

export interface FormattedDeployment {
  data: Workload;
  deployment: {
    text?: string;
    component: React.ReactNode;
  };
  forkedFrom: {
    text?: string;
    component: React.ReactNode;
  };
  kind: {
    text?: string;
    component: React.ReactNode;
  };
  status: React.ReactNode;
}

// eslint-disable-next-line no-shadow
export enum WorkloadType {
  Fork,
  Local,
}

export interface FormattedResource {
  data: Resource;
}

export interface WL {
  type: WorkloadType;
  data: FormattedDeployment | LocalWorkloadWithStatus;
}

const displayDeploymentInfo = (
  name?: string,
  namespace?: string,
  previewURL?: string
) => (
  <div>
    <div>
      <b>Name:</b> {name}
    </div>
    {namespace && (
      <div className="pt-2">
        <b>Namespace:</b> {namespace}
      </div>
    )}
    {previewURL && (
      <div className="pt-2">
        <b>URL:</b> {getHyperlink(previewURL)}
      </div>
    )}
  </div>
);

function formatRuntimeInfo(deployments: Workload[]): FormattedDeployment[] {
  return deployments.map((deployment) => {
    const forkName = deployment.fork?.metadata.name;
    const baselineName = deployment.baseline?.metadata.name;
    const replicas = deployment.fork?.status.replicas;
    const readyReplicas = deployment.fork?.status.readyReplicas;
    const { kind } = deployment;
    // TODO: Figure out if we can add the Tooltip2 back in but not have it change the cursor to a question mark on hover.
    return {
      data: deployment,
      deployment: {
        text: forkName,
        component: displayDeploymentInfo(
          forkName,
          deployment.fork?.metadata.namespace,
          deployment.previewEndpoint && deployment.previewEndpoint.previewURL
        ),
      },
      forkedFrom: {
        text: baselineName,
        component: displayDeploymentInfo(
          baselineName,
          deployment.baseline?.metadata.namespace,
          deployment.previewEndpoint &&
            deployment.previewEndpoint.baselinePreviewURL
        ),
      },
      kind: {
        text: kind,
        component: kind === ArgoWorkloadKind ? "Argo Rollout" : kind,
      },
      status: (
        <Tag
          round
          style={{
            backgroundColor:
              replicas === readyReplicas
                ? SdTheme.Status.good
                : SdTheme.Status.warning,
          }}
        >
          {replicas === readyReplicas ? "Available" : "Unavailable"}
        </Tag>
      ),
    };
  });
}

const formatResourceInfo = (resources: Resource[]): FormattedResource[] =>
  resources.map((resource) => ({ data: resource }));

interface SandboxOverviewProps {
  sandbox: SandboxV2;
}

const SandboxOverview: React.FC<SandboxOverviewProps> = ({ sandbox }) => {
  // If we get a 400 error from the backend, prevent ourselves from constantly trying to
  // fetch data and turning the error page into a spinner load page. If we can't find
  // the formattedSandboxData because it doesn't exist, we shouldn't keep trying.
  const { sandboxName } = useParams<{ sandboxName: string }>();
  // TODO: Use error boundaries to immediately detect a 404 for disabling the query.
  // Requires an update to have the formattedSandboxData endpoint return a 404.

  // Deployments.
  const deploymentsSearch = useMemo(
    () => ({ workspaceID: sandbox?.routingKey ?? "" }),
    [sandbox?.routingKey]
  );
  const runtimeInfo = useSandboxDeploymentsApi(deploymentsSearch);
  const deployments = useMemo(() => {
    if (runtimeInfo.isSuccess) {
      return formatRuntimeInfo(runtimeInfo.data.deployments);
    }
    return [];
  }, [runtimeInfo.isSuccess, runtimeInfo.data]);

  // Local workloads
  const localWorkloadsWithStatus = useMemo(() => {
    if (!runtimeInfo.isSuccess || !sandbox) {
      return [];
    }
    // Local Workload status map
    const statusMap = sandbox?.status?.local?.reduce((map, lwStatus) => {
      map[lwStatus.name] = lwStatus;
      return map;
    }, {}) as Map<string, LocalWorkloadStatus>;

    return sandbox.spec.local?.map((localWorkload) => ({
      localWorkload: localWorkload,
      status: statusMap?.[localWorkload?.name],
    })) as LocalWorkloadWithStatus[];
  }, [runtimeInfo.isSuccess, sandbox]);

  const clusterName = useMemo(() => sandbox?.spec.cluster, [sandbox]);

  // Resources.
  const resourcesInfo = useSandboxResources(sandboxName!, clusterName);
  const resources: FormattedResource[] = useMemo(() => {
    if (!resourcesInfo.isSuccess) {
      return [];
    }
    if (!Array.isArray(resourcesInfo.data?.resources)) {
      return [];
    }
    return formatResourceInfo(resourcesInfo.data!.resources as Resource[]);
  }, [resourcesInfo.isSuccess, resourcesInfo.data]);

  const formattedSandboxData = formatSandboxV2(sandbox);
  if (!formattedSandboxData) {
    return null;
  }

  const { status } = sandbox;
  const preparedStatus = parseStatus(status);

  const getDeletionTTLElement = () => {
    const spec = sandbox.spec;

    const inheritTTL = sandbox.defaults?.cluster?.find(
      (d) => d.class === "ttl" && d.resourceKind === "sandbox"
    );

    if (!spec.ttl && !inheritTTL) return "";

    const offsetFrom = spec.ttl?.offsetFrom ?? inheritTTL?.value.offsetFrom;

    const scheduledDeleteTime = sandbox?.status?.scheduledDeleteTime;

    let description: string;
    switch (offsetFrom) {
      case TTL_POLICIES.CREATED_AT.value:
        description = ", scheduled for deletion :deletionTime";
        break;

      case TTL_POLICIES.UPDATED_AT.value:
        description = ", scheduled for deletion :deletionTime if not updated";
        break;

      default:
        description = ", scheduled for deletion :deletionTime";
    }

    return (
      <TTLDeletionDescription
        deletionTime={scheduledDeleteTime!}
        description={description}
        inheritedFromCluster={!spec.ttl}
      />
    );
  };

  return (
    <div>
      <div className={styles.info}>
        <p>
          <b>Created</b> {formattedSandboxData?.createdAt}
          {formattedSandboxData?.updatedAt && (
            <> (updated {formattedSandboxData?.updatedAt})</>
          )}
          {getDeletionTTLElement()}
        </p>
        <DetailItem
          title="Description"
          value={formattedSandboxData?.description.text}
        />
        <DetailItem title="Routing Key" value={formattedSandboxData?.id} />
        <DetailItem
          title="Cluster"
          value={formattedSandboxData?.clusterName.text}
        />
        {sandbox.spec.labels ? (
          <DetailItem
            title="Labels"
            value={Object.keys(sandbox.spec.labels).map((key) => (
              <Tag
                key={key}
                className="mr-1 mb-1"
                style={{ backgroundColor: SdColors.NEUTRAL500 }}
              >
                {key}: {sandbox.spec.labels?.[key]}
              </Tag>
            ))}
          />
        ) : null}
      </div>
      <div className={styles.status}>
        <ResourceStatus
          statusText={preparedStatus?.statusText}
          statusDetail={preparedStatus?.statusDetail}
          hideIfHealthy
        />
      </div>
      {runtimeInfo.isError && (
        <Error
          text={`Could not get info on forked Workloads: ${runtimeInfo.error.message}`}
        />
      )}
      {(runtimeInfo.isLoading || runtimeInfo.isSuccess) && (
        <>
          <ListSection
            title="Workloads"
            items={
              [
                ...(deployments || []).map((deployment) => ({
                  type: WorkloadType.Fork,
                  data: deployment,
                })),
                ...(localWorkloadsWithStatus || []).map(
                  (localWorkloadWithStatus) => ({
                    type: WorkloadType.Local,
                    data: localWorkloadWithStatus,
                  })
                ),
              ] as WL[]
            }
            renderItem={(item: WL) => {
              if (item.type === WorkloadType.Fork) {
                const d = item.data as FormattedDeployment;
                return (
                  <ForkCard
                    key={d.deployment.text}
                    sandboxId={formattedSandboxData.id}
                    sandboxName={formattedSandboxData.name.text!}
                    deployment={d}
                  />
                );
              }
              if (item.type === WorkloadType.Local) {
                const d = item.data as LocalWorkloadWithStatus;
                return <LocalWorkloadCard localWorkloadWithStatus={d} />;
              }
              return null;
            }}
          />
          {Array.isArray(resources) && resources.length > 0 ? (
            <ListSection
              title="Resources"
              items={resources}
              renderItem={(item: FormattedResource) => (
                <ResourceCard
                  key={`${item.data.name}`}
                  sandboxId={formattedSandboxData?.id}
                  sandboxName={formattedSandboxData!.name.text!}
                  resource={item.data}
                />
              )}
            />
          ) : null}
        </>
      )}
      <PreviewEndpoints endpoints={formattedSandboxData.endpoints} />
      {/* Remove `status?.matchingRouteGroups` once the data is present on the API. Normally feature flags would be used here */}
      {status?.matchingRouteGroups && (
        <MatchingRouteGroups routeGroups={status.matchingRouteGroups} />
      )}
    </div>
  );
};

export default SandboxOverview;
