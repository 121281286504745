import React, { useMemo, useRef, useState } from "react";
import useApi from "../../../hooks/UseApi";
import LogContainer from "./LogContainer";
import { mergeLogs } from "./Utils";
import type { LogItem } from "../../../components/Logs/Logs";

export interface LogParams {
  cluster: string;
  kind: string;
  namespace: string;
  container: string; // * = all containers
  name: string;
  sinceTime?: string;
}

type LogParamsKV = {
  [K in keyof LogParams]: string;
};

interface Props {
  logParams: LogParamsKV;
  logPanelOpen: boolean;
}

export interface ContainerLogs {
  container: string;
  logs: LogItem[];
}

const REFRESH_INTERVAL_IN_MS = 5 * 1000;

const LogPanel: React.FunctionComponent<Props> = ({
  logParams,
  logPanelOpen,
}) => {
  const [allContainerLogs, setAllContainerLogs] = useState<ContainerLogs[]>([]);
  const [sinceTime, setSinceTime] = useState<string | undefined>(undefined);
  const previousLogName = useRef("");

  const logUrl = useMemo(() => {
    const params = { ...logParams };
    const isDifferentWl = previousLogName.current !== logParams.name;
    if (isDifferentWl) {
      setAllContainerLogs([]);
      previousLogName.current = logParams.name;
    }

    if (sinceTime && !isDifferentWl) {
      params.sinceTime = sinceTime;
    }
    return `/api/v2/orgs/:orgName/logs?${new URLSearchParams(
      params
    ).toString()}`;
  }, [logParams, sinceTime]);

  const queryID = `fetch_logs_${JSON.stringify(logParams)}`;
  useApi<ContainerLogs[]>(queryID, logUrl, {
    refetchInterval: REFRESH_INTERVAL_IN_MS,
    onSuccess: (data: ContainerLogs[]) => {
      const mergedLogs: ContainerLogs[] = mergeLogs(allContainerLogs, data);
      setAllContainerLogs(mergedLogs);
      mergedLogs.forEach((containerLogs) => {
        const logs = containerLogs?.logs;
        if (Array.isArray(logs) && logs.length > 0) {
          const lastEntryTime = Date.parse(logs[logs.length - 1].time);
          if (!sinceTime || lastEntryTime > Date.parse(sinceTime)) {
            setSinceTime(logs[logs.length - 1].time);
          }
        }
      });
    },
  });

  return (
    <LogContainer
      allContainerLogs={allContainerLogs}
      logPanelOpen={logPanelOpen}
    />
  );
};

export default LogPanel;
