import { Popover2 } from "@blueprintjs/popover2";
import { DateRangePicker } from "@blueprintjs/datetime";
import { Classes, Icon } from "@blueprintjs/core";
import React, { useCallback } from "react";
import moment from "moment";
import SdButton from "../../components/theming/SdButton";
import { getDateShortcuts } from "./utils";

interface Props {
  selectedDateStart: Date;
  setSelectedDateStart: (d: Date | null) => void;
  selectedDateEnd: Date;
  setSelectedDateEnd: (d: Date | null) => void;
}

const FilterByDate: React.FunctionComponent<Props> = ({
  selectedDateStart,
  setSelectedDateStart,
  selectedDateEnd,
  setSelectedDateEnd,
}) => {
  const formatDate = useCallback((d: Date | null, fallback: string) => {
    if (!d) {
      return fallback;
    }
    return moment(d).format("yyyy-MM-DD");
  }, []);
  return (
    <div className="flex">
      <div
        className="flex mr-2"
        style={{
          alignItems: "center",
          justifyContent: "center",
          gap: "1em",
        }}
      >
        <div>Duration:</div>
      </div>
      <Popover2
        content={
          <DateRangePicker
            className={Classes.ELEVATION_1}
            onChange={([start, end]) => {
              setSelectedDateStart(start);
              setSelectedDateEnd(end);
            }}
            shortcuts={getDateShortcuts()}
            defaultValue={[selectedDateStart, selectedDateEnd]}
          />
        }
      >
        <div
          className="flex"
          style={{
            alignItems: "center",
            justifyContent: "center",
            gap: "1em",
          }}
        >
          <SdButton minimal>
            {formatDate(selectedDateStart, "no range start")}
          </SdButton>
          <div>
            <Icon icon="arrow-right" />
          </div>
          <SdButton minimal>
            {formatDate(selectedDateEnd, "no range end")}
          </SdButton>
        </div>
      </Popover2>
    </div>
  );
};

export default FilterByDate;
