import React from "react";
import { MultiSelect2 } from "@blueprintjs/select";
import type { MultiSelect2Props } from "@blueprintjs/select/src/components/multi-select/multiSelect2";
import { Icon } from "@blueprintjs/core";
import styles from "./FilterByEventType.module.css";

interface Props {
  onEventItemAdded: (v: string) => void;
  onEventItemRemoved: (v: string) => void;
  selectedEvents: string[];
}

export const EVENTS_TYPE = ["create-sandbox", "update-sandbox", "submit-job"];

const EventTagRenderer: MultiSelect2Props<string>["tagRenderer"] = (item) => (
  <p style={{ padding: 0, margin: 0 }}>{item}</p>
);

const EventItemRenderer: MultiSelect2Props<string>["itemRenderer"] = (
  item,
  { handleClick, modifiers: { disabled } }
) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  <div onClick={handleClick} className={styles.event_option}>
    <Icon icon={disabled ? "tick" : "blank"} size={14} />
    <p>{item}</p>
  </div>
);

const FilterByEventType: React.FunctionComponent<Props> = ({
  onEventItemAdded,
  onEventItemRemoved,
  selectedEvents,
}) => (
  <MultiSelect2
    itemDisabled={(item) => selectedEvents?.includes(item) ?? false}
    itemRenderer={EventItemRenderer}
    itemListPredicate={(query, items) =>
      items.filter((item) => item.includes(query))
    }
    noResults={<p>No RESULTS</p>}
    popoverProps={{ placement: "bottom-start", minimal: true }}
    tagRenderer={EventTagRenderer}
    onRemove={onEventItemRemoved}
    onItemSelect={onEventItemAdded}
    items={EVENTS_TYPE}
    selectedItems={selectedEvents ?? EVENTS_TYPE}
  />
);

export default FilterByEventType;
