import React from "react";
import SdTheme from "../../styles/theme";

interface Props {
  lightBackground?: boolean;
  small?: boolean;
  children?: React.ReactNode;
}

export const SdHeading1: React.FunctionComponent<Props> = ({
  lightBackground,
  small,
  children,
}) => (
  <div
    className="mt-5"
    style={{
      fontSize: small ? 34 : 48,
      fontWeight: small ? "normal" : "bolder",
      color: lightBackground
        ? SdTheme.Text.lightBackground
        : SdTheme.Text.darkBackground,
    }}
  >
    {children}
  </div>
);

export const SdHeading2: React.FunctionComponent<Props> = ({
  lightBackground,
  small,
  children,
}) => (
  <div
    style={{
      fontSize: small ? 24 : 34,
      fontWeight: small ? 400 : "bold",
      color: lightBackground
        ? SdTheme.Text.lightBackground
        : SdTheme.Text.darkBackground,
    }}
  >
    {children}
  </div>
);

export const SdHeading5: React.FunctionComponent<Props> = ({
  lightBackground,
  small,
  children,
}) => (
  <div
    className="mb-3"
    style={{
      fontSize: small ? 14 : 16,
      fontWeight: small ? 400 : "bold",
      color: lightBackground
        ? SdTheme.Text.lightBackground
        : SdTheme.Text.darkBackground,
    }}
  >
    {children}
  </div>
);
