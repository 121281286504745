import type { FormEvent } from "react";
import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import type { StripeCardElement } from "@stripe/stripe-js";
import { Intent, Spinner } from "@blueprintjs/core";
import SdButton from "../../components/theming/SdButton";
import useApiMutation from "../../hooks/UseApiMutation";

interface CreateSubscriptionRequest {
  paymentMethodId: string;
}

export default function CheckoutForm() {
  const createSubscriptionApi = useApiMutation<
    unknown,
    CreateSubscriptionRequest
  >("api_mutate:create_subscription", "POST", []);

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [inProgress, setInProgress] = useState(false);
  const [cardElement, setCardElement] = useState<StripeCardElement | null>(
    null
  );

  if (createSubscriptionApi.isError) {
    setMessage(createSubscriptionApi.error.message);
    setInProgress(false);
    createSubscriptionApi.reset();
  } else if (createSubscriptionApi.isSuccess) {
    window.location.href = `${window.location.origin}${window.location.pathname}`;
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements || !cardElement) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setInProgress(true);

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (result.error) {
      setMessage(result.error.message ?? "Unknown error occurred");
      setInProgress(false);
      return;
    }
    createSubscriptionApi.mutate({
      url: `/api/v1/orgs/:orgName/billing/subscriptions`,
      data: {
        paymentMethodId: result.paymentMethod.id,
      },
    });
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="pb-4">
        <CardElement
          id="card-element"
          onReady={(card) => {
            setCardElement(card);
          }}
        />
      </div>

      <SdButton
        disabled={inProgress || !stripe || !elements || !cardElement}
        type="submit"
      >
        <span id="button-text">
          {inProgress ? <Spinner intent={Intent.WARNING} /> : "Upgrade"}
        </span>
      </SdButton>

      {/* Show any error or success messages */}
      {message && (
        <div className="pt-4" id="payment-message">
          {message}
        </div>
      )}
    </form>
  );
}
