import type { IconName } from "@blueprintjs/icons";

// eslint-disable-next-line import/prefer-default-export
export const ICON_MAPPING: Record<string, IconName> = {
  queued: "time",
  succeeded: "tick-circle",
  failed: "cross-circle",
  running: "symbol-circle",
  canceled: "ban-circle",
};
