/* eslint-disable react/no-array-index-key */

import React from "react";
import { Intent } from "@blueprintjs/core";
import Collapse from "../../../common/Collapse";
import type { CombinedTrafficDiffFinding } from "./util";
import { isFindingCollapseSectionVisible } from "./util";
import styles from "./DataDisplay.module.css";
import BaseDataDisplay from "./BaseDataDisplay/BaseDataDisplay";
import FindingDiff from "../../../common/FindingDiff/FindingDiff";
import type { FindingDiffOp } from "../../../../../../@types/sd/findings";

type DataDisplayProps = {
  type: "request" | "response";
  headers: CombinedTrafficDiffFinding[];
  body: CombinedTrafficDiffFinding[];
  metadata: CombinedTrafficDiffFinding[];
};

const getAllDiffOps = (
  findings: CombinedTrafficDiffFinding[],
  type: "request" | "response" = "request",
  key: "body" | "header" | "metadata" = "header"
) => {
  const diffOps: FindingDiffOp[] = [];

  findings.forEach((f) => {
    const ops = f[type][key];
    if (Array.isArray(ops)) diffOps.push(...ops);
  });

  return diffOps;
};

const DataDisplay = ({ type, headers, body, metadata }: DataDisplayProps) => (
  <BaseDataDisplay
    title={type}
    show={isFindingCollapseSectionVisible(headers, body, metadata)}
    tags={[
      {
        label: `${headers.length + body.length + metadata.length} findings`,
        intent: Intent.NONE,
      },
    ]}
    defaultOpen
  >
    <Collapse
      title="Headers"
      show={headers.length > 0}
      tags={[
        {
          label: `${headers.length} findings`,
          intent: Intent.NONE,
        },
      ]}
      containerStyle={styles.partContainerStyleOverride}
      headerStyle={styles.partHeaderStyleOverride}
      bodyStyle={styles.partBodyStyleOverride}
      defaultOpen
    >
      <div className={styles.partBody}>
        <FindingDiff
          diffOps={getAllDiffOps(headers, type, "header")}
          labelPath="Key"
        />
      </div>
    </Collapse>

    <Collapse
      title="Body"
      show={body.length > 0}
      tags={[
        {
          label: `${body.length} findings`,
          intent: Intent.NONE,
        },
      ]}
      defaultOpen
      containerStyle={styles.partContainerStyleOverride}
      headerStyle={styles.partHeaderStyleOverride}
      bodyStyle={styles.partBodyStyleOverride}
    >
      <div className={styles.partBody}>
        <FindingDiff
          diffOps={getAllDiffOps(body, type, "body")}
          labelPath="Path"
        />
      </div>
    </Collapse>

    <Collapse
      title="Metadata"
      show={metadata.length > 0}
      tags={[
        {
          label: `${metadata.length} findings`,
          intent: Intent.NONE,
        },
      ]}
      defaultOpen
      containerStyle={styles.partContainerStyleOverride}
      headerStyle={styles.partHeaderStyleOverride}
      bodyStyle={styles.partBodyStyleOverride}
    >
      <div className={styles.partBody}>
        <FindingDiff
          diffOps={getAllDiffOps(metadata, type, "metadata")}
          labelPath="Key"
        />
      </div>
    </Collapse>
  </BaseDataDisplay>
);

export default DataDisplay;
