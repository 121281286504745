import React from "react";
import type { IconProps } from "@blueprintjs/core";
import { Icon } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { TbServerBolt } from "react-icons/tb";
import type { Job } from "../../@types/sd/job";
import styles from "./JobsList.module.css";
import { ICON_MAPPING } from "./constants";
import type { FilteredViewColumn } from "../FilteredView";
import { getTimeAgoElement } from "../../util/Util";
import { getRoutingContext } from "../../pages/Testing/Jobs/Jobs/utils";

// @ts-ignore
// eslint-disable-next-line import/extensions
import { AttemptPhaseEnum } from "../../@types/sd/job.d.ts";

export function getDuration(startedAt: string, finishedAt: string): string {
  const start = new Date(startedAt);
  const end = new Date(finishedAt);

  // Calculate the difference in milliseconds
  const durationMs = end.getTime() - start.getTime();

  // Convert milliseconds to hours, minutes, and seconds
  const hours = Math.floor(durationMs / (1000 * 60 * 60));
  const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((durationMs % (1000 * 60)) / 1000);

  let formattedTime = "";
  if (hours > 0) formattedTime += `${hours}h `;
  if (minutes > 0) formattedTime += `${minutes}m `;
  if (hours === 0 && seconds > 0) formattedTime += `${seconds}s`;

  return formattedTime.trim();
}

type JobColumnProps = {
  job: Job;
};

// Not too great an approach, but it works for now.
const jobStatusColorMap = {
  queued: styles.queued,
  running: styles.running,
  succeeded: styles.succeeded,
  canceled: styles.canceled,
  failed: styles.failed,
};

const getIconStyles = (job: Job): IconProps => ({
  icon: ICON_MAPPING[job.status.attempts[0].phase],
  size: 16,
  className: jobStatusColorMap[job.status.attempts[0].phase],
});

const runPrefix = (status: AttemptPhaseEnum) => {
  switch (status) {
    case AttemptPhaseEnum.RUNNING:
      return "Running";
    case (AttemptPhaseEnum.SUCCEEDED, AttemptPhaseEnum.FAILED):
      return "Ran";
    default:
      return "Run";
  }
};

export const JobRoutingContext: React.FC<JobColumnProps> = ({ job }) => {
  const routingContext = getRoutingContext(job);
  return (
    <div className={styles.job_routing_context}>
      {runPrefix(job.status.attempts[0].phase)} against the environment:{" "}
      <span className={styles.environment}>{routingContext.value}</span>{" "}
      <span>
        {routingContext.value === ""
          ? `${routingContext.context}`
          : `(${routingContext.context})`}
      </span>
    </div>
  );
};

const JobDetailsColumn: React.FC<JobColumnProps> = ({ job }) => (
  <div className={styles.job_details}>
    <Tooltip2 content={job.status.attempts[0].phase}>
      <Icon {...getIconStyles(job)} />
    </Tooltip2>
    <div className={styles.job_details__body}>
      <p className={styles.job_details__title}>{job.name}</p>
      <JobRoutingContext job={job} />
    </div>
  </div>
);

const JobDurationColumn: React.FC<JobColumnProps> = ({ job }) => {
  const attempt = job.status.attempts[0];
  if (!attempt) return null;
  return (
    <div className={styles.job_duration}>
      {attempt.createdAt && (
        <div>
          <Icon icon="calendar" size={12} />
          <span>Created {getTimeAgoElement(job.createdAt)}</span>
        </div>
      )}
      {attempt.createdAt && attempt.finishedAt && (
        <div>
          <Icon icon="time" size={12} />
          <span>
            Duration: {getDuration(attempt.createdAt, attempt.finishedAt)}
          </span>
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const JOB_COLUMNS: FilteredViewColumn<Job>[] = [
  { id: "detail", render: (job) => <JobDetailsColumn job={job} />, weight: 3 },
  {
    id: "job_runner_group",
    render: (job) => (
      <Tooltip2
        content={`${runPrefix(
          job.status.attempts[0].phase
        )} on Job Runner Group: ${job.spec.runnerGroup}`}
      >
        <div className={styles.jrg}>
          <TbServerBolt className={styles.jrg_icon} />
          <div>{job.spec.runnerGroup}</div>
        </div>
      </Tooltip2>
    ),
    weight: 1,
  },
  {
    id: "duration__execution_time",
    render: (job) => <JobDurationColumn job={job} />,
    weight: 1,
  },
];
