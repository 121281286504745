import type { WorkloadKind } from "../../../../../../../@types/sd/workload";

export type PLAIN_WORKLOAD = {
  kind: WorkloadKind;
  name: string;
  namespace: string;
};

export const INITIAL_TRIGGER = {
  executionTemplate: { autodiff: { enabled: true }, cluster: "" },
  id: "0",
  sandboxOf: { kind: "Deployment", name: "", namespace: "" },
};

export const MAX_RENDERED_WORKLOADS = 5;

export const INVALID_PATH_ERROR_MESSAGE =
  "Invalid workload, should have format 'namespace/name'";
