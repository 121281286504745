import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { Job } from "../../@types/sd/job";
import FilteredView from "../FilteredView";
import { JOB_COLUMNS } from "./utils";
import type { FilterOptionItem } from "../FilteredView/Filter/Filter";
import SdInput from "../theming/SdInput";
import { filterJobs } from "../../pages/Testing/Jobs/Jobs/utils";

type JobsListProps = {
  jobs: Job[];
};

const JobsList: React.FC<JobsListProps> = ({ jobs }) => {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const filteredList = React.useMemo(
    () => filterJobs(jobs || [], filterText),
    [jobs, filterText]
  );

  const handleRowClick = (row: Job) => {
    navigate(`/testing/jobs/${row.name}`);
  };

  const options = useMemo(
    () =>
      jobs.reduce(
        (previousValue, job) => {
          previousValue.status.add(job.status.attempts[0].phase);
          previousValue.clusters.add(job.spec.cluster);
          previousValue.runners.add(job.spec.runnerGroup);

          return previousValue;
        },
        {
          status: new Set<string>(),
          clusters: new Set<string>(),
          runners: new Set<string>(),
        }
      ),
    [jobs]
  );

  const getStatusOptions = (statusesInJobs: string[]): FilterOptionItem[] => {
    const baseOptions = [
      "succeeded",
      "failed",
      "running",
      "canceled",
      "queued",
    ];
    const notInJobs = baseOptions.filter((o) => !statusesInJobs.includes(o));

    return [
      ...statusesInJobs,
      ...notInJobs.map((s) => ({ label: s, disabled: true })),
    ];
  };

  return (
    <>
      <SdInput
        name="search-field"
        type="text"
        placeholder="Type to search ..."
        className="bp4-input bp4-fill bp4-medium mb-2"
        onChange={(e) => setFilterText(e.target.value)}
      />
      <FilteredView
        columns={JOB_COLUMNS}
        title={(count) => `${count} job runs`}
        data={filteredList}
        filters={[
          {
            label: "Status",
            filter: (job, filterValue) =>
              job.status.attempts[0]?.phase === filterValue,
            options: getStatusOptions([...options.status]),
          },
          {
            label: "Runner Group",
            filter: (job, filterValue) => job.spec.runnerGroup === filterValue,
            options: [...options.runners],
          },
        ]}
        totalWeight={5}
        onRowClick={handleRowClick}
      />
    </>
  );
};

export default JobsList;
