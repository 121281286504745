import type {
  ChangeEventHandler,
  FormEventHandler,
  KeyboardEventHandler,
} from "react";
import React from "react";
import { InputGroup } from "@blueprintjs/core";
import SdColors from "../../styles/colors";
import SdTheme from "../../styles/theme";

interface Props {
  id?: string;
  value?: string;
  autoFocus?: boolean;
  name?: string;
  type?: string;
  placeholder?: string;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onInput?: FormEventHandler<HTMLInputElement>;
  ref?: React.ForwardedRef<HTMLInputElement>;
}

const SdInput: React.FunctionComponent<Props> =
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLInputElement>((args, ref) => (
    <input
      style={{
        color: SdTheme.Text.lightBackground,
        backgroundColor: SdColors.NEUTRAL50,
      }}
      ref={ref}
      className="bp4-input"
      {...args}
    />
  ));

export const SdInputGroup: React.FunctionComponent<Omit<Props, "ref">> = (
  args
) => (
  <InputGroup
    style={{
      color: SdTheme.Input.text,
      backgroundColor: SdTheme.Input.background,
    }}
    {...args}
  />
);

export default SdInput;
