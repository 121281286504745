// eslint-disable-next-line import/prefer-default-export
import type { Job } from "../../../../@types/sd/job";

export const getRoutingContext = (
  job: Job
): { value: string; context: "baseline" | "sandbox" | "routegroup" } => {
  const routingContext = job.spec.routingContext;

  if (routingContext?.sandbox) {
    return {
      context: "sandbox",
      value: routingContext.sandbox,
    };
  }

  if (routingContext?.routegroup) {
    return {
      context: "routegroup",
      value: routingContext.routegroup,
    };
  }

  return {
    value: "",
    context: "baseline",
  };
};

const matchInName = (job: Job, filterText: string): boolean =>
  job.name.toLowerCase().includes(filterText);

const matchInRunnerGroup = (job: Job, filterText: string): boolean =>
  job.spec.runnerGroup.toLowerCase().includes(filterText);

const matchInRoutingContext = (job: Job, filterText: string): boolean => {
  const routingContext = job.spec.routingContext;
  if (!routingContext) return "baseline".includes(filterText);

  if (routingContext.routegroup) {
    return routingContext.routegroup.toLowerCase().includes(filterText);
  }

  if (routingContext.sandbox) {
    return routingContext.sandbox.toLowerCase().includes(filterText);
  }

  return "baseline".includes(filterText);
};

const matchInLabels = (job: Job, filterText: string): boolean => {
  if (!job.spec.labels) return false;

  const labels = Object.entries(job.spec.labels);

  return labels.some(
    ([k, v]) =>
      k.toLowerCase().includes(filterText) ||
      v.toLowerCase().includes(filterText)
  );
};

export const filterJobs = (jobs: Job[], filterText: string): Job[] => {
  const matchers = [
    matchInName,
    matchInRunnerGroup,
    matchInRoutingContext,
    matchInLabels,
  ];
  const searchTexts = filterText
    .trim()
    .split(" ")
    .map((f) => f.toLowerCase());
  const filterJob = (job: Job): boolean =>
    matchers.some((match) =>
      searchTexts.some((searchText) => match(job, searchText))
    );

  return jobs.filter(filterJob);
};
