import type { RunnerGroup } from "../@types/sd/runnergroup";
import type { ApiMutationResult } from "../hooks/UseApiMutation";
import useApiMutation from "../hooks/UseApiMutation";
import type { ApiError } from "../hooks/UseApi";
import useApi from "../hooks/UseApi";

export const RUNNER_GROUPS_API = "api:runner_groups";
export const GET_RUNNER_GROUP_API = "api:get_runner_group";
export const APPLY_RUNNER_GROUP_API = "api:apply_runner_group";
export const DELETE_RUNNER_GROUP_API = "api:delete_runner_group";

const standardQueryConfig = {
  retry: 1,
  refetchInterval: 30000,
};

export const useGetRunnerGroups = (options = {}) =>
  useApi<RunnerGroup[]>(
    RUNNER_GROUPS_API,
    `/api/v2/orgs/:orgName/runnergroups`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );

export const useGetRunnerGroup = (name: string, options = {}) =>
  useApi<RunnerGroup>(
    [GET_RUNNER_GROUP_API, name],
    `/api/v2/orgs/:orgName/runnergroups/${name}`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );

type ApplyRunnerGroupApiReturn = [
  (spec: RunnerGroup) => void,
  ApiMutationResult<RunnerGroup, RunnerGroup>
];

export const useApplyRunnerGroupApi = (
  onSuccess: (data: RunnerGroup) => void,
  onError: (err: ApiError) => void
): ApplyRunnerGroupApiReturn => {
  const mutation = useApiMutation<RunnerGroup, RunnerGroup>(
    APPLY_RUNNER_GROUP_API,
    "PUT",
    [RUNNER_GROUPS_API],
    onSuccess,
    onError
  );
  return [
    (runnerGroup: RunnerGroup) => {
      mutation.mutate({
        data: runnerGroup,
        url: `/api/v2/orgs/:orgName/runnergroups/${runnerGroup.name}`,
      });
    },
    mutation,
  ];
};

export const useDeleteRunnerGroup = (
  onSuccess: () => void,
  onError: (err: ApiError) => void
) =>
  useApiMutation<unknown, unknown>(
    DELETE_RUNNER_GROUP_API,
    "DELETE",
    [RUNNER_GROUPS_API],
    onSuccess,
    onError
  );
