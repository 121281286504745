import React from "react";
import { Alignment, Switch } from "@blueprintjs/core";
import styles from "./SSOEnabled.module.css";
import ActionLink from "../../../../../components/theming/ActionLink";
import Wrapper from "../../Wrapper";
import type { SSOSettings } from "../../../../../@types/sd/authsettings";

interface Props {
  settings: SSOSettings;
  enforceSSOFn: (state: boolean) => void;
  autoProvisionUsersFn: (state: boolean) => void;
}

const SSOEnabled: React.FC<Props> = ({
  settings,
  enforceSSOFn,
  autoProvisionUsersFn,
}) => (
  <Wrapper>
    <p>SSO has been enabled for the below domain(s):</p>
    <ul className={styles.list}>
      {settings.domains?.map((domain) => (
        <li className={styles.item} key={domain}>
          {domain}
        </li>
      ))}
    </ul>
    <div className={styles.manage}>
      {settings.managementURL && (
        <div>
          <ActionLink
            onClick={() => {
              window.open(settings.managementURL);
            }}
          >
            Manage SSO Configuration
          </ActionLink>
        </div>
      )}
      <ul className={styles.switches}>
        <li>
          <Switch
            checked={settings.enforceSSO}
            label="Enforce SSO"
            alignIndicator={Alignment.LEFT}
            onChange={() => enforceSSOFn(!settings.enforceSSO)}
          />
        </li>
        <li>
          <Switch
            checked={settings.autoProvisionUsers}
            label="Auto-Provision Users"
            alignIndicator={Alignment.LEFT}
            onChange={() => autoProvisionUsersFn(!settings.autoProvisionUsers)}
          />
        </li>
      </ul>
    </div>
  </Wrapper>
);

export default SSOEnabled;
