import React from "react";
import { Icon } from "@blueprintjs/core";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoList } from "react-icons/io5";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import styles from "./ExtraDetails.module.css";
import { getTimeAgoElement } from "../../../../../../../../util/Util";
import type { Test } from "../../../../../../../../@types/sd/testspec";
import { useGetTestExecutions } from "../../../../../../../../api/TestExecutionsApi";
import type { TestExecution } from "../../../../../../../../@types/sd/testexecutions";
import { getIconStyles } from "../../../../../ListExecutions/table/GeneralInfo/GeneralInfo";

interface Props {
  test: Test;
}

type ExecutionDetailsProps = {
  executions: TestExecution[];
};

const MAX_CAP_EXECUTIONS = 500;
const ExecutionDetails = ({ executions }: ExecutionDetailsProps) => {
  const lastExecution = executions.length > 0 && executions[0];
  const totalExecutions =
    executions.length > MAX_CAP_EXECUTIONS
      ? MAX_CAP_EXECUTIONS
      : executions.length;

  if (!lastExecution)
    return (
      <>
        <div className={styles.row}>
          <Icon icon="blank" size={12} />
        </div>
        <div className={styles.row}>
          <IoList />
          <span> No executions</span>
        </div>
      </>
    );
  const isInProgress = lastExecution.status.phase === "in_progress";

  return (
    <>
      <div className={styles.row}>
        <Icon {...getIconStyles(lastExecution)} size={12} />

        <span>
          {isInProgress ? (
            "Running test"
          ) : (
            <span>
              Last execution{" "}
              {getTimeAgoElement(lastExecution.status.finishedAt)}
            </span>
          )}
        </span>
      </div>

      <div className={styles.row}>
        <IoList />
        <span> {totalExecutions} executions</span>
      </div>
    </>
  );
};

// eslint-disable-next-line import/prefer-default-export
const ExtraDetails: React.FC<Props> = ({ test }) => {
  const { isLoading, data } = useGetTestExecutions(test.name);

  return (
    <div className={styles.duration}>
      {isLoading && <AiOutlineLoading3Quarters className={styles.loading} />}
      {data && <ExecutionDetails executions={data.map((e) => e.execution)} />}
      <div>
        <Icon icon="calendar" size={12} />
        <span>Created {getTimeAgoElement(test.createdAt)}</span>
      </div>
    </div>
  );
};

export default ExtraDetails;
