import React from "react";

import { usePostHog } from "posthog-js/react";
import type { EventProps } from "../../../@types/sd/event";
// @ts-ignore
import Quickstart from "../../../assets/images/icons/icons8-startup-WC0cm5Os58bF.svg";
// @ts-ignore
import Playground from "../../../assets/images/icons/icons8-playground-BDJp4DZHzIK5.svg";
// @ts-ignore
import WaitingAnimation from "../../../assets/images/icons/icons8-loading-xxx.gif";
// @ts-ignore
import WarningTriangle from "../../../assets/images/icons/icons8-warning-hP6pCUyT8QGk.svg";
// @ts-ignore
import Flask from "../../../assets/images/icons/icons8-flask-65359.svg";
// @ts-ignore
import HandWave from "../../../assets/images/icons/icons8-so-so-CEBxxG8oPhMl.svg";
// @ts-ignore
import DoneCheckmark from "../../../assets/images/icons/icons8-done-108641.svg";
// @ts-ignore
import New from "../../../assets/images/icons/icons8-new-43235.svg";
// @ts-ignore
import Cross from "../../../assets/images/icons/icons8-cross-1510.svg";
// @ts-ignore
import YoutubePlay from "../../../assets/images/icons/icons8-youtube-19318.svg";
// @ts-ignore
import Logo from "../../../assets/images/signadot-logoform-solid-dark.svg";

interface IconProps extends EventProps {
  src: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const Icon: React.FC<IconProps> = (props) => {
  const posthog = usePostHog();
  const { onClick, eventName, ...rest } = props;
  if (!onClick) {
    return <img {...props} alt="" />;
  }
  const newprops = {
    ...rest,
    onClick: (e: React.MouseEvent<HTMLDivElement>) => {
      if (eventName) {
        posthog?.capture(eventName);
      }
      onClick?.(e);
    },
  };
  return <img {...newprops} alt="" />;
};

interface CustomIconProps extends EventProps {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const QuickstartIcon: React.FC<CustomIconProps> = (props) => (
  <Icon src={Quickstart} {...props} />
);
const PlaygroundIcon: React.FC<CustomIconProps> = (props) => (
  <Icon src={Playground} {...props} />
);
const WaitingAnimationIcon: React.FC<CustomIconProps> = (props) => (
  <Icon src={WaitingAnimation} {...props} />
);
const WarningTriangleIcon: React.FC<CustomIconProps> = (props) => (
  <Icon src={WarningTriangle} {...props} />
);
const FlaskIcon: React.FC<CustomIconProps> = (props) => (
  <Icon src={Flask} {...props} />
);
const HandWaveIcon: React.FC<CustomIconProps> = (props) => (
  <Icon src={HandWave} {...props} />
);
const DoneCheckmarkIcon: React.FC<CustomIconProps> = (props) => (
  <Icon src={DoneCheckmark} {...props} />
);
const NewIcon: React.FC<CustomIconProps> = (props) => (
  <Icon src={New} {...props} />
);
const CrossIcon: React.FC<CustomIconProps> = (props) => (
  <Icon src={Cross} {...props} />
);
const YoutubePlayIcon: React.FC<CustomIconProps> = (props) => (
  <Icon src={YoutubePlay} {...props} />
);
const LogoIcon: React.FC<CustomIconProps> = (props) => (
  <Icon src={Logo} {...props} />
);

export {
  QuickstartIcon,
  PlaygroundIcon,
  WaitingAnimationIcon,
  WarningTriangleIcon,
  FlaskIcon,
  HandWaveIcon,
  DoneCheckmarkIcon,
  NewIcon,
  CrossIcon,
  YoutubePlayIcon,
  LogoIcon,
};
