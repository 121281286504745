import React from "react";
import styled from "styled-components";
import { TbServerBolt } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import styles from "./EmptyTests.module.css";
import CreateFirst from "../../../../components/CreateFirst";
import { useManagedRunnerGroups } from "../../../../api/ManagedRunnerGroupsApi";
import Loading from "../../../../components/Loading/Loading";
import { canRunSmartTests } from "../utils";

const ContainerMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const EmptyTests = () => {
  const managedRgs = useManagedRunnerGroups();
  const navigate = useNavigate();

  if (managedRgs.isLoading) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      {!canRunSmartTests(managedRgs.data) ? (
        <CreateFirst
          icon={<TbServerBolt size={80} />}
          buttonLabel="Set up a Managed Runner Group"
          onClick={() => navigate("/settings/global")}
          message={
            <ContainerMessage>
              <p>
                No Managed Runner Group is available on any connected cluster.
              </p>
            </ContainerMessage>
          }
        />
      ) : (
        <CreateFirst
          icon={<TbServerBolt size={80} />}
          buttonLabel="Add your first test"
          onClick={() => navigate("/testing/tests/editor/specification")}
          message={
            <ContainerMessage>
              <p>
                No Smart Tests found. For more details, please refer to the
                <a
                  href="https://www.signadot.com/docs/reference/smart-tests"
                  target="_blank"
                  rel="noreferrer"
                >
                  documentation
                </a>
                .
              </p>
            </ContainerMessage>
          }
        />
      )}
    </div>
  );
};

export default EmptyTests;
