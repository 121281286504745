import React from "react";
import { useNavigate } from "react-router-dom";
import BaseWidget from "../BaseWidget";
import styles from "./Sandboxes.module.css";
import useGetStatus from "./useGetStatus";
import Error from "../../../../components/Error/Error";
import HistoryLink from "../../../../components/HistoryLink";
import Loading from "../../../../components/Loading/Loading";

const Sandboxes = () => {
  const navigate = useNavigate();

  const sandboxesStatus = useGetStatus();

  if (sandboxesStatus.isError) {
    return (
      <BaseWidget title="Sandboxes" iconName="layers">
        <Error text="Currently Unavailable" />
      </BaseWidget>
    );
  }

  if (sandboxesStatus.isLoading) {
    return (
      <BaseWidget title="Sandboxes" iconName="layers">
        <div className={styles.details}>
          <Loading />
        </div>
      </BaseWidget>
    );
  }

  return (
    <BaseWidget title="Sandboxes" iconName="layers">
      <HistoryLink className={styles.title} url="/sandboxes">
        {sandboxesStatus.totalSandboxes} <span>active sandboxes</span>
      </HistoryLink>
      <div className={styles.details}>
        <BaseWidget.Status
          type="error"
          message={`Not Ready ${sandboxesStatus.notReady}`}
          onClick={() => navigate("/sandboxes?showUnhealthy=true")}
        />
      </div>
    </BaseWidget>
  );
};

export default Sandboxes;
