import React from "react";
import type { RouteGroup } from "../../../../@types/sd/routegroup";
import DetailItem from "../../../../components/DetailItem";
import MatchesDetailItem from "../../MatchesDetailItem";
import { getTimeAgoElement } from "../../../../util/Util";
import ResourceStatus from "../../../../components/ResourceStatus";
import Spacer from "../../../../components/Util/Util";
import { SdHeading2 } from "../../../../components/theming/SdHeading";
import MatchedSandboxesTable from "../../MatchedSandboxesTable";
import EndpointsTable from "../../EndpointsTable";
import { TTL_POLICIES } from "../../Constants";
import { STATUS } from "../../../../util/StatusUtil/StatusUtils";
import { TTLDeletionDescription } from "../../../../components/TTLDeletionDescription/TTLDeletionDescription";

type RouteGroupOverviewProps = {
  routeGroup: RouteGroup;
};

const RouteGroupOverview: React.FC<RouteGroupOverviewProps> = ({
  routeGroup,
}) => {
  let statusText = STATUS.Healthy;
  let statusDetail = "";
  if (!routeGroup.status.ready) {
    statusText = STATUS.Unhealthy;
    statusDetail = routeGroup.status.message;
  }

  const getDeletionTTLElement = () => {
    const spec = routeGroup.spec;

    const inheritTTL = routeGroup.defaults?.cluster?.find(
      (d) => d.class === "ttl" && d.resourceKind === "routegroup"
    );

    if (!spec.ttl && !inheritTTL) return "";

    const offsetFrom = spec.ttl?.offsetFrom ?? inheritTTL?.value.offsetFrom;

    const handleNoMatchedSandboxes = (scheduledDeleteTime: string) => {
      const matchedSandboxes = routeGroup.status.matchedSandboxes;
      if (!matchedSandboxes || matchedSandboxes.length === 0) {
        return (
          <TTLDeletionDescription
            deletionTime={scheduledDeleteTime}
            description=", scheduled for deletion :deletionTime if empty"
            inheritedFromCluster={!spec.ttl}
          />
        );
      }

      return null;
    };

    const scheduledDeleteTime = routeGroup.status.scheduledDeleteTime;

    let description = ", scheduled for deletion :deletionTime";
    switch (offsetFrom) {
      case TTL_POLICIES.CREATED_AT.value:
        description = ", scheduled for deletion :deletionTime";
        break;

      case TTL_POLICIES.UPDATED_AT.value:
        description = ", scheduled for deletion :deletionTime if not updated";
        break;
      case TTL_POLICIES.NO_MATCHED_SANDBOXES.value:
        return handleNoMatchedSandboxes(scheduledDeleteTime);
      default:
        return (
          <TTLDeletionDescription
            deletionTime={scheduledDeleteTime}
            description=", scheduled for deletion :deletionTime"
            inheritedFromCluster={!spec.ttl}
          />
        );
    }

    return (
      <TTLDeletionDescription
        deletionTime={scheduledDeleteTime}
        description={description}
        inheritedFromCluster={!spec.ttl}
      />
    );
  };

  return (
    <>
      <DetailItem title="Name" value={routeGroup.name} />
      {routeGroup.spec.description && (
        <DetailItem title="Description" value={routeGroup.spec.description} />
      )}
      <DetailItem title="Routing Key" value={routeGroup.routingKey} />
      <MatchesDetailItem routeGroup={routeGroup} />
      <DetailItem title="Cluster" value={routeGroup.spec.cluster} />
      <DetailItem
        title="Created"
        value={
          <p style={{ display: "inline" }}>
            {getTimeAgoElement(routeGroup.createdAt)}
            {getDeletionTTLElement()}
          </p>
        }
      />
      {!routeGroup.status.ready && (
        <ResourceStatus statusText={statusText} statusDetail={statusDetail} />
      )}

      <Spacer />
      <Spacer />
      <Spacer />
      <Spacer />
      <Spacer />

      <SdHeading2 small lightBackground>
        Matched Sandboxes{" "}
        {routeGroup.status.matchedSandboxes && (
          <span>({routeGroup.status.matchedSandboxes.length})</span>
        )}
      </SdHeading2>
      <MatchedSandboxesTable routeGroup={routeGroup} />

      <Spacer />
      <Spacer />
      <Spacer />

      <SdHeading2 small lightBackground>
        Endpoints
      </SdHeading2>
      <EndpointsTable routeGroup={routeGroup} />
    </>
  );
};

export default RouteGroupOverview;
