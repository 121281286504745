import React from "react";
import { Icon } from "@blueprintjs/core";

interface Props {
  description: string;
  complete: boolean;
}

const BaseStyle = {
  fontSize: "1.1em",
};
const CompleteStyle = {
  ...BaseStyle,
  color: "green",
};
const InProgressStyle = { ...BaseStyle };

const SetupStep: React.FunctionComponent<Props> = function ({
  description,
  complete,
}) {
  const icon = complete ? "tick-circle" : "social-media";
  const style = complete ? CompleteStyle : InProgressStyle;

  return (
    <p style={style} className="mt-5 mb-5">
      <Icon icon={icon} /> {description}:{" "}
      {complete ? "Complete" : "In-progress"}
    </p>
  );
};

export default SetupStep;
