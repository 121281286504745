import React from "react";
import { useBillingApi } from "../../../api/BillingApi";
import styles from "./BillingPlanBadge.module.css";

const BillingPlanBadge: React.FunctionComponent = () => {
  const { data, error } = useBillingApi();
  if (!data || error) {
    return null;
  }
  return (
    <div className={styles.container}>
      {data.tier.displayName}
      {data.tier.trial ? " (Trial) " : ""}
    </div>
  );
};

export default BillingPlanBadge;
