import { isEmpty } from "lodash";
import type {
  LegacyResourceStatusCondition,
  Resource,
  ResourceStatusCondition,
  ResourceV1,
  ResourceV1RuntimeInfo,
} from "../../@types/sd/resource";

interface StatusData {
  ready?: boolean;
  healthy?: boolean;
  reason: string;
  message: string;
  error?: {
    code: string;
    detail: string;
  };
}

export const STATUS = {
  Unknown: "Unknown",
  Healthy: "Healthy",
  Unhealthy: "Unhealthy",
};

export const RESOURCE_PROVISIONING_PHASE = {
  Provisioning: "Provisioning",
  Deprovisioning: "Deprovisioning",
};

export const RESOURCE_PROV_DEPROV_INPUT_STATUS = {
  Available: "Available",
  Failed: "Failed",
};

export const RESOURCE_PROV_DEPROV_OUTPUT_STATUS = {
  InProgress: "In-progress",
  Succeeded: "Succeeded",
  Failed: "Failed",
};

export interface ResourceStatus {
  phase: string;
  status: string;
  message?: string;
}

export const parseStatus = (statusData?: StatusData) => {
  if (!statusData || isEmpty(statusData)) {
    return null;
  }

  const response = {
    statusText: "",
    statusDetail: "",
  };

  const { ready, healthy, reason, message, error }: StatusData = statusData;
  const messages = [];

  if (error) {
    response.statusText = STATUS.Unknown;
    messages.push(error.detail);
  } else {
    if (healthy !== undefined) {
      response.statusText = healthy ? STATUS.Healthy : STATUS.Unhealthy;
    } else {
      response.statusText = ready ? STATUS.Healthy : STATUS.Unhealthy;
    }

    if (reason) {
      messages.push(reason);
    }
    if (message) {
      messages.push(message);
    }
  }
  response.statusDetail = messages.join(": ");

  return response;
};

export const isResourceReady = (resource: ResourceV1) => {
  const { conditions } = resource.runtimeInfo.status;
  let ready = false;
  let latestUpdate = 0;
  for (let i = 0; i < conditions.length; i++) {
    const c = conditions[i];
    if (c.type !== "Ready") {
      continue;
    }
    const updateTime = Date.parse(c.lastTransitionTime);
    if (updateTime > latestUpdate) {
      ready = c.status === "True";
      latestUpdate = updateTime;
    }
  }
  return ready;
};

const convertLegacyResourceStatus = (
  phase: string,
  sc: LegacyResourceStatusCondition
): ResourceStatusCondition => ({
  ready: sc.type === "Ready" && sc.status === "True",
  reason: sc.reason,
  message: sc.message,
  phase: phase,
});

export const convertLegacyResource = (
  r: ResourceV1RuntimeInfo,
  clusterName?: string
): Resource => {
  const { metadata, spec, status } = r;
  const conditions: LegacyResourceStatusCondition[] | undefined =
    (Array.isArray(status.conditions) &&
      status.conditions.filter((c) => c.type === "Ready")) ||
    undefined;
  const condition: LegacyResourceStatusCondition | undefined =
    Array.isArray(conditions) && conditions.length > 0
      ? conditions[0]
      : undefined;
  return {
    name: spec.name,
    plugin: spec.pluginName,
    cluster: clusterName,
    createdAt: metadata.creationTimestamp!,
    status: condition
      ? convertLegacyResourceStatus(status.phase, condition)
      : undefined,
    sandboxInputs:
      spec.params &&
      Object.keys(spec.params).map((name) => ({
        name,
        value: spec.params[name],
      })),
    create: status.provisionPod && [
      {
        name: "provision",
        status: {
          phase: status.phase,
          pod: {
            name: status.provisionPod.name,
            namespace: status.provisionPod.namespace,
          },
        },
      },
    ],
    delete: status.deprovisionPod && [
      {
        name: "deprovision",
        status: {
          phase: status.phase,
          pod: {
            name: status.deprovisionPod.name,
            namespace: status.deprovisionPod.namespace,
          },
        },
      },
    ],
  };
};
