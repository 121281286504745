import React from "react";
import { Intent, MenuItem, OverlayToaster } from "@blueprintjs/core";
import { TbCircleLetterD, TbCircleLetterR } from "react-icons/tb";
import type { WorkloadKind } from "../../../../../../../@types/sd/workload";
import { validPath } from "./utils";
import styles from "./TriggerEditor.module.css";
import { INVALID_PATH_ERROR_MESSAGE } from "./constant";

type NewWorkloadOptionProps = {
  query: string;
  beforeClick: () => void;
  handleClick: (
    event: React.MouseEvent<HTMLElement>,
    selectedKind: WorkloadKind
  ) => void;
};

const toaster = OverlayToaster.create();

const NewWorkloadOption = ({
  query,
  beforeClick,
  handleClick,
}: NewWorkloadOptionProps) => {
  const onClick = (
    event: React.MouseEvent<HTMLElement>,
    selectedKind: WorkloadKind
  ) => {
    beforeClick();

    const formatError = {
      message: INVALID_PATH_ERROR_MESSAGE,
      intent: Intent.DANGER,
    };

    if (!validPath(query)) {
      toaster.show(formatError);
      return;
    }

    handleClick(event, selectedKind);
  };

  return (
    <div className={styles.new_option}>
      <MenuItem
        icon={<TbCircleLetterD color="red" size={20} />}
        key="new-deployment"
        onClick={(e) => onClick(e, "Deployment")}
        text={`custom deployment "${query}"`}
      />
      <MenuItem
        icon={<TbCircleLetterR color="green" size={20} />}
        key="new-rollout"
        onClick={(e) => onClick(e, "Rollout")}
        text={`custom rollout "${query}"`}
      />
    </div>
  );
};

export default NewWorkloadOption;
