import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import { Icon } from "@blueprintjs/core";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { DiffCat } from "../../../../../../@types/sd/findings.d.ts";
import style from "./CategoryFilter.module.css";

export type FindingsCount = {
  high: number;
  medium: number;
  low: number;
};

type Props = {
  initialFilters: DiffCat[];
  onChange: (value: DiffCat[]) => void;
  findingsCount: FindingsCount;
};

const CategoryFilter: React.FC<Props> = ({
  initialFilters,
  onChange,
  findingsCount,
}) => {
  const [filters, setFilters] = useState<DiffCat[]>(initialFilters);
  const highSelected = React.useMemo(
    () => filters.includes(DiffCat.Red),
    [filters]
  );
  const mediumSelected = React.useMemo(
    () => filters.includes(DiffCat.Yellow),
    [filters]
  );
  const lowSelected = React.useMemo(
    () => filters.includes(DiffCat.Green),
    [filters]
  );
  const toggleState = (value: DiffCat) => {
    setFilters((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((v) => v !== value);
      }
      return [...prevState, value];
    });
  };
  useEffect(() => {
    onChange(filters);
  }, [filters]);

  useEffect(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  return (
    <div className={style.container}>
      <Popover2>
        <Tooltip2
          content="This classification is based on an AI model and will improve over time."
          intent="none"
          hoverOpenDelay={250}
        >
          <div className={style.title}>
            Relevance <Icon icon="info-sign" className={style.tooltipIcon} />
          </div>
        </Tooltip2>
      </Popover2>
      <div className={style.filters}>
        <div>
          <button
            className={classNames(style.filter, style.filter_high, {
              [style.selected]: highSelected,
            })}
            onClick={() => toggleState(DiffCat.Red)}
            disabled={findingsCount.high === 0}
          >
            {findingsCount.high}
          </button>
          <p>High</p>
        </div>
        <div>
          <button
            className={classNames(style.filter, style.filter_medium, {
              [style.selected]: mediumSelected,
            })}
            onClick={() => toggleState(DiffCat.Yellow)}
            disabled={findingsCount.medium === 0}
          >
            {findingsCount.medium}
          </button>
          <p>Medium</p>
        </div>
        <div>
          <button
            className={classNames(style.filter, style.filter_low, {
              [style.selected]: lowSelected,
            })}
            onClick={() => toggleState(DiffCat.Green)}
            disabled={findingsCount.low === 0}
          >
            {findingsCount.low}
          </button>
          <p>Low</p>
        </div>
      </div>
    </div>
  );
};

export default CategoryFilter;
