import { useState } from "react";

export type TTLPolicy = {
  value: string;
  label: string;
};

export type RawTTl = {
  duration?: string;
  offsetFrom: string;
};

export type ParsedTTL = {
  offsetFrom: string;
  duration?: number;
  durationType: string;
  enabled: boolean;
};

type UseTTLProps = RawTTl & {
  policies: Record<string, TTLPolicy>;
};

export type OnChangeTTLHandler = <K extends keyof ParsedTTL>(
  target: K,
  value: ParsedTTL[K] | ((prev: ParsedTTL) => ParsedTTL)
) => void;

type UseTTLReturn = {
  ttl: ParsedTTL;
  policies: TTLPolicy[];
  onChange: OnChangeTTLHandler;
  recalculate: (raw: RawTTl) => void;
};

export const parseTTL = (
  rawTTL: RawTTl,
  defaultPolicy: TTLPolicy | undefined
): ParsedTTL => {
  if (!rawTTL.duration)
    return {
      offsetFrom: defaultPolicy?.value || "",
      durationType: "m",
      enabled: false,
    };

  const rawDuration = rawTTL.duration.slice(0, -1);
  const durationType = rawTTL.duration.slice(-1);

  return {
    durationType: durationType,
    duration: parseInt(rawDuration, 10),
    offsetFrom: rawTTL.offsetFrom,
    enabled: true,
  };
};

// eslint-disable-next-line import/prefer-default-export
export const useTTL = ({
  duration,
  offsetFrom,
  policies,
}: UseTTLProps): UseTTLReturn => {
  const [currentTTL, setCurrentTTL] = useState<ParsedTTL>(
    parseTTL(
      {
        duration,
        offsetFrom,
      },
      Object.values(policies)[0]
    )
  );

  const onChange: OnChangeTTLHandler = <K extends keyof ParsedTTL>(
    target: K,
    value: ParsedTTL[K] | ((prev: ParsedTTL) => ParsedTTL)
  ) => {
    setCurrentTTL((prev) => {
      if (typeof value === "function") {
        return value(prev);
      }

      return {
        ...prev,
        [target]: value,
      };
    });
  };

  const recalculate = (raw: RawTTl) => {
    setCurrentTTL(parseTTL(raw, Object.values(policies)[0]));
  };

  return {
    policies: Object.values(policies),
    ttl: currentTTL,
    onChange,
    recalculate,
  };
};
