import React from "react";
import Wrapper from "../../Wrapper";

const SSONotAvailable: React.FC = () => (
  <Wrapper>
    <div>
      SSO is only available in Enterprise plan. Please{" "}
      <a
        href="mailto:info@signadot.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        contact us
      </a>{" "}
      to upgrade.
    </div>
  </Wrapper>
);

export default SSONotAvailable;
