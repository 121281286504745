import React, { useMemo } from "react";
import { OverlayToaster } from "@blueprintjs/core";
import type { Job } from "../../../../../../@types/sd/job";
import { useGetJobArtifacts } from "../../../../../../api/JobsApi";
import {
  getArtifactURLPath,
  getFilenameFromPath,
  formatArtifactsData,
  filterArtifacts,
} from "./utils";
import PaginatedTable from "../../../../../../components/PaginatedTable/PaginatedTable";
import { useAuth } from "../../../../../../contexts/AuthContext";
import useDownloadFile from "../../../../../../hooks/UseDownloadFile";

interface Props {
  job: Job;
}

const ARTIFACTS_COLUMNS = [
  {
    Header: "Path",
    accessor: "path",
  },
  {
    Header: "Raw Path",
    accessor: "rawPath",
    show: false,
  },
  {
    Header: "Uploaded At",
    accessor: "lastModified",
  },
  {
    Header: "Size",
    accessor: "size",
  },
  {
    Header: "Space",
    accessor: "space",
    show: false,
  },
  {
    Header: "Action",
    accessor: "actions",
  },
];

const toaster = OverlayToaster.create();

const Artifacts: React.FC<Props> = ({ job }) => {
  const { state } = useAuth();
  const { org } = state;
  const { download } = useDownloadFile();

  const {
    data: artifacts,
    isSuccess,
    isError,
    error,
  } = useGetJobArtifacts(job.name, 0);

  const filteredArtifacts = useMemo(
    () => filterArtifacts(artifacts),
    [artifacts]
  );

  const formattedArtifacts = React.useMemo(
    () => formatArtifactsData(filteredArtifacts),
    [artifacts]
  );

  if (isError) {
    if (error?.response?.status === 404) {
      return <div>No artifacts yet</div>;
    }
    return <div>{error?.message}</div>;
  }

  if (!isSuccess || !job) {
    return null;
  }

  if (Array.isArray(artifacts) && artifacts.length === 0) {
    return <div>No artifacts found.</div>;
  }

  return (
    <PaginatedTable
      columns={ARTIFACTS_COLUMNS}
      data={formattedArtifacts}
      enableSearch
      onActionCallback={(row) => {
        download(
          getArtifactURLPath(
            org!.name,
            job.name,
            job.status.attempts[0]!.id,
            row.rawPath,
            row.space
          ),
          getFilenameFromPath(row.rawPath),
          () => {},
          (message) => toaster.show({ message: message })
        );
      }}
    />
  );
};

export default Artifacts;
