import React from "react";
import {
  LuChevronRight,
  LuMinusCircle,
  LuPlusCircle,
  LuRepeat,
} from "react-icons/lu";
import { Tooltip2 } from "@blueprintjs/popover2";
import styles from "./FindingDiff.module.css";
import type { FindingDiffOp } from "../../../../../@types/sd/findings";
import { displayValue } from "../DiffViewer/util";

type QueryDiffProps = {
  diffOps: FindingDiffOp[];
  labelPath: string;
};

const FindingDiff = ({ diffOps, labelPath}: QueryDiffProps) => {
  const renderDiffValue = (baselineValue: any, targetValue: any) => {
    if (baselineValue === targetValue) return targetValue;
    return (
      <span className={styles.flexRow}>
        <span className={styles.strikeRed}>{displayValue(baselineValue)}</span>
        <LuChevronRight className={styles.icon} />
        <span className={styles.greenText}>{displayValue(targetValue)}</span>
      </span>
    );
  };

  const getChangeIcon = (op: string) => {
    if (op === "add") return <LuPlusCircle className={styles.greenIcon} />;
    if (op === "remove") return <LuMinusCircle className={styles.redIcon} />;
    return <LuRepeat className={styles.blueIcon} />;
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.gridContainer}>
          <div className={styles.gridHeader} />
          <div className={styles.gridHeader}>{labelPath}</div>
          <div className={styles.gridHeader}>Value</div>

          {diffOps.map((diffOp) => (
            <React.Fragment key={diffOp.path}>
              <div>
                <Tooltip2 content={diffOp.op}>
                  {getChangeIcon(diffOp.op)}
                </Tooltip2>
              </div>
              <div className={styles.fontMedium}>
                {diffOp.path.replace("/", "")}
              </div>
              <div>{renderDiffValue(diffOp.oldValue, diffOp.value)}</div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FindingDiff;
