import React from "react";
import type { JobArtifact } from "../../../../../../@types/sd/job";
import { getTimeAgoElement } from "../../../../../../util/Util";
import IconWithTooltip from "../../../../../../components/theming/IconWithTooltip";

export const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return "0 bytes";

  const sizes = ["bytes", "KB", "MB", "GB", "TB", "PB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const size = bytes / 1024 ** i;

  return `${size.toFixed(i === 0 ? 0 : 2)} ${sizes[i]}`;
};

const FILTER_ARTIFACTS_NAME = ["stderr.index", "stdout.index"];

export const filterArtifacts = (artifacts: JobArtifact[] | undefined) =>
  !artifacts
    ? []
    : artifacts.filter((a) => !FILTER_ARTIFACTS_NAME.includes(a.path));

export const formatArtifactsData = (artifacts: JobArtifact[]) =>
  artifacts.map((a) => ({
    path: a.space === "user" ? a.path : `@${a.path.trim()}`,
    rawPath: a.path,
    lastModified: {
      component: getTimeAgoElement(a.lastModified),
    },
    size: formatFileSize(a.size),
    space: a.space,
    actions: <IconWithTooltip text="Download" icon="download" />,
  }));

export const getArtifactURLPath = (
  orgName: string,
  jobName: string,
  attemptID: number,
  path: string,
  space: string
): string =>
  `/api/v2/orgs/${orgName}/artifacts/jobs/${jobName}/attempts/${attemptID}/objects/download?${encodeURI(
    `path=${path}&space=${space}`
  )}`;

export const getFilenameFromPath = (path: string): string => {
  const parts = path.split("/");
  return parts.length > 1 ? parts[parts.length - 1] : path;
};
