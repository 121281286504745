import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Elevation, Icon, Intent } from "@blueprintjs/core";
import { useSandboxResources } from "../../api/ResourcesApi";
import Loading from "../../components/Loading/Loading";
import { SdHeading5 } from "../../components/theming/SdHeading";
import SdTheme from "../../styles/theme";
import { useSandboxByIdApi } from "../../api/SandboxesApi";

import styles from "./ResourceDetails.module.css";
import Table from "../../components/Table/Table";
import Error from "../../components/Error/Error";
import DetailItem from "../../components/DetailItem";
import { getTimeAgoElement } from "../../util/Util";
import Spacer from "../../components/Util/Util";
import type { Resource } from "../../@types/sd/resource";
import StatusCard from "../../components/ResourceStatus/StatusCard";
import MultiTabsLayout from "../../components/MultiTabsLayout/MultiTabsLayout";

type Params = {
  sandboxId: string;
  resourceName: string;
};

const columns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Value",
    accessor: "value",
  },
];

const ResourceDetails: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { sandboxId, resourceName } = useParams<Params>();

  const sandboxInfo = useSandboxByIdApi(sandboxId!);
  const sandboxName = sandboxInfo.data?.sandbox.name;
  const clusterName = sandboxInfo.data?.sandbox.clusterName;

  const sandboxResources = useSandboxResources(sandboxName, clusterName, {
    enabled: !!sandboxName && !!clusterName,
  });
  if (sandboxInfo.error) {
    return <Error text={sandboxInfo.error.response.data.error} />;
  }
  if (sandboxResources.error) {
    return <Error text={sandboxResources.error.response.data.error} />;
  }
  if (
    sandboxInfo.isLoading ||
    !sandboxInfo.data ||
    sandboxResources.isLoading ||
    !sandboxResources.data
  ) {
    return <Loading />;
  }

  // eslint-disable-next-line no-lone-blocks
  {
    /* Note: In the absence of the API to fetch a single desired resource, we are fetching all the
    resources for a sandbox, and filtering by name. But it's desirable to introduce that API.
    Created a ticket here: https://github.com/signadot/signadot/issues/3086 */
  }
  const resources = (
    sandboxResources.data.resources as Resource[] | undefined
  )?.filter((res) => res.name === resourceName);
  if (!Array.isArray(resources) || resources.length === 0) {
    return null;
  }
  const resource: Resource = resources[0];
  // @ts-ignore
  return (
    <MultiTabsLayout
      tabs={[]}
      title="Resource Details"
      breadcrumbs={[
        {
          icon: <Icon icon="layers" color={SdTheme.Link.lightBackground} />,
          text: "Sandboxes",
          onClick: () => navigate(`/sandboxes`),
        },
        {
          text: sandboxInfo.data.sandbox.name,
          onClick: () => navigate(`/sandbox/id/${sandboxId}`),
        },
        {
          text: resourceName,
        },
      ]}
    >
      <div>
        <br />
        <Card
          elevation={Elevation.ZERO}
          style={{
            backgroundColor: SdTheme.Card.Background.light,
          }}
        >
          <div className={styles.resourceDetailCard}>
            <div className={styles.resourceDetailColumn}>
              <DetailItem title="Name" value={`${resource.name}`} />
              <DetailItem title="Provisioned By" value={resource.plugin} />
            </div>
            <div className={styles.resourceDetailColumn}>
              <DetailItem
                title="Created"
                value={getTimeAgoElement(resource.createdAt)}
              />
              <DetailItem
                title="Cluster"
                value={sandboxInfo.data.sandbox.clusterName}
              />
            </div>
          </div>
          {!resource.status?.ready && (
            <StatusCard
              title={resource.status!.reason}
              description={resource.status!.message}
              intent={Intent.WARNING}
            />
          )}
        </Card>
        <Spacer />
        <Spacer />
        <Spacer />
        <Spacer />
        <div className={styles.inputSection}>
          <SdHeading5 lightBackground>Inputs</SdHeading5>
          <Table columns={columns} data={resource.sandboxInputs || []} />
        </div>
      </div>
    </MultiTabsLayout>
  );
};

export default ResourceDetails;
