import React from "react";
import type { UsageBannerConfig } from "../utils";
import Announcement from "../../../../components/theming/announcement";
import HistoryLink from "../../../../components/HistoryLink";
import styles from "./BannerFromConfig.module.css";
import { constructBannerMessage } from "./utils";

interface Props {
  usageBannerConfig: UsageBannerConfig;
}

const BannerFromConfig: React.FC<Props> = ({ usageBannerConfig }) => {
  if (!usageBannerConfig.displayBanner) {
    return null;
  }
  const message = constructBannerMessage(usageBannerConfig);
  return (
    <Announcement
      id="billing-banner"
      canDismiss={usageBannerConfig.dismissible}
      highAlert={usageBannerConfig.highAlert}
    >
      {message} Check{" "}
      <HistoryLink url="/billing" className={styles.link}>
        usage
      </HistoryLink>{" "}
      for details.
    </Announcement>
  );
};

export default BannerFromConfig;
