import type { ApiError } from "../../../hooks/UseApi";
import useApi from "../../../hooks/UseApi";
import type {
  ApiMutationResult,
  MutationVariables,
} from "../../../hooks/UseApiMutation";
import useApiMutation from "../../../hooks/UseApiMutation";
import type {
  CreateIntegrationRequest,
  GetIntegrationsResponse,
  Integration,
  Integrations,
} from "../../../@types/sd/interation";

export const GET_INTEGRATIONS = "api:get-integrations";
export const CREATE_INTEGRATION = "api:create-integration";

export const useGetIntegrationsApi = (): GetIntegrationsResponse => {
  const { isLoading, isIdle, isSuccess, error, data } = useApi<Integrations>(
    GET_INTEGRATIONS,
    "/api/v1/orgs/:orgName/integrations",
    {
      refetchInterval: 30000,
    }
  );
  if (error) {
    return {
      error: error.message,
    };
  }
  if (isLoading || isIdle || !isSuccess || !data) {
    return {};
  }
  return { data };
};

type CreateIntegrationApiReturn = [
  (data: CreateIntegrationRequest) => void,
  ApiMutationResult<Integration, CreateIntegrationRequest>
];

type OnSuccessFn = (
  response: Integration,
  vars: MutationVariables<CreateIntegrationRequest>
) => void;

type OnErrorFn = (
  error: ApiError,
  vars: MutationVariables<CreateIntegrationRequest>
) => void;

export const useCreateIntegrationApi = (
  onSuccessFn?: OnSuccessFn,
  onErrorFn?: OnErrorFn
): CreateIntegrationApiReturn => {
  const mutation = useApiMutation<Integration, CreateIntegrationRequest>(
    CREATE_INTEGRATION,
    "POST",
    [],
    onSuccessFn,
    onErrorFn
  );
  return [
    (data) => {
      mutation.mutate({
        url: "/api/v1/orgs/:orgName/integrations",
        data,
      });
    },
    mutation,
  ];
};
