import React from "react";
import type { Test } from "../../../../../../@types/sd/testspec";
import FilteredView, {
  type FilteredViewColumn,
} from "../../../../../../components/FilteredView";
import { ExtraDetails, GeneralInfo } from "./table";

export type TestWithID = {
  id: string;
} & Test;

type TestViewProps = {
  tests: TestWithID[];
  onSelected: (test: TestWithID) => void;
};

const COLUMNS: FilteredViewColumn<TestWithID>[] = [
  {
    id: "detail",
    render: (test) => <GeneralInfo test={test} />,
    weight: 2,
  },
  {
    id: "extra_details",
    render: (test) => <ExtraDetails test={test} />,
    weight: 1,
  },
];

const TestView = ({ tests, onSelected }: TestViewProps) => (
  <FilteredView
    title={(count) => `${count} tests`}
    data={tests}
    columns={COLUMNS}
    totalWeight={3}
    onRowClick={onSelected}
  />
);

export default TestView;
