import React from "react";
import { Intent, Icon } from "@blueprintjs/core";
import SdTheme from "../../styles/theme";
import SdColors from "../../styles/colors";

export interface StatusProps {
  title?: string;
  description?: string;
  intent?: Intent;
}

const StatusCard: React.FunctionComponent<StatusProps> = ({
  title,
  description,
  intent,
}) => {
  let color = SdColors.NEUTRAL50;
  let iconColor = SdTheme.Status.neutral;
  // eslint-disable-next-line default-case
  switch (intent) {
    case Intent.DANGER:
      color = SdColors.ERROR50;
      iconColor = SdTheme.Status.bad;
      break;
    case Intent.WARNING:
      color = SdColors.WARNING50;
      iconColor = SdTheme.Status.warning;
      break;
    case Intent.SUCCESS:
      color = SdColors.SUCCESS50;
      iconColor = SdTheme.Status.good;
      break;
  }
  return (
    <div
      className="mt-1 p-3"
      style={{
        display: "inline-block",
        position: "relative",
        top: -1,
        color: SdTheme.Text.lightBackground,
        backgroundColor: color,
        borderRadius: 10,
        borderColor: iconColor,
        borderWidth: 1,
        minWidth: 400,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          fontWeight: "bold",
        }}
      >
        <Icon icon="ban-circle" color={iconColor} />
        <span className="ml-3" />
        {title}
      </div>
      {description && <div className="mt-3">{description}</div>}
    </div>
  );
};

export default StatusCard;
