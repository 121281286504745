import React from "react";
import {
  Card,
  Dialog,
  Elevation,
  Intent,
  OverlayToaster,
} from "@blueprintjs/core";
import SdButton from "../../../components/theming/SdButton";
import styles from "./VcsIntegration.module.css";
import { useCreateIntegrationApi } from "./VcsIntegrationApi";

interface VcsIntegrationModalProps {
  isModalOpen: boolean;
  setModalOpen: (val: boolean) => void;
}

const toaster = OverlayToaster.create();

const VcsIntegrationModal: React.FunctionComponent<
  VcsIntegrationModalProps
> = ({ isModalOpen, setModalOpen }) => {
  const [createIntegration, createIntegrationApi] = useCreateIntegrationApi();
  const handleCreateIntegration = () => {
    createIntegration({
      type: "github",
    });
  };

  if (createIntegrationApi.error) {
    toaster.show({
      message: createIntegrationApi.error.response.data.error,
      intent: Intent.DANGER,
    });
    createIntegrationApi.reset();
  } else if (createIntegrationApi.isSuccess) {
    const installationUrl =
      createIntegrationApi.data.data.github?.installationURL;
    if (installationUrl) {
      window.location.href = installationUrl;
    } else {
      toaster.show({
        message: "failed to proceed with Signadot Github app installation",
        intent: Intent.DANGER,
      });
    }
  }
  if (!isModalOpen) {
    return null;
  }
  return (
    <Dialog
      icon="info-sign"
      title="Github Integration"
      isOpen={isModalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
    >
      <Card style={{ marginBottom: "-20px" }} elevation={Elevation.TWO}>
        <p>
          In order to integrate your Pull Requests with the Sandbox lifecycle,
          you&apos;ll need to install Signadot app in your GitHub organization.
        </p>
        <SdButton
          icon="plus"
          className={styles.button}
          onClick={() => {
            setModalOpen(false);
            handleCreateIntegration();
          }}
        >
          Install Github App
        </SdButton>
      </Card>
    </Dialog>
  );
};
export default VcsIntegrationModal;
