import React from "react";
import { SiKubernetes } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import BaseWidget from "../BaseWidget";
import styles from "./Clusters.module.css";
import useGetStatus from "./useGetStatus";
import Error from "../../../../components/Error/Error";
import HistoryLink from "../../../../components/HistoryLink";
import Loading from "../../../../components/Loading/Loading";

const Clusters = () => {
  const navigate = useNavigate();

  const clustersStatus = useGetStatus();

  if (clustersStatus.isError) {
    return (
      <BaseWidget title="Clusters" iconName={<SiKubernetes size="1.1em" />}>
        <Error text="Currently Unavailable" />
      </BaseWidget>
    );
  }

  if (clustersStatus.isLoading) {
    return (
      <BaseWidget title="Clusters" iconName={<SiKubernetes size="1.1em" />}>
        <div className={styles.details}>
          <Loading />
        </div>
      </BaseWidget>
    );
  }

  return (
    <BaseWidget title="Clusters" iconName={<SiKubernetes size="1.1em" />}>
      <HistoryLink className={styles.title} url="/settings/clusters">
        {clustersStatus.totalClusters} <span>clusters connected</span>
      </HistoryLink>
      <div className={styles.details}>
        <BaseWidget.Status
          type="error"
          message={`Not Connected ${clustersStatus.unhealthy}`}
          onClick={() => navigate("/settings/clusters?showUnhealthy=true")}
        />
        <BaseWidget.Status
          type="warning"
          message={`Pending Upgrades ${clustersStatus.pendingUpgrade}`}
          onClick={() => navigate("/settings/clusters?showUpgrades=true")}
        />
      </div>
    </BaseWidget>
  );
};

export default Clusters;
