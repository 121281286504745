import React, { useEffect, useRef, useState } from "react";
import styles from "./LogContainer.module.css";
import type { ContainerLogs } from "../LogPanel";
import Logs from "../../../../components/Logs";
import type { LogItem } from "../../../../components/Logs/Logs";

interface Props {
  allContainerLogs?: ContainerLogs[];
  follow?: boolean;
  logPanelOpen: boolean;
}

const LogContainer: React.FunctionComponent<Props> = ({
  allContainerLogs = [],
  follow: followInput = true,
  logPanelOpen,
}) => {
  const [containers, setContainers] = useState<string[]>([]);
  const [logs, setLogs] = useState<LogItem[]>([]);
  const [selectedContainer, setSelectedContainer] = useState<
    string | undefined
  >(undefined);

  const previousContainers = useRef<string[]>([]);

  useEffect(() => {
    if (containers.length > 0) {
      const areSameContainers = (a: string[], b: string[]) =>
        a.length === b.length && a.every((v, i) => v === b[i]);
      if (!areSameContainers(containers, previousContainers.current)) {
        setSelectedContainer(containers[0]);
      }
    }

    previousContainers.current = containers;
  }, [containers]);

  const selectedContainerLogs: ContainerLogs | undefined = React.useMemo(() => {
    if (!selectedContainer || !Array.isArray(allContainerLogs)) {
      return undefined;
    }
    return allContainerLogs.filter(
      (containerLogs) => containerLogs.container === selectedContainer
    )[0];
  }, [selectedContainer, allContainerLogs]);

  useEffect(() => {
    if (!Array.isArray(allContainerLogs)) return;

    const containerNames = allContainerLogs.map(
      (containerLog) => containerLog.container
    );
    setContainers(containerNames);

    if (!selectedContainer && containerNames.length > 0) {
      setSelectedContainer(containerNames[0]);
    }
  }, [selectedContainerLogs, allContainerLogs]);

  useEffect(() => {
    setLogs(selectedContainerLogs?.logs ?? []);
  }, [selectedContainerLogs]);

  return (
    <Logs
      logPanelOpen={logPanelOpen}
      follow={followInput}
      logs={logs}
      header={
        <div className={styles.selectContainer}>
          <span className={styles.label}>Container:</span>{" "}
          <select onChange={(e) => setSelectedContainer(e.target.value)}>
            {containers.map((container) => (
              <option key={container}>{container}</option>
            ))}
          </select>
        </div>
      }
    />
  );
};

export default LogContainer;
