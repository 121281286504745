import React from "react";
import { Button } from "@blueprintjs/core";
import Interstitial from "../Interstitial";

const EmailNotVerifiedError = () => (
  <Interstitial>
    <div className="mt-4">
      <p className="text-2xl mb-5 font-bold">Email not verified</p>
      <p className="mb-10 leading-6">
        Please verify your email address to continue. If you have not received
        the verification email, please check your &quot;Spam&quot; or &quot;Junk
        Email&quot; folder.
        <br />
        <br />
        Once you&apos;ve verified your email, click the below button to continue
        with signing-in.
      </p>
      <Button
        icon="log-out"
        onClick={() => {
          window.location.href = "/signout";
        }}
      >
        I have verified my email
      </Button>
    </div>
  </Interstitial>
);

export default EmailNotVerifiedError;
