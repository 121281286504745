import type { Usage, UsageItem } from "../../../@types/sd/billing";

export const INFO_THRESHOLD = 75;
export const WARN_THRESHOLD = 90;
export const UNLIMITED_CAPACITY = -1;

export interface UsageBannerConfig {
  displayBanner: boolean;
  sandboxUsagePercentage?: number;
  jobUsagePercentage?: number;
  dismissible?: boolean;
  highAlert?: boolean;
}

export const calculateUsagePercentage = (
  usageItem: UsageItem | undefined
): number | undefined => {
  if (usageItem && usageItem.cap !== UNLIMITED_CAPACITY && usageItem.used) {
    const percentage = (100 * usageItem.used) / usageItem.cap!;
    return parseFloat(percentage.toFixed(2));
  }
  return undefined;
};

export const constructBannerConfig = (
  percentages: (number | undefined)[]
): UsageBannerConfig => {
  const displayBanner = percentages.some((x) => x && x >= INFO_THRESHOLD);
  const highAlert = percentages.some((x) => x && x >= WARN_THRESHOLD);

  return {
    displayBanner,
    dismissible: !highAlert,
    highAlert,
  };
};

export const getUsageBannerConfig = (usage: Usage): UsageBannerConfig => {
  if (!usage) {
    return { displayBanner: false };
  }

  const sandboxUsagePercentage = calculateUsagePercentage(usage.sandbox);
  const jobUsagePercentage = calculateUsagePercentage(usage.job);

  const bannerConfig = constructBannerConfig([
    sandboxUsagePercentage,
    jobUsagePercentage,
  ]);
  bannerConfig.sandboxUsagePercentage = sandboxUsagePercentage;
  bannerConfig.jobUsagePercentage = jobUsagePercentage;
  return bannerConfig;
};
