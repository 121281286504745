import React from "react";
import styles from "./PodsStatus.module.css";
import { SdHeading2 } from "../../../../../../../components/theming/SdHeading";

type PodsStatusProps = {
  idle: number;
  ready: number;
  notReady: number;
};

const PodsStatus = ({ idle, ready, notReady }: PodsStatusProps) => (
  <div className={styles.container}>
    <SdHeading2 small lightBackground>
      Runner Status
    </SdHeading2>
    <div className={styles.pods}>
      <div className={`${styles.pod_status} ${styles.pod_total}`}>
        {ready + notReady} Total
      </div>
      <div className={`${styles.pod_status} ${styles.pod_ready}`}>
        {ready} Ready
      </div>
      <div className={`${styles.pod_status} ${styles.pod_running}`}>
        {ready + notReady - idle} Running
      </div>
      <div className={`${styles.pod_status} ${styles.pod_idle}`}>
        {idle} Idle
      </div>
      <div className={`${styles.pod_status} ${styles.pod_not_ready}`}>
        {notReady} Not ready
      </div>
    </div>
  </div>
);

export default PodsStatus;
