import type { Job, JobArtifact } from "../@types/sd/job";
import type { ApiError } from "../hooks/UseApi";
import useApi from "../hooks/UseApi";
import useApiMutation from "../hooks/UseApiMutation";

export const JOBS_API = "api:jobs";
export const JOB_ARTIFACTS_API = "api:job-artifacts";
export const CANCEL_JOB_API_NAME = "api_mutation:cancel-job";

const standardQueryConfig = {
  retry: 1,
  refetchInterval: 5000,
};

export const useGetJobs = (options = {}) =>
  useApi<Job[]>(JOBS_API, `/api/v2/orgs/:orgName/jobs`, {
    ...standardQueryConfig,
    ...options,
  });

export const useGetJob = (name: string, options = {}) =>
  useApi<Job>([JOBS_API, name], `/api/v2/orgs/:orgName/jobs/${name}`, {
    ...standardQueryConfig,
    ...options,
  });

export const useCancelJob = (
  onSuccess: () => void,
  onError: (err: ApiError) => void
) =>
  useApiMutation<unknown, unknown>(
    CANCEL_JOB_API_NAME,
    "PUT",
    [JOBS_API],
    onSuccess,
    onError
  );

export const useGetJobArtifacts = (
  jobName: string,
  attemptId: number,
  options = {}
) =>
  useApi<JobArtifact[]>(
    [JOB_ARTIFACTS_API, jobName, attemptId],
    `/api/v2/orgs/:orgName/artifacts/jobs/${jobName}/attempts/${attemptId}`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );
