import useApi from "../hooks/UseApi";
import type { BillingData } from "../@types/sd/billing";

export const BILLING_API_NAME = "api:billing";

export interface BillingResponse {
  data: BillingData | null;
  error: string | null;
}

export const useBillingApi = (): BillingResponse => {
  const { isLoading, isIdle, isSuccess, error, data } = useApi<BillingData>(
    BILLING_API_NAME,
    "/api/v2/orgs/:orgName/billing",
    {
      refetchInterval: 30000,
    }
  );
  if (error) {
    return {
      data: null,
      error: error.message,
    };
  }
  if (isLoading || isIdle || !isSuccess || !data) {
    return {
      data: null,
      error: null,
    };
  }
  return {
    data,
    error: null,
  };
};
