import { useBasicApi } from "./Common";
import type { ApiMutationResult } from "../hooks/UseApiMutation";
import useApiMutation from "../hooks/UseApiMutation";

export const UPDATE_USER_ROLE = "api:update_user_role";

// eslint-disable-next-line no-shadow
export enum UserRole {
  ADMIN = "admin",
  MEMBER = "member",
}

export interface User {
  id: string;
  createdAt: string;
  updatedAt: string;
  email: string;
  firstName?: string;
  lastName?: string;
  role?: string;
}

interface GetUsersResponse {
  users: User[];
}

interface DeleteUserRequest {
  email: string;
}

type DeleteUserApiReturn = [
  (email: string) => void,
  ApiMutationResult<unknown, DeleteUserRequest>
];

export const ALL_USERS_API_NAME = "api:all_users";
export const DELETE_USER_API_NAME = "api:delete_user";

export const useUsersApi = () =>
  useBasicApi<User[], "users", GetUsersResponse>(
    ALL_USERS_API_NAME,
    "/api/v1/orgs/:orgName/users",
    "users"
  );

export const useDeleteUserApi = (): DeleteUserApiReturn => {
  const mutation = useApiMutation<unknown, DeleteUserRequest>(
    DELETE_USER_API_NAME,
    "DELETE",
    [ALL_USERS_API_NAME]
  );
  return [
    (publicId: string) => {
      mutation.mutate({
        url: `/api/v1/orgs/:orgName/users/${publicId}`,
      });
    },
    mutation,
  ];
};

export interface UpdateUserRoleRequest {
  value: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UpdateUserRoleResponse {}

type UpdateUserRoleApiReturn = [
  (userId: string, role: UserRole) => void,
  ApiMutationResult<UpdateUserRoleResponse, UpdateUserRoleRequest>
];

export const useUpdateUserRole = (): UpdateUserRoleApiReturn => {
  const mutation = useApiMutation<
    UpdateUserRoleResponse,
    UpdateUserRoleRequest
  >(UPDATE_USER_ROLE, "POST", [ALL_USERS_API_NAME]);
  return [
    (userId: string, role: UserRole) => {
      const data: UpdateUserRoleRequest = { value: role };
      mutation.mutate({
        url: `/api/v1/orgs/:orgName/users/${userId}/roles`,
        data,
      });
    },
    mutation,
  ];
};
