import React from "react";
import { useSearchParams } from "react-router-dom";
import { useCreateIntegrationApi } from "./VcsIntegrationApi";
import Interstitial from "../../../components/Interstitial";
import type { ApiError } from "../../../hooks/UseApi";

const DEFAULT_MESSAGE = "Completing integration. Please wait.";

const SetupIntegration: React.FunctionComponent = () => {
  const [message, setMessage] = React.useState(DEFAULT_MESSAGE);
  const [errorState, setErrorState] = React.useState(false);

  const onSuccessFn = () => {
    window.location.href = "/settings/global";
  };
  const onErrorFn = (error: ApiError) => {
    setMessage(error.response.data.error);
    setErrorState(true);
  };
  const [createIntegration] = useCreateIntegrationApi(onSuccessFn, onErrorFn);

  const [searchParams] = useSearchParams();
  const installationId = searchParams.get("installation_id");
  const setupAction = searchParams.get("setup_action");
  const stateToken = searchParams.get("state");

  React.useEffect(() => {
    if (setupAction !== "install" || !installationId || !stateToken) {
      setMessage("Invalid request: incomplete request params");
      setErrorState(true);
      return;
    }
    createIntegration({
      type: "github",
      github: {
        token: stateToken!,
        installationID: Number(installationId!),
      },
    });
  }, []);

  return (
    <Interstitial includeHeaderWithLogo={false}>
      <p style={{ textAlign: "center", fontWeight: "bold" }}>{message}</p>
      {errorState && (
        <p style={{ textAlign: "center" }}>
          Please click <a href="/settings/global">here</a> to continue.
        </p>
      )}
    </Interstitial>
  );
};

export default SetupIntegration;
