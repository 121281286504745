import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Tests.module.css";
import ListTests from "./ListTests";

const Tests = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <ListTests onSelected={(t) => navigate(`/testing/tests/${t}`)} />
    </div>
  );
};

export default Tests;
