import React from "react";
import { Icon } from "@blueprintjs/core";
import styles from "./RouteGroupStatus.module.css";

interface SandboxStatusProps {
  ready: boolean;
}

const getStatusStyle = (ready: boolean) => {
  if (!ready) return styles.notReady;

  return styles.ready;
};

const SandboxStatus: React.FC<SandboxStatusProps> = ({ ready }) => (
  <Icon icon="dot" size={32} className={getStatusStyle(ready)} />
);

export default SandboxStatus;
