import React from "react";

import { useNavigate } from "react-router-dom";
import styles from "./NewUser.module.css";
import logo from "../../components/Interstitial/logo";
import SdButton from "../../components/theming/SdButton";
import HistoryLink from "../../components/HistoryLink";
import { useAuth } from "../../contexts/AuthContext";
import Loading from "../../components/Loading/Loading";
import AuthError from "../../components/Auth/Error";

const NewUser: React.FunctionComponent = () => {
  const { loading, error, user } = useAuth().state;
  const navigate = useNavigate();
  if (error) {
    return <AuthError />;
  }
  if (loading) {
    return (
      <div className={styles.page}>
        <div className={styles.container}>
          <Loading />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <img src={logo} alt="Signadot" className={styles.logo} />
        <div className={styles.elements}>
          <div>Welcome {user?.email ?? "new user"}. Would you like to</div>
          <SdButton
            onClick={() => {
              navigate("/orgs/setup");
            }}
          >
            Create a new Organization
          </SdButton>
          <div>Or are you</div>
          <HistoryLink url="/users/new/waiting">
            Waiting for an Invite
          </HistoryLink>
          <div className={styles.logout}>
            <SdButton
              icon="log-out"
              onClick={() => {
                navigate("/signout");
              }}
            >
              Sign out
            </SdButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUser;
