// eslint-disable-next-line import/no-extraneous-dependencies
import moment from "moment";
import type { DateRangeShortcut } from "@blueprintjs/datetime/src/shortcuts";
import { Parser } from "@json2csv/plainjs";
import type { EventsApi } from "../../api/AnalyticsApi";

export const exportToCsv = (api: EventsApi): Blob => {
  if (!api.data) return new Blob([], { type: "text/csv" });

  const json2csvParser = new Parser({

    fields: [
      "timestamp",
      "eventType",
      { value: "data.clusterName", label: "cluster" },
      "name",
      { value: "labels", label: "labels" },
    ],
    transforms: [
      (item: any) => ({
        ...item,
        name: item.data.sandboxName ?? item.data.jobName,
      }),
      (item: any) => {
        const labels = Object.entries(item.data.labels);

        item.labels = labels.map(([k, v]) => `${k}:${v}`).join(";");

        return item;
      },
    ],
  });
  const csv = json2csvParser.parse(api.data.events);

  return new Blob([csv], { type: "text/csv" });
};

export const getExportFilename = (
  eventTypes: string[],
  startDate: Date,
  endDate: Date
): string => {
  const events = eventTypes.join("_");
  const start = startDate.toJSON().slice(0, 10);
  const end = endDate.toJSON().slice(0, 10);

  return `analytics-${[events, start, end].join("_")}.csv`;
};

export const getDateShortcuts = (): DateRangeShortcut[] => {
  const startOfMonth = new Date();
  startOfMonth.setDate(1);
  const endOfMonth = new Date();

  const startOfWeek = moment().startOf("week").toDate();
  const endOfWeek = moment().toDate();

  const months: DateRangeShortcut[] = [];
  for (let i = 0; i < 6; i++) {
    const startMonth = moment(startOfMonth)
      .subtract(i + 1, "months")
      .toDate();
    const endMonth = moment(startOfMonth)
      .subtract(i + 1, "months")
      .endOf("month")
      .toDate();

    const label = `${moment(startMonth).format("MMMM")} ${moment(
      startMonth
    ).format("YYYY")}`;

    months.push({
      dateRange: [startMonth, endMonth],
      label,
    });
  }

  return [
    { dateRange: [startOfWeek, endOfWeek], label: "Current week" },
    { dateRange: [startOfMonth, endOfMonth], label: "Current month" },
    ...months,
  ];
};
