import React, { useEffect, useState } from "react";
import { Button, ButtonGroup } from "@blueprintjs/core";
import { BsCardList } from "react-icons/bs";
import { FaListUl } from "react-icons/fa";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import style from "./Controls.module.css";
import CategoryFilter from "./CategoryFilter";
import type { DiffCat } from "../../../../../@types/sd/findings";

// eslint-disable-next-line no-shadow
export enum ViewType {
  Flat = "Flat",
  Grouped = "Grouped",
}

export interface ControlProps {
  filterByCategories: DiffCat[];
  showHeaderFindings?: boolean;
  showMetadataFindings?: boolean;
  viewType?: ViewType;
  findingsCount: React.ComponentProps<typeof CategoryFilter>["findingsCount"];
}

interface Props {
  initialValue: ControlProps;
  onChange: (value: ControlProps) => void;
}

const Controls: React.FC<Props> = ({ initialValue, onChange }) => {
  const [controlProps, setControlProps] = useState<ControlProps>(initialValue);
  useEffect(() => {
    onChange(controlProps);
  }, [controlProps]);

  useEffect(() => {
    setControlProps(initialValue);
  }, [initialValue]);
  return (
    <div className={style.container}>
      <CategoryFilter
        initialFilters={controlProps.filterByCategories}
        onChange={(categories) =>
          setControlProps((prevState) => ({
            ...prevState,
            filterByCategories: categories,
          }))
        }
        findingsCount={controlProps.findingsCount}
      />
      <div className={style.checkboxes}>
        <div>
          <input
            type="checkbox"
            id="hide-headers-checkbox"
            checked={controlProps.showHeaderFindings}
            onChange={(e) =>
              setControlProps((prevState) => ({
                ...prevState,
                showHeaderFindings: e.target.checked,
              }))
            }
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="hide-headers-checkbox"> Show header findings</label>
        </div>
      </div>
      <div className={style.viewOptions}>
        <ButtonGroup>
          <Popover2>
            <Tooltip2
              content="Grouped view"
              intent="none"
              hoverOpenDelay={250}
              position="top-left"
            >
              <Button
                icon={<BsCardList />}
                active={controlProps.viewType === ViewType.Grouped}
                intent={
                  controlProps.viewType === ViewType.Grouped
                    ? "primary"
                    : "none"
                }
                onClick={() =>
                  setControlProps((prevState) => ({
                    ...prevState,
                    viewType: ViewType.Grouped,
                  }))
                }
              />
            </Tooltip2>
          </Popover2>
          <Popover2>
            <Tooltip2
              content="Flat view"
              intent="none"
              hoverOpenDelay={250}
              position="top-left"
            >
              <Button
                icon={<FaListUl />}
                value="Flat view"
                active={controlProps.viewType === ViewType.Flat}
                intent={
                  controlProps.viewType === ViewType.Flat ? "primary" : "none"
                }
                onClick={() =>
                  setControlProps((prevState) => ({
                    ...prevState,
                    viewType: ViewType.Flat,
                  }))
                }
              />
            </Tooltip2>
          </Popover2>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default Controls;
