/* eslint-disable react/no-array-index-key */

import React, { useMemo, useState } from "react";
import { Intent } from "@blueprintjs/core";
import type {
  FindingDiffOp,
  TrafficDiffFinding,
} from "../../../../../../@types/sd/findings";
import type { StructuredDiffOps } from "../../../common/utils";
import { restructureDiffs } from "../../../common/utils";
import { isFindingOfType } from "./util";
import Collapse from "../../../common/Collapse";
import DataDisplay from "./DataDisplay";
import ServiceDescription from "./ServiceDescription";
import styles from "./ServiceDetail.module.css";
import StatusCode from "./StatusCodeDiff";
import BaseDataDisplay from "./BaseDataDisplay/BaseDataDisplay";
import FindingDiff from "../../../common/FindingDiff/FindingDiff";

type ServiceDetailsProps = {
  findings: TrafficDiffFinding[];
  testScript: string;
};

type CombinedTrafficDiffFinding = TrafficDiffFinding & StructuredDiffOps;

// TODO: Let's rename it's name to PairedTrafficFindings
// Skipping for now as both David and I (James) are working on different parts of the GroupedView
// and this might lead to merge conflicts.
const ServiceDetail = ({
  findings: rawFindings,
  testScript,
}: ServiceDetailsProps) => {
  const [statusCode, setStatusCode] = useState<FindingDiffOp | undefined>();

  const findings: CombinedTrafficDiffFinding[] = useMemo(() => {
    setStatusCode(undefined);
    return rawFindings.map((f) => {
      const diffs = restructureDiffs(f.diffOps);

      if (diffs.extra.statusCode) {
        setStatusCode(diffs.extra.statusCode);
      }

      return {
        ...f,
        ...diffs,
      };
    });
  }, [rawFindings]);

  const queryOps: (FindingDiffOp | undefined)[] = findings
    .map((f) => f.extra.queryParameters)
    .filter((f) => f !== undefined);

  const splitFindings = useMemo(() => {
    const split: {
      request: {
        headers: CombinedTrafficDiffFinding[];
        body: CombinedTrafficDiffFinding[];
        metadata: CombinedTrafficDiffFinding[];
      };
      response: {
        headers: CombinedTrafficDiffFinding[];
        body: CombinedTrafficDiffFinding[];
        metadata: CombinedTrafficDiffFinding[];
      };
    } = {
      request: {
        headers: [],
        body: [],
        metadata: [],
      },
      response: {
        headers: [],
        body: [],
        metadata: [],
      },
    };

    findings.forEach((f) => {
      const isReqHeader = isFindingOfType(f, "header", "request");
      if (isReqHeader) {
        split.request.headers.push(f);
        return;
      }

      const isReqBody = isFindingOfType(f, "body", "request");
      if (isReqBody) {
        split.request.body.push(f);
      }

      const isReqMetadata = isFindingOfType(f, "metadata", "request");
      if (isReqMetadata) {
        split.request.metadata.push(f);
      }

      const isRespHeader = isFindingOfType(f, "header", "response");
      if (isRespHeader) {
        split.response.headers.push(f);
        return;
      }

      const isRespBody = isFindingOfType(f, "body", "response");
      if (isRespBody) {
        split.response.body.push(f);
        return;
      }

      const isRespMetadata = isFindingOfType(f, "metadata", "response");
      if (isRespMetadata) split.response.metadata.push(f);
    });

    return split;
  }, [findings]);

  const totalFindings = useMemo(() => {
    const {
      request: { headers: reqHeaders, body: reqBody, metadata: reqMetadata },
      response: {
        headers: respHeaders,
        body: respBody,
        metadata: respMetadata,
      },
    } = splitFindings;

    return (
      reqBody.length +
      respBody.length +
      reqHeaders.length +
      respHeaders.length +
      reqMetadata.length +
      respMetadata.length
    );
  }, [splitFindings]);

  const { request, response } = splitFindings;
  const firstFinding = findings[0];

  return (
    <Collapse
      title={
        <ServiceDescription finding={firstFinding} statusDiffOps={statusCode} testScript={testScript} />
      }
      show
      tags={[{ label: `${totalFindings} findings`, intent: Intent.NONE }]}
      headerStyle={styles.collapseHeader}
    >
      <div className={styles.bodyWrapper}>
        <div className={styles.pairingInfo}>
          <div>
            <span className={styles.label}>
              <b>Pairing ID:</b>
            </span>
            {firstFinding.pairingID}
          </div>
          {firstFinding.source.requestName && (
            <div>
              <span className={styles.label}>
                <b>Request Name:</b>
              </span>
              {firstFinding.source.requestName}
            </div>
          )}

          <StatusCode diffOps={statusCode} />
        </div>
        <BaseDataDisplay
          title="Query"
          show={queryOps.length > 0}
          defaultOpen={false}
        >
          <FindingDiff
            diffOps={queryOps as FindingDiffOp[]}
            labelPath="Parameter"
          />
        </BaseDataDisplay>
        <DataDisplay type="request" {...request} />
        <DataDisplay type="response" {...response} />
      </div>
    </Collapse>
  );
};

export default ServiceDetail;
