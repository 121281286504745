import type { Dispatch, SetStateAction } from "react";
import React from "react";
import SdTheme from "../../../styles/theme";
import PlanButton from "./Utils/PlanButton";
import { getPlanDisplayNameByType } from "../Utils";
import type { BillingData } from "../../../@types/sd/billing";
import { SdHeading2, SdHeading5 } from "../../../components/theming/SdHeading";
import styles from "./ManagePlan.module.css";

interface PlanInfo {
  planType: string;
  title: string;
  subTitle?: string;
  features: string[];
}

interface PlanColumnProps {
  orgName: string;
  targetPlanType: string;
  planInfo: PlanInfo;
  billingData: BillingData | null;
  setCheckoutOpen: Dispatch<SetStateAction<boolean>>;
}

const PlanColumn: React.FunctionComponent<PlanColumnProps> = ({
  orgName,
  targetPlanType,
  planInfo,
  billingData,
  setCheckoutOpen,
}) => (
  <div className={styles.planCard}>
    <div>
      <div className={styles.heading}>
        <SdHeading2 small lightBackground>
          {getPlanDisplayNameByType(planInfo.planType)}
        </SdHeading2>
      </div>
      <div className={styles.pricingSection}>
        <span className={styles.title}>{planInfo.title}</span>
        {planInfo.subTitle && <span>{planInfo.subTitle}</span>}
      </div>
      <div className={styles.featuresTitle}>
        <SdHeading5 small lightBackground>
          Includes
        </SdHeading5>
      </div>
      <div className="px-5" style={{ color: SdTheme.Text.lightBackground }}>
        <ul style={{ paddingLeft: 15 }}>
          {planInfo.features.map((feature: string) => (
            <li className="mb-2" key={`${planInfo.planType}-${feature}`}>
              {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
    <div className={styles.buttonSection}>
      <PlanButton
        targetPlanType={targetPlanType}
        orgName={orgName}
        billingData={billingData}
        setCheckoutOpen={setCheckoutOpen}
      />
      <div className="mb-3" />
    </div>
  </div>
);

export default PlanColumn;
