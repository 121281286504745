import React from "react";
import { TiWarning } from "react-icons/ti";
import type { Test } from "../../../../../@types/sd/testspec";
import styles from "./StatusBadge.module.css";
import {useNavigate} from "react-router-dom";

type StatusBadgeProps = {
  test: Test;
  isNewTest: boolean;
};

const StatusBadge = ({ test, isNewTest }: StatusBadgeProps) => {
  const triggers = test.spec.triggers;
  const navigate = useNavigate();

  if (!test || isNewTest) return null;

  if (triggers) return null;

  return (
    <div className={styles.wrapper} onClick={() => navigate(`/testing/tests/${test.name}/triggers`)}>
      <TiWarning />
      <span>No triggers</span>
    </div>
  );
};

export default StatusBadge;
