import useApi from "../../../hooks/UseApi";
import type {
  AuthSettings,
  GetAuthSettingsResponse,
} from "../../../@types/sd/authsettings";
import type { ApiMutationResult } from "../../../hooks/UseApiMutation";
import useApiMutation from "../../../hooks/UseApiMutation";

export const GET_AUTH_SETTINGS = "api:auth-settings";
export const UPDATE_ENFORCE_SSO_FLAG = "api:update-enforce-sso-flag";
export const UPDATE_AUTO_PROVISION_USERS_FLAG =
  "api:update-auto-provision-users-flag";

interface AuthSetting {
  value: boolean;
}

type EnforceSSOApiReturn = [
  (state: boolean) => void,
  ApiMutationResult<unknown, AuthSetting>
];

export const useGetAuthSettings = (): GetAuthSettingsResponse => {
  const { isLoading, isIdle, isSuccess, error, data } = useApi<AuthSettings>(
    GET_AUTH_SETTINGS,
    "/api/v2/orgs/:orgName/settings/auth",
    {
      refetchInterval: 30000,
    }
  );
  if (error) {
    return {
      error: error.message,
    };
  }
  if (isLoading || isIdle || !isSuccess || !data) {
    return {};
  }
  return { data };
};

export const useEnforceSSO = (): EnforceSSOApiReturn => {
  const mutation = useApiMutation<unknown, AuthSetting>(
    UPDATE_ENFORCE_SSO_FLAG,
    "PUT",
    [GET_AUTH_SETTINGS]
  );
  return [
    (state: false) => {
      mutation.mutate({
        url: `/api/v2/orgs/:orgName/settings/auth/sso/enforce-sso`,
        data: {
          value: state,
        } as AuthSetting,
      });
    },
    mutation,
  ];
};

export const useAutoProvisionUsers = (): EnforceSSOApiReturn => {
  const mutation = useApiMutation<unknown, AuthSetting>(
    UPDATE_AUTO_PROVISION_USERS_FLAG,
    "PUT",
    [GET_AUTH_SETTINGS]
  );
  return [
    (state: false) => {
      mutation.mutate({
        url: `/api/v2/orgs/:orgName/settings/auth/sso/auto-provision-users`,
        data: {
          value: state,
        } as AuthSetting,
      });
    },
    mutation,
  ];
};
