import { useBasicApi } from "./Common";
import type { ApiMutationResult } from "../hooks/UseApiMutation";
import useApiMutation from "../hooks/UseApiMutation";

export interface Invite {
  id: string;
  email: string;
  hasLoggedIn: boolean;
  createdAt: string;
}

interface AllInvitesResponse {
  invites: Invite[];
}

interface CreateInviteRequest {
  email: string;
  role: string;
}

interface CreateInviteResponse {
  publicId: string;
}

interface DeleteInviteRequest {
  email: string;
}

type CreateInviteApiReturn = [
  (email: string, role: string) => void,
  ApiMutationResult<CreateInviteResponse, CreateInviteRequest>
];

type DeleteInviteApiReturn = [
  (publicId: string) => void,
  ApiMutationResult<unknown, DeleteInviteRequest>
];

export const ALL_INVITES_API_NAME = "api:all_invites";
export const CREATE_INVITE_API_NAME = "api:create_invite";
export const DELETE_INVITE_API_NAME = "api:delete_invite";

export const useInvitesApi = () =>
  useBasicApi<Invite[], "invites", AllInvitesResponse>(
    ALL_INVITES_API_NAME,
    "/api/v1/orgs/:orgName/invites",
    "invites"
  );

export const useCreateInviteApi = (): CreateInviteApiReturn => {
  const mutation = useApiMutation<CreateInviteResponse, CreateInviteRequest>(
    CREATE_INVITE_API_NAME,
    "POST",
    [ALL_INVITES_API_NAME]
  );
  return [
    (email: string, role: string) => {
      mutation.mutate({
        url: `/api/v1/orgs/:orgName/invites`,
        data: {
          email,
          role,
        },
      });
    },
    mutation,
  ];
};

export const useDeleteInviteApi = (): DeleteInviteApiReturn => {
  const mutation = useApiMutation<unknown, DeleteInviteRequest>(
    DELETE_INVITE_API_NAME,
    "DELETE",
    [ALL_INVITES_API_NAME]
  );
  return [
    (publicId: string) => {
      mutation.mutate({
        url: `/api/v1/orgs/:orgName/invites/${publicId}`,
      });
    },
    mutation,
  ];
};
