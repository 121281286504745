import React, { useState } from "react";
import { ControlGroup, MenuItem, HTMLSelect } from "@blueprintjs/core";
import type { ItemPredicate, ItemRenderer } from "@blueprintjs/select";
import { Suggest } from "@blueprintjs/select";
import styles from "./EndpointSelect.module.css";
import { HTTP_PROTOCOL } from "../Explore/SavedRequestCatalog/types";

export class ClusterEndpoint {
  readonly name: string;

  readonly namespace: string;

  readonly port: number;

  constructor({
    name,
    namespace,
    port,
  }: {
    name: string;
    namespace: string;
    port: number;
  }) {
    this.name = name;
    this.namespace = namespace;
    this.port = port;
  }

  get hostname(): string {
    return `${this.name}.${this.namespace}.svc:${this.port}`;
  }
}

interface Hostname {
  hostname: string;
}

const renderClusterEndpointItem: ItemRenderer<Hostname> = (
  item,
  { handleClick, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      key={item.hostname}
      active={modifiers.active}
      disabled={modifiers.disabled}
      label=""
      onClick={handleClick}
      text={item.hostname}
    />
  );
};

function renderNewItem(
  query: string,
  active: boolean,
  handleClick: React.MouseEventHandler<HTMLElement>
) {
  return (
    <MenuItem
      text={query}
      active={active}
      onClick={handleClick}
      shouldDismissPopover={false}
    />
  );
}

const filterEndpointItems: ItemPredicate<Hostname> = (
  query,
  endpoint,
  _index,
  exactMatch
) => {
  const normalizedHostname = endpoint.hostname.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedHostname === normalizedQuery;
  }
  return normalizedHostname.indexOf(normalizedQuery) >= 0;
};

function buildNewHostnameFromQuery(hostname: string): Hostname {
  return { hostname };
}

interface EndpointSelectProps {
  items: ClusterEndpoint[];
  onChange?: ((protocol: string, hostname: string) => void) | undefined;
  supportedProtocols?: string[];
  selectedProtocol?: string;
  selectedHost?: string;
}

export default function EndpointSelect({
  items,
  onChange,
  supportedProtocols = ["https", "http", "grpc"],
  selectedProtocol,
  selectedHost,
}: EndpointSelectProps) {
  const [protocol, setProtocol] = useState(HTTP_PROTOCOL);
  const [hostname, setHostname] = useState("");
  const defaultSelectedItem = React.useMemo(
    () => items.filter((item) => item.hostname === selectedHost)?.[0],
    [items, selectedHost]
  );
  React.useEffect(() => {
    setHostname(defaultSelectedItem?.hostname ?? "");
  }, [selectedHost]);

  return (
    <ControlGroup>
      <HTMLSelect
        options={supportedProtocols}
        value={selectedProtocol}
        onChange={(e) => {
          const newProtocol = e.currentTarget.value;
          setProtocol(newProtocol);
          if (onChange) {
            onChange(newProtocol, hostname);
          }
        }}
      />
      <Suggest<Hostname>
        fill
        items={items}
        itemRenderer={renderClusterEndpointItem}
        itemPredicate={filterEndpointItems}
        inputValueRenderer={() => hostname}
        createNewItemFromQuery={buildNewHostnameFromQuery}
        createNewItemRenderer={renderNewItem}
        createNewItemPosition="first"
        resetOnQuery={false}
        onItemSelect={(endpoint) => {
          setHostname(endpoint.hostname);
          if (onChange) {
            onChange(protocol, endpoint.hostname);
          }
        }}
        selectedItem={defaultSelectedItem}
        popoverProps={{ popoverClassName: styles.scrollableDropdown }}
        inputProps={{
          placeholder: "kube-service.kube-namespace.svc:port",
        }}
        className={styles.suggest}
      />
    </ControlGroup>
  );
}
