import * as React from "react";
import { Card } from "@blueprintjs/core";
import { useMemo } from "react";
import type { Patch } from "../../@types/sd/patch";
import Loading from "../../components/Loading/Loading";

interface Props {
  patch: Patch | null;
}

const CustomPatchTab: React.FunctionComponent<Props> = ({ patch }) => {
  const patchFormatted = useMemo(() => {
    if (!patch?.value) {
      return "";
    }
    return JSON.stringify(patch.value, null, 4);
  }, [patch?.value]);

  if (!patch) {
    return <Loading />;
  }
  return (
    <Card
      style={{
        whiteSpace: "pre",
        fontFamily: "monospace",
        maxHeight: 500,
        overflow: "scroll",
        overflowX: "auto",
        overflowY: "auto",
        overflowWrap: "break-word",
      }}
    >
      {patchFormatted}
    </Card>
  );
};

export default CustomPatchTab;
