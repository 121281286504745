import {
  Alert,
  Button,
  ButtonGroup,
  Drawer,
  Icon,
  Intent,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import type { RouteGroup } from "../../@types/sd/routegroup";
import {
  useDeleteRouteGroupApi,
  useRouteGroupsApi,
} from "../../api/RouteGroupsApi";
import Error from "../../components/Error/Error";
import Loading from "../../components/Loading/Loading";
import PaginatedTable from "../../components/PaginatedTable/PaginatedTable";
import SdButton from "../../components/theming/SdButton";
import { SdHeading1 } from "../../components/theming/SdHeading";
import Spacer from "../../components/Util/Util";
import StatusIndicator from "../../util/StatusUtil/StatusIndicator";
import { STATUS } from "../../util/StatusUtil/StatusUtils";
import CreateRouteGroup from "./CreateRouteGroup";
import SdTheme from "../../styles/theme";
import HistoryLink from "../../components/HistoryLink";
import CreateFirst from "../../components/CreateFirst";

type FormattedStatus = {
  text: string;
  component: React.ReactNode;
};

type FormattedRouteGroup = {
  name: string;
  sandboxes: {
    text: string;
    component: React.ReactNode;
  };
  status: FormattedStatus;
  actions: React.ReactNode;
};

const columns = [
  {
    Header: "Route Group Name",
    accessor: "name",
    search: true,
  },
  {
    Header: "Matched Sandboxes",
    accessor: "sandboxes",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "actions",
  },
];

const MAX_TABLE_SANDBOXES = 3;

const formatStatus = (routeGroup: RouteGroup): FormattedStatus => {
  if (routeGroup.status.ready) {
    return {
      text: "Ready",
      component: <StatusIndicator status={{ statusText: STATUS.Healthy }} />,
    };
  }

  return {
    text: "Not Ready",
    component: (
      <span className="ml-4">
        <Popover2>
          <Tooltip2
            content={routeGroup.status.message}
            intent="none"
            placement="top"
            hoverOpenDelay={200}
            usePortal
          >
            <Icon
              icon="full-circle"
              color={SdTheme.Status.warning}
              iconSize={14}
            />
          </Tooltip2>
        </Popover2>
      </span>
    ),
  };
};

const formatRouteGroups = (
  routeGroups: RouteGroup[],
  editRouteGroup: (routeGroup: RouteGroup) => void,
  deleteRouteGroup: (name: string) => void
): FormattedRouteGroup[] =>
  routeGroups.map((routeGroup) => {
    let { matchedSandboxes } = routeGroup.status;
    let otherSandboxes = 0;
    if (!matchedSandboxes) {
      matchedSandboxes = [];
    }
    if (matchedSandboxes.length > MAX_TABLE_SANDBOXES) {
      otherSandboxes = matchedSandboxes.length - MAX_TABLE_SANDBOXES;
      matchedSandboxes = matchedSandboxes.slice(0, MAX_TABLE_SANDBOXES);
    }
    return {
      name: routeGroup.name,
      sandboxes: {
        text: (routeGroup.status.matchedSandboxes ?? []).join(" "),
        component: (
          <>
            {matchedSandboxes.map((sandboxName, idx) => (
              <>
                <HistoryLink
                  key={sandboxName}
                  url={`/sandbox/name/${sandboxName}`}
                >
                  {sandboxName}
                </HistoryLink>
                {idx + 1 < matchedSandboxes!.length && ", "}
              </>
            ))}
            {otherSandboxes > 0 && <span> and {otherSandboxes} more</span>}
          </>
        ),
      },
      status: formatStatus(routeGroup),
      actions: (
        <ButtonGroup>
          <Button
            minimal
            icon="edit"
            onClick={() => {
              editRouteGroup(routeGroup);
            }}
          />
          <Button
            minimal
            icon="trash"
            onClick={() => {
              deleteRouteGroup(routeGroup.name);
            }}
          />
        </ButtonGroup>
      ),
    };
  });

const RouteGroups: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const [isCreateRouteGroupOpen, setCreateRouteGroupOpen] = useState(false);
  const [selectedRouteGroupName, setSelectedRouteGroupName] = useState("");
  const [selectedRouteGroup, setSelectedRouteGroup] = useState<
    RouteGroup | undefined
  >(undefined);
  const [isDeleteRouteGroupOpen, setDeleteRouteGroupOpen] = useState(false);

  const deleteRouteGroupApi = useDeleteRouteGroupApi();

  const handleRowClick = useCallback((row: { name: string }) => {
    navigate(`/routegroups/${row.name}`);
  }, []);

  // Edit handlers.
  const handleEditRouteGroup = useCallback((routeGroup: RouteGroup) => {
    setCreateRouteGroupOpen(true);
    setSelectedRouteGroup(routeGroup);
  }, []);

  // Deletion handlers.
  const handleConfirmDeleteRouteGroup = useCallback((name: string) => {
    setSelectedRouteGroupName(name);
    setDeleteRouteGroupOpen(true);
  }, []);
  const handleCancelDeleteRouteGroup = useCallback(() => {
    setSelectedRouteGroupName("");
    setDeleteRouteGroupOpen(false);
  }, []);
  const handleDeleteRouteGroup = useCallback(() => {
    deleteRouteGroupApi.mutate({
      url: `/api/v2/orgs/:orgName/routegroups/${selectedRouteGroupName}`,
    });
    setSelectedRouteGroupName("");
    setDeleteRouteGroupOpen(false);
  }, [selectedRouteGroupName]);

  const handleOpenCreateRouteGroup = useCallback(() => {
    setCreateRouteGroupOpen(true);
  }, []);
  const handleCloseCreateRouteGroup = useCallback(() => {
    setCreateRouteGroupOpen(false);
    setSelectedRouteGroup(undefined);
  }, []);

  const routeGroupsApi = useRouteGroupsApi();
  const routeGroupsList = useMemo(() => {
    if (!routeGroupsApi.data) {
      return null;
    }
    return formatRouteGroups(
      routeGroupsApi.data,
      handleEditRouteGroup,
      handleConfirmDeleteRouteGroup
    );
  }, [routeGroupsApi.data]);

  if (routeGroupsApi.error) {
    return <Error text={routeGroupsApi.error.message} />;
  }
  if (routeGroupsApi.isLoading || !routeGroupsApi.data) {
    return <Loading />;
  }

  return (
    <>
      <SdHeading1 small lightBackground>
        Route Groups
      </SdHeading1>
      <br />

      {Array.isArray(routeGroupsList) && routeGroupsList.length > 0 ? (
        <>
          <PaginatedTable
            columns={columns}
            data={routeGroupsList}
            onRowClickCallback={handleRowClick}
          />
          <Spacer />
          <SdButton icon="plus" onClick={handleOpenCreateRouteGroup}>
            Create Route Group
          </SdButton>
        </>
      ) : (
        <CreateFirst
          icon={IconNames.LAYOUT}
          message="Create your first Route Group"
          buttonLabel="Create Route Group"
          onClick={handleOpenCreateRouteGroup}
        />
      )}

      <Drawer
        onClose={handleCloseCreateRouteGroup}
        isOpen={isCreateRouteGroupOpen}
      >
        <CreateRouteGroup
          setDrawerOpen={setCreateRouteGroupOpen}
          routeGroup={selectedRouteGroup}
        />
      </Drawer>

      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Delete Route Group"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={isDeleteRouteGroupOpen}
        onCancel={handleCancelDeleteRouteGroup}
        onConfirm={handleDeleteRouteGroup}
      >
        <div>
          <p>
            Are you sure you want to delete <b>{selectedRouteGroupName}</b>
          </p>
        </div>
      </Alert>
    </>
  );
};

export default RouteGroups;
