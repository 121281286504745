import { useCallback } from "react";
import useApi from "../hooks/UseApi";
import type { ApiMutationResult } from "../hooks/UseApiMutation";
import useApiMutation from "../hooks/UseApiMutation";

export interface AuthorizedDomain {
  domain: string;
}

interface AuthorizedDomainData {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  authorizedDomains: string[];
  setAuthorizedDomains: (authorizedDomains: string[]) => void;
  allDomains: string[];
  error: string;
  enableAuthorizedDomainApi: ApiMutationResult;
  setAuthorizedDomainApi: ApiMutationResult;
}

interface GetAuthorizedDomainsResponse {
  authorizedDomains?: AuthorizedDomain[];
  allDomains?: string[];
}

interface SetAuthorizedDomainsRequest {
  domains: string[];
}

interface GetAuthorizedDomainsEnabledResponse {
  enabled: boolean;
}

interface SetAuthorizedDomainsEnabledRequest {
  enabled: boolean;
}

export const GET_AUTHORIZED_DOMAINS_API_NAME = "api:get_authorized_domains";
export const SET_AUTHORIZED_DOMAINS_API_NAME =
  "api_mutate:set_authorized_domains";
export const GET_AUTHORIZED_DOMAINS_ENABLED_API_NAME =
  "api:get_authorized_domains_enabled";
export const SET_AUTHORIZED_DOMAINS_ENABLED_API_NAME =
  "api_mutate:set_authorized_domains_enabled";

export const useAuthorizedDomainsApi = (): AuthorizedDomainData => {
  const authorizedDomainsEnabledApi =
    useApi<GetAuthorizedDomainsEnabledResponse>(
      GET_AUTHORIZED_DOMAINS_ENABLED_API_NAME,
      `/api/v1/orgs/:orgName/settings/authorized-domains/enabled`
    );
  const authorizedDomainsApi = useApi<GetAuthorizedDomainsResponse>(
    GET_AUTHORIZED_DOMAINS_API_NAME,
    `/api/v1/orgs/:orgName/settings/authorized-domains`,
    {
      enabled: authorizedDomainsEnabledApi.data?.enabled ?? false,
    }
  );

  const setAuthorizedDomainsEnabledApi = useApiMutation<
    unknown,
    SetAuthorizedDomainsEnabledRequest
  >(SET_AUTHORIZED_DOMAINS_ENABLED_API_NAME, "POST", [
    GET_AUTHORIZED_DOMAINS_API_NAME,
    GET_AUTHORIZED_DOMAINS_ENABLED_API_NAME,
  ]);
  const setAuthorizedDomainsApi = useApiMutation<
    unknown,
    SetAuthorizedDomainsRequest
  >(SET_AUTHORIZED_DOMAINS_API_NAME, "POST", [GET_AUTHORIZED_DOMAINS_API_NAME]);

  // useCallback hook is required in order to have a "stable" function. Otherwise, any time
  // the hook is run a new function will be created.
  const setEnabled = useCallback((enabled: boolean) => {
    setAuthorizedDomainsEnabledApi.mutate({
      url: `/api/v1/orgs/:orgName/settings/authorized-domains/enabled`,
      data: {
        enabled,
      },
    });
  }, []);
  const setAuthorizedEmailDomains = useCallback((domains: string[]) => {
    setAuthorizedDomainsApi.mutate({
      url: `/api/v1/orgs/:orgName/settings/authorized-domains`,
      data: {
        domains,
      },
    });
  }, []);

  const enabled = authorizedDomainsEnabledApi.data?.enabled ?? false;

  if (authorizedDomainsApi.error) {
    return {
      error: authorizedDomainsApi.error.response.data.error,
      enabled,
      authorizedDomains: [],
      allDomains: [],
      setEnabled,
      setAuthorizedDomains: setAuthorizedEmailDomains,
      enableAuthorizedDomainApi: setAuthorizedDomainsEnabledApi,
      setAuthorizedDomainApi: setAuthorizedDomainsApi,
    };
  }
  if (authorizedDomainsEnabledApi.error) {
    return {
      error: authorizedDomainsEnabledApi.error.response.data.error,
      enabled,
      authorizedDomains: [],
      allDomains: [],
      setEnabled,
      setAuthorizedDomains: setAuthorizedEmailDomains,
      enableAuthorizedDomainApi: setAuthorizedDomainsEnabledApi,
      setAuthorizedDomainApi: setAuthorizedDomainsApi,
    };
  }
  if (setAuthorizedDomainsEnabledApi.error) {
    return {
      error: setAuthorizedDomainsEnabledApi.error.response.data.error,
      enabled,
      authorizedDomains: [],
      allDomains: [],
      setEnabled,
      setAuthorizedDomains: setAuthorizedEmailDomains,
      enableAuthorizedDomainApi: setAuthorizedDomainsEnabledApi,
      setAuthorizedDomainApi: setAuthorizedDomainsApi,
    };
  }
  if (
    authorizedDomainsEnabledApi.isLoading ||
    authorizedDomainsApi.isFetching ||
    !authorizedDomainsApi.data ||
    authorizedDomainsEnabledApi.isLoading ||
    authorizedDomainsEnabledApi.isFetching ||
    !authorizedDomainsEnabledApi.data
  ) {
    return {
      error: "",
      enabled,
      authorizedDomains: [],
      allDomains: [],
      setEnabled,
      setAuthorizedDomains: setAuthorizedEmailDomains,
      enableAuthorizedDomainApi: setAuthorizedDomainsEnabledApi,
      setAuthorizedDomainApi: setAuthorizedDomainsApi,
    };
  }

  const authorizedDomains = authorizedDomainsApi.data.authorizedDomains ?? [];
  const allDomains = authorizedDomainsApi.data.allDomains ?? [];
  return {
    error: "",
    enabled,
    authorizedDomains: authorizedDomains.map((d) => d.domain),
    allDomains: allDomains,
    setEnabled,
    setAuthorizedDomains: setAuthorizedEmailDomains,
    enableAuthorizedDomainApi: setAuthorizedDomainsEnabledApi,
    setAuthorizedDomainApi: setAuthorizedDomainsApi,
  };
};
