import React from "react";
import type { Status } from "../../../../../@types/sd/playground";
import {
  WaitingAnimationIcon,
  WarningTriangleIcon,
} from "../../../../../components/theming/icons";
import {
  Degraded,
  Healthy,
  Missing,
  Progressing,
} from "../../../../../api/PlaygroundApi";
import styles from "./PlaygroundInfoContent.module.css";
import ActionLink from "../../../../../components/theming/ActionLink";
import {
  ClustersOpenQuickstart,
  OPEN_IN_NEW_TAB,
} from "../../../../../Constants";

interface PlaygroundInfoContentProps {
  isLoading: boolean;
  status?: Status;
}

// Keeping this component separate from PlaygroundClusterInfo because the logic here is completely
// testable, but it's harder to test PlaygroundClusterInfo because of API calls.
const PlaygroundInfoContent: React.FC<PlaygroundInfoContentProps> = ({
  isLoading,
  status,
}) => {
  if (isLoading) {
    return <p>Checking playground status</p>;
  }
  if (status === Healthy) {
    return (
      <p className={styles.container}>
        The playground cluster is ready! Here&apos;s the{" "}
        <ActionLink
          onClick={() => {
            window?.open(
              "https://www.signadot.com/docs/tutorials/quickstart/first-sandbox",
              OPEN_IN_NEW_TAB
            );
          }}
          eventName={ClustersOpenQuickstart}
        >
          Quickstart Guide
        </ActionLink>{" "}
        for you to get started.
      </p>
    );
  }
  if (status === Missing || status === Progressing || status === Degraded) {
    return (
      <p className={styles.container}>
        <span>
          <WaitingAnimationIcon className={styles.waiting} />
        </span>
        <span>
          Playground cluster is being provisioned. This may take up to 2
          minutes.
        </span>
      </p>
    );
  }
  return (
    <p className={styles.container}>
      <span>
        <WarningTriangleIcon className={styles.warning} />
      </span>
      <span>Playground cluster status unknown.</span>
    </p>
  );
};

export default PlaygroundInfoContent;
