import React from "react";
import { useNavigate } from "react-router-dom";
import { Boundary, Breadcrumbs, Icon } from "@blueprintjs/core";
import { SdHeading1 } from "../../../../components/theming/SdHeading";
import styles from "./RunnerGroupEditor.module.css";
import content from "./default-content";
import ApplyRunnerGroupAction from "./ApplyRunnerGroupAction";
// TODO: Move this editor out as a reusable component (across various entities) later.
import Editor from "../../../CreateSandbox/Editor";

const RunnerGroupEditor: React.FC = () => {
  const navigate = useNavigate();
  const [spec, setSpec] = React.useState(content);
  return (
    <div className={styles.container}>
      <SdHeading1 small lightBackground>
        Job Runner Groups
      </SdHeading1>
      <Breadcrumbs
        collapseFrom={Boundary.START}
        items={[
          {
            icon: <Icon icon="walk" />,
            text: "Testing: Job Runner Groups",
            onClick: () => navigate(`/testing/job-runner-groups`),
          },
          {
            text: "Editor",
          },
        ]}
      />
      <Editor text={content} onChange={setSpec} />
      <ApplyRunnerGroupAction spec={spec} />
    </div>
  );
};

export default RunnerGroupEditor;
