import React, { useState } from "react";

const HoverUnderline: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isHover, setHover] = useState(false);
  return (
    <span
      style={{ textDecoration: isHover ? "underline" : "none" }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {children}
    </span>
  );
};

export default HoverUnderline;
