import React from "react";
import type { TabId } from "@blueprintjs/core";
import { Tab, Tabs } from "@blueprintjs/core";
import styles from "./InfoPanels.module.css";

export interface PanelInfo {
  id: string;
  title: string;
  disabled: boolean;
  content?: string;
  render: (panelInfo: PanelInfo) => React.ReactElement;
  placeholder?: string;
  language?: string;
  onChange?: (value?: string) => void;
}

interface Props {
  title: string;
  panelInfos: PanelInfo[];
  selectedTabIndex?: TabId;
  onTabChange?: (tabId: TabId) => void;
}

const InfoPanels: React.FC<Props> = ({
  title,
  panelInfos,
  selectedTabIndex,
  onTabChange,
}) => (
  <div className={styles.container}>
    <div>
      <div className={styles.title}>{title}</div>
      <div>
        <Tabs selectedTabId={selectedTabIndex ?? 0} onChange={onTabChange}>
          {panelInfos.map((panelInfo) => (
            <Tab
              key={panelInfo.title}
              id={panelInfo.id}
              title={panelInfo.title}
              panel={
                <div>
                  {panelInfo.render ? panelInfo.render(panelInfo) : null}
                </div>
              }
            />
          ))}
        </Tabs>
      </div>
    </div>
  </div>
);

export default InfoPanels;
