import type { ReactElement } from "react";
import React from "react";
import {
  useNavigate,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import styles from "./Tabs.module.css";
import ActionLink from "../ActionLink";
import type { EventName } from "../../../Constants";

export interface TabProps {
  slug: string;
  label: ReactElement | string;
  eventName: EventName;
  content: JSX.Element;
  hide?: boolean;
}

interface TabsProps {
  tabs: TabProps[];
  defaultSlug: string;
}

const Tabs: React.FC<TabsProps> = ({ tabs, defaultSlug }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div>
      <div className={styles.tabs}>
        {tabs
          .filter((t) => !t.hide)
          .map((tab) => {
            const isActive = location.pathname.endsWith(`/${tab.slug}`);
            return (
              <ActionLink
                key={`tab-${tab.label}`}
                className={isActive ? styles.active : ""}
                onClick={() => {
                  navigate(tab.slug);
                }}
                eventName={tab.eventName}
              >
                {tab.label}
              </ActionLink>
            );
          })}
      </div>
      <Routes>
        <Route path="" element={<Navigate to={defaultSlug} replace />} />
        {tabs.map((tab) => (
          <Route key={tab.slug} path={`${tab.slug}`} element={tab.content} />
        ))}
      </Routes>
    </div>
  );
};

export default Tabs;
