import { useMemo } from "react";
import _ from "lodash";
import useApi from "../hooks/UseApi";
import type { Workload } from "../@types/sd/workload";
import type { Patch } from "../@types/sd/patch";
import type { Sandbox, SandboxStatus } from "../@types/sd/sandbox";
import type { SandboxV2 } from "../@types/sd/sandboxv2";
import type { ApiMutationResult } from "../hooks/UseApiMutation";
import useApiMutation from "../hooks/UseApiMutation";

export interface SandboxesData {
  sandboxesList: SandboxV2[] | null;
  error: string | null;
}

export interface SandboxStatusesData {
  statuses: SandboxStatus[] | null;
  error: string | null;
}

interface SandboxApiResponse {
  sandbox: Sandbox;
}

interface SandboxStatusApiResponse {
  status?: SandboxStatus;
}

type SandboxesResponse = SandboxV2[];

interface SandboxStatusesResponse {
  statuses: SandboxStatus[];
}

interface PatchesApiResponse {
  patches: Patch[];
}

export interface DeploymentsApiResponse {
  deployments: Workload[];
}

const standardQueryConfig = {
  retry: 1,
  refetchInterval: 5000,
};

export const SANDBOXES_API_NAME = "api:sandboxes";
export const SANDBOX_STATUSES_API_NAME = "api:sandbox_statuses";
export const CREATE_SANDBOX_API_NAME = "api:create_sandbox";

export const useSandboxesApi = (): SandboxesData => {
  const { isLoading, isIdle, isSuccess, error, data } =
    useApi<SandboxesResponse>(
      SANDBOXES_API_NAME,
      "/api/v2/orgs/:orgName/sandboxes",
      {
        refetchInterval: 5000,
      }
    );
  if (error) {
    return {
      sandboxesList: null,
      error: error.message,
    };
  }
  if (isLoading || isIdle || !isSuccess || !data) {
    return {
      sandboxesList: null,
      error: null,
    };
  }
  return {
    sandboxesList: data ?? [],
    error: null,
  };
};

export const useSandboxStatusesApi = (): SandboxStatusesData => {
  const { isLoading, isIdle, isSuccess, error, data } =
    useApi<SandboxStatusesResponse>(
      SANDBOX_STATUSES_API_NAME,
      "/api/v1/orgs/:orgName/sandboxes/status",
      {
        refetchInterval: 30000,
      }
    );
  if (error) {
    return {
      statuses: null,
      error: error.message,
    };
  }
  if (isLoading || isIdle || !isSuccess || !data) {
    return {
      statuses: null,
      error: null,
    };
  }
  return {
    statuses: data.statuses,
    error: null,
  };
};

export const useSandboxByNameApi = (sandboxName: string, options = {}) =>
  useApi<SandboxV2>(
    ["sandboxes", sandboxName],
    `/api/v2/orgs/:orgName/sandboxes/${sandboxName}`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );

export const useSandboxByIdApi = (sandboxId: string, options = {}) =>
  useApi<SandboxApiResponse>(
    ["sandboxes", sandboxId],
    `/api/v1/orgs/:orgName/sandboxes/${sandboxId}`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );

export const useSandboxStatusApi = (sandboxId: string, options = {}) =>
  useApi<SandboxStatusApiResponse>(
    ["sandbox_status", sandboxId],
    `/api/v1/orgs/:orgName/sandboxes/${sandboxId}/status`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );

/**
 * `searchParams` must either be a constant or the return value of useMemo.
 */
export const useSandboxDeploymentsApi = (
  searchParams: Record<string, string>,
  options = {}
) => {
  const apiName = useMemo(() => {
    const keys = Object.keys(searchParams);
    keys.sort();
    const name = ["deployments"];
    keys.forEach((key) => name.push(searchParams[key]));
    return name;
  }, [searchParams]);
  return useApi<DeploymentsApiResponse>(
    apiName,
    `/api/v1/orgs/:orgName/deployments?${new URLSearchParams(
      searchParams
    ).toString()}`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );
};

export const useSandboxPatchesApi = (sandboxId: string, options = {}) => {
  const envPatchUrl = useMemo(
    () =>
      `/api/v1/orgs/:orgName/sandboxes/${sandboxId}/patches?${new URLSearchParams(
        { type: "signadot/env" }
      ).toString()}`,
    [sandboxId]
  );
  return useApi<PatchesApiResponse>(
    ["patch_info", sandboxId],
    envPatchUrl,
    options
  );
};

export const getSandboxName = (sandbox: Sandbox | SandboxV2) => {
  const name = _.get(sandbox, ["name"]);
  if (name && name.trim().length > 0) {
    return name;
  }
  return _.get(sandbox, ["id"]);
};

export const useSandboxPatch = (
  sandboxId: string,
  namespace: string,
  name: string,
  options = {}
): [boolean, Patch | null] => {
  const api = useSandboxPatchesApi(sandboxId, options);
  if (!api.isSuccess) {
    return [false, null];
  }
  for (let i = 0; i < api.data.patches.length; i++) {
    const patch = api.data.patches[i];
    if (patch.target.namespace === namespace && patch.target.name === name) {
      return [true, patch];
    }
  }
  return [true, null];
};

export const useSandboxNameById = (sandboxId: string) => {
  const api = useSandboxByIdApi(sandboxId);
  if (!api.isSuccess) {
    return null;
  }
  return getSandboxName(api.data.sandbox);
};

type CreateSandboxApiReturn = [
  (spec: SandboxV2) => void,
  ApiMutationResult<SandboxV2, SandboxV2>
];

export const useCreateSandboxApi = (): CreateSandboxApiReturn => {
  const mutation = useApiMutation<SandboxV2, SandboxV2>(
    CREATE_SANDBOX_API_NAME,
    "PUT",
    [SANDBOXES_API_NAME, SANDBOX_STATUSES_API_NAME]
  );
  return [
    (spec: SandboxV2) => {
      mutation.mutate({
        url: `/api/v2/orgs/:orgName/sandboxes/${spec.name}`,
        data: spec,
      });
    },
    mutation,
  ];
};
