import { Alert, Intent, OverlayToaster } from "@blueprintjs/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDeleteRunnerGroup } from "../../../../../api/RunnerGroupApi";
import type { RunnerGroup } from "../../../../../@types/sd/runnergroup";

type Props = {
  runnerGroup: RunnerGroup;
  isOpen?: boolean;
  onCancel: () => void;
  close: () => void;
};

const toaster = OverlayToaster.create();
const DeleteRunnerGroup: React.FC<Props> = ({
  runnerGroup,
  isOpen,
  onCancel,
  close,
}) => {
  const navigate = useNavigate();
  const onSuccess = () => {
    toaster.show({
      message: "Job Runner Group deleted",
      intent: Intent.SUCCESS,
    });
    close();
    navigate("/testing/job-runner-groups");
  };
  const onError = () => {
    toaster.show({
      message: "Unable to delete Job Runner Group",
      intent: Intent.DANGER,
    });
    close();
  };
  const deleteJRGApi = useDeleteRunnerGroup(onSuccess, onError);
  const deleteRunnerGroup = (jrgName: string) => {
    deleteJRGApi.mutate({
      url: `/api/v2/orgs/:orgName/runnergroups/${jrgName}`,
    });
  };
  return (
    <Alert
      cancelButtonText="Close"
      confirmButtonText="Delete Job Runner Group"
      icon="trash"
      intent={Intent.DANGER}
      isOpen={isOpen}
      onCancel={onCancel}
      onClose={close}
      onConfirm={() => deleteRunnerGroup(runnerGroup.name)}
    >
      <div>
        <p>
          Are you sure you want to delete the Job Runner Group{" "}
          {runnerGroup.name}?
        </p>
      </div>
    </Alert>
  );
};

export default DeleteRunnerGroup;
