import React from "react";
import { Card } from "@blueprintjs/core";
import type { Usage } from "../../../@types/sd/billing";
import styles from "./UsageInfo.module.css";
import { getFormattedUsage } from "./helper";
import UsageEntry from "./UsageEntry";

interface UsageInfoProps {
  usage: Usage;
}

const UsageInfo: React.FunctionComponent<UsageInfoProps> = ({ usage }) => {
  const formattedUsage = getFormattedUsage(usage);
  return (
    <Card className={styles.usageCard}>
      <div className={styles.title}>Limits & Usage</div>
      <div className={styles.usageEntries}>
        {formattedUsage.map((entry) => (
          <UsageEntry key={entry.label} entry={entry} />
        ))}
      </div>
    </Card>
  );
};

export default UsageInfo;
