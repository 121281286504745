import produce from "immer";
import _ from "lodash";
import type {
  ContainerEnvVar,
  ContainerSpec,
  EnvValueFrom,
} from "../../../@types/k8s/container";
import type { Patch, PatchValue } from "../../../@types/sd/patch";

export type EnvVarTableRow = {
  name: string;
  value?: string;
  valueFrom?: EnvValueFrom;
  isModified: boolean;
};

type EnvVarBase = {
  container: string;
  name: string;
};

type EnvVarDelete = {
  type: "delete";
  operation: "delete";
  value?: string;
} & EnvVarBase;

type EnvVarValue = {
  type: "value";
  value?: string;
} & EnvVarBase;

type EnvVarFrom = {
  type: "valueFrom";
  valueFrom: EnvValueFrom;
} & EnvVarBase;

export type EnvVar = EnvVarDelete | EnvVarValue | EnvVarFrom;

/**
 * Represents the current state of the environment variables for a Deployment, along with any
 * changes that are going to be made to it via a patch API call.
 *
 * It tracks the order that environment variables were encountered so that the UI can stay
 * consistent and doesn't depend upon object key ordering.
 *
 * Keeps track of both the environment variables in the baseline Deployment as well as any
 * customizations that have been made to it in the forked Deployment. This is due to having
 * to treat baseline environment variables differently from forked ones. Namely when deleting
 * them from the forked Deployment, we are required to insert a "delete" operation versus
 * removing the upsert entry.
 *
 */
export type AggregateEnvVars = {
  container: string;
  insertionOrder: string[];
  baseline: Record<string, EnvVar>;
  patched: Record<string, EnvVar>;
  modified: Record<string, EnvVar>;
};

function getValue(envVar: EnvVar): string | unknown | undefined {
  switch (envVar.type) {
    case "value":
      return envVar.value;
    case "valueFrom":
      return envVar.valueFrom;
    default:
      return undefined;
  }
}

function valueEquals(value?: string, envVar?: EnvVar) {
  if (!envVar) {
    return false;
  }
  return getValue(envVar) === value;
}

/**
 * TODO: Remove this in the future with an insertion-order tracking container.
 */
export function isDisplayed(
  aggregateEnvVars: AggregateEnvVars,
  name: string
): boolean {
  return aggregateEnvVars.insertionOrder.some((existing) => existing === name);
}

/**
 * Immutably adds or updates an environment variable for eventual persistence via the patch API.
 */
export function upsert(
  aggregateEnvVars: AggregateEnvVars,
  name: string,
  value?: string
): AggregateEnvVars {
  const modifiedEnvVar = aggregateEnvVars.modified[name];
  const patchedEnvVar = aggregateEnvVars.patched[name];
  const baselineEnvVar = aggregateEnvVars.baseline[name];

  if (baselineEnvVar && baselineEnvVar.type === "valueFrom") {
    return aggregateEnvVars;
  }

  // If there exists a modification and the new value for it is the same as something that is already
  // part of the committed patch information or the baseline, remove it from the set of modifications.
  if (
    modifiedEnvVar &&
    (valueEquals(value, patchedEnvVar) || valueEquals(value, baselineEnvVar))
  ) {
    return produce(aggregateEnvVars, (draft) => {
      delete draft.modified[name];
    });
  }

  // We don't care if a patched or baseline env var exists other than for the purposes of figuring out if
  // we should remove an existing modification. So at this point we just unconditionally add a new
  // modification.
  // We also make sure to not add the same env var name to display more than once.
  return produce(aggregateEnvVars, (draft) => {
    draft.modified[name] = {
      type: "value",
      name,
      value,
      container: aggregateEnvVars.container,
    };
    if (!isDisplayed(aggregateEnvVars, name)) {
      draft.insertionOrder.push(name);
    }
  });
}

function upsertValueFrom(
  aggregateEnvVars: AggregateEnvVars,
  name: string,
  value: EnvVarFrom
): AggregateEnvVars {
  return produce(aggregateEnvVars, (draft) => {
    draft.baseline[name] = value;
    if (!isDisplayed(aggregateEnvVars, name)) {
      draft.insertionOrder.push(name);
    }
  });
}

/**
 * Immutably marks an environment variable for removal from the set of changes. A new object is returned and the
 * given one is not modified at all.
 *
 * Some things this need to consider:
 *
 * 1. If a variable definition already exists in the forked Deployment that is marking it for deletion,
 * we should do nothing.
 * 2. Even when adding a new deletion marker, we don't want to remove anything from the insertion
 * order array because the marked items should still show up in red until the changes are actually applied.
 * 3. We need to make sure to copy the value from the baseline to the marked environment variable so
 * that the UI doesn't suddenly have just the value portion disappear from it.
 * 4. If the given name doesn't exist in either the baseline or fork, we don't want to do anything.
 */
export function remove(
  aggregateEnvVars: AggregateEnvVars,
  name: string
): AggregateEnvVars {
  const modifiedEnvVar = aggregateEnvVars.modified[name];
  const patchedEnvVar = aggregateEnvVars.patched[name];
  const baselineEnvVar = aggregateEnvVars.baseline[name];
  // Corner case: User added a new env var but never committed it to a patch, then later deleted
  // it. The env var should be removed from only the modified values.
  if (modifiedEnvVar && !patchedEnvVar && !baselineEnvVar) {
    return produce(aggregateEnvVars, (draft) => {
      delete draft.modified[name];
      draft.insertionOrder.splice(draft.insertionOrder.indexOf(name), 1);
    });
  }
  // Any Env Var that gets its value from a Resource should not be allowed to be modified
  // in any way.
  if (
    (patchedEnvVar && patchedEnvVar.type === "valueFrom") ||
    (baselineEnvVar && baselineEnvVar.type === "valueFrom")
  ) {
    return aggregateEnvVars;
  }
  if (
    (modifiedEnvVar && modifiedEnvVar.type !== "delete") ||
    (patchedEnvVar && patchedEnvVar.type !== "delete") ||
    baselineEnvVar
  ) {
    return produce(aggregateEnvVars, (draft) => {
      draft.modified[name] = {
        type: "delete",
        container: aggregateEnvVars.container,
        name,
        operation: "delete",
      };
    });
  }
  return aggregateEnvVars;
}

/**
 * Immutably creates a new aggregated set of environment variables with all the modifications merged
 * into the patched environment variables. This includes removing any environment variables in the
 * patched set that were deleted on top of moving any edited/added values into them.
 */
export function applyModifications(
  aggregateEnvVars: AggregateEnvVars
): AggregateEnvVars {
  const patched = { ...aggregateEnvVars.patched };
  let { insertionOrder } = aggregateEnvVars;
  insertionOrder
    .filter((key) => aggregateEnvVars.modified[key])
    .forEach((key) => {
      const value = aggregateEnvVars.modified[key];
      const baselineValue = aggregateEnvVars.baseline[key];
      if (
        value.type === "value" &&
        baselineValue?.type === "value" &&
        baselineValue.value === value.value
      ) {
        delete patched[key];
      } else if (value.type === "delete" && !aggregateEnvVars.baseline[key]) {
        delete patched[key];
        insertionOrder = insertionOrder.filter((name) => name !== key);
      } else {
        patched[key] = value;
      }
    });
  return {
    ...aggregateEnvVars,
    patched,
    insertionOrder,
    modified: {},
  };
}

/**
 * Immutably takes a set of changes in the patch value format from the API and creates a
 * modified set of environment changes from them. This effectively acts as a bunch of
 * upserts and removes applied in a row, depending on whether or not each individual
 * patch value has the operation property set to "delete".
 */
export function applyPatchValues(
  aggregateEnvVars: AggregateEnvVars,
  changes: EnvVar[]
): AggregateEnvVars {
  if (changes.length === 0) {
    return aggregateEnvVars;
  }
  return changes.reduce((prevChanges, change) => {
    switch (change.type) {
      case "delete":
        return remove(prevChanges, change.name);
      case "value":
        return upsert(prevChanges, change.name, change.value);
      case "valueFrom":
        return upsertValueFrom(prevChanges, change.name, change);
      default:
        return prevChanges;
    }
  }, aggregateEnvVars);
}

/**
 * Returns whether or not the given set of changes has been modified in the UI without submitting
 * the changes to the server yet.
 */
export function isModified(aggregateEnvVars: AggregateEnvVars): boolean {
  return !_.isEmpty(aggregateEnvVars.modified);
}

export function getEnvVar(
  aggregateEnvVars: AggregateEnvVars,
  name: string
): EnvVar | null {
  const modified = aggregateEnvVars.modified[name];
  if (modified) {
    return modified;
  }
  const patched = aggregateEnvVars.patched[name];
  if (patched) {
    return patched;
  }
  const baseline = aggregateEnvVars.baseline[name];
  if (baseline) {
    return baseline;
  }
  return null;
}

export function getModifiedNameListElements(
  aggregateEnvVars: AggregateEnvVars
): string[] {
  return aggregateEnvVars.insertionOrder.filter(
    (name) => aggregateEnvVars.modified[name]
  );
}

/**
 * Creates an array in the exact format that the patch API expects for submitting any
 * changes.
 */
export function toPatch(aggregateEnvVars: AggregateEnvVars): ContainerEnvVar[] {
  return aggregateEnvVars.insertionOrder
    .filter(
      (name) =>
        (aggregateEnvVars.baseline[name] &&
          aggregateEnvVars.baseline[name].type === "valueFrom") ||
        aggregateEnvVars.patched[name] ||
        aggregateEnvVars.modified[name]
    )
    .map((name) => {
      const envVar = getEnvVar(aggregateEnvVars, name)!;
      if (envVar.type === "value") {
        return {
          container: aggregateEnvVars.container,
          name: envVar.name,
          value: envVar.value,
        };
      }
      if (envVar.type === "valueFrom") {
        return {
          container: aggregateEnvVars.container,
          name: envVar.name,
          valueFrom: envVar.valueFrom,
        };
      }
      return {
        container: aggregateEnvVars.container,
        name: envVar.name,
        operation: "delete",
      };
    });
}

export function toTable(aggregateEnvVars: AggregateEnvVars): EnvVarTableRow[] {
  /**
   * Whether or not a specific environment variable should be shown in the table.
   */
  function shouldDisplay(name: string): boolean {
    return (
      !(
        aggregateEnvVars.modified[name] &&
        aggregateEnvVars.modified[name].type === "delete"
      ) &&
      !(
        aggregateEnvVars.patched[name] &&
        aggregateEnvVars.patched[name].type === "delete" &&
        !aggregateEnvVars.modified[name]
      )
    );
  }

  return aggregateEnvVars.insertionOrder.filter(shouldDisplay).map((name) => {
    const envVar = getEnvVar(aggregateEnvVars, name)!;
    if (envVar.type === "value") {
      return {
        name,
        value: envVar.value,
        isModified: !!aggregateEnvVars.modified[name],
      };
    }
    return {
      name,
      valueFrom: (envVar as EnvVarFrom).valueFrom,
      isModified: !!aggregateEnvVars.modified[name],
    };
  });
}

export function patchToEnvVar(patchValue: PatchValue): EnvVar {
  if (patchValue.valueFrom) {
    return {
      type: "valueFrom",
      name: patchValue.name,
      container: patchValue.container,
      valueFrom: patchValue.valueFrom!,
    };
  }
  if (patchValue.operation === "delete") {
    return {
      type: "delete",
      name: patchValue.name,
      container: patchValue.container,
      operation: "delete",
      value: patchValue.value as string | undefined,
    };
  }
  return {
    type: "value",
    name: patchValue.name,
    container: patchValue.container,
    value: patchValue.value as string | undefined,
  };
}

/**
 * Creates a container for all state related to the environment variable table.
 */
export function newAggregateEnvVars(
  baselineContainer: ContainerSpec,
  patch: Patch | null
): AggregateEnvVars {
  const ret: AggregateEnvVars = {
    container: baselineContainer.name,
    insertionOrder: [],
    baseline: {},
    patched: {},
    modified: {},
  };
  (baselineContainer.env ?? []).forEach((envVar) => {
    if (envVar.valueFrom) {
      ret.baseline[envVar.name] = {
        type: "valueFrom",
        name: envVar.name,
        container: envVar.container,
        valueFrom: envVar.valueFrom,
      };
    } else {
      ret.baseline[envVar.name] = {
        type: "value",
        name: envVar.name,
        container: envVar.container,
        value: envVar.value,
      };
    }
    ret.insertionOrder.push(envVar.name);
  });
  let value: EnvVar[] = [];
  if (patch && patch.value) {
    value = patch.value.map(patchToEnvVar);
  }
  if (Array.isArray(value) && value.length > 0) {
    return applyModifications(applyPatchValues(ret, [...value]));
  }
  return applyModifications(applyPatchValues(ret, []));
}
