import { HTMLSelect, NumericInput } from "@blueprintjs/core";
import React from "react";
import type { OnChangeTTLHandler, ParsedTTL } from "../../hooks/UseTTL";

type Policy = {
  value: string;
  label: string;
};

type TTLSelectorProps = {
  policiesOptions: Policy[];
  onChangeTTL: OnChangeTTLHandler;
  ttl: ParsedTTL;
};

const TTLSelector = ({
  policiesOptions,
  ttl,
  onChangeTTL,
}: TTLSelectorProps) => (
  <div className="flex mb-2 gap-2 items-center">
    <NumericInput
      placeholder="duration"
      min={1}
      buttonPosition="none"
      clampValueOnBlur
      value={ttl.duration || ""}
      onValueChange={(val) => onChangeTTL("duration", val)}
    />
    <HTMLSelect
      value={ttl.durationType}
      onChange={(item) => {
        onChangeTTL("durationType", item.target.value);
      }}
    >
      <option value="m">Minutes</option>
      <option value="h">Hours</option>
      <option value="d">Days</option>
      <option value="w">Weeks</option>
    </HTMLSelect>

    <span
      style={{
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
      }}
    >
      after
    </span>
    <HTMLSelect
      value={ttl.offsetFrom}
      onChange={(item) => {
        onChangeTTL("offsetFrom", item.target.value);
      }}
    >
      {policiesOptions.map((policy) => (
        <option key={policy.value} value={policy.value}>
          {policy.label}
        </option>
      ))}
    </HTMLSelect>
  </div>
);

export default TTLSelector;
