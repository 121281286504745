import React from "react";
import styled from "styled-components";
import { GrTrigger } from "react-icons/gr";
import styles from "./EmptyTriggers.module.css";
import CreateFirst from "../../../../../../../components/CreateFirst";

const ContainerMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

type EmptyTriggersProps = {
  onAddFirstTrigger: () => void;
  isShowingNewTrigger: boolean;
};

const EmptyTriggers = ({
  onAddFirstTrigger,
  isShowingNewTrigger,
}: EmptyTriggersProps) => (
  <div className={styles.container}>
    <CreateFirst
      icon={<GrTrigger size={80} />}
      buttonLabel={!isShowingNewTrigger ? "Add first trigger" : undefined}
      onClick={onAddFirstTrigger}
      message={
        <ContainerMessage>
          <p>
            You can use triggers to automatically run this test when sandboxes are created / updated. 
          </p>
        </ContainerMessage>
      }
    />
  </div>
);

export default EmptyTriggers;
