export const HTTP_PROTOCOL = "HTTP";

export const SUPPORTED_HTTP_METHODS = [
  "GET",
  "HEAD",
  "POST",
  "PUT",
  "DELETE",
  "PATCH",
] as const;

export type SupportedHttpMethod = typeof SUPPORTED_HTTP_METHODS[number];
export type HttpHeaders = { [header: string]: string[] };
type SendToSandboxFunction = (sandbox: string) => void;
type SendToBaselineFunction = (cluster: string) => void;
type ResetFunction = () => void;

export interface RequestData {
  host: string;
  protocol: string;
  method: SupportedHttpMethod;
  requestURI: string;
  headers: HttpHeaders;
  body?: string;
}

export interface RequestPayload {
  request: RequestData;
  destination: {
    sandbox?: string;
    routeGroup?: string;
    cluster?: string;
  };
}

export interface SavedRequest {
  name: string;
  request?: RequestData;
  id: string;
  createdAt: string;
}

export interface SavedRequestsResponse {
  data?: SavedRequest[];
  error?: string;
}

export interface GetSavedRequestResponse {
  data: SavedRequest | null;
  error: string | null;
}

export interface RequestExecResponseData {
  status: string;
  statusCode: number;
  contentLength: number;
  headers?: HttpHeaders;
  body?: string;
}

export type ExecResponseApiResponse =
  | {
      isLoading: true; // Loading state
      error: undefined;
      data: undefined;
      sendToSandbox: SendToSandboxFunction;
      sendToBaseline: SendToBaselineFunction;
      reset: ResetFunction;
    }
  | {
      isLoading: false;
      error: string; // Error state
      data: undefined;
      sendToSandbox: SendToSandboxFunction;
      sendToBaseline: SendToBaselineFunction;
      reset: ResetFunction;
    }
  | {
      isLoading: false;
      error: undefined;
      data: RequestExecResponseData; // Success state
      sendToSandbox: SendToSandboxFunction;
      sendToBaseline: SendToBaselineFunction;
      reset: ResetFunction;
    }
  | {
      isLoading: false; // Not in any state (Used when the criteria to make a call is not met)
      error: undefined;
      data: undefined;
      sendToSandbox: SendToSandboxFunction;
      sendToBaseline: SendToBaselineFunction;
      reset: ResetFunction;
    };

type SaveRequestFunction = (name: string) => void;
export type SaveRequestApiResponse =
  | {
      isLoading: true; // Loading state
      error: undefined;
      data: undefined;
      submit: SaveRequestFunction;
      reset: ResetFunction;
    }
  | {
      isLoading: false;
      error: string; // Error state
      data: undefined;
      submit: SaveRequestFunction;
      reset: ResetFunction;
    }
  | {
      isLoading: false;
      error: undefined;
      data: SavedRequest; // Success state
      submit: SaveRequestFunction;
      reset: ResetFunction;
    }
  | {
      isLoading: false; // Not in any state (Used when the criteria to make a call is not met)
      error: undefined;
      data: undefined;
      submit: SaveRequestFunction;
      reset: ResetFunction;
    };
