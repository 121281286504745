import React, { useState } from "react";
import { Alert, Button, Intent, OverlayToaster } from "@blueprintjs/core";
import _ from "lodash";
import useApi from "../../hooks/UseApi";
import useApiMutation from "../../hooks/UseApiMutation";
import Loading from "../../components/Loading/Loading";
import { formatDateTime, getTimeAgoElement } from "../../util/Util";
import PaginatedTable from "../../components/PaginatedTable/PaginatedTable";
import useClusterTokenCreator from "./TokenApi";

type Props = {
  clusterName: string;
  setCreatedToken: (createdToken: string) => void;
};

interface ClusterToken {
  id: string;
  createdAt: string;
  lastConnectedAt: string;
  lastConnectedIp: string;
  maskedValue: string;
}

interface FormattedClusterToken {
  id: string;
  createdAt: React.ReactNode;
  lastConnectedAt: string;
  lastConnectedIp: string;
  maskedValue: string;
  icon: React.ReactNode;
}

interface SelectedToken {
  id: string;
  redactedToken: string;
}

const toaster = OverlayToaster.create();

const columns = [
  {
    Header: "ID",
    accessor: "id",
    search: true,
    show: false,
  },
  {
    Header: "Created",
    accessor: "createdAt",
  },
  {
    Header: "Token",
    accessor: "maskedValue",
    search: true,
  },
  {
    Header: "Last Connected At",
    accessor: "lastConnectedAt",
  },
  {
    Header: "Last Connected IP",
    accessor: "lastConnectedIp",
  },
  {
    Header: "Action",
    accessor: "icon",
  },
];

interface ClusterTokensApiResponse {
  clusterTokens: ClusterToken[];
}

const ClusterTokens: React.FunctionComponent<Props> = ({
  clusterName,
  setCreatedToken,
}) => {
  const queryIdentifier = ["clusterTokens", clusterName];

  // state for the modal for confirming deletion.
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState<SelectedToken | null>(
    null
  );

  const showToken = (token: string) => {
    if (!token) return;
    setCreatedToken(token);
  };

  const { isLoading, isIdle, data, error, isSuccess } =
    useApi<ClusterTokensApiResponse>(
      queryIdentifier,
      `/api/v1/orgs/:orgName/clusters/${clusterName}/tokens`
    );
  const [mutation, createNewToken] = useClusterTokenCreator(showToken);
  const deletion = useApiMutation(
    "delete_cluster_token",
    "DELETE",
    [queryIdentifier] /* invalidation of get query output */
  );

  const handleDelete = (row: FormattedClusterToken) => {
    setSelectedToken({ id: row.id, redactedToken: row.maskedValue });
    setDeleteModalOpen(true);
  };

  if (isLoading || isIdle) {
    return <Loading />;
  }
  if (error) {
    return <>Error: {error?.message || "An unknown error occurred."}</>;
  }

  // show success / failure messages for API calls.
  if (mutation.error) {
    toaster.show({
      message: _.get(mutation.error, "response.data.error"),
      intent: Intent.DANGER,
    });
    mutation.reset();
  } else if (mutation.isSuccess) {
    mutation.reset();
  }

  if (deletion.error) {
    toaster.show({
      message: _.get(deletion.error, "response.data.error"),
      intent: Intent.DANGER,
    });
    deletion.reset();
  } else if (deletion.isSuccess) {
    toaster.show({ message: "Cluster Token Deleted", intent: Intent.SUCCESS });
    deletion.reset();
  }

  const formatClusterTokens = (
    _data: ClusterToken[]
  ): FormattedClusterToken[] => {
    if (_data === null) {
      return [];
    }
    const clusterTokens: ClusterToken[] = JSON.parse(JSON.stringify(_data));
    return clusterTokens.map((_item): FormattedClusterToken => {
      const item = _item as FormattedClusterToken;
      item.createdAt = getTimeAgoElement(_item.createdAt);
      item.lastConnectedAt =
        item.lastConnectedAt === null
          ? "Never"
          : formatDateTime(item.lastConnectedAt);
      item.lastConnectedIp =
        item.lastConnectedIp === null ? "None" : item.lastConnectedIp;
      item.maskedValue = `${item.maskedValue}...`;

      item.icon = <Button minimal icon="trash" />;
      return item;
    });
  };

  const clusterTokens = formatClusterTokens(data!.clusterTokens);
  return (
    <>
      <h1>Cluster Tokens</h1>
      {isSuccess && (
        <>
          {(Array.isArray(clusterTokens) && clusterTokens.length > 0 && (
            <PaginatedTable
              columns={columns}
              data={clusterTokens}
              onActionCallback={handleDelete}
              enableSearch={false}
            />
          )) || <p className="mb-3">Nothing here yet</p>}

          {/* Modal Dialog to confirm deletion */}
          <Alert
            cancelButtonText="Cancel"
            confirmButtonText="Delete Token"
            icon="trash"
            intent={Intent.DANGER}
            isOpen={isDeleteModalOpen}
            onCancel={() => {
              setSelectedToken(null);
              setDeleteModalOpen(false);
            }}
            onConfirm={() => {
              const tokenId = selectedToken!.id;
              deletion.mutate({
                data: {},
                url: `/api/v1/orgs/:orgName/clusters/${clusterName}/tokens/${tokenId}`,
              });
              setSelectedToken(null);
              setDeleteModalOpen(false);
            }}
          >
            <p>
              Are you sure you want to delete the token:{" "}
              <b>{selectedToken?.redactedToken}</b>?
            </p>
          </Alert>

          <Button icon="plus" onClick={() => createNewToken(clusterName)}>
            Create New Token
          </Button>
        </>
      )}
    </>
  );
};

export default ClusterTokens;
