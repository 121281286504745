import type { ItemPredicate } from "@blueprintjs/select";
import type { TestTrigger } from "../../../../../../../@types/sd/testspec";
import type { PLAIN_WORKLOAD } from "./constant";

export const validateTrigger = (t: TestTrigger) => {
  if (!t.executionTemplate?.cluster) {
    return "Cluster name is missing";
  }

  if (!t.sandboxOf?.namespace) {
    return "Workload namespace is missing";
  }

  if (!t.sandboxOf?.name) {
    return "Workload name is missing";
  }

  if (!t.sandboxOf?.kind) {
    return "Workload kind is missing";
  }

  return undefined;
};

export const validPath = (path: string) => {
  if (!path.includes("/")) return false;

  const parts = path.split("/");
  if (parts.length !== 2) {
    return false;
  }

  const [namespace, serviceName] = parts;
  return !(serviceName.length === 0 || namespace.length === 0);
};

export const pathExists = (
  workloads: PLAIN_WORKLOAD[],
  current: PLAIN_WORKLOAD,
  index: number
) => {
  const path = `${current.kind}@${current.namespace}/${current.name}`;

  // Check only up to the current index
  return workloads
    .slice(0, index)
    .some(
      (service) =>
        `${service.kind}@${service.namespace}/${service.name}` === path
    );
};

export const filterWorkloadItems: ItemPredicate<PLAIN_WORKLOAD> = (
  query,
  workload
) => {
  if (query.includes("/")) {
    const combinedPath = `${workload.namespace}/${workload.name}`;

    return combinedPath.includes(query);
  }

  if (workload.namespace.includes(query)) return true;

  return workload.name.includes(query);
};

export const getFirstN = (
  query: string,
  workloads: Array<PLAIN_WORKLOAD>,
  n: number
) => {
  let filtered = 0;

  return workloads.filter((item) => {
    if (filtered === n) return false;

    const isFiltered = filterWorkloadItems(query, item);

    if (isFiltered) {
      filtered += 1;
    }
    return isFiltered;
  });
};
