// eslint-disable-next-line no-shadow
export enum AttemptPhaseEnum {
  QUEUED = "queued",
  RUNNING = "running",
  CANCELED = "canceled",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

type JobSucceededState = {
  phase: AttemptPhaseEnum.SUCCEEDED;
  state: {
    succeeded: {
      exitCode: number;
    };
  };
};

type JobCanceledState = {
  phase: AttemptPhaseEnum.CANCELED;
  state: {
    canceled: {
      inCluster: boolean;
      canceledAt: string;
      canceledBy: string;
      message: string;
    };
  };
};

type JobFailedState = {
  phase: AttemptPhaseEnum.FAILED;
  state: {
    failed: {
      exitCode: number;
      message: string;
      retryAttempt: false;
    };
  };
};

type JobRunningState = {
  phase: AttemptPhaseEnum.RUNNING;
  state: {
    running: {
      jobExecutorAddr: string;
      podName: string;
      podNamespace: string;
    };
  };
};

type JobQueuedState = {
  phase: AttemptPhaseEnum.QUEUED;
  state: {
    queued: {
      message: string;
      lastMessage: string;
    };
  };
};

type JobAttempt = {
  id: number;
  createdAt: string;
  sentToClusterBy: string;
  startedAt: string;
  finishedAt: string;
  executionCount: number;
} & (
  | JobFailedState
  | JobSucceededState
  | JobCanceledState
  | JobRunningState
  | JobQueuedState
);

export type Job = {
  id: string; // Needed by the <FilteredView> component. Setting this to name for now.
  name: string;
  createdAt: string;
  spec: {
    namePrefix: string;
    cluster: string;
    runnerGroup: string;
    labels?: Record<string, string>;
    command: string[];
    script: string;
    routingContext?: {
      sandbox: string;
      routegroup: string;
    };
    env: {
      name: string;
      value: string;
    }[];
    uploadArtifacts: {
      path: string;
      meta: Record<string, string>;
    }[];
  };
  status: {
    attempts: JobAttempt[];
    runnerGroupDeletionCompletedAt?: string;
  };
};

export interface JobArtifact {
  name: string;
  url: string;
  artifactID: string; // TODO: It won't be artifact ID. Will address this during integration.
  createdAt: string;

  path: string;
  lastModified: string;
  size: number;
  space: string;
}
