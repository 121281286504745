import type { ToastProps } from "@blueprintjs/core";
import { Intent } from "@blueprintjs/core";
import type { Test, TestTrigger } from "../../../../../../@types/sd/testspec";

export type ValidationResponse = Map<number | string, string>;

const validateTriggers = (
  triggers: Partial<TestTrigger>[],
  errors: ValidationResponse
): ValidationResponse => {
  triggers.forEach((trigger, index) => {
    if (!trigger.executionTemplate?.cluster) {
      errors.set(index, "Cluster name is missing");
      return;
    }

    if (!trigger.sandboxOf?.namespace) {
      errors.set(index, "Sandbox namespace is missing");
      return;
    }

    if (!trigger.sandboxOf?.name) {
      errors.set(index, "Sandbox name is missing");
      return;
    }

    if (!trigger.sandboxOf?.kind) {
      errors.set(index, "Sandbox kind is missing");
    }
  });

  return errors;
};

// eslint-disable-next-line import/prefer-default-export
export const validateTest = (
  test: Partial<Test>
): {
  errors: ValidationResponse;
  getFirstError: () => ToastProps | undefined;
} => {
  const errors = new Map<string, string>();

  if (!test.name) {
    errors.set("name", "You must specify a name for the test");
  }

  if (test.name && test.name === "editor") {
    errors.set("name", "Invalid name specified for test");
  }

  if (!test.spec?.script) {
    errors.set("editor", "Test script must not be empty");
  }

  const mergedErrors = validateTriggers(test.spec?.triggers ?? [], errors);

  return {
    errors: mergedErrors,
    getFirstError: () => {
      const firstError = errors.get(mergedErrors.keys().next().value);
      if (!firstError) return undefined;

      return {
        message: firstError,
        intent: Intent.DANGER,
      };
    },
  };
};
