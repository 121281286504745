import React from "react";
import { Card, Dialog, Elevation } from "@blueprintjs/core";
import SdButton from "../../../../components/theming/SdButton";
import styles from "./ConfirmDelete.module.css";

interface Props {
  name: string;
  showDialog: boolean;
  setShowDialog: (showDialog: boolean) => void;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDelete: React.FC<Props> = ({
  name,
  showDialog,
  setShowDialog,
  onConfirm,
  onCancel,
}) => (
  <Dialog
    title="Confirm Deletion"
    isOpen={!!name && showDialog}
    onClose={() => {
      setShowDialog(false);
      onCancel();
    }}
    canEscapeKeyClose
    canOutsideClickClose
  >
    <Card className={styles.container} interactive elevation={Elevation.TWO}>
      <div className={styles.prompt}>
        Are you sure you want to delete: <b>{name}</b>?
      </div>
      <div className={styles.buttons}>
        <SdButton
          onClick={() => {
            setShowDialog(false);
            onConfirm();
          }}
        >
          Confirm
        </SdButton>
        <SdButton
          onClick={() => {
            setShowDialog(false);
            onCancel();
          }}
        >
          Cancel
        </SdButton>
      </div>
    </Card>
  </Dialog>
);

export default ConfirmDelete;
