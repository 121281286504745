import type {
  CreateTestExecution,
  QueryResult,
  TestExecution,
} from "../@types/sd/testexecutions";
import useApi, { type ApiError } from "../hooks/UseApi";
import useApiMutation from "../hooks/UseApiMutation";

export const GET_TEST_EXECUTIONS_API_NAME = "api:get-test-executions";
export const CREATE_TEST_EXECUTION_API_NAME =
  "api_mutation:create-test-execution";
export const GET_TEST_EXECUTION_API_NAME = "api:get-test-execution";
export const FILTER_TEST_EXECUTIONS_API_NAME = "api:filter-test-executions";

const standardQueryConfig = {
  retry: 1,
  refetchInterval: 5000,
};

export const useGetTestExecutions = (testName: string, options = {}) =>
  useApi<{ execution: TestExecution }[]>(
    [GET_TEST_EXECUTIONS_API_NAME, testName],
    `/api/v2/orgs/:orgName/tests/executions?isAutoDiff=true&skipDeletedTests=true&test=${testName}&orderDir=desc&pageSize=100`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );

export const useGetTestExecution = (
  testName: string,
  executionName: string,
  options = {}
) =>
  useApi<TestExecution>(
    [GET_TEST_EXECUTION_API_NAME, testName, executionName],
    `/api/v2/orgs/:orgName/tests/${testName}/executions/${executionName}`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );

export const useCreateTestExecution = (
  onSaved: (data: TestExecution) => void,
  onError: (error: ApiError) => void
) =>
  useApiMutation<TestExecution, CreateTestExecution>(
    CREATE_TEST_EXECUTION_API_NAME,
    "POST",
    [GET_TEST_EXECUTIONS_API_NAME],
    onSaved,
    onError
  );

export const useQuerySandboxTestExecutions = (
  sandboxName: string,
  options = {}
) =>
  useApi<QueryResult[]>(
    [FILTER_TEST_EXECUTIONS_API_NAME, sandboxName],
    `/api/v2/orgs/:orgName/tests/executions?targetSandbox=${sandboxName}&groupBy=test&orderDir=desc`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );
