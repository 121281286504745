import React from "react";
import { HTMLSelect } from "@blueprintjs/core";

interface Props {
  splitBy: string;
  setSplitBy: (v: string) => void;
}

const FilterSplitBy: React.FunctionComponent<Props> = ({
  splitBy,
  setSplitBy,
}) => (
  <HTMLSelect
    defaultValue={splitBy}
    onChange={(e) => setSplitBy(e.currentTarget.value)}
  >
    <option value="Hour">Hour</option>
    <option value="Day">Day</option>
  </HTMLSelect>
);

export default FilterSplitBy;
