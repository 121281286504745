import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import type { MaybeElement } from "@blueprintjs/core";
import { Button, Icon } from "@blueprintjs/core";
import React from "react";
import type { IconName } from "@blueprintjs/icons";

interface Props {
  text: string;
  icon: IconName | MaybeElement;
}

const IconWithTooltip: React.FC<Props> = ({ text, icon }) => (
  <Popover2>
    <Tooltip2 content={text} intent="none">
      <Button minimal>
        <Icon icon={icon} />
      </Button>
    </Tooltip2>
  </Popover2>
);

export default IconWithTooltip;
