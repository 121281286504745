import React from "react";
import type { SandboxSpecV2 } from "../../../@types/sd/sandboxv2";
import { GITHUB_PULL_REQUEST_KEY, GITHUB_REPO_KEY } from "../constants";
import ActionLink from "../../../components/theming/ActionLink";
import styles from "./PRInfo.module.css";
import { OPEN_IN_NEW_TAB } from "../../../Constants";

interface PRInfoProps {
  spec?: SandboxSpecV2;
}

const PRInfo: React.FC<PRInfoProps> = ({ spec }) => {
  const labels = spec?.labels;
  const repoKey = labels?.[GITHUB_REPO_KEY];
  const prKey = labels?.[GITHUB_PULL_REQUEST_KEY];
  if (repoKey && prKey) {
    const repoURL = `https://github.com/${repoKey}`;
    const prURL = `${repoURL}/pull/${prKey}`;
    return (
      <div className={styles.container}>
        PR{" "}
        <ActionLink onClick={() => window.open(prURL, OPEN_IN_NEW_TAB)}>
          #{prKey}
        </ActionLink>{" "}
        in repository{" "}
        <ActionLink onClick={() => window.open(repoURL, OPEN_IN_NEW_TAB)}>
          {repoKey}
        </ActionLink>
      </div>
    );
  }
  return null;
};

export default PRInfo;
