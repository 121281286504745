import React, { useEffect, useState } from "react";
import { Card, Intent, OverlayToaster, Switch } from "@blueprintjs/core";
import styles from "./ManagedRunnerGroups.module.css";
import { SdHeading5 } from "../../../components/theming/SdHeading";
import ClustersTable from "./ClustersTable";
import SdButton from "../../../components/theming/SdButton";
import type {
  ManagedRGClusterSettings,
  ManagedRGData,
} from "../../../api/ManagedRunnerGroupsApi";
import {
  useManagedRunnerGroups,
  useSaveManagedRGSettings,
} from "../../../api/ManagedRunnerGroupsApi";

const toaster = OverlayToaster.create();

const ManagedRunnerGroups = () => {
  const { data: managedRG } = useManagedRunnerGroups();
  const saveManagedRGApi = useSaveManagedRGSettings(
    () => {
      toaster.show({
        message: "Managed Runner Group Settings saved successfully",
        intent: Intent.SUCCESS,
      });
      saveManagedRGApi.reset();
    },
    (error) => {
      toaster.show({ message: error.message, intent: Intent.DANGER });
      saveManagedRGApi.reset();
    }
  );
  const [localState, setLocalState] = useState<ManagedRGData | undefined>(
    undefined
  );
  useEffect(() => {
    if (!localState) {
      setLocalState(managedRG);
    }
  }, [managedRG]);

  const updateRemoteState = () => {
    if (!localState) return;
    saveManagedRGApi.mutate({
      data: localState,
      url: `/api/v2/orgs/:orgName/settings/managed-jobrunnergroups`,
    });
  };

  useEffect(() => {
    if (!localState) return;
    if (localState.enabled !== managedRG?.enabled) {
      // Changes to enabled state will be applied immediately unlike the cluster
      // level settings which will be applied only when the user clicks on apply.
      updateRemoteState();
    }
  }, [localState]);

  const handleEnabledChange = (enabled: boolean) => {
    setLocalState((prev) => {
      if (!prev) return;
      return {
        ...prev,
        enabled: enabled,
      };
    });
  };

  const handleClusterSettingsChange = (
    newSettings: Record<string, ManagedRGClusterSettings>
  ) => {
    setLocalState((prev) => {
      if (!prev) return;
      return {
        ...prev,
        clusters: newSettings,
      };
    });
  };

  if (!localState) {
    return null;
  }
  return (
    <Card className={styles.managedRunnerGroupContainer}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <SdHeading5 lightBackground>Managed Runner Group (Beta)</SdHeading5>
        &nbsp;&nbsp;
        <Switch
          checked={!!localState?.enabled}
          onChange={() => handleEnabledChange(!localState?.enabled)}
        />
      </div>
      <p className={styles.info}>
        Managed Runner Groups are used to run Smart Tests. This functionality
        requires Signadot Operator v0.19+.
      </p>

      {localState.enabled && (
        <>
          <ClustersTable
            clusterSettings={localState.clusters}
            onChange={(newSettings) => handleClusterSettingsChange(newSettings)}
          />
          <SdButton onClick={updateRemoteState}>Apply</SdButton>
        </>
      )}
    </Card>
  );
};

export default ManagedRunnerGroups;
