import React from "react";
import SavedRequestCatalog from "./SavedRequestCatalog";
import ExplorePanel from "./ExplorePanel";
import styles from "./Explore.module.css";
import type { SavedRequest } from "./SavedRequestCatalog/types";
import type { SandboxV2 } from "../../@types/sd/sandboxv2";

interface Props {
  sandbox: SandboxV2;
}

const Explore: React.FC<Props> = ({ sandbox }) => {
  const [selectedRequest, setSelectedRequest] = React.useState<
    SavedRequest | undefined
  >(undefined);
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <SavedRequestCatalog onSelect={setSelectedRequest} />
      </div>
      <div className={styles.right}>
        <ExplorePanel
          selectedRequestPartial={selectedRequest}
          sandbox={sandbox}
        />
      </div>
    </div>
  );
};

export default Explore;
