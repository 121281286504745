import { Button, Icon, Tag } from "@blueprintjs/core";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SdColors from "../../styles/colors";

interface Props {
  text: string;
}

const FilterTag: React.FunctionComponent<Props> = ({ text }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Tag
      round
      style={{ backgroundColor: SdColors.PRIMARY700, paddingLeft: 17 }}
    >
      {text}
      <Button
        minimal
        onClick={() => {
          navigate(location.pathname);
        }}
      >
        {" "}
        <Icon icon="cross" style={{ color: "white" }} />
      </Button>
    </Tag>
  );
};

export default FilterTag;
