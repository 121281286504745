import React from "react";
import style from "./ValidationError.module.css";

type ValidationErrorProps = {
  errorMessage?: string;
};

const ValidationError = ({ errorMessage }: ValidationErrorProps) => {
  if (!errorMessage) return null;

  return <div className={style.error}>{errorMessage}</div>;
};

export default ValidationError;
