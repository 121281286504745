import React from "react";
import type { RouteGroup } from "../../@types/sd/routegroup";
import HistoryLink from "../../components/HistoryLink";
import PaginatedTable from "../../components/PaginatedTable/PaginatedTable";

interface Props {
  routeGroup: RouteGroup;
}

const columns = [
  {
    Header: "Name",
    accessor: "name",
    search: true,
  },
];

const MatchedSandboxesTable: React.FunctionComponent<Props> = React.memo(
  ({ routeGroup }) => {
    const sandboxes = routeGroup.status.matchedSandboxes;
    if (!sandboxes || sandboxes.length === 0) {
      return <div>No matching sandboxes found</div>;
    }
    const data = sandboxes.map((name) => ({
      name: {
        text: name,
        component: (
          <HistoryLink url={`/sandbox/name/${name}`}>{name}</HistoryLink>
        ),
      },
    }));

    return <PaginatedTable columns={columns} data={data} />;
  },
  (prevProps, nextProps) => {
    const prevSandboxes = prevProps.routeGroup.status.matchedSandboxes;
    const nextSandboxes = nextProps.routeGroup.status.matchedSandboxes;
    if (prevSandboxes === nextSandboxes) {
      return true;
    }
    if (!prevSandboxes || !nextSandboxes) {
      return false;
    }
    if (prevSandboxes.length !== nextSandboxes.length) {
      return false;
    }
    for (let i = 0; i < prevSandboxes.length; i++) {
      if (prevSandboxes[i] !== nextSandboxes[i]) {
        return false;
      }
    }
    return true;
  }
);

MatchedSandboxesTable.displayName = "MatchedSandboxesTable";

export default MatchedSandboxesTable;
