import React from "react";
import { Intent, Tag } from "@blueprintjs/core";
import { STATUS } from "../../util/StatusUtil/StatusUtils";
import SdTheme from "../../styles/theme";
import StatusCard from "./StatusCard";

interface Props {
  statusText?: string;
  statusDetail?: string;
  intent?: Intent;
  title?: string;
  hideIfHealthy?: boolean;
}

const ResourceStatus: React.FunctionComponent<Props> = ({
  statusText,
  statusDetail,
  intent,
  title,
  hideIfHealthy,
}) => {
  if (!title && !intent) {
    if (statusText === STATUS.Healthy) {
      if (hideIfHealthy) {
        return null;
      }
      return (
        <div className="mt-1" style={{ position: "relative", top: -1 }}>
          <Tag style={{ backgroundColor: SdTheme.Status.good }} round>
            Ready
          </Tag>
        </div>
      );
    }

    title = "Not Ready";
    intent = Intent.DANGER;

    if (statusText === STATUS.Unknown) {
      title = "Unknown";
      intent = Intent.WARNING;
    }
  }
  return (
    <StatusCard title={title} description={statusDetail} intent={intent} />
  );
};

export default ResourceStatus;
