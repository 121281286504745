import React from "react";

import { Outlet } from "react-router-dom";
import { Sidebar } from "./Sidebar/Sidebar";
import { useAuth } from "../../contexts/AuthContext";
import Loading from "../Loading/Loading";
import AuthError from "../Auth/Error";
import Error from "../Error/Error";
import styles from "./Layout.module.css";
import TopBar from "./TopBar";
import BillingBanner from "../../pages/Billing/BillingBanner";

const MIN_VIEWPORT_WIDTH_PX = 800;

const Layout: React.FunctionComponent = () => {
  const { state } = useAuth();
  const { loading, error, org } = state;
  const [width, setWidth] = React.useState(
    typeof window !== "undefined" ? window.innerWidth : MIN_VIEWPORT_WIDTH_PX
  );

  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <AuthError message={error} />;
  }

  if (width < MIN_VIEWPORT_WIDTH_PX) {
    return (
      <Error
        text={`Viewport must be ${MIN_VIEWPORT_WIDTH_PX}px or wider to use the Signadot Dashboard`}
      />
    );
  }

  return (
    <>
      {/* Layout guideline: Define the general page layout inline using tailwind */}
      <div className="flex flex-col h-screen overflow-hidden font-sans">
        <BillingBanner />
        <div className="flex h-full">
          <Sidebar org={org} />
          <div className="overflow-y-auto h-full w-full relative">
            <div className="sticky top-0 z-20">
              <TopBar />
            </div>
            <main className={`content ${styles.main}`}>
              <Outlet />
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
