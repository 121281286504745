import React from "react";
import { Icon } from "@blueprintjs/core";
import SdButton from "../theming/SdButton";

interface Props {
  pageCount: number;
  onPageChange: (page: number) => void;
  currentPage: number;
}

const MAX_PAGES_TO_SHOW = 5;

export const generatePageRange = (
  pageCount: number,
  currentPage: number,
  maxPagesToShow: number
): (number | "ellipsis")[] => {
  const pageRange: (number | "ellipsis")[] = [];
  const halfMaxPages = Math.floor(maxPagesToShow / 2);

  let startPage = Math.max(1, currentPage - halfMaxPages);
  let endPage = Math.min(pageCount, currentPage + halfMaxPages);

  if (endPage - startPage + 1 > maxPagesToShow) {
    if (currentPage > halfMaxPages) {
      startPage = currentPage - halfMaxPages;
    }
    endPage = startPage + maxPagesToShow - 1;
  }

  if (startPage > 1) {
    pageRange.push(1);
    if (startPage > 2) {
      pageRange.push("ellipsis");
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pageRange.push(i);
  }

  if (endPage < pageCount) {
    if (endPage < pageCount - 1) {
      pageRange.push("ellipsis");
    }
    pageRange.push(pageCount);
  }

  return pageRange;
};

export const ButtonGroup: React.FunctionComponent<Props> = ({
  pageCount,
  onPageChange,
  currentPage,
}) => {
  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  const renderButtons = () => {
    const pageButtons: React.ReactNode[] = [];
    const pages = generatePageRange(pageCount, currentPage, MAX_PAGES_TO_SHOW);

    pages.forEach((page, index) => {
      if (page === "ellipsis") {
        pageButtons.push(
          // eslint-disable-next-line react/no-array-index-key
          <Icon key={`${page}-${index}`} icon="more" className="mx-2" />
        );
      } else {
        pageButtons.push(
          <SdButton
            className="mx-2"
            key={page}
            onClick={() => handlePageChange(page)}
            disabled={page === currentPage}
            minimal={page !== currentPage}
          >
            {page}
          </SdButton>
        );
      }
    });

    return pageButtons;
  };

  return (
    <div className="mt-5">
      <SdButton
        className="mx-2"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        minimal
      >
        <Icon icon="arrow-left" />
      </SdButton>
      {renderButtons()}
      <SdButton
        className="mx-2"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === pageCount}
        minimal
      >
        <Icon icon="arrow-right" />
      </SdButton>
    </div>
  );
};

export default ButtonGroup;
