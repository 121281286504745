import React, { useState } from "react";
import { Intent, Spinner, SpinnerSize } from "@blueprintjs/core";
import useApiMutation from "../../../../hooks/UseApiMutation";
import SdButton from "../../../../components/theming/SdButton";
import Error from "../../../../components/Error/Error";

interface OpenPortalButtonProps {
  text: string;
}

interface CreateStripePortalRequest {
  returnUrl: string;
}

interface CreateStripePortalResponse {
  portalUrl: string;
}

const OpenPortalButton: React.FunctionComponent<OpenPortalButtonProps> =
  React.memo(({ text }) => {
    const [isOpeningPortal, setOpeningPortal] = useState(false);
    const [openPortalError, setOpenPortalError] = useState("");
    const portalMutation = useApiMutation<
      CreateStripePortalResponse,
      CreateStripePortalRequest
    >("api_mutate:create_stripe_portal", "POST", []);

    if (portalMutation.isSuccess) {
      window.location.href = portalMutation.data.data.portalUrl;
    }
    if (portalMutation.error) {
      setOpenPortalError(portalMutation.error.message);
    }

    return (
      <>
        <SdButton
          onClick={() => {
            setOpeningPortal(true);
            portalMutation.mutate({
              url: `/api/v1/orgs/:orgName/billing/portal`,
              data: {
                returnUrl: `${window.location.origin}${window.location.pathname}`,
              },
            });
          }}
        >
          {!isOpeningPortal && text}
          {isOpeningPortal && (
            <Spinner intent={Intent.WARNING} size={SpinnerSize.SMALL} />
          )}
        </SdButton>
        {openPortalError && (
          <>
            <div className="pt-3" />
            <Error text={openPortalError} />
          </>
        )}
      </>
    );
  });
OpenPortalButton.displayName = "OpenPortalButton";

export default OpenPortalButton;
