import React from "react";
import { useBillingApi } from "../../../api/BillingApi";
import { getUsageBannerConfig } from "./utils";
import BannerFromConfig from "./BannerFromConfig";

const BillingBanner: React.FC = () => {
  const { data, error } = useBillingApi();
  if (!data || error) {
    return null;
  }
  const bannerConfig = getUsageBannerConfig(data.usage);
  return <BannerFromConfig usageBannerConfig={bannerConfig} />;
};

export default BillingBanner;
