import type { TrafficDiffFinding } from "../../../../../../@types/sd/findings";
import type { StructuredDiffOps } from "../../../common/utils";

export type CombinedTrafficDiffFinding = TrafficDiffFinding & StructuredDiffOps;

export const isFindingOfType = (
  finding: CombinedTrafficDiffFinding,
  type:
    | keyof StructuredDiffOps["request"]
    | keyof StructuredDiffOps["response"],
  direction: "request" | "response"
): boolean => {
  const findingType = finding[direction][type];
  return findingType.length > 0 && findingType.some((item) => item.isPrimary);
};

export const isFindingCollapseSectionVisible = (
  headers: TrafficDiffFinding[],
  body: TrafficDiffFinding[],
  metadata: TrafficDiffFinding[]
): boolean => headers.length > 0 || body.length > 0 || metadata.length > 0;

export const getServiceUrl = (finding: TrafficDiffFinding): string => {
  const { method, port, host, path, queryParams: rawParams } = finding;

  let queryParamsStr = "";
  const queryParams = Object.entries(rawParams ?? {});
  queryParams.forEach(([name, value], index) => {
    if (index === 0) {
      queryParamsStr += "?";
    }

    queryParamsStr += `${name}=${value}`;

    if (index + 1 !== queryParams.length) {
      queryParamsStr += "&";
    }
  });

  return `${method} ${host}:${port}${path}${queryParamsStr}`;
};
