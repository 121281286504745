import React from "react";

interface Props {
  children: React.ReactNode;
}
const MenuItem: React.FC<Props> = ({ children }) => (
  <div style={{ padding: "0em 0.6em" }}>{children}</div>
);

export default MenuItem;
