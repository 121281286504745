import type { ChangeEvent } from "react";
import React, { useRef, useEffect } from "react";
import { FormGroup, InputGroup } from "@blueprintjs/core";
import MenuItem from "./MenuItem";
import styles from "./LogsHeader.module.css";

interface Props {
  matchCount: number;
  setSearchTerms: (terms: string[]) => void;
  follow: boolean;
  setFollow: (follow: boolean) => void;
  innerContent?: React.ReactNode;
}

const getSearchTermsFromEvent = (e: ChangeEvent<HTMLInputElement>) =>
  e.target.value
    .toLowerCase()
    .split(" ")
    .filter((x) => x.length > 0);

const LogContainerMenu: React.FunctionComponent<Props> = ({
  matchCount,
  setSearchTerms,
  follow = true,
  setFollow,
  innerContent,
}) => (
  <div className="flex justify-between">
    <MenuItem>
      <FormGroup>
        <InputGroup
          id="name"
          aria-label="search-input"
          placeholder="Type to search ..."
          onChange={(e) => setSearchTerms(getSearchTermsFromEvent(e))}
          style={{
            width: "20em",
          }}
        />
      </FormGroup>
    </MenuItem>
    <div className="flex content-end">
      {innerContent}
      <MenuItem>
        <span className={styles.label}>Follow:</span>{" "}
        <button
          type="button"
          aria-label="toggle-follow"
          className={`${styles.followState} ${follow ? styles.on : styles.off}`}
          onClick={() => setFollow(!follow)}
        >
          {follow ? "ON" : "OFF"}
        </button>
      </MenuItem>
      <MenuItem>
        <p className={styles.label}>{matchCount} matches</p>
      </MenuItem>
    </div>
  </div>
);

export default LogContainerMenu;
