import { ENTERPRISE, FREE, TEAM } from "../Constants";

// eslint-disable-next-line import/prefer-default-export
export const PLAN_INFO = {
  [FREE]: {
    planType: "free",
    title: "$0/mo",
    subTitle: "Free Forever",
    features: [
      "100 Sandboxes/month limit",
      "Includes up to 10 active services",
      "1 Kubernetes cluster",
      "3 Resource Plugins",
      "Web Dashboard / Kubernetes Operator / API / CLI / SDK",
      "Istio Integration",
      "CI Integration",
      "Community Support",
    ],
  },
  [TEAM]: {
    planType: "team",
    title: "$500/mo",
    subTitle: "Billed Monthly",
    features: [
      "Unlimited Sandboxes",
      "Includes up to 10 active services",
      "5 Kubernetes clusters",
      "10 Resource Plugins",
      "Web Dashboard / Kubernetes Operator / API / CLI / SDK",
      "Istio Integration",
      "CI Integration",
      "Business Support",
      "Sandbox Usage Reporting",
    ],
  },
  [ENTERPRISE]: {
    planType: "enterprise",
    title: "Custom Pricing",
    features: [
      "Unlimited Sandboxes",
      "Unlimited Active Services",
      "Unlimited Kubernetes clusters",
      "Unlimited Resource Plugin",
      "Web Dashboard / Kubernetes Operator / API / CLI / SDK",
      "Istio Integration",
      "CI Integration",
      "Premium Support",
      "Sandbox Usage Reporting",
      "Custom Domains for Sandbox URLs",
      "SSO Integration",
      "SOC2 Type II Report",
    ],
  },
};
