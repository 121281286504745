import React from "react";
import { Editor as MonacoEditor } from "@monaco-editor/react";
import styles from "./ViewSpec.module.css";
import ActionLink from "../ActionLink";
import useOpenInEditorURL from "./hook";
import Editor from "../../Editor";

type ViewSpecProps = {
  yaml: string;
  eventName?: string;
};
const ViewSpec: React.FC<ViewSpecProps> = ({ yaml, eventName }) => {
  const [openInEditorURL] = useOpenInEditorURL(yaml);
  return (
    <div className={styles.container}>
      <div className={styles.editor}>
        <Editor
          value={yaml}
          language="yaml"
          options={{
            readOnly: true,
          }}
        />
      </div>
      {eventName && (
        <ActionLink
          eventName={eventName}
          onClick={() => window.open(openInEditorURL)}
          className={styles.openInEditor}
        >
          Open in Editor
        </ActionLink>
      )}
    </div>
  );
};

export default ViewSpec;
