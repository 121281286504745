// eslint-disable-next-line import/prefer-default-export
export const displayValue = (value: any, ident = 2): string | undefined => {
  if (value === null) {
    return "null";
  }
  if (value === undefined) {
    return undefined;
  }

  if (typeof value === "number" || typeof value === "boolean") {
    return value.toString();
  }

  if (typeof value === "string") {
    return `"${value.toString()}"`;
  }
  try {
    return JSON.stringify(value, null, ident).replace(
      /\n/g,
      `\n${" ".repeat(ident)}`
    );
  } catch (e) {
    return "Unable to display value";
  }
};
