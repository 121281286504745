import React from "react";
import logo from "./logo";

interface Props {
  includeHeaderWithLogo?: boolean;
  children: React.ReactNode;
}

const HeaderWithLogo = function () {
  return (
    <div
      className="header-with-logo"
      style={{ display: "flex", paddingTop: "1em", paddingBottom: "2em" }}
    >
      <img src={logo} style={{ height: "7em" }} alt="logo" />
    </div>
  );
};

const Interstitial: React.FunctionComponent<Props> = function ({
  children,
  includeHeaderWithLogo = true,
}) {
  return (
    <div
      className="interstitial-container"
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className="interstitial-box"
        style={{
          width: "90%",
          maxWidth: "680px",
          maxHeight: "90vh",
          backgroundColor: "#F8F9FA",
          padding: "1.5em",
          position: "relative",
          border: "2px solid #C3CFD9",
        }}
      >
        {includeHeaderWithLogo && <HeaderWithLogo />}
        {children}
      </div>
    </div>
  );
};

export default Interstitial;
