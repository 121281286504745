import React from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { Editor as MonacoEditor } from "@monaco-editor/react";
import { formatContent } from "../utils";
import styles from "./ViewInEditor.module.css";
import type { PanelInfo } from "../InfoPanels";

interface Props {
  panelInfo: PanelInfo;
}

const ViewInEditor: React.FC<Props> = ({ panelInfo }) => (
  <MonacoEditor
    value={formatContent(panelInfo.content || "", panelInfo.language)}
    defaultValue={panelInfo.placeholder}
    className={styles.editor}
    language={panelInfo.language}
    defaultLanguage="plaintext"
    options={{
      readOnly: panelInfo.disabled,
    }}
    onChange={(value) => panelInfo.onChange?.(value || "")}
    theme="vs-dark"
  />
);

export default ViewInEditor;
