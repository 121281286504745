import type { ForkWL, WL, WLInfo } from "./types";
import { EntityType } from "./types";
import type { LogParams } from "../../../DeploymentDetails/LogPanel/LogPanel";
import type { SandboxV2 } from "../../../../@types/sd/sandboxv2";
import type { Step } from "../../../../@types/sd/resource";

// eslint-disable-next-line import/prefer-default-export
export const getWLInfo = (wl: WL | undefined): WLInfo | undefined => {
  if (!wl) {
    return undefined;
  }
  switch (wl.type) {
    case EntityType.Fork: {
      const forkWL = wl.data;
      return {
        name: forkWL.fork?.metadata.name,
        type: EntityType.Fork,
        references: forkWL.baseline?.metadata.name,
      } as WLInfo;
    }
    case EntityType.Resource: {
      const resourceWL = wl.data;
      return {
        name: resourceWL.name,
        type: EntityType.Resource,
        references: resourceWL.plugin,
      } as WLInfo;
    }
    default:
      return undefined;
  }
};

export const forkToLogParams = (
  sandbox: SandboxV2,
  workload: WL,
  container: string
): LogParams | undefined => ({
  container: container,
  cluster: sandbox.spec.cluster,
  kind: "Deployment",
  namespace: (workload.data as ForkWL).fork!.metadata.namespace,
  name: (workload.data as ForkWL).fork!.metadata.name,
});

export const resourceToLogParams = (
  sandbox: SandboxV2,
  container: string,
  step: Step
): LogParams | undefined => ({
  kind: "Pod",
  cluster: sandbox.spec.cluster,
  container: container,
  namespace: step.status.pod.namespace,
  name: step.status.pod.name,
});
