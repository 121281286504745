import React from "react";
import type { MaybeElement } from "@blueprintjs/core";
import { Icon } from "@blueprintjs/core";
import styled from "styled-components";
import type { IconName } from "@blueprintjs/icons";
import SdButton from "../theming/SdButton";

type WithButton = {
  buttonLabel: string;
  onClick: () => void;
};

type WithNoButton = NonNullable<unknown>;
type Props = {
  icon: IconName | MaybeElement;
  message: React.ReactNode;
} & (WithButton | WithNoButton);

const CreateFirstCard = styled.div`
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const isWithButton = (
  props: Omit<Props, "icon" | "message">
): props is Props & WithButton =>
  (props as WithButton).buttonLabel !== undefined;

const CreateFirst: React.FunctionComponent<Props> = ({
  icon,
  message,
  ...props
}) => (
  <CreateFirstCard>
    <Icon icon={icon} size={80} />
    <div className="p-5">{message}</div>
    {isWithButton(props) && (
      <div>
        <SdButton onClick={() => props.onClick()}>{props.buttonLabel}</SdButton>
      </div>
    )}
  </CreateFirstCard>
);

export default CreateFirst;
