import React from "react";
import styles from "./WorkloadDropdownItem.module.css";

interface DropdownBaseItemProps {
  title: string;
  subtitle?: string;
  tag?: string;
  onClick?: () => void;
}

const WorkloadDropdownItem: React.FC<DropdownBaseItemProps> = ({
  title,
  subtitle,
  tag,
  onClick,
}) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  <div className={styles.container} onClick={onClick}>
    <div className={styles.left}>
      <div className={styles.title}>{title}</div>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
    {tag && <div className={styles.tag}>{tag}</div>}
  </div>
);

export default WorkloadDropdownItem;
