import { useCallback } from "react";
import useApi from "../../../hooks/UseApi";
import type {
  ExecResponseApiResponse,
  GetSavedRequestResponse,
  RequestData,
  RequestExecResponseData,
  RequestPayload,
  SavedRequest,
  SavedRequestsResponse,
  SaveRequestApiResponse,
} from "../SavedRequestCatalog/types";
import type { ApiMutationResult } from "../../../hooks/UseApiMutation";
import useApiMutation from "../../../hooks/UseApiMutation";

export const GET_A_SAVED_REQUEST_API_NAME = "api:get-saved-request-by-id";
export const EXECUTE_REQUEST_API_NAME = "api:execute-request";
export const SAVE_REQUEST_API_NAME = "api:save-request";
export const UPDATE_REQUEST_API_NAME = "api:update-request";
export const SAVED_REQUESTS_API_NAME = "api:saved-requests";
export const DELETE_SAVED_REQUEST_API_NAME = "api:delete-saved-request";

export const useSavedRequestByIdApi = (
  id?: string
): GetSavedRequestResponse => {
  const { isLoading, isIdle, isSuccess, error, data } = useApi<SavedRequest>(
    [GET_A_SAVED_REQUEST_API_NAME, id],
    `/api/v2/orgs/:orgName/requests/${id}`,
    {
      refetchInterval: 5000,
      enabled: !!id,
    }
  );
  if (!id) {
    return { data: null, error: null };
  }
  if (error) {
    return {
      data: null,
      error: error.message,
    };
  }
  if (isLoading || isIdle || !isSuccess || !data) {
    return {
      data: null,
      error: null,
    };
  }
  return {
    data: data,
    error: null,
  };
};

export const useExecuteRequest = (
  reqData?: RequestData
): ExecResponseApiResponse => {
  const api = useApiMutation<RequestExecResponseData, RequestPayload>(
    EXECUTE_REQUEST_API_NAME,
    "POST",
    []
  );
  const normalize = (r?: RequestData) => ({
    host: r?.host,
    requestURI: r?.requestURI,
    method: r?.method,
    protocol: r?.protocol,
    headers: r?.headers ?? {}, // API Server requires headers. So setting an empty Object if undefined;
    body: r?.body,
  });
  const sendToSandbox = useCallback(
    (sandbox: string) => {
      const payload = {
        request: normalize(reqData),
        destination: {
          sandbox: sandbox,
        },
      } as RequestPayload;
      api.mutate({
        url: `/api/v2/orgs/:orgName/requests/exec`,
        data: payload,
      });
    },
    [reqData]
  );
  const sendToBaseline = useCallback(
    (cluster: string) => {
      const payload = {
        request: normalize(reqData),
        destination: {
          cluster: cluster,
        },
      } as RequestPayload;
      api.mutate({
        url: `/api/v2/orgs/:orgName/requests/exec`,
        data: payload,
      });
    },
    [reqData]
  );
  const reset = () => api.reset();
  if (!reqData) {
    return {
      isLoading: false,
      error: undefined,
      data: undefined,
      sendToSandbox,
      sendToBaseline,
      reset,
    } as ExecResponseApiResponse;
  }
  if (api.isError) {
    return {
      isLoading: api.isLoading,
      error: api.error?.response?.data?.error ?? "No response from server",
      data: undefined,
      sendToSandbox,
      sendToBaseline,
      reset,
    } as ExecResponseApiResponse;
  }
  return {
    isLoading: api.isLoading,
    error: undefined,
    data: api.data?.data,
    sendToSandbox,
    sendToBaseline,
    reset,
  } as ExecResponseApiResponse;
};

export const useSaveRequest = (
  reqData?: RequestData
): SaveRequestApiResponse => {
  const api = useApiMutation<SavedRequest, SavedRequest>(
    SAVE_REQUEST_API_NAME,
    "POST",
    [SAVED_REQUESTS_API_NAME, GET_A_SAVED_REQUEST_API_NAME]
  );
  const submit = useCallback(
    (name: string) => {
      const payload = {
        name: name,
        request: {
          host: reqData?.host,
          requestURI: reqData?.requestURI,
          method: reqData?.method,
          protocol: reqData?.protocol,
          headers: reqData?.headers ?? {},
          body: reqData?.body,
        },
      } as SavedRequest;
      api.mutate({
        url: `/api/v2/orgs/:orgName/requests`,
        data: payload,
      });
    },
    [reqData]
  );
  const reset = () => api.reset();
  if (!reqData) {
    return {
      isLoading: false,
      error: undefined,
      data: undefined,
      submit,
      reset,
    } as SaveRequestApiResponse;
  }
  if (api.isError) {
    return {
      isLoading: api.isLoading,
      error: api.error?.response?.data?.error ?? "No response from server",
      data: undefined,
      submit,
      reset,
    } as SaveRequestApiResponse;
  }
  return {
    isLoading: api.isLoading,
    error: undefined,
    data: api.data?.data,
    submit,
    reset,
  } as SaveRequestApiResponse;
};

export const useSavedRequestsApi = (): SavedRequestsResponse => {
  const { isLoading, isIdle, isSuccess, error, data } = useApi<SavedRequest[]>(
    SAVED_REQUESTS_API_NAME,
    "/api/v2/orgs/:orgName/requests",
    {
      refetchInterval: 5000,
    }
  );
  if (error) {
    return {
      error: error.message,
    } as SavedRequestsResponse;
  }
  if (isLoading || isIdle || !isSuccess || !data) {
    return {} as SavedRequestsResponse;
  }
  return {
    data: data ?? [],
  } as SavedRequestsResponse;
};

type DeleteSavedRequestApiReturn = [
  (id: string) => void,
  ApiMutationResult<unknown, unknown>
];

export const useDeleteSavedRequestApi = (): DeleteSavedRequestApiReturn => {
  const mutation = useApiMutation<unknown, unknown>(
    DELETE_SAVED_REQUEST_API_NAME,
    "DELETE",
    [SAVED_REQUESTS_API_NAME, GET_A_SAVED_REQUEST_API_NAME]
  );
  return [
    (id: string) => {
      mutation.mutate({
        url: `api/v2/orgs/:orgName/requests/${id}`,
      });
    },
    mutation,
  ];
};
