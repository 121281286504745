import yaml from "js-yaml";
import React from "react";
import type { ResourcePlugin } from "../../../../../@types/sd/resourceplugin";
import ViewSpec from "../../../../../components/theming/ViewSpec/ViewSpec";

type ResourcePluginSpecProps = {
  resourcePlugin: ResourcePlugin;
};

const ResourcePluginSpec: React.FC<ResourcePluginSpecProps> = ({
  resourcePlugin,
}) => (
  <ViewSpec
    yaml={yaml.dump({
      name: resourcePlugin.name,
      spec: resourcePlugin.spec,
    })}
  />
);

export default ResourcePluginSpec;
