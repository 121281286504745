import React from "react";
import InvitationError from "../PreInstall/InvitationError";
import useApi from "../../../hooks/UseApi";
import Loading from "../../../components/Loading/Loading";

interface InstallationsResponse {
  githubOrg: string;
  signadotOrgCreated: string;
}

interface Props {
  setOrgName: (orgName: null | string) => void;
  setInstallationComplete: (complete: boolean) => void;
  setSetupComplete: (complete: boolean) => void;
  refetchIntervalMs?: number;
}

const PostInstallationCheck: React.FunctionComponent<Props> = function ({
  setOrgName,
  setInstallationComplete,
  setSetupComplete,
  refetchIntervalMs = 5 * 1000, // if refetchInterval evaluates to falsy (false, 0 etc), refetch will not happen
}) {
  const { isLoading, isIdle, data, error } = useApi<InstallationsResponse>(
    "apikeys",
    `/api/v1/setup/installations`,
    {
      cacheTime: 0,
      refetchInterval: refetchIntervalMs || false,
    }
  );

  if (isLoading || isIdle) {
    return <Loading />;
  }

  if (error) {
    return <InvitationError />;
  }

  const { githubOrg, signadotOrgCreated } = data!;
  if (githubOrg) {
    setOrgName(githubOrg);
  }
  const installationComplete = githubOrg && githubOrg.length > 0;
  const setupComplete = installationComplete && signadotOrgCreated;

  setInstallationComplete(!!installationComplete);
  setSetupComplete(!!setupComplete);

  return null;
};

export default PostInstallationCheck;
