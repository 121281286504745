import React, { forwardRef, useEffect, useMemo, useRef } from "react";
import reactStringReplace from "react-string-replace";
// eslint-disable-next-line import/no-extraneous-dependencies
import Ansi from "ansi-to-react";
import ReactDOMServer from "react-dom/server";
import styles from "./LogEntry.module.css";
import type { LogItem } from "../Logs";

interface Props {
  message: string;
  searchTerms: string[];
}

const LogEntry = forwardRef<HTMLParagraphElement, Props & LogItem>(
  (props, ref) => {
    const { searchTerms, message } = props;

    const ansiRef = useRef<HTMLDivElement>(null);

    const search = useMemo(
      () => ({
        enabled: searchTerms.length > 0,
        pattern: new RegExp(`(${searchTerms.join("|")})`, "gi"),
      }),
      [searchTerms]
    );

    useEffect(() => {
      if (!ansiRef.current) return;
      if (searchTerms.length === 0) return;

      const tokens = ansiRef.current.querySelectorAll("span");
      if (!tokens) return;

      if (!search.pattern.exec(message)) {
        return;
      }

      tokens.forEach((token) => {
        if (!token.innerText) return;

        // eslint-disable-next-line no-control-regex
        const ansiRegex = /\u001b\[\d{1,2}m/g;
        const cleaned = token.innerText.replace(ansiRegex, "");
        const searchMatches = search.pattern.exec(cleaned);
        if (!searchMatches) return;

        token.innerHTML = "";

        const newMaps = reactStringReplace(
          cleaned,
          search.pattern,
          (match, i) => (
            <span key={i} className={styles.highlighted}>
              {match}
            </span>
          )
        );

        const nodes = newMaps.map((node) =>
          ReactDOMServer.renderToString(
            node as React.ReactElement<
              never,
              string | React.JSXElementConstructor<never>
            >
          )
        );

        nodes.forEach((node) => {
          token.innerHTML += node;
        });
      });
    }, [search]);

    return (
      <p
        className={styles.container}
        ref={ref}
        style={{ color: props.type === "error" ? "red" : "auto" }}
      >
        <span ref={ansiRef}>
          <Ansi>{message}</Ansi>
        </span>
      </p>
    );
  }
);

LogEntry.displayName = "LogEntry";

export default LogEntry;
