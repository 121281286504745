import useApi, { type ApiError } from "../hooks/UseApi";
import useApiMutation from "../hooks/UseApiMutation";
import type { Test, BaseTestSpec } from "../@types/sd/testspec";

export const LIST_TESTS_API_NAME = "api:get-tests";
export const GET_TEST_API_NAME = "api:get-test";
export const SAVE_TEST_SPEC_API_NAME = "api_mutation:save-test";
export const DELETE_TEST_SPEC_API_NAME = "api_mutation:delete-test";

const standardQueryConfig = {
  retry: 1,
  refetchInterval: 5000,
};

export const useGetTests = () =>
  useApi<Test[]>(LIST_TESTS_API_NAME, `/api/v2/orgs/:orgName/tests`, {
    ...standardQueryConfig,
  });

export const useGetTest = (name: string, options = {}) =>
  useApi<Test>(
    [GET_TEST_API_NAME, name],
    `/api/v2/orgs/:orgName/tests/${name}`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );

export const useSaveTest = (
  onSaved: () => void,
  onError: (error: ApiError) => void
) =>
  useApiMutation<unknown, BaseTestSpec>(
    SAVE_TEST_SPEC_API_NAME,
    "PUT",
    [GET_TEST_API_NAME, LIST_TESTS_API_NAME],
    onSaved,
    onError
  );

export const useDeleteTest = (
  onSaved: () => void,
  onError: (error: ApiError) => void
) =>
  useApiMutation<unknown>(
    DELETE_TEST_SPEC_API_NAME,
    "DELETE",
    [LIST_TESTS_API_NAME],
    onSaved,
    onError
  );
