import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Icon } from "@blueprintjs/core";
import copy from "copy-to-clipboard";
import { Popover2 } from "@blueprintjs/popover2";
import type { Property } from "csstype";
import styles from "./CopyableText.module.css";

type CopyableTextProperties = {
  bashScriptMode?: boolean;
  inline?: boolean;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
};

const CopyableText: React.FC<CopyableTextProperties> = ({
  bashScriptMode = false,
  inline = false,
  children,
  className,
}) => {
  const [isClicked, setClicked] = useState(false);
  useEffect(() => {
    if (isClicked) {
      setTimeout(() => setClicked(false), 1000);
    }
  }, [isClicked]);
  const childrenRef = useRef<HTMLElement>(null);

  const getText = () => {
    const { current } = childrenRef;
    if (!current) {
      return "";
    }
    if (bashScriptMode) {
      // Allow the children to be written "naturally" by adding br elements to create
      // newlines in the display, but still generate a valid multi-line bash command.
      return current.innerText.replace(/\n/, " && \\\n");
    }
    return current.innerText;
  };

  const onCopyClick = () => {
    copy(getText());
    setClicked(true);
  };

  const iconStyle = {
    position: "relative" as Property.Position,
    padding: "0.5em",
    top: "-0.5em",
    right: "-0.5em",
    cursor: "pointer",
  };

  return (
    <div
      role="presentation"
      className={className}
      style={{
        background: "#f0f0f0",
        border: "1px solid #ccc",
        fontFamily: "monospace",
        overflowWrap: "break-word",
        display: inline ? "inline-block" : "block",
      }}
    >
      <div style={{ float: "right" }}>
        <Popover2
          isOpen={isClicked}
          content={<span className="m-2">Copied!</span>}
          placement="top"
        >
          <Icon
            icon="duplicate"
            style={iconStyle}
            onClick={onCopyClick}
            aria-label="copy-text-button"
          />
        </Popover2>
      </div>
      <span ref={childrenRef} className={styles.content}>
        {children}
      </span>
    </div>
  );
};

CopyableText.defaultProps = {
  bashScriptMode: false,
  inline: false,
  className: "",
};

export default CopyableText;
