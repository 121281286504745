import React from "react";
import { FaGithub, FaSlack, FaYoutube } from "react-icons/fa";
import { useAuth0 } from "@auth0/auth0-react";
import styles from "./TopBar.module.css";
import TopBarItem from "./TopBarItem/TopBarItem";

const TopBar = () => {
  const { user } = useAuth0();
  return (
    <ul className={`flex justify-end ${styles.container}`}>
      <li>
        <TopBarItem
          icon="headset"
          url="https://www.signadot.com/schedule-a-call"
        >
          <div className={styles.content}>Book a Demo</div>
        </TopBarItem>
      </li>
      <li className={styles.dropdown}>
        <TopBarItem icon="people" url="#">
          <div className={styles.content}>Community</div>
        </TopBarItem>
        <div className={styles.dropdownContent}>
          <TopBarItem
            icon={<FaSlack />}
            url="https://join.slack.com/t/signadotcommunity/shared_invite/zt-1estxm8pv-qfiaNfiFFCaW~eUlXsVoEQ"
          >
            <div className={styles.content}>Slack</div>
          </TopBarItem>
          <TopBarItem icon={<FaGithub />} url="https://github.com/signadot">
            <div className={styles.content}>Github</div>
          </TopBarItem>
          <TopBarItem
            icon={<FaYoutube />}
            url="https://www.youtube.com/@signadot"
          >
            <div className={styles.content}>YouTube</div>
          </TopBarItem>
        </div>
      </li>
      <li>
        <TopBarItem icon="document-open" url="https://signadot.com/docs">
          <div className={styles.content}>Docs</div>
        </TopBarItem>
      </li>
      <li className={styles.dropdown}>
        <TopBarItem url="#">
          <img src={user!.picture} alt="" className={styles.avatar} />
          <span className="mr-3" />
        </TopBarItem>
        <div className={styles.dropdownContent}>
          {user?.name || user?.email ? (
            <div className={styles.userInfo}>
              {(user?.name && user.name !== user.email && (
                <div>{user.name}</div>
              )) ||
                null}
              {user?.email && <div>{user.email}</div>}
            </div>
          ) : null}
          <TopBarItem icon="log-out" url="/signout">
            <div className={styles.content}>Sign Out</div>
          </TopBarItem>
        </div>
      </li>
    </ul>
  );
};

export default TopBar;
