import React from "react";
import { Collapse, Icon } from "@blueprintjs/core";
import styles from "./SidebarSection.module.css";
import useDisclosure from "../../../../hooks/UseDisclosure";

type SidebarSectionProps = {
  title: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
  defaultOpen?: boolean;
  isCollapsed?: boolean;
};

const SidebarSection = ({
  title,
  children,
  defaultOpen,
  isCollapsed,
}: SidebarSectionProps) => {
  const { isOpen, toggle } = useDisclosure(defaultOpen);

  return (
    <div className={`${styles.section}`}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
      <div
        onClick={toggle}
        className={`${styles.title} ${
          isCollapsed ? "white" : "bg-gray-100"
        } pr-5  py-3`}
      >
        {!isCollapsed && (
          <>
            <span className="pl-5 text-md">{title}</span>
            <Icon icon={isOpen ? "chevron-up" : "chevron-down"} />
          </>
        )}
      </div>
      <Collapse isOpen={isOpen || isCollapsed}>{children}</Collapse>
    </div>
  );
};

export default SidebarSection;
