import React from "react";
import { Intent, OverlayToaster } from "@blueprintjs/core";
import {
  useAutoProvisionUsers,
  useEnforceSSO,
  useGetAuthSettings,
} from "./AuthSettingsApi";
import { useBillingApi } from "../../../api/BillingApi";
import { ENTERPRISE } from "../../Billing/Constants";
import SSOEnabled from "./variants/SSOEnabled";
import SSONotEnabled from "./variants/SSONotEnabled";
import SSONotAvailable from "./variants/SSONotAvailable";

const toaster = OverlayToaster.create();

const AuthSettings: React.FC = () => {
  const { data: billingData } = useBillingApi();
  const { data } = useGetAuthSettings();
  const [enforceSSOFn, enforceSSOApi] = useEnforceSSO();
  const [autoProvisionUsersFn, autoProvisionUsersApi] = useAutoProvisionUsers();

  if (enforceSSOApi.error) {
    toaster.show({
      message: enforceSSOApi.error.response.data.error,
      intent: Intent.DANGER,
    });
    enforceSSOApi.reset();
  }
  if (autoProvisionUsersApi.error) {
    toaster.show({
      message: autoProvisionUsersApi.error.response.data.error,
      intent: Intent.DANGER,
    });
    autoProvisionUsersApi.reset();
  }

  // If auth data is not available, display blank.
  if (!data) {
    return null;
  }

  // If SSO information is available, display that.
  // The feature is only available to Enterprise users as of now (enabled manually). However,
  // the application doesn't have that constraint. Hence, if the SSO data is available on an
  // org (irrespective of plan), we display that to the users.
  if (data.sso) {
    return (
      <SSOEnabled
        settings={data.sso}
        enforceSSOFn={enforceSSOFn}
        autoProvisionUsersFn={autoProvisionUsersFn}
      />
    );
  }

  // SSO setting is not available for the org. Do the following:

  // Wait for the billing information to be available.
  if (!billingData) {
    return null;
  }

  // If the org is on Enterprise plan, ask the user to contact us to enable the feature.
  if (billingData.tier.planType === ENTERPRISE) {
    return <SSONotEnabled />;
  }

  // Display the message that SSO is only available on Enterprise plan.
  return <SSONotAvailable />;
};

export default AuthSettings;
