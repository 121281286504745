import React from "react";
import type { SandboxV2 } from "../../../../@types/sd/sandboxv2";
import { useSandboxSpecYAML } from "../../../../components/theming/ViewSpecDialog/hook";
import { SandboxDetailOpenSpecInEditor } from "../../../../Constants";
import ViewSpec from "../../../../components/theming/ViewSpec/ViewSpec";

interface SandboxSpecProps {
  sandbox: SandboxV2;
}

const SandboxSpec: React.FC<SandboxSpecProps> = ({ sandbox }) => {
  const yamlSpec = useSandboxSpecYAML(sandbox);
  return <ViewSpec yaml={yamlSpec} eventName={SandboxDetailOpenSpecInEditor} />;
};

export default SandboxSpec;
