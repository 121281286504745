import { Alert, Intent, OverlayToaster } from "@blueprintjs/core";
import React from "react";
import { useCancelJob } from "../../../../../api/JobsApi";
import type { Job } from "../../../../../@types/sd/job";

type CancelCurrentJobProps = {
  job: Job;
  isOpen?: boolean;
  onCancel: () => void;
  close: () => void;
};

const toaster = OverlayToaster.create();
const CancelCurrentJob: React.FC<CancelCurrentJobProps> = ({
  job,
  isOpen,
  onCancel,
  close,
}) => {
  const onSuccess = () =>
    toaster.show({
      message: "job cancelled",
      intent: Intent.SUCCESS,
    });
  const onError = () => {
    toaster.show({
      message: "could not cancel job",
      intent: Intent.DANGER,
    });
    close();
  };
  const cancelJobApi = useCancelJob(onSuccess, onError);
  const cancelJob = (jobName: string) => {
    cancelJobApi.mutate({
      url: `/api/v2/orgs/:orgName/jobs/${jobName}/cancel`,
    });
  };
  return (
    <Alert
      cancelButtonText="Close"
      confirmButtonText="Cancel Job"
      icon="trash"
      intent={Intent.DANGER}
      isOpen={isOpen}
      onCancel={onCancel}
      onClose={close}
      onConfirm={() => cancelJob(job.name)}
    >
      <div>
        <p>Are you sure you want to cancel the job {job.name}?</p>
      </div>
    </Alert>
  );
};

export default CancelCurrentJob;
