import React from "react";
import { ProgressBar } from "@blueprintjs/core";
import type { FormattedItem } from "../helper";
import styles from "./UsageEntry.module.css";

interface UsageInfoProps {
  entry: FormattedItem;
}

const UsageEntry: React.FunctionComponent<UsageInfoProps> = ({ entry }) => (
  <div className={styles.usageEntry}>
    <div className={styles.message}>
      <div>
        <b>{entry.label}</b>: {entry.title}
      </div>
      {entry.subTitle && (
        <div className={styles.subTitle}>{entry.subTitle}</div>
      )}
    </div>
    <div>
      <ProgressBar
        value={entry.percentageUsage / 100.0}
        intent={entry.color}
        animate={false}
        stripes={false}
      />
    </div>
  </div>
);

export default UsageEntry;
