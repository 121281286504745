// eslint-disable-next-line import/prefer-default-export
export const SIGNAL_RESTART = "RESTART";

export type StandardStreamType = string;
export const STDOUT: StandardStreamType = "stdout";
export const STDERR: StandardStreamType = "stderr";

export type LogType = string;
export const LOG_TYPE_OUTPUT: LogType = "output";
export const LOG_TYPE_ERROR: LogType = "error";
