import React from "react";
import { Button } from "@blueprintjs/core";
import styles from "./SavedRequestCatalog.module.css";
import type { SavedRequest } from "./types";
import ActionLink from "../../../components/theming/ActionLink";
import { groupByHost } from "./utils";
import ConfirmDelete from "./ConfirmDelete";
import { useDeleteSavedRequestApi, useSavedRequestsApi } from "../hooks/hooks";

interface SavedRequestCatalog {
  onSelect: (savedRequest: SavedRequest) => void;
}

const SavedRequestCatalog: React.FC<SavedRequestCatalog> = ({ onSelect }) => {
  const [filterText, setFilterText] = React.useState<string>("");
  const [selectedRequest, setSelectedRequest] = React.useState<
    SavedRequest | undefined
  >(undefined);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    React.useState<boolean>(false);
  const { data, error } = useSavedRequestsApi();
  const [deleteSavedRequest] = useDeleteSavedRequestApi();
  const filteredData = React.useMemo(() => {
    if (!data) return [];
    return data.filter(
      (entry) =>
        entry.name.toLowerCase().includes(filterText.toLowerCase()) ||
        entry.request?.host.toLowerCase().includes(filterText.toLowerCase())
    );
  }, [data, filterText]);
  const savedRequestsMap = React.useMemo(
    () => groupByHost(filteredData),
    [filteredData]
  );
  const showDialog = React.useCallback((savedRequest: SavedRequest) => {
    setSelectedRequest(savedRequest);
    setIsConfirmDeleteDialogOpen(true);
  }, []);
  if (error) {
    // Display error message
    return null;
  }
  return (
    <>
      <ConfirmDelete
        name={selectedRequest?.name ?? ""}
        showDialog={isConfirmDeleteDialogOpen}
        setShowDialog={setIsConfirmDeleteDialogOpen}
        onConfirm={() => deleteSavedRequest(selectedRequest!.id)}
        onCancel={() => {}}
      />
      <div className={styles.container}>
        <div className={styles.title}>Saved Requests</div>
        <div>
          <input
            type="text"
            className={styles.search}
            placeholder="Type to filter ..."
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
        <div className={styles.catalog}>
          {Array.from(savedRequestsMap.entries()).map(
            ([host, savedRequests]) => (
              <div key={host} className={styles.service}>
                <div className={styles.serviceTitle}>{host}</div>
                <ul className={styles.services}>
                  {savedRequests.map((savedRequest) => (
                    <li key={savedRequest.id}>
                      <div className={styles.entry}>
                        <ActionLink
                          onClick={() => onSelect(savedRequest)}
                          className={styles.link}
                        >
                          {savedRequest.name}
                        </ActionLink>
                        <Button
                          minimal
                          icon="trash"
                          className={styles.deleteBtn}
                          onClick={() => showDialog(savedRequest)}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default SavedRequestCatalog;
