import type { Dispatch, ReactNode } from "react";
import React, { useEffect, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import _ from "lodash";
import { usePostHog } from "posthog-js/react";

export interface User {
  id: string;
  createdAt: string;
  updatedAt: string;
  email: string;
  firstName?: string;
  lastName?: string;
  role?: string;
}

interface Org {
  name: string;
  displayName: string;
  billingTier: number;
  user: User;
}

const CreateOrg = "createOrg";

interface UserFlags {
  [CreateOrg]: boolean;
}

// TODO: Make a better state machine.
// Ref: https://github.com/signadot/signadot/pull/2223#discussion_r932524926
interface AuthState {
  loading: boolean;
  error: null | string;
  AuthToken?: string;
  user?: User;
  org?: Org;
  userFlags?: UserFlags;
}

interface AuthAction {
  type: "set_auth_info" | "set_error";
  token?: string;
  user?: User;
  org?: Org;
  error?: string;
  userFlags?: UserFlags;
}

interface AuthContextValue {
  state: AuthState;
  dispatch: Dispatch<AuthAction>;
}

interface Props {
  children?: ReactNode | undefined;
}

const baseURL = process.env.VITE_API_BASE_URL;
axios.defaults.baseURL = baseURL;

const initialState: AuthState = {
  loading: true,
  error: null,
  AuthToken: "",
};
const AuthContext = React.createContext<AuthContextValue>({
  state: initialState,
  dispatch: () => {},
});

function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case "set_auth_info": {
      return {
        ...state,
        AuthToken: action.token,
        user: action.user,
        org: action.org,
        loading: false,
        userFlags: action.userFlags,
      };
    }
    case "set_error": {
      return {
        ...state,
        error: action.error!,
        loading: false,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const AuthContextProvider = function ({ children }: Props) {
  const posthog = usePostHog();
  const [state, dispatch] = React.useReducer(authReducer, initialState);
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    async function auth() {
      if (!isAuthenticated) {
        // we are not ready to fetch token yet.
        return;
      }

      let user: User | undefined;
      let org: Org | undefined;
      let userFlags: UserFlags | undefined;
      let token = "";
      try {
        token = await getAccessTokenSilently();
        [user, org, userFlags] = await axios
          .get(`/api/v1/orgs/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((result) => {
            const firstOrg: Org | undefined = _.get(result, [
              "data",
              "orgs",
              0,
            ]);
            user = result.data.user;
            userFlags = result.data.userFlags;
            if (!userFlags?.createOrg && !firstOrg) {
              throw new Error("user does not belong to a valid org");
            }

            // we only return the first org for now.
            // TODO: allow the user to switch between different orgs they have access to.
            return [user, firstOrg, userFlags];
          });
        const posthogID = posthog?.get_distinct_id();
        if (user && posthogID && posthogID.indexOf("@") === -1) {
          posthog?.identify(user.email);
        }
        if (org && posthog && Object.keys(posthog.getGroups()).length === 0) {
          // I have added the logic on the server side to register a PostHog org every time a new
          // org is created on Signadot. However, since we have missed that for the existing orgs,
          // I have added it here as well so that it will register Posthog org for those too anytime
          // a user from the org logs in. We can delete it from here once we have all those orgs
          // on PostHog.
          // Note: There is no harm in registering the same org on PostHog multiple times.
          posthog?.group("org", org.name, {
            name: org.name,
            displayName: org.displayName,
          });
        }

        // When encountering a new user that is not part of an org during the self-service flow,
        // we want to restrict them to only be able to access the self-service pages. We also
        // want to restrict existing users in an org from seeing the self-service pages. So we check
        // if the current page is one of the self-service pages and either kick them back to the
        // main dashboard page or into the self-service pages if they're a new user.
        const isOnNewUserPage =
          window.location.pathname.indexOf("users/new") > -1 ||
          window.location.pathname.indexOf("orgs/setup") > -1;
        if (userFlags?.createOrg) {
          if (!isOnNewUserPage) {
            // ensure user is redirected to new org creation flow.
            window.location.href = "/users/new";
            return;
          }
        } else if (isOnNewUserPage) {
          // user shouldn't be here.
          window.location.href = "/";
          return;
        }
      } catch (e) {
        dispatch({
          type: "set_error",
          error: e.response?.data?.error || e.message,
        });
        return;
      }

      dispatch({
        type: "set_auth_info",
        token: token,
        user,
        org,
        userFlags,
      });
    }

    auth();
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthContextProvider");
  }
  return context;
}

export { AuthContextProvider, useAuth };
