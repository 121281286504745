import type { SandboxV2 } from "../../../../@types/sd/sandboxv2";
import type { Workload } from "../../../../@types/sd/workload";
import type { ResourceV2 } from "../../../../@types/sd/resource";

export interface SandboxLogsProps {
  sandbox: SandboxV2;
}

// eslint-disable-next-line no-shadow
export enum SupportedStepType {
  Create = "create",
  Delete = "delete",
}

// TODO(james): The entities listed here needs some rethinking such as whether or
// not to use Deployment/Rollout vs Fork/Local/Resource.
// eslint-disable-next-line no-shadow
export enum EntityType {
  Deployment = "Deployment",
  Fork = "Fork",
  Resource = "Resource",
}

export interface WorkloadInfo {
  type: EntityType;
  namespace?: string;
  name: string;
  references: string; // loose reference to: baseline name in case of fork/local, plugin name in case of Resources
}

export interface QueryParams {
  type: "Fork" | "Resource";
  name: string;
  step?: string; // only applicable for type=Resource
}

export type ForkWL = Workload;
export type ResourceWL = ResourceV2;

type outerForkWL = {
  type: "Fork" | EntityType.Fork;
  data: ForkWL;
};

type outerResourceWL = {
  type: "Resource" | EntityType.Resource;
  data: ResourceWL;
};

export type WL = {
  uname: string;
  selected: boolean;
} & (outerResourceWL | outerForkWL);

export interface WLInfo {
  name: string;
  type: EntityType;
  references: string; // references baseline svc in case of fork and plugin name in case of resources
}

export const isForkWL = (data: unknown): data is ForkWL =>
  !!((data as ForkWL).kind && (data as ForkWL).clusterName);
