import React from "react";
import { Icon } from "@blueprintjs/core";
import { usePostHog } from "posthog-js/react";
import styles from "./InfoCapsule.module.css";
import type { EventName } from "../../../Constants";

// eslint-disable-next-line no-shadow
export enum Type {
  DEMO_VIDEO,
}

interface InfoCapsuleProps {
  type: "video" | "docs";
  label: string;
  onClick: () => void;
  eventName?: EventName;
}

const InfoCapsule: React.FC<InfoCapsuleProps> = ({
  type,
  label,
  onClick,
  eventName,
}) => {
  const posthog = usePostHog();

  const typesMapper: Record<
    InfoCapsuleProps["type"],
    {
      iconName: React.ComponentProps<typeof Icon>["icon"];
      iconSize?: number;
      title: string;
    }
  > = {
    docs: { iconName: "document", title: "Documentation", iconSize: 20 },
    video: { iconName: "play", title: "Demo Video" },
  };

  const currentInfo = typesMapper[type];

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={styles.container}
      onClick={() => {
        onClick();
        if (eventName) {
          posthog?.capture(eventName);
        }
      }}
    >
      <div className={styles.icon}>
        <Icon
          icon={currentInfo.iconName}
          iconSize={currentInfo.iconSize ?? 30}
        />
      </div>
      <div>
        <div className={styles.category}>{currentInfo.title}</div>
        <div>{label}</div>
      </div>
    </div>
  );
};

export default InfoCapsule;
