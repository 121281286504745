import React, { useEffect, useState } from "react";
import { Tag } from "@blueprintjs/core";
import BaseWidget from "../BaseWidget";
import styles from "./RecentBlogs.module.css";
import { IoIosDocument } from "react-icons/io";

type BlogReference = {
  title: string;
  link: string;
  pubDate: string;
};

const RecentBlogs = () => {
  const [blogs, setBlogs] = useState<BlogReference[]>([]);
  const [loadingBlogs, setLoadingBlogs] = useState(false);

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoadingBlogs(true);

      const resp = await fetch("/assets/blogs.json");

      setLoadingBlogs(false);
      const data = await resp.json();
      setBlogs(data);
    };

    fetchBlogs();
  }, []);

  if (loadingBlogs) {
    return (
      <BaseWidget title="Recent Blogs">
        <div className={styles.container}>
          <p>Loading blogs feed</p>
        </div>
      </BaseWidget>
    );
  }

  const isOneWeekAgo = (pubDate: string) => {
    const inputDate = new Date(pubDate);
    const currentDate = new Date();

    const differenceInMilliseconds =
      currentDate.getTime() - inputDate.getTime();

    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    return differenceInDays <= 7;
  };

  return (
    <BaseWidget title="Recent Blogs" iconName="book">
      <div className={styles.container}>
        {blogs.length === 0 ? (
          <p>No blogs found</p>
        ) : (
          blogs.slice(0, 10).map((blog) => (
            <div key={blog.title} className={styles.blog_item}>
              <a href={blog.link} target="_blank" rel="noreferrer">
                {isOneWeekAgo(blog.pubDate) && <Tag>New</Tag>}
                {blog.title}
              </a>
            </div>
          ))
        )}
      </div>
    </BaseWidget>
  );
};

export default RecentBlogs;
