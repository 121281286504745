import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import LogPanel from "../../../DeploymentDetails/LogPanel";
import { useQuery, useQueryParams } from "./hooks";
import type { ResourceWL, SandboxLogsProps, WL } from "./types";
import { EntityType } from "./types";
import type { ResourceV2, Step } from "../../../../@types/sd/resource";
import type { Workload } from "../../../../@types/sd/workload";
import { useSandboxResources } from "../../../../api/ResourcesApi";
import { useSandboxDeploymentsApi } from "../../../../api/SandboxesApi";
import { forkToLogParams, getWLInfo, resourceToLogParams } from "./utils";
import type { LogParams } from "../../../DeploymentDetails/LogPanel/LogPanel";
import type { WorkloadDropdownOption } from "./WorkloadDropdown";
import { WorkloadDropdown } from "./WorkloadDropdown";

const SandboxLogs: React.FC<SandboxLogsProps> = ({ sandbox }) => {
  const query = useQuery();
  const qp = useQueryParams(query);

  const [container] = useState<string>("*");
  const [searchParams, setSearchParams] = useSearchParams();

  const mergeWorkloads = (
    rawForks: Workload[] | undefined,
    rawResources: ResourceWL[] | undefined
  ): WL[] => {
    const workloads: WL[] = [];

    if (rawForks) {
      const forks = rawForks.map(
        (f, index): WL => ({
          type: EntityType.Fork,
          data: f,
          selected: false,
          uname: `${index}-fork`,
        })
      );

      workloads.push(...forks);
    }

    if (rawResources) {
      const resources = rawResources.map(
        (r, index): WL => ({
          type: EntityType.Resource,
          data: r,
          selected: false,
          uname: `${index}-resource`,
        })
      );

      workloads.push(...resources);
    }

    return workloads;
  };

  const resourcesResponse = useSandboxResources(
    sandbox.name,
    sandbox.spec.cluster
  );

  const deploymentsResponse = useSandboxDeploymentsApi({
    sandboxID: sandbox.routingKey,
  });

  const workloads = useMemo(
    () =>
      mergeWorkloads(
        deploymentsResponse.data?.deployments || [],
        (resourcesResponse.data?.resources as ResourceV2[]) || []
      ),
    [resourcesResponse.data, deploymentsResponse.data, qp]
  );

  const handleSelect = (data: WorkloadDropdownOption | undefined) => {
    if (!data) return;

    const wlInfo = getWLInfo(data.original);
    if (!wlInfo) return;

    if (wlInfo.type === EntityType.Resource) {
      setSearchParams({
        type: wlInfo.type,
        name: wlInfo.name,
        step: (data.value as Step).name,
      });
    } else {
      setSearchParams({
        type: wlInfo.type,
        name: wlInfo.name,
      });
    }
  };

  const getDefaultWorkload = (): WorkloadDropdownOption | undefined => {
    if (workloads.length === 0) return undefined;

    const typeParam = query.get("type");
    if (typeParam === "Fork" && deploymentsResponse.isFetching)
      return undefined;

    if (typeParam === "Resource" && resourcesResponse.isFetching)
      return undefined;

    const firstWorkload = workloads[0];
    const wlInfo = getWLInfo(firstWorkload);
    if (!wlInfo) return;

    if (firstWorkload.type === EntityType.Fork) {
      setSearchParams({
        type: wlInfo.type,
        name: wlInfo.name,
      });
      return { original: firstWorkload, value: firstWorkload.data };
    }

    const mergeSteps = [
      ...(firstWorkload.data.create || []),
      ...(firstWorkload.data.delete || []),
    ];
    if (mergeSteps.length === 0) return undefined;

    setSearchParams({
      type: wlInfo.type,
      name: wlInfo.name,
      step: mergeSteps[0].name,
    });
    return { original: firstWorkload, value: mergeSteps[0] };
  };

  const selectedWorkload: WorkloadDropdownOption | undefined = useMemo(() => {
    const typeParam = query.get("type");
    const nameParam = query.get("name");

    if (!typeParam) {
      return getDefaultWorkload();
    }

    if (typeParam === "Fork") {
      const wl = workloads.find(
        (w) => w.type === "Fork" && w.data.fork?.metadata.name === nameParam
      );

      if (!wl || !wl.data) return getDefaultWorkload();

      return { original: wl, value: wl.data as Workload };
    }

    const stepParam = query.get("step");

    // The wl will be always of type Resource however TS is not able to guess it so to avoid a cast,
    // an explicit check is required
    const wl = workloads.find(
      (w) => w.type === "Resource" && w.data.name === nameParam
    );
    if (!wl || wl.type === "Fork") return getDefaultWorkload();

    const mergeSteps = [...(wl.data.create || []), ...(wl.data.delete || [])];
    if (mergeSteps.length === 0) return getDefaultWorkload();

    const step = mergeSteps.find((s) => s.name === stepParam) || mergeSteps[0];

    return {
      original: wl,
      value: step,
    };
  }, [searchParams, workloads]);

  const getLogParams = (
    selectedOption: WorkloadDropdownOption | undefined
  ): LogParams | undefined => {
    if (!selectedOption) {
      return undefined;
    }

    if (selectedOption.original.type === "Fork") {
      return forkToLogParams(sandbox, selectedOption.original, container);
    }

    return resourceToLogParams(
      sandbox,
      container,
      selectedOption.value as Step
    );
  };

  const logParams = getLogParams(selectedWorkload);

  return (
    <div>
      <div>
        <WorkloadDropdown
          onSelect={handleSelect}
          workloads={workloads}
          selectedWorkload={selectedWorkload || null}
        />
      </div>
      <div>
        {logParams ? <LogPanel logParams={logParams} logPanelOpen /> : null}
      </div>
    </div>
  );
};

export default SandboxLogs;
