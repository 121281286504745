import useApi from "../hooks/UseApi";

// The following three types are used to define the three possible states that can be
// returned from the useBasicApi call. Generally, Typescript's narrowing algorithms
// allow it to figure out a concrete type based on runtime code checking properties
// of a union type. An example being if two types exist where one has foo: undefined
// and the other has foo: T, if you have an if that checks for if foo !== undefined,
// then Typescript will infer that the type inside of the if can only be the one that
// has foo: T, along with any other properties available on that type.
//
// Typescript allows literals to be used in the generic parameters. So each of the
// three types takes a generic N which is expected to be a string literal that will
// be used to generate a new type which has that literal as the sole property on it.
// This uses the mapped types feature to create a property for every possible type
// in the type union N that has the value type of T. Note that a type union can be
// a single value, such as a single string literal, which is what we use here.
//
// When you use the mapped type feature, you are not allowed to create any other
// properties on that type. So in order to ensure that all three of the types
// used in the union have the error and loading properties, we have to use a
// type intersection with & to "add" those properties to the mapped type. Effectively
// it's a semi-hacky workaround for not being able to add more properties to a
// mapped type.
//
// Finally we take all three of the states that the result of useBasicApi can be
// in and create a type union around them so code calling it can (theoretically)
// narrow the type down to one such as the ApiData state. In practice it seems
// to still think that the mapped type property could still be undefined even
// if you write code that sees loading as false and error as undefined, which
// should narrow it to the only valid state left being the ApiData one with a
// non-nullable T value. So other code had to have a check such as
// if (foo.loading || !foo.data) { return ... }
// to not require the ! or ? operators later on such as in foo.data!.bar. If we
// can find a way to solve this it would help the ergonomics of using this code
// even more.
//
// It's expected that this will be used by code that defines "nice" APIs for the
// rest of the UI code to use. e.g.:
// useGetAllUsers = () => useBasicApi<User[], "user", UserResult>(...)
// The code that interacts with the return value is expected to check for loading and
// error, and if neither of those are truthy, it should use the data in the
// defined property without having to worry about null/undefined. (though as mentioned
// above, it will likely have to check for !foo.data for an early return to remove
// errors about possibly undefined unless that can be solved)

type ApiData<T, N extends string> = {
  readonly [K in N]: T;
} & { error: undefined; loading: false };

type ApiError<N extends string> = {
  readonly [K in N]: undefined;
} & { readonly error: string; loading: false };

type ApiLoading<N extends string> = {
  readonly [K in N]: undefined;
} & { error: undefined; loading: true };

export type ApiResult<T, N extends string> =
  | ApiLoading<N>
  | ApiError<N>
  | ApiData<T, N>;

export function useBasicApi<T, N extends string, R extends Record<N, T>>(
  apiName: string,
  url: string,
  dataParam: N
) {
  type ReturnType = ApiResult<T, N>;
  const { isLoading, isIdle, isSuccess, error, data } = useApi<R>(apiName, url);
  if (error) {
    return {
      error: error.message,
      [dataParam]: undefined,
      loading: false,
    } as ReturnType;
  }
  if (isLoading || isIdle || !isSuccess || !data) {
    return {
      error: undefined,
      [dataParam]: undefined,
      loading: true,
    } as ReturnType;
  }
  return {
    [dataParam]: data[dataParam],
    error: undefined,
    loading: false,
  } as ReturnType;
}
