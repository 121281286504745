import React from "react";
import { Intent, OverlayToaster } from "@blueprintjs/core";
import yaml from "js-yaml";
import { useNavigate } from "react-router-dom";
import type { ApiError } from "../../../../../hooks/UseApi";
import type { RunnerGroup } from "../../../../../@types/sd/runnergroup";
import { useApplyRunnerGroupApi } from "../../../../../api/RunnerGroupApi";
import SdButton from "../../../../../components/theming/SdButton";
import { RunnerGroupEditorPageApplyRunnerGroup } from "../../../../../Constants";

interface Props {
  spec?: string;
}

const toaster = OverlayToaster.create();

const ApplyRunnerGroupAction: React.FC<Props> = ({ spec }) => {
  const navigate = useNavigate();
  const [applyRunnerGroup, applyRunnerGroupApi] = useApplyRunnerGroupApi(
    (runnerGroup) => {
      toaster.show({
        message: "Job Runner Group applied",
        intent: Intent.SUCCESS,
      });
      console.log(applyRunnerGroupApi.data?.data);
      navigate(`/testing/job-runner-groups/${runnerGroup.name}`);
    },
    (error: ApiError) => {
      toaster.show({
        message: error.response.data.error,
        intent: Intent.DANGER,
      });
    }
  );

  const handleApplyRunnerGroup = () => {
    try {
      const req = yaml.load(spec!) as RunnerGroup;
      applyRunnerGroup(req);
    } catch (e) {
      toaster.show({
        message: `error parsing yaml`,
        intent: Intent.DANGER,
      });
    }
  };

  return (
    <div>
      <SdButton
        eventName={RunnerGroupEditorPageApplyRunnerGroup}
        onClick={handleApplyRunnerGroup}
        disabled={!spec || applyRunnerGroupApi.isLoading}
        className="mt-3"
      >
        Apply
      </SdButton>
    </div>
  );
};

export default ApplyRunnerGroupAction;
