import React, { useState } from "react";
import style from "./Filter.module.css";
import SdInput from "../../theming/SdInput";
import FilterSelect from "../FilterSelect";

export type FilterOptionItem =
  | string
  | {
      label: string;
      disabled: boolean;
    };

export type FilterOptions<T> = {
  label: string;
  options: FilterOptionItem[];
  filter: (data: T, filterValue: string) => boolean;
};

type FilterProps<T> = {
  showSearch?: boolean;
  onChange: (selectedOption: FilterOptionItem | undefined) => void;
  selectedValue?: string;
} & FilterOptions<T>;

const Filter = <T,>({
  label,
  options,
  onChange,
  showSearch,
  selectedValue,
}: FilterProps<T>) => {
  const [filterText, setFilterText] = useState("");

  const filteredOptions = options.filter((opt) => {
    if (typeof opt === "string") return opt.includes(filterText);

    return opt.label.includes(filterText);
  });

  return (
    <div className={style.filter}>
      <p className={style.filter_name}>{label}</p>
      <div className={style.filter_options}>
        {showSearch && (
          <SdInput
            name="search-field"
            type="text"
            placeholder={`Filter by ${label}`}
            onChange={(e) => setFilterText(e.target.value)}
            className="bp4-input bp4-fill bp4-medium mb-2"
          />
        )}

        <FilterSelect
          options={filteredOptions}
          onChange={(selectedOption) => {
            setFilterText("");
            onChange(selectedOption);
          }}
          selectedValue={selectedValue}
        />
      </div>
    </div>
  );
};

export default Filter;
