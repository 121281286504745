import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import styles from "./Executions.module.css";
import type { TestExecution } from "../../../../../../@types/sd/testexecutions";
import type { FilteredViewColumn } from "../../../../../../components/FilteredView";
import FilteredView from "../../../../../../components/FilteredView";
import SdInput from "../../../../../../components/theming/SdInput";
import { filterTestExecutions } from "./utils";
import {
  CapturesInfo,
  TimeInfo,
  GeneralInfo,
} from "../../../ListExecutions/table";
import Loading from "../../../../../../components/Loading/Loading";
import {
  FILTER_TEST_EXECUTIONS_API_NAME,
  useGetTestExecutions,
} from "../../../../../../api/TestExecutionsApi";

interface Props {
  testName?: string;
}

export const COLUMNS: FilteredViewColumn<TestExecution>[] = [
  {
    id: "detail",
    render: (execution) => <GeneralInfo execution={execution} />,
    weight: 3,
  },
  {
    id: "duration__captures_info",
    render: (execution) => <CapturesInfo execution={execution} />,
    weight: 1,
  },
  {
    id: "duration__execution_time",
    render: (execution) => <TimeInfo execution={execution} />,
    weight: 1,
  },
];

const Executions: React.FC<Props> = ({ testName }) => {
  const queryClient = useQueryClient();
  const [filterText, setFilterText] = React.useState<string>("");
  const { isSuccess, isLoading, data } = useGetTestExecutions(testName || "", {
    enabled: testName !== undefined,
  });
  const navigate = useNavigate();
  const filteredList = React.useMemo(
    () => filterTestExecutions(data?.map((d) => d.execution) || [], filterText),
    [data, filterText]
  );

  useEffect(() => {
    setFilterText("");
  }, [testName]);

  useEffect(() => {
    queryClient.invalidateQueries(FILTER_TEST_EXECUTIONS_API_NAME);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!isSuccess || !data) {
    return null;
  }
  return (
    <div key={testName} className={styles.container}>
      <SdInput
        name="search-field"
        type="text"
        placeholder="Type to search ..."
        className="bp4-input bp4-fill bp4-medium mb-2"
        onChange={(e) => setFilterText(e.target.value)}
      />
      <FilteredView
        title={(count) => `${count} test executions`}
        data={filteredList}
        filters={[]}
        columns={COLUMNS}
        totalWeight={5}
        pageSize={10}
        onRowClick={(r) =>
          navigate(`/testing/tests/${testName}/executions/${r.name}`)
        }
      />
    </div>
  );
};

export default Executions;
