import React from "react";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import styles from "./GeneralInfo.module.css";
// @ts-ignore
// eslint-disable-next-line import/extensions
import type {
  Test,
  TestTrigger,
} from "../../../../../../../../@types/sd/testspec";
import ExpandableList from "../../../../../../../../components/theming/ExpandableList";

interface Props {
  test: Test;
}

type TriggerProps = {
  trigger: TestTrigger;
};
const Trigger = ({ trigger }: TriggerProps) => (
  <Popover2>
    <Tooltip2
      content={`Triggered by sandbox in cluster ${
        trigger.executionTemplate.cluster
      } and namespace ${trigger.sandboxOf.namespace} forking workload named ${
        trigger.sandboxOf.name
      } of type ${trigger.sandboxOf.kind.toLowerCase()}`}
      intent="none"
      hoverOpenDelay={250}
      position="bottom-left"
    >
      <p>
        <span>{trigger.sandboxOf.namespace}</span>
        <span>/</span>
        <span>{trigger.sandboxOf.name}</span>
      </p>
    </Tooltip2>
  </Popover2>
);

const GeneralInfo: React.FC<Props> = ({ test }) => (
  <div className={styles.detail}>
    <div className={styles.body}>
      <div className={styles.title}>{test.name}</div>
      {test.spec.triggers && test.spec.triggers.length > 0 ? (
        <div className={styles.triggers}>
          <p>Triggered by</p>
          <ExpandableList
            defaultCount={3}
            items={test.spec.triggers.map((t) => (
              <Trigger trigger={t} key={t.id} />
            ))}
            separator={(index, totalCount) => {
              if (index === totalCount - 2) {
                return " or ";
              }
              if (index < totalCount - 2) {
                return ", ";
              }
              return " ";
            }}
          />
        </div>
      ) : (
        <p>No triggers</p>
      )}
    </div>
  </div>
);

export default GeneralInfo;
