import type React from "react";
import type Status from "./Status";
import styles from "./Status.module.css";

type StatusType = React.ComponentProps<typeof Status>["type"];

// eslint-disable-next-line import/prefer-default-export
export const getStatusStyle = (statusType: StatusType) => {
  const mapper: Record<StatusType, string> = {
    error: styles.status_error,
    warning: styles.status_warning,
  };

  return mapper[statusType];
};
