import React from "react";
import { Alert, Intent, OverlayToaster } from "@blueprintjs/core";
import _ from "lodash";
import useApiMutation from "../../../hooks/UseApiMutation";
import {
  SANDBOX_STATUSES_API_NAME,
  SANDBOXES_API_NAME,
} from "../../../api/SandboxesApi";
import style from "./DeleteSandboxAlert.module.css";

const toaster = OverlayToaster.create();
const identifiersToInvalidate = [SANDBOXES_API_NAME, SANDBOX_STATUSES_API_NAME];

interface DeleteSandboxAlertProps {
  name: string;
  isDeleteModalOpen: boolean;
  onCancel: () => void;
  onComplete: () => void;
}

const DeleteSandboxAlert: React.FunctionComponent<DeleteSandboxAlertProps> = ({
  name,
  isDeleteModalOpen,
  onCancel,
  onComplete,
}) => {
  const deletion = useApiMutation(
    "delete_sandbox",
    "DELETE",
    identifiersToInvalidate
  );

  if (deletion.error) {
    toaster.show({
      message: _.get(deletion.error, "response.data.error"),
      intent: Intent.DANGER,
    });
    deletion.reset();
  } else if (deletion.isSuccess) {
    toaster.show({
      message: "Sandbox will be deleted shortly",
      intent: Intent.SUCCESS,
    });
    deletion.reset();
  }

  const [forceDelete, setForceDelete] = React.useState(false);

  const handleToggleForceDelete = () => {
    setForceDelete(!forceDelete);
  };

  if (!name) {
    return null;
  }

  return (
    <Alert
      cancelButtonText="Cancel"
      confirmButtonText="Delete Sandbox"
      icon="trash"
      intent={Intent.DANGER}
      isOpen={isDeleteModalOpen}
      onCancel={onCancel}
      onConfirm={() => {
        deletion.mutate({
          data: {},
          url: `/api/v2/orgs/:orgName/sandboxes/${name}${
            forceDelete ? "?force=true" : ""
          }`,
        });
        setForceDelete(false);
        onComplete();
      }}
    >
      <div>
        <p>
          Are you sure you want to delete the sandbox: <b>{name}</b>?
        </p>
        <div className={style.forceDelete}>
          <p className={style.input}>
            <input
              type="checkbox"
              checked={forceDelete}
              onChange={handleToggleForceDelete}
            />{" "}
            Force delete
          </p>
          <p className={style.note}>
            Force deletion does not wait for confirmation that sandbox pods and
            resources have been terminated. Manual clean-up may be required.
          </p>
        </div>
      </div>
    </Alert>
  );
};

export default DeleteSandboxAlert;
