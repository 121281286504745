import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  Dialog,
  Elevation,
  FormGroup,
  Icon,
  Intent,
  OverlayToaster,
  Tag,
} from "@blueprintjs/core";
import _ from "lodash";
import { Tooltip2 } from "@blueprintjs/popover2";
import { SiKubernetes } from "react-icons/si";
import { useAuth } from "../../contexts/AuthContext";
import useApiMutation from "../../hooks/UseApiMutation";
import useApi from "../../hooks/UseApi";
import Loading from "../../components/Loading/Loading";
import {
  DeleteIcon,
  getTimeAgoElement,
  TTLIconWithTooltip,
} from "../../util/Util";
import PaginatedTable from "../../components/PaginatedTable/PaginatedTable";
import CreateFirst from "../../components/CreateFirst";
import {
  isObservedVersionLatest,
  parseOperatorVersion,
  useLatestOperatorVersion,
} from "../../api/OperatorVersion";
import useClusterTokenCreator from "../ClusterTokens/TokenApi";
import { parseStatus } from "../../util/StatusUtil/StatusUtils";
import StatusIndicator from "../../util/StatusUtil/StatusIndicator";
import SdButton from "../../components/theming/SdButton";
import { SdInputGroup } from "../../components/theming/SdInput";
import SdTheme from "../../styles/theme";
import { SdHeading1 } from "../../components/theming/SdHeading";
import type { ClusterStatus } from "../../@types/sd/cluster";
import FilterTag from "../../components/FilterTag";
import PlaygroundClusterBanner from "./Playground/PlaygroundClusterBanner";
import styles from "./Clusters.module.css";
import { FlaskIcon } from "../../components/theming/icons";
import { PLAYGROUND_EVICTION_MESSAGE } from "./constants";
import DeleteClusterDialog from "../../components/DeleteClusterDialog";

export type ClusterResponse = {
  id: string;
  name: string;
  operatorVersion?: string;
  createdAt?: string;
  isPlayground?: boolean;
};

interface ClusterStatusApiResponse {
  Statuses: ClusterStatus[];
}

type FormattedClusterData = {
  name: {
    text: string;
    component: JSX.Element;
  };
  createdAt: React.ReactNode;
  isPlayGround?: boolean;
  status: {
    text: string | null;
    component: React.ReactNode;
  };
  operatorVersion: {
    text: string;
    component: JSX.Element;
  };
  action: JSX.Element;
};

type Props = {
  openModal?: boolean;
};

const toaster = OverlayToaster.create();

const columns = [
  {
    Header: "ID",
    accessor: "id",
    search: true,
    show: false,
  },
  {
    Header: "Created",
    accessor: "createdAt",
  },
  {
    Header: "Name",
    accessor: "name",
    search: true,
  },
  {
    Header: "Operator Version",
    accessor: "operatorVersion",
    search: true,
  },
  {
    Header: "Status",
    accessor: "status",
    search: true,
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

const Clusters: React.FunctionComponent<Props> = ({
  openModal = window.location.hash === "#connect",
}): JSX.Element => {
  const { org } = useAuth().state;
  const search = new URLSearchParams(useLocation().search);
  const showUnhealthy = !!search.get("showUnhealthy");
  const showUpgrades = !!search.get("showUpgrades");
  const { isLoading, data, error, isSuccess } = useApi(
    "clusters",
    `/api/v1/orgs/:orgName/clusters`
  );
  const clusterList: ClusterResponse[] = _.get(data, ["clusters"]) || [];
  const playgroundCluster: ClusterResponse | undefined = React.useMemo(() => {
    const playgroundClusters = clusterList.filter((c) => c.isPlayground);
    return playgroundClusters.length > 0 ? playgroundClusters[0] : undefined;
  }, [clusterList]);

  const latestOperatorVersion = useLatestOperatorVersion();
  const navigate = useNavigate();

  const mutation = useApiMutation(
    "add_cluster",
    "POST",
    ["clusters"] /* invalidation of get query output */
  );
  const deleteClusterApi = useApiMutation("delete_cluster", "DELETE", [
    "clusters",
  ]);
  const [createTokenApi, createToken] = useClusterTokenCreator();

  // state for the modal for adding cluster.
  const [isAddModalOpen, setAddModalOpen] = useState(openModal);
  const [isDisconnectModalOpen, setDisconnectModalOpen] = useState(false);
  const [clusterName, setClusterName] = useState("");
  const status = useApi<ClusterStatusApiResponse>(
    "cluster_status",
    `/api/v1/orgs/:orgName/clusters/status`,
    {
      refetchInterval: 30000,
    }
  );
  let statusCollection: ClusterStatus[] = [];
  if (!status.isLoading && status.isSuccess) {
    const statusData = status.data || {};
    const statuses = statusData.Statuses;
    if (Array.isArray(statuses) && statuses.length > 0) {
      statusCollection = statuses;
    }
  }
  const showConnectClusterModal = () => setAddModalOpen(true);
  const hideConnectClusterModal = () => setAddModalOpen(false);

  const formatClusters = (
    clusters: ClusterResponse[],
    statuses: ClusterStatus[]
  ): FormattedClusterData[] => {
    if (_.isEmpty(clusters)) {
      return [];
    }
    const statusMap: Record<string, ClusterStatus> = statuses.reduce(
      (map, obj) => {
        // eslint-disable-next-line no-param-reassign
        map[obj.id] = obj;
        return map;
      },
      {}
    );
    return clusters
      .map((cluster): FormattedClusterData | null => {
        let operatorVersionComponent = (
          <Tag
            round
            color={SdTheme.Status.neutral}
            style={{ color: SdTheme.Text.darkBackground }}
          >
            {cluster.operatorVersion || "unknown"}
          </Tag>
        );
        if (
          latestOperatorVersion.version &&
          cluster.operatorVersion &&
          !isObservedVersionLatest(
            parseOperatorVersion(cluster.operatorVersion),
            latestOperatorVersion.version
          )
        ) {
          operatorVersionComponent = (
            <>
              {operatorVersionComponent}
              <Tooltip2
                placement="top"
                content="Operator upgrade available. Click for details."
                className="ml-2"
              >
                <Icon
                  icon="outdated"
                  color={SdTheme.Status.bad}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // @ts-ignore
                    window
                      .open(latestOperatorVersion.releaseNotes!, "_blank")
                      .focus();
                  }}
                />
              </Tooltip2>
            </>
          );
        } else if (showUpgrades) {
          return null;
        }
        const clusterStatus = statusMap[cluster.id];
        if (showUnhealthy && clusterStatus?.status?.healthy) {
          return null;
        }
        const preparedStatus = parseStatus(clusterStatus?.status);

        return {
          ...cluster,
          name: {
            text: cluster.name,
            component: (
              <span>
                {cluster.isPlayground ? (
                  <FlaskIcon className={styles.playgroundIcon} />
                ) : null}
                {cluster.name}
                {cluster.isPlayground && (
                  <div
                    style={{
                      display: "inline-block",
                      position: "relative",
                      bottom: "-3px",
                      paddingLeft: "5px",
                    }}
                  >
                    <TTLIconWithTooltip
                      tooltipMessage={PLAYGROUND_EVICTION_MESSAGE}
                    />
                  </div>
                )}
              </span>
            ),
          },
          status: {
            text: preparedStatus && preparedStatus.statusText,
            component: (
              <span>
                {cluster.operatorVersion !== null ? (
                  <StatusIndicator status={preparedStatus} />
                ) : (
                  <StatusIndicator
                    status={preparedStatus}
                    color={SdTheme.Status.warning}
                    tooltipContent="Cluster Setup Pending"
                  />
                )}
              </span>
            ),
          },
          createdAt: getTimeAgoElement(cluster.createdAt),
          isPlayGround: cluster.isPlayground,
          operatorVersion: {
            text: cluster.operatorVersion || "unknown",
            component: operatorVersionComponent,
          },
          action: (
            <DeleteIcon
              hasTTL={!!cluster.isPlayground}
              tooltipMessage={PLAYGROUND_EVICTION_MESSAGE}
            />
          ),
        };
      })
      .filter((cluster) => cluster !== null) as FormattedClusterData[];
  };

  if (isLoading) {
    return <Loading />;
  }
  if (!isSuccess) {
    return <>Error: {error}</>;
  }

  // show success / failure messages for API calls.
  if (mutation.error) {
    toaster.show({
      message: _.get(
        mutation.error,
        ["response", "data", "error"],
        "Unknown error"
      ),
      intent: Intent.DANGER,
    });
    mutation.reset();
    setClusterName("");
    hideConnectClusterModal();
  }

  if (createTokenApi.isError) {
    toaster.show({
      message: _.get(
        createTokenApi.error,
        ["response", "data", "error"],
        "Unknown error"
      ),
      intent: Intent.DANGER,
    });
    createTokenApi.reset();
    setClusterName("");
    hideConnectClusterModal();
  }

  if (mutation.isSuccess) {
    createToken(clusterName);
    mutation.reset();
  }

  if (createTokenApi.isSuccess) {
    const localClusterName = clusterName;
    setClusterName("");
    hideConnectClusterModal();
    const clusterToken = createTokenApi.data.data.token;
    mutation.reset();
    createTokenApi.reset();
    navigate(`/cluster/${localClusterName}`, {
      state: {
        clusterToken,
      },
    });
  }

  if (deleteClusterApi.error) {
    toaster.show({
      message: _.get(deleteClusterApi.error, ["response", "data", "error"]),
      intent: Intent.DANGER,
    });
    deleteClusterApi.reset();
  } else if (deleteClusterApi.isSuccess) {
    toaster.show({
      message: "Cluster connection deleted",
      intent: Intent.SUCCESS,
    });
    deleteClusterApi.reset();
  }

  const handleRowClick = (row: FormattedClusterData) => {
    navigate(`/cluster/${row.name.text}`);
  };

  const deleteClusterConn = (row: FormattedClusterData) => {
    setClusterName(row.name.text);
    setDisconnectModalOpen(true);
  };

  const handleCreateCluster = () => {
    mutation.mutate({
      data: { name: clusterName },
      url: `/api/v1/orgs/:orgName/clusters`,
    });
  };

  const showPlaygroundClusterBanner = (
    clusters: FormattedClusterData[]
  ): boolean =>
    // if it's the signadot org,
    // or if there have never been any healthy clusters, we show the banner
    org!.name === "signadot" ||
    clusters?.filter(
      (c) => !c.isPlayGround && c.operatorVersion.text !== "unknown"
    ).length === 0;

  const clusters = formatClusters(clusterList, statusCollection);
  return (
    <>
      <SdHeading1 small lightBackground>
        Clusters
      </SdHeading1>
      <br />
      {showPlaygroundClusterBanner(clusters) ? (
        <PlaygroundClusterBanner cluster={playgroundCluster} />
      ) : null}
      <br />
      {showUnhealthy && (
        <>
          <FilterTag text="status: error" />
          <br />
        </>
      )}
      {showUpgrades && (
        <>
          <FilterTag text="status: upgrade available" />
          <br />
        </>
      )}
      {(clusterList.length > 0 && (
        <>
          <PaginatedTable
            columns={columns}
            data={clusters}
            onRowClickCallback={handleRowClick}
            onActionCallback={deleteClusterConn}
          />
          <br />
          <SdButton icon="plus" onClick={showConnectClusterModal}>
            Connect Cluster
          </SdButton>
        </>
      )) || (
        <CreateFirst
          icon={<SiKubernetes size={80} />}
          message="Connect your first cluster"
          buttonLabel="Connect Cluster"
          onClick={showConnectClusterModal}
        />
      )}

      {/* Modal Dialog for adding cluster */}
      {isAddModalOpen && (
        <Dialog
          style={{ height: "100%" }}
          icon="info-sign"
          title="Connect Cluster"
          isOpen={isAddModalOpen}
          onClose={() => {
            mutation.reset();
            setClusterName("");
            hideConnectClusterModal();
          }}
        >
          {(mutation.isLoading || createTokenApi.isLoading) && <Loading />}
          {!(mutation.isLoading || createTokenApi.isLoading) && (
            <Card
              style={{ marginBottom: "-20px" }}
              interactive
              elevation={Elevation.TWO}
            >
              <div>
                <p>
                  Choose a name for the Kubernetes cluster that you will be
                  connecting to Signadot. This name will be used to refer to the
                  cluster in the{" "}
                  <a
                    href="https://www.signadot.com/docs/reference/sandboxes/spec"
                    target="_blank"
                    rel="noreferrer"
                  >
                    sandbox specification
                  </a>
                  .
                </p>
                <FormGroup>
                  <SdInputGroup
                    id="name"
                    placeholder="corp-staging-1"
                    value={clusterName}
                    autoFocus
                    onChange={(evt) => setClusterName(evt.target.value)}
                    onKeyDown={(e) => {
                      if (e.code === "Enter") {
                        handleCreateCluster();
                      }
                    }}
                  />
                  <br />
                  <SdButton icon="plus" onClick={handleCreateCluster}>
                    Connect
                  </SdButton>
                </FormGroup>
              </div>
            </Card>
          )}
        </Dialog>
      )}

      {/* Verify the user really wants to delete the cluster since it's more destructive than, say,
       deleting a Sandbox. */}
      {isDisconnectModalOpen && (
        <DeleteClusterDialog
          clusterName={clusterName}
          isOpen={isDisconnectModalOpen}
          onConfirm={() => {
            deleteClusterApi.mutate({
              url: `/api/v1/orgs/:orgName/clusters/${clusterName}`,
            });
            setClusterName("");
            setDisconnectModalOpen(false);
          }}
          onCancel={() => {
            setDisconnectModalOpen(false);
            setClusterName("");
          }}
        />
      )}
    </>
  );
};

export default Clusters;
