import React from "react";
import { LuAlertTriangle, LuCheckCircle } from "react-icons/lu";
import styles from "./StatusCodeDiff.module.css";
import { statusMap } from "./statusCodeDescription";
import StatusBadge from "./StatusBadge";
import type { FindingDiffOp } from "../../../../../../@types/sd/findings";

const getStatusDescription = (status: number): string =>
  statusMap.get(status)?.description || "Unknown status code";


type StatusCodeDiffProps = {
  diffOps?: FindingDiffOp;
};
const StatusCodeDiff = ({ diffOps }: StatusCodeDiffProps) => {
  if (!diffOps) return null;

  const { oldValue, value } = diffOps;
  const baseline = Number.parseInt(oldValue, 10);
  const target = Number.parseInt(value, 10);

  const Icon = baseline !== target ? LuAlertTriangle : LuCheckCircle;
  const iconColor = baseline !== target ? styles.textYellow : styles.textGreen;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardHeader}>
        <h2 className={`${styles.headerTitle}`}>
          <Icon className={`${iconColor}`} />
          Status Code Comparison
        </h2>
        <p className={styles.descriptionText}>
          Comparing baseline and target status responses
        </p>
      </div>
      <div className={styles.content}>
        <div className={styles.gridLayout}>
          <div className={styles.serviceDescription}>
            <h3>Baseline</h3>
            <StatusBadge status={baseline} />
            <p>{getStatusDescription(baseline)}</p>
          </div>
          <div className={styles.serviceDescription}>
            <h3>Target</h3>
            <StatusBadge status={target} />
            <p>{getStatusDescription(target)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusCodeDiff;
