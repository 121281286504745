import React from "react";
import { Icon } from "@blueprintjs/core";
import type { ForkWL, WL } from "../types";
import { SupportedStepType } from "../types";
import useDisclosure from "../../../../../hooks/UseDisclosure";
import styles from "./WorkloadDropdownItem/WorkloadDropdownItem.module.css";
import { getWLInfo } from "../utils";
import WorkloadDropdownItem from "./WorkloadDropdownItem/WorkloadDropdownItem";

type DropdownResourceItemProps = Extract<WL, { type: "Resource" }> & {
  onClick?: (stepType: SupportedStepType, stepIndex: number) => void;
};

export const DropdownResourceItem: React.FC<DropdownResourceItemProps> = ({
  data,
  onClick,
}) => {
  const { isOpen, toggle } = useDisclosure(false);

  const handleStepClick =
    (stepType: SupportedStepType, stepIndex: number) => () => {
      onClick?.(stepType, stepIndex);
    };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={`${styles.container} ${styles.resource}`}
        onClick={toggle}
      >
        <div className={styles.left}>
          <div className={styles.title}>{data.name}</div>
          {data.plugin && (
            <div className={styles.subtitle}>plugin: {data.plugin}</div>
          )}
        </div>

        <Icon icon={isOpen ? "chevron-up" : "chevron-down"} />
      </div>
      {isOpen && (
        <div className={styles.steps}>
          {data.create?.map((step, index) => (
            <WorkloadDropdownItem
              key={step.name}
              title={step.name}
              tag="Create"
              subtitle={`step of: ${data.name}`}
              onClick={handleStepClick(SupportedStepType.Create, index)}
            />
          ))}
          {data.delete?.map((step, index) => (
            <WorkloadDropdownItem
              key={step.name}
              title={step.name}
              tag="Delete"
              subtitle={`step of: ${data.name}`}
              onClick={handleStepClick(SupportedStepType.Delete, index)}
            />
          ))}
        </div>
      )}
    </>
  );
};

type DropdownForkItemProps = Extract<WL, { type: "Fork" }> & {
  onClick?: (data: ForkWL) => void;
};

export const DropdownForkItem: React.FC<DropdownForkItemProps> = (props) => {
  const wlInfo = getWLInfo(props);
  const { onClick, data } = props;

  return (
    <WorkloadDropdownItem
      title={wlInfo!.name}
      subtitle={`forkOf: ${wlInfo?.references}`}
      tag={wlInfo?.type}
      onClick={() => onClick?.(data)}
    />
  );
};
