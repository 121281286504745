import { ENTERPRISE, FREE, TEAM } from "./Constants";

// eslint-disable-next-line import/prefer-default-export
export const getPlanDisplayNameByType = (planType: string): string => {
  switch (planType) {
    case FREE:
      return "Starter";
    case TEAM:
      return "Business";
    case ENTERPRISE:
      return "Enterprise";
    default:
      return "";
  }
};
