import type { TrafficDiffFinding } from "../../../../../@types/sd/findings";

export type FindingsGroup = Record<string, TrafficDiffFinding[]>;

export const groupFindingsByKey = (
  findings: TrafficDiffFinding[],
  getKey: (finding: TrafficDiffFinding) => string,
): FindingsGroup => {
  const groupedFindings: FindingsGroup = {};

  findings.forEach((finding) => {
    const key = getKey(finding);

    if (groupedFindings[key]) {
      // If the key already exists, push the current finding to the array
      groupedFindings[key].push(finding);
    } else {
      // If the key doesn't exist, create a new array with the current finding
      groupedFindings[key] = [finding];
    }
  });

  return groupedFindings;
};
