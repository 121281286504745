import { useMemo, useState } from "react";

const DEFAULT_PAGE_SIZE = 10;

type UsePaginationProps<DataT> = {
  pageSize?: number;
  data: DataT[];
};

type ReturnUsePagination<DataT> = {
  data: DataT[];
  onNextPage: () => void;
  onPageChange: (page: number) => void;
  totalPages: number;
  currentPage: number;
};

const getNumberPages = (total: number, pageSize: number) =>
  Math.ceil(total / pageSize);

const usePagination = <DataT,>({
  pageSize = DEFAULT_PAGE_SIZE,
  data,
}: UsePaginationProps<DataT>): ReturnUsePagination<DataT> => {
  const [currentPage, setCurrentPage] = useState(1);

  const filteredData = useMemo(() => {
    let start = (currentPage - 1) * pageSize;
    start = start >= 0 ? start : 0;
    const end = start + pageSize;

    return data.slice(start, end);
  }, [data, pageSize, currentPage]);

  const onNextPage = () => {
    setCurrentPage((curr) => curr + 1);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  return {
    data: filteredData,
    onNextPage,
    totalPages: getNumberPages(data.length, pageSize),
    currentPage,
    onPageChange,
  };
};

export default usePagination;
