/* eslint-disable no-plusplus,no-continue */
import type { CSSProperties, KeyboardEventHandler } from "react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Callout,
  ControlGroup,
  InputGroup,
  Intent,
  Label,
  Tag,
} from "@blueprintjs/core";
import _ from "lodash";
import type { Method } from "axios";
import Table from "../../components/Table/Table";
import Spacer from "../../components/Util/Util";
import useApiMutation from "../../hooks/UseApiMutation";
import Error from "../../components/Error/Error";
import type { AggregateEnvVars } from "./AggregateEnvVars";
import {
  applyModifications,
  applyPatchValues,
  getEnvVar,
  getModifiedNameListElements,
  isModified,
  newAggregateEnvVars,
  patchToEnvVar,
  remove,
  toPatch,
  toTable,
  upsert,
} from "./AggregateEnvVars";
import type { Workload } from "../../@types/sd/workload";
import type { Patch } from "../../@types/sd/patch";
import { getApiVersion } from "../../@types/sd/workload";

interface Props {
  sandboxId: string;
  data: Workload;
  patch: Patch | null;
}

interface AddEnvVarResponse {
  id: string;
}

const NAME_FIELD_ID = "deployment-create-env-var-name";
const envVarsColumns = [
  {
    Header: "Container",
    accessor: "container",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Value",
    accessor: "value",
  },
  {
    Header: "Action",
    accessor: "actions",
  },
];

const EnvVarEditor: React.FunctionComponent<Props> = ({
  sandboxId,
  data,
  patch,
}) => {
  const baselineNamespace = _.get(data, ["baseline", "metadata", "namespace"]);
  const baselineName = _.get(data, ["baseline", "metadata", "name"]);
  const { kind } = data;
  const apiVersion = getApiVersion(kind);

  const baselineContainer = _.get(
    data,
    ["baseline", "spec", "template", "spec", "containers", 0],
    {}
  );

  // Holds the state from the name and value inputs for new environment variables.
  const [newEnvVarName, setNewEnvVarName] = useState("");
  const [newEnvVarValue, setNewEnvVarValue] = useState("");

  // If the user starts editing an environment variable, this holds the name of which one is
  // being edited.
  const [selectedEnvVar, setSelectedEnvVar] = useState("");
  // Holds the state from the created name and value inputs for editing an environment variable.
  const [editingEnvVarName, setEditingEnvVarName] = useState("");
  const [editingEnvVarValue, setEditingEnvVarValue] = useState("");
  // When beginning editing an environment variable, we focus the input with the value and
  // select all the text so that users can start typing immediately and overwrite the existing
  // value. But we only want to do that the first time that input gets focused, so we keep
  // a flag around for if we are doing that first text selection focus, so that if the user
  // clicks on the value input it doesn't select all the text again.
  const [isInitialFocus, setInitialFocus] = useState(true);
  // We want to automatically save any edits made to an environment variable and remove
  // the input fields when the user clicks away from them. So we keep state around for
  // if either of the input fields is focused so we can do those operations when neither
  // has focus.
  const [isEditingNameFocused, setEditingNameFocused] = useState(false);
  const [isEditingValueFocused, setEditingValueFocused] = useState(false);

  /** @type {[AggregateEnvVars, Function]} */
  const [aggregateEnvVars, setAggregateEnvVars] = useState(() =>
    newAggregateEnvVars(baselineContainer, patch)
  );
  const previousAggregateEnvVarsRef = useRef<AggregateEnvVars | null>(null);
  useEffect(() => {
    // Store the last set of modified changes so we can revert to them in the case of an error
    // from the patch API.
    if (isModified(aggregateEnvVars)) {
      previousAggregateEnvVarsRef.current = aggregateEnvVars;
    }
  }, [aggregateEnvVars]);
  useEffect(() => {
    if (patch && !_.isEmpty(patch.value)) {
      setAggregateEnvVars((current) =>
        applyModifications(
          applyPatchValues(current, patch.value!.map(patchToEnvVar))
        )
      );
    }
  }, [patch]);

  const addEnvVarApi = useApiMutation<AddEnvVarResponse>(
    "deployment_add_env_var",
    "POST",
    ["runtime_info", "patch_info"]
  );
  // Patch API always returns an id value, which will either be a new id if we're creating the first
  // patch for the deployment or the same as the existing patch passed to this component. So we can
  // safely always use the id from a successful patch API call for any future API calls.
  const patchId = addEnvVarApi.isSuccess
    ? addEnvVarApi.data.data.id
    : _.get(patch, ["id"]);
  // "Roll back" the set of committed changes to the last modified changes if an error state is entered
  // from the patch API.
  useEffect(() => {
    if (addEnvVarApi.isError && previousAggregateEnvVarsRef.current !== null) {
      setAggregateEnvVars(previousAggregateEnvVarsRef.current);
    }
  }, [addEnvVarApi.isError]);

  function cancelEditingMode() {
    setEditingEnvVarName("");
    setEditingEnvVarValue("");
    setSelectedEnvVar("");
    setInitialFocus(true);
  }

  function applyEdits() {
    // Because the selected name never gets changed after clicking the edit button, we
    // can detect if the user changed the name of the environment variable. If they did,
    // we need to upsert the new name and remove the old one because if a baseline
    // environment variable has their name changed this is the easiest way to ensure
    // the correct operations happen. Same for an applied forked environment variable which
    // will be marked for deletion in the UI. (and it should leave the UI in a consistent
    // state with it looking like an un-applied new environment variable got edited without
    // having two rows)
    if (editingEnvVarName !== selectedEnvVar) {
      setAggregateEnvVars(
        remove(
          upsert(aggregateEnvVars, editingEnvVarName, editingEnvVarValue),
          selectedEnvVar
        )
      );
    } else {
      setAggregateEnvVars(
        upsert(aggregateEnvVars, editingEnvVarName, editingEnvVarValue)
      );
    }
  }

  useEffect(() => {
    // Because both focus booleans start as false, we need to detect if we entered an editing
    // state at all via the selected environment variable in order to detect when we
    // want to apply the changes and leave editing mode.
    if (
      selectedEnvVar !== "" &&
      !isEditingNameFocused &&
      !isEditingValueFocused
    ) {
      applyEdits();
      cancelEditingMode();
    }
  }, [isEditingNameFocused, isEditingValueFocused]);

  const envVarData = useMemo(() => {
    /**
     * If the user presses escape we want to cancel the current set of edits and go back to
     * the original state of changes before editing started. Otherwise if they press enter
     * we want to commit the current changes just as if they'd click away from the inputs.
     * @param {KeyboardEvent.<HTMLInputElement>} event
     */
    const handleEditEnvVarKey: KeyboardEventHandler = (event) => {
      switch (event.code) {
        case "Escape":
          // A Drawer element picks up the Escape key and uses it as a signal to close itself.
          // We don't want that to happen when the user wants to cancel a set of edits.
          event.preventDefault();
          event.stopPropagation();
          cancelEditingMode();
          break;
        case "Enter":
          applyEdits();
          cancelEditingMode();
          break;
        default:
          // Nothing to do.
          break;
      }
    };

    return toTable(aggregateEnvVars).map(
      ({ name, value, valueFrom, isModified: modified }) => {
        const style: CSSProperties = {};
        if (modified) {
          style.color = "darkorange";
        }

        let displayValue: React.ReactNode = value;
        if (valueFrom) {
          if (valueFrom.resource) {
            displayValue = <Tag round>Dynamic: from resource</Tag>;
          } else if (valueFrom.fork) {
            displayValue = <Tag round>Dynamic: x-fork ref</Tag>;
          } else {
            displayValue = <Tag round>Dynamic</Tag>;
          }
        }
        let nameEl;
        let valueEl;
        if (name !== selectedEnvVar) {
          nameEl = <p style={style}>{name}</p>;
          valueEl = <p style={style}>{displayValue}</p>;
        } else {
          nameEl = (
            <InputGroup
              value={editingEnvVarName}
              onChange={(event) => setEditingEnvVarName(event.target.value)}
              onKeyDown={handleEditEnvVarKey}
              onFocus={() => {
                setEditingNameFocused(true);
              }}
              onBlur={() => {
                setTimeout(() => setEditingNameFocused(false), 0);
              }}
            />
          );
          valueEl = (
            <InputGroup
              value={editingEnvVarValue}
              autoFocus
              onChange={(event) => setEditingEnvVarValue(event.target.value)}
              onFocus={(event) => {
                if (isInitialFocus) {
                  event.target.select();
                  setInitialFocus(false);
                }
                setEditingValueFocused(true);
              }}
              onBlur={() => {
                setTimeout(() => setEditingValueFocused(false), 0);
              }}
              onKeyDown={handleEditEnvVarKey}
            />
          );
        }

        const ret = {
          name: nameEl,
          value: valueEl,
          container: <span style={style}>{aggregateEnvVars.container}</span>,
          actions: null as React.ReactNode,
        };
        if (value) {
          ret.actions = (
            <ButtonGroup>
              <Button
                minimal
                icon="edit"
                onClick={() => {
                  setEditingEnvVarName(name);
                  const envVar = getEnvVar(aggregateEnvVars, name)!;
                  if (envVar.type === "value") {
                    setEditingEnvVarValue(envVar.value ?? "");
                  }
                  setSelectedEnvVar(name);
                }}
              />
              <Button
                minimal
                icon="trash"
                onClick={() =>
                  setAggregateEnvVars(remove(aggregateEnvVars, name))
                }
              />
            </ButtonGroup>
          );
        }
        return ret;
      }
    );
  }, [
    aggregateEnvVars,
    selectedEnvVar,
    editingEnvVarName,
    editingEnvVarValue,
    isEditingNameFocused,
    isEditingValueFocused,
  ]);

  const handleAddEnvVar = () => {
    if (newEnvVarName === "" || newEnvVarValue === "") {
      return;
    }
    setAggregateEnvVars(
      upsert(aggregateEnvVars, newEnvVarName, newEnvVarValue)
    );
    setNewEnvVarName("");
    setNewEnvVarValue("");
  };

  const handleEnvVarKeyUp: KeyboardEventHandler = (e) => {
    if (e.code === "Enter") {
      handleAddEnvVar();
      document.getElementById(NAME_FIELD_ID)!.focus();
    }
  };

  return (
    <>
      {addEnvVarApi.isError && (
        <Error text={addEnvVarApi.error.response.data.error} />
      )}

      <Table columns={envVarsColumns} data={envVarData} />
      <Spacer />
      <Spacer />

      <ControlGroup className="mr-5">
        <InputGroup
          id={NAME_FIELD_ID}
          className="pr-4 w-56"
          placeholder="environment variable name"
          value={newEnvVarName}
          onChange={(e) => setNewEnvVarName(e.target.value)}
          onKeyUp={handleEnvVarKeyUp}
        />
        <InputGroup
          id="deployment-create-env-var-value"
          className="pr-4 w-56"
          placeholder="environment variable value"
          value={newEnvVarValue}
          onChange={(e) => setNewEnvVarValue(e.target.value)}
          onKeyUp={handleEnvVarKeyUp}
        />
        <Button className="mr-2" icon="plus" onClick={handleAddEnvVar}>
          Add Environment Variable
        </Button>
      </ControlGroup>

      {isModified(aggregateEnvVars) && (
        <>
          <Spacer />
          <Spacer />
          <Callout intent={Intent.PRIMARY}>
            <p>
              You have unsaved changes to the following Environment Variables:
            </p>
            <ul>
              {getModifiedNameListElements(aggregateEnvVars).map((name) => (
                <li key={name}>{name}</li>
              ))}
            </ul>
            <Spacer />
            <ControlGroup>
              <Button
                className="w-15"
                onClick={() => {
                  setAggregateEnvVars(
                    newAggregateEnvVars(baselineContainer, patch)
                  );
                }}
              >
                Reset
              </Button>
              <Label className="pr-5"> </Label>
              <Button
                className="w-15"
                intent={Intent.SUCCESS}
                onClick={() => {
                  let url = `/api/v1/orgs/:orgName/sandboxes/${sandboxId}/patches`;
                  let method: Method = "POST";
                  if (!_.isNil(patchId)) {
                    url = `${url}/${patchId}`;
                    method = "PUT";
                  }
                  const appliedEnvVars = applyModifications(aggregateEnvVars);
                  addEnvVarApi.mutate({
                    method,
                    data: {
                      target: {
                        apiVersion,
                        kind,
                        namespace: baselineNamespace,
                        name: baselineName,
                      },
                      type: "signadot/env",
                      value: toPatch(appliedEnvVars),
                    },
                    url,
                  });
                  setAggregateEnvVars(appliedEnvVars);
                }}
              >
                Apply
              </Button>
            </ControlGroup>
          </Callout>
        </>
      )}
    </>
  );
};

export default EnvVarEditor;
