import type { ReactNode } from "react";
import React from "react";
import { useCookies } from "react-cookie";
import styles from "./Announcement.module.css";
import { CrossIcon } from "../icons";

interface AnnouncementProps {
  id: string;
  icon?: React.ReactNode;
  children: ReactNode;
  canDismiss?: boolean;
  highAlert?: boolean; // Displays red banner when true
}

const Announcement: React.FC<AnnouncementProps> = ({
  id,
  icon,
  children,
  canDismiss = true,
  highAlert = false,
}) => {
  const [cookies, setCookie] = useCookies([id]);
  if (canDismiss && cookies[id] === "false") {
    return null;
  }
  return (
    <div
      id={id}
      className={`${styles.container} ${highAlert ? styles.highAlert : ""}`}
    >
      {icon}
      <div className={styles.content}>{children}</div>
      {canDismiss && (
        <CrossIcon
          eventName={`announcement.${id}.dismiss`}
          className={styles.cross}
          onClick={() => setCookie(id, false)}
        />
      )}
    </div>
  );
};

export default Announcement;
