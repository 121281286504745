import React from "react";
import classNames from "classnames";
import type { FindingDiffOp } from "../../../../../@types/sd/findings";
import { SdHeading5 } from "../../../../../components/theming/SdHeading";
import style from "./DummyDiffViewer.module.css";
import { displayValue } from "../DiffViewer/util";

interface Props {
  title?: string;
  diffs: FindingDiffOp[];
}

const DiffViewer: React.FC<Props> = ({ title, diffs }) => {
  if (diffs.length === 0) {
    return null;
  }

  const renderDiff = (diff: FindingDiffOp) => {
    const { op, path, value: v, oldValue: ov } = diff;
    const value = displayValue(v);
    const oldValue = displayValue(ov);
    const primaryIndicator = diff.isPrimary
      ? style.isPrimary
      : style.notPrimary;

    let diffContent;
    switch (op.toString()) {
      case "replace":
        diffContent = (
          <>
            <div
              className={classNames(
                style.entry,
                style.removed,
                primaryIndicator
              )}
            >
              - {oldValue}
            </div>
            <div
              className={classNames(style.entry, style.added, primaryIndicator)}
            >
              + {value}
            </div>
          </>
        );
        break;
      case "remove":
        diffContent = (
          <div
            className={classNames(style.entry, style.removed, primaryIndicator)}
          >
            - {oldValue}
          </div>
        );
        break;
      case "add":
        diffContent = (
          <div
            className={classNames(style.entry, style.added, primaryIndicator)}
          >
            + {value}
          </div>
        );
        break;
      case "copy":
        diffContent = (
          <div className={classNames(style.entry, primaryIndicator)}>
            {oldValue}
          </div>
        );
        break;
      default:
        diffContent = null;
    }

    return (
      <div key={path}>
        <h3>{path}</h3>
        <div className="ml-2">{diffContent}</div>
      </div>
    );
  };

  return (
    <div style={{ marginTop: "2rem" }}>
      {title && <SdHeading5 lightBackground>{title}</SdHeading5>}
      <div
        style={{
          fontFamily: "monospace",
          border: "1px solid gray",
          marginTop: "1rem",
          padding: "1rem",
        }}
      >
        {diffs.map((diff) => renderDiff(diff))}
      </div>
    </div>
  );
};

export default DiffViewer;
