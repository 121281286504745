import type { ReactNode } from "react";
import React from "react";
import Pluralize from "pluralize";
import styles from "./ExpandableList.module.css";

const DEFAULT_ITEM_COUNT = 5;

interface ExpandableListProps {
  items?: ReactNode[];
  defaultCount: number;
  className?: string;
  showMoreLabel?: string;
  separator?: (index: number, totalCount: number) => string;
}

const ExpandableList: React.FC<ExpandableListProps> = ({
  items,
  defaultCount = DEFAULT_ITEM_COUNT,
  className,
  showMoreLabel,
  separator,
}) => {
  const [displayExpanded, setDisplayExpanded] = React.useState(false);
  if (!items || items.length === 0) {
    return null;
  }
  const len = items.length;

  const renderItems = (start: number, end: number) => {
    const slicedItems = items.slice(start, end);

    return slicedItems.map((item, index) => {
      const actualIndex = start + index;
      return (
        <React.Fragment key={actualIndex}>
          {item}
          {separator
            ? separator(
                actualIndex,
                displayExpanded ? items.length : slicedItems.length
              )
            : ""}
        </React.Fragment>
      );
    });
  };

  return (
    <ul className={`${className || ""} ${styles.container}`}>
      {renderItems(0, Math.min(defaultCount, len))}
      {len > defaultCount && !displayExpanded && (
        <button
          className={styles.showMoreBtn}
          type="button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDisplayExpanded(true);
          }}
        >
          {showMoreLabel ||
            `... and ${len - defaultCount} ${Pluralize(
              "other",
              len - defaultCount
            )}`}
        </button>
      )}
      {displayExpanded && renderItems(defaultCount, len)}
      {len > defaultCount && displayExpanded && (
        <button
          className={styles.showMoreBtn}
          type="button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDisplayExpanded(false);
          }}
        >
          (show less)
        </button>
      )}
    </ul>
  );
};

export default ExpandableList;
