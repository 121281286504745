import useApi from "../hooks/UseApi";
import type { Findings } from "../@types/sd/findings";

export const GET_FINDINGS_API_NAME = "api:get-findings";

const standardQueryConfig = {
  retry: 1,
};

export const useGetFindings = (
  testName: string,
  executionName: string,
  diffContext: number,
  category?: string,
  maxFindings?: number,
  options = {}
) => {
  let url = `/api/v2/orgs/:orgName/tests/${testName}/executions/${executionName}/traffic-diff-findings`;
  if (category) {
    url += `?category=${category}`;
  }
  if (maxFindings) {
    url += `&maxFindings=${maxFindings}`;
  }
  if (diffContext) {
    url += `?diffContext=${diffContext}`;
  }
  return useApi<Findings>(
    [GET_FINDINGS_API_NAME, testName, executionName],
    url,
    {
      ...standardQueryConfig,
      ...options,
    }
  );
};
