import React from "react";
import { Callout } from "@blueprintjs/core";

interface Props {
  text: string;
}

const Error: React.FunctionComponent<Props> = ({ text }) => (
  <Callout title="Error">{text}</Callout>
);

export default Error;
