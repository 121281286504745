import React from "react";
import { Card } from "@blueprintjs/core";
import styles from "./AuthSettings.module.css";
import { SdHeading5 } from "../../../components/theming/SdHeading";

const Wrapper: React.FC = ({ children }) => (
  <Card className={styles.container}>
    <SdHeading5 lightBackground>Single Sign-On (SSO)</SdHeading5>
    <div>{children}</div>
  </Card>
);

export default Wrapper;
