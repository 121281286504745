import React from "react";
import SdButton from "../../../../../../../../components/theming/SdButton";
import BaseEditor from "../../../../../../../../components/Editor";
import style from "./LogPanel.module.css";

type LogPanelProps = {
  isCollapsed: boolean;
  onOpen: () => void;
  logsMessage: string;
};

const LogPanel = ({ isCollapsed, onOpen, logsMessage }: LogPanelProps) => {
  if (isCollapsed) {
    return (
      <div className={style.no_logs}>
        <SdButton onClick={onOpen}>Logs</SdButton>
      </div>
    );
  }

  return (
    <BaseEditor
      language="logs"
      hideLineNumbers
      value={logsMessage}
      options={{ readOnly: true }}
    />
  );
};

export default LogPanel;
