import React from "react";
import _ from "lodash";
import Table from "../../components/Table/Table";
import useApi from "../../hooks/UseApi";
import Loading from "../../components/Loading/Loading";
import Error from "../../components/Error/Error";
import type { Workload } from "../../@types/sd/workload";

interface ContainerStatus {
  state: {
    waiting: {
      reason: string;
    };
  };
}

interface PodStatus {
  conditions: {
    type: string;
    status: string;
    reason: string;
  }[];
  containerStatuses: ContainerStatus[];
  phase: string;
}

interface Pod {
  metadata: {
    name: string;
  };
  status: PodStatus;
}

interface FormattedPod extends Record<string, React.ReactNode> {
  podName: string;
  phase: string;
  reason: string;
}

interface Props {
  sandboxId: string;
  deployInfo: Workload;
}

interface PodsResponse {
  pods: Pod[];
}

const columns = [
  { Header: "Name", accessor: "podName" },
  { Header: "Status", accessor: "phase" },
  { Header: "Reason", accessor: "reason" },
];

const usePodsApi = (sandboxId: string, namespace: string, name: string) => {
  const apiName = ["pods"];
  if (sandboxId) {
    apiName.push(sandboxId);
  }
  if (namespace) {
    apiName.push(namespace);
  }
  if (name) {
    apiName.push(name);
  }
  return useApi<PodsResponse>(
    apiName,
    `/api/v1/orgs/:orgName/pods?${new URLSearchParams({
      workspaceID: sandboxId,
      namespace: namespace,
      name: name,
      onlyForks: "true",
    }).toString()}`,
    { retry: 1, refetchInterval: 5000 }
  );
};

const findContainerReason = (cStatuses?: ContainerStatus[]) => {
  if (!Array.isArray(cStatuses)) {
    return "none";
  }
  for (let i = 0; i < cStatuses.length; i++) {
    const { state } = cStatuses[i];
    if (state.waiting) {
      return state.waiting.reason;
    }
  }
  return "none";
};

const computeReason = (podStatus: PodStatus) => {
  if (!podStatus) {
    return "none";
  }
  const conds = podStatus.conditions || [];
  for (let i = 0; i < conds.length; i++) {
    const cond = conds[i];
    if (cond.type === "Ready") {
      if (cond.status === "True") {
        return "ContainersReady";
      }
      if (cond.reason === "ContainersNotReady") {
        return findContainerReason(podStatus.containerStatuses);
      }
    } else if (cond.status !== "True") {
      // cannot be ready in this case
      return cond.reason;
    }
  }
  return "none";
};

const formatKubernetesPod = (k8sPod: Pod): FormattedPod => {
  const podName = _.get(k8sPod, ["metadata", "name"]);
  const podStatus = _.get(k8sPod, ["status"]);
  const podPhase = _.get(podStatus, ["phase"]);
  return {
    podName: podName,
    phase: podPhase,
    reason: computeReason(podStatus),
  };
};

const genPodsTable = (sandboxId: string, namespace: string, name: string) => {
  const api = usePodsApi(sandboxId, namespace, name);
  const { data, error, isError, isLoading, isIdle } = api;
  if (isError) {
    return <Error text={`Error fetching pods: ${error}`} />;
  }
  if (isLoading || isIdle) {
    return <Loading />;
  }
  return (
    <Table<FormattedPod>
      columns={columns}
      data={data?.pods?.map(formatKubernetesPod) ?? []}
    />
  );
};

const PodsPanel: React.FunctionComponent<Props> = ({
  sandboxId,
  deployInfo,
}) => {
  const namespace = _.get(deployInfo, ["fork", "metadata", "namespace"]);
  const name = _.get(deployInfo, ["fork", "metadata", "name"]);
  return genPodsTable(sandboxId, namespace, name);
};

export default PodsPanel;
