import React, { useMemo, useState } from "react";
import { Card, Dialog, Elevation, InputGroup } from "@blueprintjs/core";
import SdButton from "../../../../../../../../components/theming/SdButton";
import { useGetTests } from "../../../../../../../../api/TestsApi";
import ValidationError from "../ValidationError/ValidationError";

type TestNameDialogProps = {
  onConfirm: (testName: string) => void;
  isOpen: boolean;
  onCancel: () => void;
};

const TestNameDialog = ({
  onConfirm,
  isOpen,
  onCancel,
}: TestNameDialogProps) => {
  const [testName, setTestName] = useState<string>("");
  const [error, setError] = useState<string>();
  const tests = useGetTests();

  const testsNames = useMemo(() => {
    if (!tests.data || tests.data.length === 0) return [];

    return tests.data.map((t) => t.name.toLowerCase());
  }, [tests.data]);

  const handleSave = () => {
    if (testsNames.includes(testName.toLowerCase())) {
      const test = tests?.data?.find(
        (t) => t.name.toLowerCase() === testName.toLowerCase()
      );

      // This case should never happen
      if (!test) {
        setError("Smart Test name must be unique");
        return;
      }

      setError(
        `Existing Smart Test named ${test.name} found, please choose a different name for your Smart Test`
      );
      return;
    }

    onConfirm(testName);
  };

  return (
    <Dialog
      style={{ height: "100%" }}
      icon="info-sign"
      title="Save Smart Test"
      isOpen={isOpen}
      onClose={() => {
        setTestName("");
        setError(undefined);
        onCancel();
      }}
    >
      <Card
        style={{ marginBottom: "-20px" }}
        interactive
        elevation={Elevation.TWO}
      >
        <div className="pt-2 pb-2">Choose a name for your Smart Test</div>
        <InputGroup
          id="name"
          value={testName}
          aria-label="enter-cluster-name"
          placeholder="My Smart Test"
          onChange={(e) => {
            setTestName(e.target.value);
            setError(undefined);
          }}
          style={{
            width: "18em",
            marginBottom: "1em",
          }}
        />
        <ValidationError errorMessage={error} />
        <SdButton onClick={handleSave} disabled={error !== undefined}>
          Save
        </SdButton>
      </Card>
    </Dialog>
  );
};

export default TestNameDialog;
