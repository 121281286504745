import React from "react";
import { Card, Icon } from "@blueprintjs/core";
import { SiGithub } from "react-icons/si";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import styles from "./VcsIntegration.module.css";
import { SdHeading5 } from "../../../components/theming/SdHeading";
import SdButton from "../../../components/theming/SdButton";
import VcsIntegrationModal from "./modal";
import { useGetIntegrationsApi } from "./VcsIntegrationApi";
import type { Integration } from "../../../@types/sd/interation";

const VcsIntegration: React.FunctionComponent = () => {
  const { data, error } = useGetIntegrationsApi();
  const [isModalOpen, setModalOpen] = React.useState(false);
  return (
    <>
      <Card className={styles.vcsIntegrationContainer}>
        <SdHeading5 lightBackground>Github Integration</SdHeading5>
        <p className={styles.info}>
          Github integration enables linking lifecycle of Pull Requests to
          sandboxes.
        </p>
        <div>{error}</div>
        {data?.integrations?.map((integration: Integration) => (
          <ul key={integration.id} className={styles.list}>
            {integration.github?.githubOrg && (
              <li className={styles.listItem}>
                <a
                  href={integration.github.githubInstallationPageURL || "#"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SiGithub />
                  {integration.github.githubOrg}
                  {integration.github.appInstalled ? null : (
                    <Popover2>
                      <Tooltip2
                        content="Signadot Github app not installed"
                        intent="none"
                        placement="top"
                        hoverOpenDelay={250}
                      >
                        <Icon icon="warning-sign" />
                      </Tooltip2>
                    </Popover2>
                  )}
                </a>
              </li>
            )}
          </ul>
        ))}
        {Array.isArray(data?.integrations) &&
        data!.integrations!.length === 0 ? (
          <SdButton
            icon="plus"
            className={styles.button}
            onClick={() => setModalOpen(true)}
          >
            Set up a new integration
          </SdButton>
        ) : null}
      </Card>
      <VcsIntegrationModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
      />
    </>
  );
};

export default VcsIntegration;
