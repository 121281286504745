import type { SavedRequest } from "./types";

// eslint-disable-next-line import/prefer-default-export
export const groupByHost = (
  savedRequests?: SavedRequest[]
): Map<string, SavedRequest[]> => {
  const hostMap = new Map<string, SavedRequest[]>();

  savedRequests?.forEach((savedRequest) => {
    if (savedRequest.request) {
      const host = savedRequest.request.host;
      const existingRequests = hostMap.get(host) || [];
      hostMap.set(host, [...existingRequests, savedRequest]);
    }
  });

  return hostMap;
};
