import React from "react";
import LogsPanel from "../../../../../../components/Logs";
import styles from "./logs.module.css";
import type { Job } from "../../../../../../@types/sd/job";
import { STDERR, STDOUT } from "./constants";
import useGetJobLogs from "../../../../../../hooks/UseGetJobLogs";

interface Props {
  job: Job;
}

const Logs: React.FC<Props> = ({ job }) => {
  const { logs, setSelectedType } = useGetJobLogs({ job });

  return (
    <LogsPanel
      logPanelOpen
      logs={logs}
      header={
        <div>
          <span className={styles.selectLogType}>Logs type:</span>{" "}
          <select
            className={styles.select}
            onChange={(event) => setSelectedType(event.target.value)}
          >
            <option>{STDOUT}</option>
            <option>{STDERR}</option>
          </select>
        </div>
      }
    />
  );
};

export default Logs;
