import type React from "react";
import type InfoCapsule from "../../../../components/theming/InfoCapsule";
import type { EventName } from "../../../../Constants";
import {
  ClickGetStartedConceptsDocs,
  ClickGetStartedConceptsVideo,
  ClickGetStartedLocalDevVideo,
} from "../../../../Constants";

export interface ResourceProps {
  url: string;
  title: string;
  eventName: EventName;
  type: React.ComponentProps<typeof InfoCapsule>["type"];
}

const resources: ResourceProps[] = [
  {
    url: "https://www.youtube.com/watch?v=HXUmPJTdwm0",
    title: "Introduction to Signadot",
    eventName: ClickGetStartedConceptsVideo,
    type: "video",
  },
  {
    url: "https://www.youtube.com/watch?v=m34WC-s6gDo",
    title: "Local development",
    eventName: ClickGetStartedLocalDevVideo,
    type: "video",
  },
  {
    url: "https://www.signadot.com/docs/guides/use-cases/run-automated-tests",
    title: "Start with Automated Tests",
    eventName: ClickGetStartedConceptsDocs,
    type: "docs",
  },
];

export default resources;
