import { Chart } from "react-chartjs-2";
import React, { useMemo } from "react";
import moment from "moment";
import type { AnalyticsApi } from "../../api/AnalyticsApi";
import Loading from "../../components/Loading/Loading";
import Error from "../../components/Error/Error";
import SdTheme from "../../styles/theme";

interface Props {
  analyticsApi: AnalyticsApi;
  selectedSplitBy: string;
}

const AnalyticsChart: React.FunctionComponent<Props> = ({
  analyticsApi,
  selectedSplitBy,
}) => {
  const chartLabels = useMemo(() => {
    if (!analyticsApi.data) {
      return [];
    }
    const ret = [];
    for (let i = 0; i < analyticsApi.data.groups.length; i++) {
      const group = analyticsApi.data.groups[i];
      for (let j = 0; j < group.entries.length; j++) {
        const e = group.entries[j];
        const start = moment(e.start).utc();
        if (selectedSplitBy === "Hour") {
          ret.push(start.format("yyyy-MM-DD HH:mm"));
        } else {
          ret.push(start.format("yyyy-MM-DD"));
        }
      }
    }
    return ret;
  }, [analyticsApi.data, selectedSplitBy]);

  const eventTypes = useMemo(() => {
    if (!analyticsApi.data) {
      return [];
    }
    const typesSet = new Set<string>();
    analyticsApi.data.groups.forEach((group) => {
      group.entries.forEach((entry) => {
        entry.counts.forEach((count) => {
          typesSet.add(count.eventType);
        });
      });
    });
    return Array.from(typesSet);
  }, [analyticsApi.data]);

  const colors = useMemo(() => {
    const colorMapping: { [key: string]: string } = {
      "create-sandbox": SdTheme.Chart.color1,
      "update-sandbox": SdTheme.Chart.color2,
    };
    return colorMapping;
  }, []);

  const datasets = useMemo(() => {
    if (!analyticsApi.data) {
      return [];
    }
    return eventTypes.map((eventType) => {
      const data = [];
      for (let i = 0; i < analyticsApi.data.groups.length; i++) {
        const group = analyticsApi.data.groups[i];
        for (let j = 0; j < group.entries.length; j++) {
          const e = group.entries[j];
          const count =
            e.counts.find((c) => c.eventType === eventType)?.count || 0;
          data.push(count);
        }
      }
      return {
        label: eventType,
        data,
        backgroundColor: colors[eventType] || "gray",
      };
    });
  }, [analyticsApi.data, eventTypes, colors]);

  return (
    <>
      {analyticsApi.isLoading && <Loading />}
      {analyticsApi.error !== undefined && <Error text={analyticsApi.error} />}
      {analyticsApi.data && datasets.length === 0 && (
        <div>No data available.</div>
      )}
      {analyticsApi.data && datasets.length > 0 && (
        <Chart
          style={{ maxHeight: "35em" }}
          type="bar"
          data={{
            labels: chartLabels,
            datasets,
          }}
          options={{
            datasets: {
              bar: {
                maxBarThickness: 35,
              },
            },
            skipNull: true,
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
                ticks: {
                  stepSize: 1,
                },
              },
            },
            plugins: {
              legend: {
                display: true,
              },
            },
          }}
        />
      )}
    </>
  );
};

export default AnalyticsChart;
