import type { Monaco } from "@monaco-editor/react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { editor } from "monaco-editor";
import pythonImpl from "./python";
import logsImpl from "./logs";
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor;

export interface ILanguageDefinition {
  beforeMount?(monaco: Monaco): void;
  onMount?(e: IStandaloneCodeEditor): void;
  onMonacoAvailable?(monaco: Monaco): void;
}

export const loadLanguageImplementations = (
  targetLanguage: string,
): ILanguageDefinition => {
  const impls: Array<ILanguageDefinition> = [];

  // TODO: Only load if the language is not loaded and not use the target language
  // eslint-disable-next-line default-case
  switch (targetLanguage) {
    case "python":
      impls.push(pythonImpl);
      break;
    case "logs":
      impls.push(logsImpl);
  }

  return {
    beforeMount(monaco: Monaco) {
      impls.forEach((imp) => imp.beforeMount?.(monaco));
    },
    onMount(e: IStandaloneCodeEditor) {
      impls.forEach((imp) => imp.onMount?.(e));
    },
    onMonacoAvailable(monaco: Monaco) {
      impls.forEach((imp) => imp.onMonacoAvailable?.(monaco));
    },
  };
};
