import React, { useEffect, useMemo, useState } from "react";

import { Dialog, HTMLSelect, MenuItem } from "@blueprintjs/core";
import { TbCircleLetterD, TbCircleLetterR } from "react-icons/tb";
import type { ItemRenderer } from "@blueprintjs/select";
import { Suggest2 } from "@blueprintjs/select";
import type { TestTrigger } from "../../../../../../../@types/sd/testspec";
import ClusterSelector from "../../../ClusterSelector";
import SdButton from "../../../../../../../components/theming/SdButton";
import { ValidationError } from "../../TestSpecEditor/components";
import styles from "./TriggerEditor.module.css";
import {
  filterWorkloadItems,
  getFirstN,
  pathExists,
  validateTrigger,
} from "./utils";
import { useGetDeploymentsByClusterName } from "../../../../../../../api/ClustersApi";
import type { WorkloadKind } from "../../../../../../../@types/sd/workload";
import type { PLAIN_WORKLOAD } from "./constant";
import { MAX_RENDERED_WORKLOADS, INITIAL_TRIGGER } from "./constant";
import NewWorkloadOption from "./NewWorkloadOption";

const renderWorkloadItem: ItemRenderer<PLAIN_WORKLOAD> = (
  workloadItem,
  { handleClick, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  if (!workloadItem) return null;

  return (
    <MenuItem
      icon={
        workloadItem.kind === "Deployment" ? (
          <TbCircleLetterD color="red" size={20} />
        ) : (
          <TbCircleLetterR color="green" size={20} />
        )
      }
      key={workloadItem.name + workloadItem.namespace}
      active={modifiers.active}
      disabled={modifiers.disabled}
      label=""
      onClick={handleClick}
      text={`${workloadItem.namespace}/${workloadItem.name}`}
    />
  );
};

type CreateTriggerProps = {
  isOpen: boolean;
  trigger?: TestTrigger;
  onSave: (t: TestTrigger) => void;
  onCancel: () => void;
};

const TriggerEditor = ({
  trigger: baseTrigger,
  onSave,
  onCancel,
  isOpen,
}: CreateTriggerProps) => {
  const [trigger, setTrigger] = useState<TestTrigger>(
    baseTrigger || INITIAL_TRIGGER
  );

  const [error, setError] = useState<string>();

  const [selectedKind, setSelectedKind] = useState<WorkloadKind>();

  const deployments = useGetDeploymentsByClusterName(
    trigger.executionTemplate.cluster,
    { enabled: trigger.executionTemplate.cluster.length > 0 }
  );

  const workloadSuggestions: PLAIN_WORKLOAD[] = useMemo(() => {
    if (!deployments.data) return [];

    const data = deployments.data.deployments;

    return data
      .map((d) => ({
        kind: d.kind,
        name: d.baseline.metadata.name,
        namespace: d.baseline.metadata.namespace,
      }))
      .filter(
        (value, index, array) =>
          // Ensure only unique paths are included by checking up to the current position
          !pathExists(array, value, index)
      );
  }, [deployments.data]);

  useEffect(() => {
    setTrigger(baseTrigger || INITIAL_TRIGGER);
  }, [baseTrigger]);

  useEffect(() => {
    setError(undefined);
  }, [baseTrigger, trigger]);

  useEffect(() => {
    if (!selectedKind) return;

    setTrigger((prev) => ({
      ...prev,
      sandboxOf: {
        ...prev.sandboxOf,
        kind: selectedKind,
      },
    }));
  }, [selectedKind]);

  const handleSave = () => {
    const validationError = validateTrigger(trigger);

    if (validationError) {
      setError(validationError);
      return;
    }

    onSave(trigger);
  };

  const getWorkloadPath = (): PLAIN_WORKLOAD =>
    ({
      kind: trigger.sandboxOf.kind,
      name: trigger.sandboxOf.name,
      namespace: trigger.sandboxOf.namespace,
    } as PLAIN_WORKLOAD);

  return (
    <Dialog
      style={{ height: "100%" }}
      icon="info-sign"
      title="New trigger"
      isOpen={isOpen}
      onClose={() => {
        onCancel();
      }}
    >
      <div className={styles.wrapper}>
        <p className={styles.triggerInfo}>
          The trigger specifies a rule for when a test is run. This test will
          run when you create a sandbox containing a workload matching the criteria below.
        </p>
        <div className={styles.input_form}>
          <span>Cluster</span>
          <ClusterSelector
            value={trigger.executionTemplate.cluster}
            onChange={(cluster) => {
              setTrigger((prev) => ({
                ...prev,
                executionTemplate: {
                  ...prev.executionTemplate,
                  cluster: cluster,
                },
              }));
            }}
          />
        </div>

        <div className={styles.input_form}>
          <span>Kind</span>
          <HTMLSelect
            // disabled={selectedKind === undefined}
            disabled
            value={trigger.sandboxOf.kind}
            options={["Deployment", "Rollout"]}
            onChange={(event) => {
              setTrigger((prev) => ({
                ...prev,
                sandboxOf: {
                  ...prev.sandboxOf,
                  kind: event.target.value,
                },
              }));
            }}
          />
        </div>

        <div className={styles.input_form}>
          <span>Namespace/Name</span>
          <Suggest2<PLAIN_WORKLOAD>
            items={workloadSuggestions}
            itemListPredicate={(query, items) =>
              getFirstN(query, items, MAX_RENDERED_WORKLOADS)
            }
            itemPredicate={filterWorkloadItems}
            createNewItemPosition="last"
            createNewItemFromQuery={(value) => {
              const [namespace, workloadName] = value.split("/");

              return {
                kind: "Deployment",
                namespace: namespace,
                name: workloadName,
              };
            }}
            createNewItemRenderer={(query, _active, handleClick) => (
              <NewWorkloadOption
                query={query}
                beforeClick={() => {
                  setSelectedKind(undefined);
                }}
                handleClick={(event, kind) => {
                  setSelectedKind(kind);
                  handleClick(event);
                }}
              />
            )}
            itemRenderer={renderWorkloadItem}
            inputValueRenderer={(workloadItem) => {
              if (!workloadItem) return "";

              if (
                !workloadItem?.name?.length ||
                !workloadItem?.namespace?.length
              )
                return "";

              return `${workloadItem.namespace}/${workloadItem.name}`;
            }}
            onItemSelect={(path) => {
              setTrigger((prev) => ({
                ...prev,
                sandboxOf: {
                  ...prev.sandboxOf,
                  namespace: path.namespace,
                  name: path.name,
                  kind: path.kind,
                },
              }));
            }}
            selectedItem={getWorkloadPath()}
          />
        </div>

        <div className={styles.actions}>
          <SdButton disabled={false} onClick={handleSave}>
            {baseTrigger ? "Update trigger" : "Create trigger"}
          </SdButton>
          <SdButton onClick={onCancel} minimal>
            Cancel
          </SdButton>
        </div>
      </div>
      <ValidationError errorMessage={error} />
    </Dialog>
  );
};

export default TriggerEditor;
