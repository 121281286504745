import React from "react";
import { Tag } from "@blueprintjs/core";
import DetailItem from "../../../../../../components/DetailItem";
import type { RunnerGroup } from "../../../../../../@types/sd/runnergroup";
import { getTimeAgoElement } from "../../../../../../util/Util";
import styles from "./Overview.module.css";
import PodsStatus from "./PodsStatus";

interface Props {
  runnerGroup: RunnerGroup;
}

const Overview: React.FC<Props> = ({ runnerGroup }) => {
  const pods = runnerGroup.status?.pods;

  return (
    <div>
      <DetailItem title="Cluster" value={runnerGroup.spec.cluster} />
      {runnerGroup.spec.labels && (
        <DetailItem
          title="Labels"
          value={
            <div className={styles.tags}>
              {Object.keys(runnerGroup.spec.labels).map((key) => (
                <Tag key={key} className={styles.tag}>
                  {key}: {runnerGroup.spec.labels![key]}
                </Tag>
              ))}
            </div>
          }
        />
      )}
      <DetailItem
        title="Created"
        value={getTimeAgoElement(runnerGroup.createdAt)}
      />
      {runnerGroup.updatedAt && (
        <DetailItem
          title="Updated"
          value={getTimeAgoElement(runnerGroup.updatedAt)}
        />
      )}
      {pods && <PodsStatus {...pods} />}
    </div>
  );
};

export default Overview;
