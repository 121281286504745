import React from "react";
import yaml from "js-yaml";
import type { SandboxInputSpec, SandboxV2 } from "../../../@types/sd/sandboxv2";
import type { SandboxFork } from "../../../@types/sd/sandbox";

type ViewSpecResult = [openInEditorURL?: string];

export const useSandboxSpecYAML = (sandbox?: SandboxV2): string => {
  const spec: SandboxInputSpec | undefined = React.useMemo(() => {
    if (!sandbox) {
      return undefined;
    }

    // The received spec includes a number of additional properties such as endpoints, status etc. at the top
    // level that the user hadn't provided. So, create an object by plucking out only the properties that
    // were originally provided by the user.
    const result = {
      name: sandbox.name,
      spec: sandbox.spec,
    };

    // Delete .spec.endpoints
    if (result && result.spec) {
      delete result.spec.endpoints;
    }

    // Delete .spec.forks[].endpoints
    result.spec?.forks?.forEach((fork: SandboxFork) => {
      delete fork.endpoints;
    });

    return result;
  }, [sandbox]);
  return yaml.dump(spec);
};

export const useOpenInEditorURL = (spec?: string): ViewSpecResult => {
  const [openInEditorURL, setOpenInEditorURL] = React.useState<string>();

  const encodedSpec = React.useMemo(() => {
    const base64Content = window?.btoa(spec || "");
    return encodeURIComponent(base64Content);
  }, [spec]);

  React.useLayoutEffect(() => {
    setOpenInEditorURL(`/sandboxes/create?encodedData=${encodedSpec}`);
  }, [encodedSpec]);

  return [openInEditorURL];
};

export default useOpenInEditorURL;
