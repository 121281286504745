// eslint-disable-next-line import/prefer-default-export
export const TTL_POLICIES = {
  NO_MATCHED_SANDBOXES: {
    value: "noMatchedSandboxes",
    label: "No Matched Sandboxes",
  },
  CREATED_AT: {
    value: "createdAt",
    label: "Created At",
  },
  UPDATED_AT: {
    value: "updatedAt",
    label: "Updated At",
  },
};
