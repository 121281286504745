import type { Dispatch, SetStateAction } from "react";
import React from "react";
import yaml from "js-yaml";
import { Intent, OverlayToaster } from "@blueprintjs/core";
import styles from "./CreateSandboxAction.module.css";
import SdButton from "../../../components/theming/SdButton";
import { useCreateSandboxApi } from "../../../api/SandboxesApi";
import type { SandboxV2 } from "../../../@types/sd/sandboxv2";
import { placeholderRegex } from "../Constants";
import { CreateSandboxPageApplySandbox } from "../../../Constants";

interface CreateSandboxActionProps {
  spec?: string;
  onSandboxCreated: Dispatch<SetStateAction<SandboxV2 | undefined>>;
}

const toaster = OverlayToaster.create();

const verifyNoPlaceholders = (text: string) => {
  const matches = placeholderRegex.exec(text);
  return matches == null;
};

const CreateSandboxAction: React.FC<CreateSandboxActionProps> = ({
  spec,
  onSandboxCreated,
}) => {
  const [createSandbox, createSandboxApi] = useCreateSandboxApi();
  const handleCreateSandbox = () => {
    if (verifyNoPlaceholders(spec!)) {
      try {
        const req = yaml.load(spec!) as SandboxV2;
        createSandbox(req);
      } catch (e) {
        toaster.show({
          message: `error parsing yaml`,
          intent: Intent.DANGER,
        });
      }
    } else {
      toaster.show({
        message: "spec has parameters without values",
        intent: Intent.DANGER,
      });
    }
  };

  if (createSandboxApi.error) {
    toaster.show({
      message: createSandboxApi.error.response.data.error,
      intent: Intent.DANGER,
    });
    createSandboxApi.reset();
  } else if (createSandboxApi.isSuccess) {
    toaster.show({
      message: "Sandbox created!",
      intent: Intent.SUCCESS,
    });
    createSandboxApi.reset();
    onSandboxCreated(createSandboxApi.data?.data);
  }
  return (
    <div className={styles.container}>
      <SdButton
        eventName={CreateSandboxPageApplySandbox}
        onClick={handleCreateSandbox}
        disabled={!spec || createSandboxApi.isLoading}
      >
        Apply Sandbox
      </SdButton>
    </div>
  );
};

export default CreateSandboxAction;
