import React from "react";

import { SdHeading1 } from "../../components/theming/SdHeading";

const NotFound = () => (
  <>
    <SdHeading1 small lightBackground>
      Page not found
    </SdHeading1>
    <br />
    <div>
      <p>
        The page you are trying to visit does not exist. This may also be for
        one of one of the following reasons:
      </p>
      <ul>
        <li>The resource was removed</li>
        <li>You don&apos;t have permissions to access this resource</li>
      </ul>
    </div>
  </>
);

export default NotFound;
