import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Tag } from "@blueprintjs/core";
import { TbServerBolt } from "react-icons/tb";
import { SdHeading1 } from "../../../../components/theming/SdHeading";
import { useGetRunnerGroups } from "../../../../api/RunnerGroupApi";
import styles from "./RunnerGroups.module.css";
import { filterRunnerGroups } from "./utils";
import SdInput from "../../../../components/theming/SdInput";
import SdButton from "../../../../components/theming/SdButton";
import CreateFirst from "../../../../components/CreateFirst";

const RunnerGroups: React.FC = () => {
  const [filterText, setFilterText] = React.useState<string>("");
  const { isSuccess, data } = useGetRunnerGroups();
  const filteredList = React.useMemo(
    () => filterRunnerGroups(data || [], filterText),
    [data, isSuccess, filterText]
  );
  const navigate = useNavigate();

  if (!isSuccess) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SdHeading1 small lightBackground>
          Job Runner Groups
        </SdHeading1>
      </div>
      {filteredList.length !== 0 ? (
        <>
          <div>
            <SdInput
              name="search-field"
              type="text"
              placeholder="Type to search ..."
              className="bp4-input bp4-fill bp4-medium mb-4 mt-10"
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
          <div className={styles.catalog}>
            {filteredList
              .filter((e) => !e.deletedAt)
              .map((runnerGroup) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <Card
                  key={runnerGroup.name}
                  className={styles.entry}
                  onClick={() =>
                    navigate(`/testing/job-runner-groups/${runnerGroup.name}`)
                  }
                >
                  <div className={styles.title}>{runnerGroup.name}</div>
                  {runnerGroup.spec.labels && (
                    <div className={styles.tags}>
                      {Object.keys(runnerGroup.spec.labels).map((key) => (
                        <Tag key={key} className={styles.tag}>
                          {key}: {runnerGroup.spec.labels![key]}
                        </Tag>
                      ))}
                    </div>
                  )}
                </Card>
              ))}
          </div>
          <SdButton
            onClick={() => {
              navigate(`/testing/job-runner-groups/editor`);
            }}
            icon="plus"
          >
            Create
          </SdButton>
        </>
      ) : (
        <CreateFirst
          icon={<TbServerBolt size={80} />}
          message="No Job Runner Groups found"
          buttonLabel="Create Job Runner Group"
          onClick={() => navigate("/testing/job-runner-groups/editor")}
        />
      )}
    </div>
  );
};

export default RunnerGroups;
