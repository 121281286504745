import React from "react";
import { Intent, Spinner, SpinnerSize } from "@blueprintjs/core";

const Loading = () => (
  <Spinner
    className="flex h-screen"
    intent={Intent.PRIMARY}
    size={SpinnerSize.STANDARD}
  />
);

export default Loading;
