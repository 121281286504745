import type { UseQueryOptions } from "react-query";
import type { Cluster, ClusterDefaults } from "../@types/sd/cluster";
import { useBasicApi } from "./Common";
import type { ApiError } from "../hooks/UseApi";
import useApi from "../hooks/UseApi";
import useApiMutation from "../hooks/UseApiMutation";

export const CLUSTERS_API_NAME = "api:all_clusters";
export const GET_CLUSTER = "api:cluster";
export const GET_CLUSTER_DEFAULTS = "api:cluster_defaults";
export const DELETE_CLUSTER_DEFAULT = "api_mutation:remove_cluster_default";
export const ADD_CLUSTER_DEFAULT = "api_mutation:add_cluster_default";
export const GET_CLUSTER_DEPLOYMENTS = "api:cluster_deployments";

interface AllClustersResponse {
  clusters: Cluster[];
}

// This is not moved to all te types since is just a smaller version of the full type
type Workload = {
  kind: "Deployment" | "Rollout";
  clusterName: string;
  baseline: {
    metadata: {
      name: string;
      namespace: string;
    };
  };
};

const standardQueryConfig = {
  retry: 1,
  refetchInterval: 30000,
};

export const useClustersApi = () =>
  useBasicApi<Cluster[], "clusters", AllClustersResponse>(
    CLUSTERS_API_NAME,
    "/api/v1/orgs/:orgName/clusters",
    "clusters"
  );
export const useGetClusterApi = (name: string) =>
  useApi<Cluster>(GET_CLUSTER, `/api/v2/orgs/:orgName/clusters/${name}`, {
    ...standardQueryConfig,
  });

export const useGetClusterDefaultsApi = (name: string) =>
  useApi<ClusterDefaults>(
    [GET_CLUSTER_DEFAULTS, name],
    `/api/v2/orgs/:orgName/clusters/${name}/defaults`,
    {
      ...standardQueryConfig,
    }
  );

export const useSaveClusterDefaultApi = (
  onSaved: () => void,
  onError: (error: ApiError) => void
) =>
  useApiMutation<unknown, Omit<ClusterDefaults, "cluster">>(
    ADD_CLUSTER_DEFAULT,
    "PUT",
    [GET_CLUSTER_DEFAULTS],
    onSaved,
    onError
  );

export const useRemoveClusterDefaultApi = (
  onSaved: () => void = () => {},
  onError: (error: ApiError) => void = () => {}
) =>
  useApiMutation<unknown, unknown>(
    DELETE_CLUSTER_DEFAULT,
    "DELETE",
    [GET_CLUSTER_DEFAULTS],
    onSaved,
    onError
  );

export const useGetDeploymentsByClusterName = (
  clusterName: string,
  options: UseQueryOptions = {}
) =>
  useApi<{ deployments: Workload[] }>(
    [GET_CLUSTER_DEPLOYMENTS, clusterName],
    `/api/v1/orgs/:orgName/deployments?clusterName=${clusterName}`,
    { ...standardQueryConfig, ...options }
  );
