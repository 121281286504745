import React from "react";
import styles from "./CapturesInfo.module.css";
import type { TestExecution } from "../../../../../../@types/sd/testexecutions";
import CategoryIcon from "../../../../TestExecutionDetail/common/CategoryIcon/CategoryIcon";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { DiffCat } from "../../../../../../@types/sd/findings.d.ts";
import { getCountOfSummary } from "../../../../TestExecutionDetail/common/utils";

interface Props {
  execution: TestExecution;
}

const CapturesInfo: React.FC<Props> = ({ execution }) => {
  const trafficDiff = execution.results?.trafficDiff;

  if (!trafficDiff) {
    // Adding blank spaces to keep the same height
    return (
      <div className={styles.items}>
        <div style={{ height: 24 }} />
        <div style={{ height: 24 }} />
        <div style={{ height: 24 }} />
      </div>
    );
  }

  const { red, yellow, green } = trafficDiff;

  const getFindingsText = (count: number, category: DiffCat) => {
    const suffix = count === 1 ? "" : "s";

    return (
      <div>
        <CategoryIcon category={category} addOpacity={count === 0} />
        {count} finding{suffix}
      </div>
    );
  };

  return (
    <div className={styles.items}>
      {getFindingsText(getCountOfSummary(red), DiffCat.Red)}
      {getFindingsText(getCountOfSummary(yellow), DiffCat.Yellow)}
      {getFindingsText(getCountOfSummary(green), DiffCat.Green)}
    </div>
  );
};

export default CapturesInfo;
