import React from "react";
import { Button, Spinner } from "@blueprintjs/core";
import SdButton from "../../../../components/theming/SdButton";
import { ENTERPRISE, FREE, INDEXED_PLANS, TEAM } from "../../Constants";
import type { BillingData } from "../../../../@types/sd/billing";
import OpenPortalButton from "./OpenPortalButton";
import SdColors from "../../../../styles/colors";
import EnterpriseButton from "./EnterpriseButton";

interface PlanButtonProps {
  targetPlanType: string;
  orgName: string;
  billingData: BillingData | null;
  setCheckoutOpen: (isOpen: boolean) => void;
}

const PlanButton: React.FunctionComponent<PlanButtonProps> = React.memo(
  ({ targetPlanType, orgName, billingData, setCheckoutOpen }) => {
    if (billingData === null) {
      return (
        <div
          className="p-2"
          style={{
            backgroundColor: SdColors.NEUTRAL200,
            color: SdColors.NEUTRAL500,
            height: 34,
            borderRadius: 10,
          }}
        >
          <Spinner />
        </div>
      );
    }

    const { planType } = billingData.tier;
    if (targetPlanType === ENTERPRISE || planType === ENTERPRISE) {
      return (
        <EnterpriseButton
          orgName={orgName}
          billingData={billingData}
          targetPlanType={targetPlanType}
        />
      );
    }
    if (
      planType === TEAM &&
      billingData.tier.trial === true &&
      targetPlanType === FREE
    ) {
      return null;
    }
    if (
      INDEXED_PLANS.indexOf(targetPlanType) === INDEXED_PLANS.indexOf(planType)
    ) {
      if (billingData.tier.planType === TEAM && billingData.tier.trial) {
        return (
          <SdButton
            onClick={() => {
              setCheckoutOpen(true);
            }}
          >
            Upgrade from Free Trial
          </SdButton>
        );
      }
      return <Button disabled>Current Plan</Button>;
    }
    if (
      INDEXED_PLANS.indexOf(targetPlanType) < INDEXED_PLANS.indexOf(planType)
    ) {
      return <OpenPortalButton text="Downgrade" />;
    }

    return (
      <SdButton
        onClick={() => {
          setCheckoutOpen(true);
        }}
      >
        Upgrade
      </SdButton>
    );
  }
);
PlanButton.displayName = "PlanButton";

export default PlanButton;
