import { useEffect, useState } from "react";
import type {
  ExecResponseApiResponse,
  RequestExecResponseData,
} from "../SavedRequestCatalog/types";

interface DiffData {
  baselineData: RequestExecResponseData;
  sandboxData: RequestExecResponseData;
}

interface ComparisonResponse {
  isLoading: boolean;
  error?: string;
  data?: DiffData;
  submit: () => void;
  reset: () => void;
}

const getFormattedJSON = (str = "") => JSON.stringify(JSON.parse(str), null, 2);

const getDiffData = (
  baselineData: RequestExecResponseData,
  sandboxData: RequestExecResponseData
): DiffData => {
  try {
    baselineData.body = getFormattedJSON(baselineData.body);
  } catch (e) {
    // ignore
  }
  try {
    sandboxData.body = getFormattedJSON(sandboxData.body);
  } catch (e) {
    // ignore
  }
  return {
    baselineData,
    sandboxData,
  } as DiffData;
};

// eslint-disable-next-line import/prefer-default-export
export const useCompareResponses = (
  sandbox: string,
  cluster: string,
  baselineRequest: ExecResponseApiResponse,
  sandboxRequest: ExecResponseApiResponse
): ComparisonResponse => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [data, setData] = useState<DiffData | undefined>();

  // Reset function to clear previous results and errors
  const reset = () => {
    baselineRequest.reset();
    sandboxRequest.reset();
    setIsLoading(false);
    setError(undefined);
    setData(undefined);
  };

  // Submit function to trigger comparison
  const submit = () => {
    setIsLoading(true);
    setError(undefined);
    setData(undefined);

    sandboxRequest.sendToSandbox(sandbox);
    baselineRequest.sendToBaseline(cluster);
  };

  // Effect hook to handle the completion of both requests
  useEffect(() => {
    if (isLoading) {
      if (!sandboxRequest.isLoading && !baselineRequest.isLoading) {
        if (sandboxRequest.error || baselineRequest.error) {
          setError(sandboxRequest.error || baselineRequest.error);
        } else if (sandboxRequest.data && baselineRequest.data) {
          const diffData = getDiffData(
            baselineRequest.data,
            sandboxRequest.data
          );
          setData(diffData);
        } else {
          setError("Unexpected error. Please try again later.");
        }
        setIsLoading(false);
      }
    }
    // Explicitly return undefined if isLoading is false.
    return undefined;
  }, [isLoading, sandboxRequest, baselineRequest]);

  return {
    isLoading,
    error,
    data,
    submit,
    reset,
  };
};
