import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon, Intent, OverlayToaster } from "@blueprintjs/core";
import type { ImperativePanelHandle } from "react-resizable-panels";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Panel, PanelGroup } from "react-resizable-panels";
import BaseEditor from "../../../../../../components/Editor";
import SdButton from "../../../../../../components/theming/SdButton";
import type { BaseTestSpec, Test } from "../../../../../../@types/sd/testspec";
import { useSaveTest } from "../../../../../../api/TestsApi";
import { validateTest } from "./validate";
import useDisclosure from "../../../../../../hooks/UseDisclosure";
import {
  useCreateTestExecution,
  useGetTestExecution,
} from "../../../../../../api/TestExecutionsApi";
import ClusterSelector from "../../ClusterSelector/ClusterSelector";
import { useGetJob } from "../../../../../../api/JobsApi";
import useGetJobLogs from "../../../../../../hooks/UseGetJobLogs";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { AttemptPhaseEnum } from "../../../../../../@types/sd/job.d.ts";
import { LogPanel, TestNameDialog } from "./components";
import {
  LEFT_PANEL_MIN_SIZE,
  LOGS_PANEL_COLLAPSED_SIZE,
  LOGS_PANEL_DEFAULT_SIZE,
  LOGS_PANEL_MAX_SIZE,
  LOGS_PANEL_MIN_SIZE,
  MAIN_PANEL_NAME,
} from "./constant";

import style from "./TestSpecEditor.module.css";
import CustomPanelResizeHandle from "../../../../../../components/CustomPanelResizeHandle/CustomPanelResizeHandle";
import useLocalSavedPreferences, {
  PREFERENCES_NAMES,
} from "../../../../../../hooks/UseLocalSavedPreferences";
import { getTimeAgo } from "../../../../../../util/Util";

const toaster = OverlayToaster.create();

type TestSpecEditorProps = {
  test: Test;
  isExistingTest: boolean;
};

const TestSpecEditor = ({
  test: originalTest,
  isExistingTest,
}: TestSpecEditorProps) => {
  const [test, setTest] = useState(originalTest);
  const { setPreference, values: savedPreferences } =
    useLocalSavedPreferences();
  const [originalTestScript, setOriginalTestScript] = useState<string>();
  const navigate = useNavigate();
  const [clusterNameTest, setClusterNameTest] = useState<string | undefined>();
  const [executionName, setExecutionName] = useState<string>();
  const [targetJobName, setTargetJobName] = useState<string>();
  const [ranAt, setRanAt] = useState<string>();
  const isUserCallingTestRef = useRef(false);

  const [logsMessage, setLogsMessage] = useState<string>(
    "No Smart Test started"
  );
  const logPanelRef = useRef<ImperativePanelHandle>(null);

  const showLogPanelContent = useDisclosure(false);
  const showSetName = useDisclosure(false);

  const [originTest, setOriginTest] = useState<"save" | "save_run">();

  const testExecutionApi = useGetTestExecution(
    test?.name ?? "",
    executionName!,
    {
      enabled: test?.name !== undefined && executionName !== undefined,
    }
  );

  const jobDetailApi = useGetJob(targetJobName!, {
    enabled: targetJobName !== undefined,
  });

  const { mergedLogs: logs } = useGetJobLogs({
    job: jobDetailApi.data,
  });
  const saveTestSpecApi = useSaveTest(
    () => {
      toaster.show({
        message: isExistingTest ? "Smart Test updated" : "Smart Test created",
        intent: Intent.SUCCESS,
      });

      if (!isExistingTest) {
        const tab = originTest === "save" ? "triggers" : "specification";
        navigate(`/testing/tests/${test?.name}/${tab}`, {
          replace: true,
        });
      }
    },
    (apiError) => {
      toaster.show({
        message: apiError.response.data.error,
        intent: Intent.DANGER,
      });
    }
  );
  const createTestExecutionApi = useCreateTestExecution(
    (response) => {
      toaster.show({
        message: "Smart Test job created",
        intent: Intent.SUCCESS,
      });
      setExecutionName(response.name);

      setPreference(PREFERENCES_NAMES.DEBUG_SMART_TEST, (prev) => ({
        ...prev,
        testName: test.name,
        executionName: response.name,
        ranAt: new Date(),
      }));

      setLogsMessage(
        "Smart Test started. Wait for Smart Test job is created..."
      );
      showLogPanelContent.open();
    },
    (apiError) => {
      setLogsMessage("Smart Test no started. An error occurred");
      toaster.show({
        message: apiError.response.data.error,
        intent: Intent.DANGER,
      });
    }
  );

  const checkTestNameMissing = () => {
    if (isExistingTest || originTest) {
      return false;
    }

    showSetName.open();
    return true;
  };

  const onSave = () => {
    if (checkTestNameMissing()) {
      setOriginTest("save");
      return;
    }

    const validationResult = validateTest(test ?? {});

    const firstError = validationResult.getFirstError();
    if (firstError) {
      toaster.show(firstError);
      return;
    }

    saveTestSpecApi.mutate({
      url: `/api/v2/orgs/:orgName/tests/${test.name}`,
      data: test?.spec as BaseTestSpec,
    });
  };

  const handleRunTest = () => {
    jobDetailApi.remove();
    testExecutionApi.remove();
    createTestExecutionApi.reset();
    setExecutionName(undefined);
    setTargetJobName(undefined);
    setPreference(PREFERENCES_NAMES.DEBUG_SMART_TEST, {});

    setLogsMessage("Starting test");
    createTestExecutionApi.mutate({
      url: `/api/v2/orgs/:orgName/tests/${test.name}/executions`,
      data: {
        executionContext: {
          cluster: clusterNameTest!,
        },
      },
    });
    isUserCallingTestRef.current = true;
  };

  const handleSaveAndRunTest = () => {
    if (checkTestNameMissing()) {
      setOriginTest("save_run");
      return;
    }

    saveTestSpecApi.mutate(
      {
        url: `/api/v2/orgs/:orgName/tests/${test.name}`,
        data: test?.spec as BaseTestSpec,
      },
      {
        onSuccess: handleRunTest,
      }
    );
  };

  useEffect(() => {
    if (
      !targetJobName &&
      test.name === savedPreferences["smart-tests/debug"].testName
    ) {
      setTargetJobName(savedPreferences["smart-tests/debug"].targetJobName);
      setRanAt(
        getTimeAgo(savedPreferences["smart-tests/debug"].ranAt?.toString())
      );
      showLogPanelContent.open();
    }
  }, [savedPreferences["smart-tests/debug"]]);

  useEffect(() => {
    if (!originalTest?.spec?.script) {
      return;
    }

    setTest(originalTest);
    setOriginalTestScript(originalTest.spec.script);
  }, [originalTest]);

  useEffect(() => {
    if (!testExecutionApi.isSuccess) return;

    const status = testExecutionApi.data.status;
    setLogsMessage(`Smart Test execution in ${status.phase} state`);

    if (status.phase === "pending") return;

    if (status.phase === "failed" && status.finalState?.failed?.message) {
      setLogsMessage(
        `Smart Test execution failed with ${status.finalState?.failed?.message}`
      );
      return;
    }

    setLogsMessage(`Smart Test execution running with ${status.targetJob}`);
    setTargetJobName(status.targetJob);

    setPreference(PREFERENCES_NAMES.DEBUG_SMART_TEST, (prev) => ({
      ...prev,
      targetJobName: status.targetJob,
    }));
    showLogPanelContent.open();
  }, [testExecutionApi.data]);

  useEffect(() => {
    if (!jobDetailApi.isSuccess || jobDetailApi.isRefetching) return;
    if (
      jobDetailApi.data.status.attempts[0].phase === AttemptPhaseEnum.QUEUED
    ) {
      setLogsMessage(`Waiting job ${targetJobName} to start`);
      return;
    }

    let runText = "";
    if (ranAt && !isUserCallingTestRef.current) {
      runText = ` that ran ${ranAt}`;
    }

    const header = `Showing logs for job ${targetJobName}${runText}\n`;
    setLogsMessage(header + logs.map((l) => l.message).join(""));

    const attempt = jobDetailApi.data.status.attempts[0];

    if (attempt.phase === "failed") {
      setLogsMessage((prev) => {
        prev += `Run failed with ${attempt.state.failed.message}`;

        return prev;
      });
    }

    if (attempt.phase === "succeeded") {
      setLogsMessage((prev) => {
        prev += "Run finished successfully";

        return prev;
      });
    }
  }, [jobDetailApi, logs, targetJobName]);

  useEffect(() => {
    logPanelRef.current?.resize(
      showLogPanelContent.isOpen ? LOGS_PANEL_DEFAULT_SIZE : LOGS_PANEL_MIN_SIZE
    );
  }, [showLogPanelContent.isOpen]);

  useEffect(() => {
    if (isExistingTest) return;

    if (!originTest) return;

    // eslint-disable-next-line default-case
    switch (originTest) {
      case "save":
        onSave();
        break;
      case "save_run":
        handleSaveAndRunTest();
    }

    setOriginTest(undefined);
  }, [test]);

  if (test?.spec?.script === undefined) {
    return null;
  }

  return (
    <div className={style.wrapper}>
      <TestNameDialog
        isOpen={showSetName.isOpen}
        onCancel={() => {
          setOriginTest(undefined);
          showSetName.close();
        }}
        onConfirm={(newTestName: string) => {
          setTest((prev) => ({
            ...prev,
            name: newTestName,
          }));
          showSetName.close();
        }}
      />

      <PanelGroup
        direction="horizontal"
        className={style.wrapper}
        autoSaveId={MAIN_PANEL_NAME}
      >
        <Panel order={1} minSize={LEFT_PANEL_MIN_SIZE}>
          <PanelGroup direction="vertical">
            <Panel order={1}>
              <BaseEditor
                language="python"
                value={test?.spec?.script}
                onChange={(script) =>
                  setTest((t) => ({
                    ...t,
                    spec: {
                      ...t.spec,
                      script: script || "",
                    },
                  }))
                }
                header={
                  <div className="flex justify-between items-center">
                    <div>
                      {originalTestScript !== test.spec?.script && (
                        <div className={style.unsave_message}>
                          <Icon icon="warning-sign" intent={Intent.WARNING} />
                          <p>Unsaved changes</p>
                        </div>
                      )}
                    </div>
                    <div className="flex gap-1 justify-end items-end">
                      <ClusterSelector
                        value={clusterNameTest}
                        onChange={setClusterNameTest}
                        unsetInitial
                      />
                      <SdButton
                        icon={<Icon icon="bug" color="white" />}
                        onClick={handleSaveAndRunTest}
                        disabled={
                          !clusterNameTest ||
                          (testExecutionApi.data &&
                            !["succeeded", "failed", "canceled"].includes(
                              testExecutionApi.data.status.phase ?? ""
                            ))
                        }
                      >
                        Save & Run
                      </SdButton>
                      <SdButton onClick={onSave}>Save</SdButton>
                    </div>
                  </div>
                }
              />
            </Panel>
            <CustomPanelResizeHandle direction="vertical" />

            <Panel
              order={2}
              maxSize={LOGS_PANEL_MAX_SIZE}
              minSize={LOGS_PANEL_MIN_SIZE}
              collapsedSize={LOGS_PANEL_COLLAPSED_SIZE}
              defaultSize={LOGS_PANEL_DEFAULT_SIZE}
              collapsible
              onCollapse={showLogPanelContent.close}
              onExpand={showLogPanelContent.open}
              ref={logPanelRef}
            >
              <LogPanel
                isCollapsed={!showLogPanelContent.isOpen}
                onOpen={() => {
                  showLogPanelContent.open();
                  logPanelRef.current?.resize(20);
                }}
                logsMessage={logsMessage}
              />
            </Panel>
          </PanelGroup>
        </Panel>
      </PanelGroup>
    </div>
  );
};

export default TestSpecEditor;
