import React from "react";
import styles from "./Fork.module.css";

interface ForkProps {
  parent: React.ReactNode;
  child: React.ReactNode;
}

const Fork: React.FC<ForkProps> = ({ parent, child }) => (
  <div className={styles.tree}>
    <div className={styles.baseline}>{parent}</div>
    <div className={styles.fork}>&#x21B3; {child}</div>
  </div>
);

export default Fork;
