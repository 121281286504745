import React, { useEffect, useState } from "react";
import type { Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Boundary, Breadcrumbs, Dialog } from "@blueprintjs/core";
import { Elements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { SdHeading1 } from "../../../components/theming/SdHeading";
import { useAuth } from "../../../contexts/AuthContext";
import CheckoutForm from "../CheckoutForm";
import { useBillingApi } from "../../../api/BillingApi";
import PlanColumn from "./PlanColumn";
import { PLAN_INFO } from "./data";
import { INDEXED_PLANS } from "../Constants";
import styles from "./ManagePlan.module.css";

// Normally importing loadStripe will cause Stripe to get loaded immediately. However, this
// causes a bit of a problem because of Auth0. There's a chance that Stripe will start loading
// before a user has been authorized, and the loading will get cancelled due to the Auth0
// redirect. This causes an error to occur that will get reported to Sentry even though it
// technically isn't an error. So instead we use the "pure" version of loadStripe that will
// lazily load Stripe's code, and put the load work into a local effect on this component, so
// it only happens when the component is displayed.
//
// ref: https://github.com/stripe/stripe-js/issues/26#issuecomment-791468166
let stripePromise: Promise<Stripe | null> | null = null;

const ManagePlan: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { org } = useAuth().state;
  const { data } = useBillingApi();
  const [isCheckoutOpen, setCheckoutOpen] = useState(false);

  useEffect(() => {
    if (!stripePromise) {
      stripePromise = loadStripe(process.env.VITE_STRIPE_KEY!);
    }
  }, []);

  const stripeElementOptions: StripeElementsOptions = {
    appearance: {
      theme: "stripe",
    },
  };

  return (
    <>
      <SdHeading1 small lightBackground>
        Manage Plans
      </SdHeading1>
      <br />
      <Breadcrumbs
        collapseFrom={Boundary.START}
        items={[
          {
            icon: "credit-card",
            text: "Billing",
            onClick: () => navigate(`/billing`),
          },
          { text: "Plans" },
        ]}
      />
      <div className={styles.pricingTable}>
        {INDEXED_PLANS.map((planType) => (
          <PlanColumn
            key={`plan-column-${planType}`}
            orgName={org!.name}
            targetPlanType={planType}
            planInfo={PLAN_INFO[planType]}
            billingData={data}
            setCheckoutOpen={setCheckoutOpen}
          />
        ))}
      </div>

      <Dialog
        title="Upgrade to Team Plan"
        isOpen={isCheckoutOpen}
        onClose={() => {
          setCheckoutOpen(false);
        }}
      >
        <div className="px-4 pt-4">
          <Elements options={stripeElementOptions} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        </div>
      </Dialog>
    </>
  );
};

export default ManagePlan;
