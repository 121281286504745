import React from "react";
import styles from "./Banner.module.css";

interface BannerProps {
  icon?: React.ReactNode;
  title?: string;
  children: React.ReactNode;
  controls?: React.ReactNode;
}

const Banner: React.FC<BannerProps> = ({ children, icon, title, controls }) => (
  <div className={styles.container}>
    {icon && <div className={styles.icon}>{icon}</div>}
    <div className={styles.content}>
      <div className={styles.header}>
        {title && <div className={styles.title}>{title}</div>}
        {controls && <div className={styles.controls}>{controls}</div>}
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  </div>
);

export default Banner;
