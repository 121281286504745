export type StatusCodeDescription = {
  description: string;
  category: "info" | "success" | "redirect" | "error";
};

export const statusMap = new Map<number, StatusCodeDescription>([
  // Informational responses
  [
    100,
    {
      description:
        "Continue - The server has received the request headers and the client should proceed to send the request body",
      category: "info",
    },
  ],
  [
    101,
    {
      description:
        "Switching Protocols - The requester has asked the server to switch protocols and the server has agreed to do so",
      category: "info",
    },
  ],
  [
    103,
    {
      description:
        "Early Hints - Used to return some response headers before final HTTP message",
      category: "info",
    },
  ],

  // Successful responses
  [
    200,
    { description: "OK - The request was successful", category: "success" },
  ],
  [
    201,
    {
      description:
        "Created - The request has been fulfilled and a new resource has been created",
      category: "success",
    },
  ],
  [
    202,
    {
      description:
        "Accepted - The request has been accepted for processing, but the processing has not been completed",
      category: "success",
    },
  ],
  [
    204,
    {
      description:
        "No Content - The server successfully processed the request but is not returning any content",
      category: "success",
    },
  ],
  [
    206,
    {
      description:
        "Partial Content - The server is delivering only part of the resource due to a range header sent by the client",
      category: "success",
    },
  ],

  // Redirection messages
  [
    300,
    {
      description:
        "Multiple Choices - The request has more than one possible response",
      category: "redirect",
    },
  ],
  [
    301,
    {
      description:
        "Moved Permanently - The URL of the requested resource has been changed permanently",
      category: "redirect",
    },
  ],
  [
    302,
    {
      description:
        "Found - The URI of requested resource has been changed temporarily",
      category: "redirect",
    },
  ],
  [
    304,
    {
      description:
        "Not Modified - Indicates that the resource has not been modified since the version specified by the request headers",
      category: "redirect",
    },
  ],
  [
    307,
    {
      description:
        "Temporary Redirect - The server sends this response to direct the client to get the requested resource at another URI with same method that was used in the prior request",
      category: "redirect",
    },
  ],
  [
    308,
    {
      description:
        "Permanent Redirect - This means that the resource is now permanently located at another URI, specified by the Location: HTTP Response header",
      category: "redirect",
    },
  ],

  // Client error responses
  [
    400,
    {
      description:
        "Bad Request - The server cannot or will not process the request due to an apparent client error",
      category: "error",
    },
  ],
  [
    401,
    {
      description:
        "Unauthorized - Authentication is required and has failed or has not been provided",
      category: "error",
    },
  ],
  [
    403,
    {
      description:
        "Forbidden - The request was valid, but the server is refusing action",
      category: "error",
    },
  ],
  [
    404,
    {
      description: "Not Found - The requested resource could not be found",
      category: "error",
    },
  ],
  [
    405,
    {
      description:
        "Method Not Allowed - The request method is known by the server but is not supported by the target resource",
      category: "error",
    },
  ],
  [
    408,
    {
      description:
        "Request Timeout - The server timed out waiting for the request",
      category: "error",
    },
  ],
  [
    429,
    {
      description:
        "Too Many Requests - The user has sent too many requests in a given amount of time",
      category: "error",
    },
  ],

  // Server error responses
  [
    500,
    {
      description:
        "Internal Server Error - A generic error message, given when an unexpected condition was encountered",
      category: "error",
    },
  ],
  [
    501,
    {
      description:
        "Not Implemented - The server does not support the functionality required to fulfill the request",
      category: "error",
    },
  ],
  [
    502,
    {
      description:
        "Bad Gateway - The server was acting as a gateway or proxy and received an invalid response from the upstream server",
      category: "error",
    },
  ],
  [
    503,
    {
      description:
        "Service Unavailable - The server is temporarily unable to handle the request",
      category: "error",
    },
  ],
  [
    504,
    {
      description:
        "Gateway Timeout - The server was acting as a gateway or proxy and did not receive a timely response from the upstream server",
      category: "error",
    },
  ],
  [
    511,
    {
      description:
        "Network Authentication Required - The client needs to authenticate to gain network access",
      category: "error",
    },
  ],
]);
