import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import type { ResourcePlugin } from "../../../../../@types/sd/resourceplugin";
import styles from "./ResourcePluginOverview.module.css";
import DetailItem from "../../../../../components/DetailItem";
import { getTimeAgoElement } from "../../../../../util/Util";
import { SdHeading2 } from "../../../../../components/theming/SdHeading";
import PaginatedTable from "../../../../../components/PaginatedTable/PaginatedTable";
import MarkdownViewer from "../../../../../components/MarkdownViewer";
import type { FormattedResourceInfo } from "../../ResourcePluginDetails";

const columns = [
  {
    Header: "Resource Name",
    accessor: "name",
    search: true,
  },
  {
    Header: "Sandbox",
    accessor: "sandbox",
    search: true,
  },
  {
    Header: "Cluster",
    accessor: "cluster",
    search: true,
  },
];

type ResourcePluginOverviewProps = {
  resourcePlugin: ResourcePlugin;
};

const ResourcePluginOverview: React.FC<ResourcePluginOverviewProps> = ({
  resourcePlugin,
}) => {
  const navigate = useNavigate();

  const formattedResources = useMemo((): FormattedResourceInfo[] => {
    if (
      !resourcePlugin ||
      !resourcePlugin.status ||
      !Array.isArray(resourcePlugin.status.resources)
    ) {
      return [];
    }
    return resourcePlugin.status.resources.map((resource) => ({
      name: resource.name,
      cluster: resource.cluster,
      sandbox: resource.sandbox,
    }));
  }, [resourcePlugin]);

  const handleRowClick = (row: FormattedResourceInfo) => {
    navigate(`/sandbox/name/${row.sandbox}`);
  };

  return (
    <>
      <DetailItem title="Name" value={resourcePlugin.name} />
      <DetailItem
        title="Created"
        value={getTimeAgoElement(resourcePlugin.createdAt)}
      />
      <DetailItem
        title="Updated"
        value={getTimeAgoElement(resourcePlugin.updatedAt)}
      />

      <div className={styles.resources}>
        <SdHeading2 small lightBackground>
          Resources
        </SdHeading2>
        {formattedResources.length > 0 && (
          <PaginatedTable
            columns={columns}
            data={formattedResources}
            onRowClickCallback={handleRowClick}
          />
        )}
        {formattedResources.length === 0 && "No active resources"}
      </div>

      {resourcePlugin.spec?.description && (
        <MarkdownViewer content={resourcePlugin.spec.description} />
      )}
    </>
  );
};

export default ResourcePluginOverview;
