import type { Container } from "../k8s/container";

export type APIVersion = "apps/v1" | "argoproj.io/v1alpha1";
export type WorkloadKind = "Deployment" | "Rollout";

export const AppsV1APIVersion: APIVersion = "apps/v1";
export const ArgoAPIVersion: APIVersion = "argoproj.io/v1alpha1";
export const DeploymentWorkloadKind: WorkloadKind = "Deployment";
export const ArgoWorkloadKind: WorkloadKind = "Rollout";

export interface Workload {
  clusterName: string;
  kind: WorkloadKind;
  baseline?: Container;
  fork?: Container;
  previewEndpoint?: {
    previewURL: string;
    protocol: string;
    baselinePreviewURL: string;
  };
}

export const getApiVersion = (k: WorkloadKind): APIVersion => {
  switch (k) {
    case DeploymentWorkloadKind:
      return AppsV1APIVersion;
    case ArgoWorkloadKind:
      return ArgoAPIVersion;
    default:
      throw new Error(`Unhandled workload kind: ${k}`);
  }
};
