import React, { useEffect, useMemo, useRef, useState } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import LogsHeader from "./LogsHeader";
import styles from "./Logs.module.css";
import LogEntry from "./LogEntry";

export interface LogItem {
  message: string;
  time: string;
  type?: "error" | "output";
}

export interface LogResponseItem {
  message: string;
  cursor: string;
}

interface Props {
  header?: React.ReactNode;
  logs?: LogItem[];
  follow?: boolean;
  logPanelOpen: boolean;
}

const Logs: React.FunctionComponent<Props> = ({
  header,
  logs = [],
  follow: followInput = true,
  logPanelOpen,
}) => {
  // const [filteredLogs, setFilteredLogs] = useState<LogItem[] | undefined>(logs);
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [follow, setFollow] = useState(followInput);

  const parentRef = useRef<HTMLDivElement>(null);

  const filteredLogs = useMemo(
    () =>
      logs.filter((log) => {
        if (searchTerms.length === 0) return true;

        const message = log.message.toLowerCase();
        return searchTerms.some((key) => message.includes(key));
      }),
    [logs, searchTerms]
  );
  const rowVirtualizer = useVirtualizer({
    count: filteredLogs?.length || 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 25,
  });

  useEffect(() => {
    if (follow && logPanelOpen && filteredLogs && filteredLogs.length > 0) {
      rowVirtualizer.scrollToIndex(filteredLogs.length);
    }
  }, [filteredLogs, follow, logPanelOpen]);

  return (
    <div className={styles.container}>
      <LogsHeader
        matchCount={Array.isArray(filteredLogs) ? filteredLogs.length : 0}
        setSearchTerms={setSearchTerms}
        follow={follow}
        setFollow={setFollow}
        innerContent={header}
      />
      <div
        key="log-container-menu"
        id="log-container"
        style={{
          fontFamily: 'monospace, SFMono-Regular, "SF Mono", Menlo',
          fontSize: "1em",
          lineHeight: "18px",
          height: "52vh",
          overflow: "auto",
          whiteSpace: "nowrap",
          paddingBottom: "2em",
        }}
        ref={parentRef}
      >
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: "100%",
            position: "relative",
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => (
            <div
              key={virtualRow.index}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              <LogEntry
                message={
                  (filteredLogs && filteredLogs[virtualRow.index].message) ?? ""
                }
                searchTerms={searchTerms}
                time={
                  (filteredLogs && filteredLogs[virtualRow.index].time) ?? ""
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Logs;
