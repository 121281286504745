import type { ChangeEvent } from "react";
import React, { useEffect, useState } from "react";
import SdInput from "../theming/SdInput";

interface Props<ColumnT, DataT> {
  columns: ColumnT;
  data: DataT[];
  handleFilteredData: (filteredData: DataT[]) => void;
}

export function TableSearch<ColumnT, DataT>({
  columns,
  data = [],
  handleFilteredData,
}: Props<ColumnT, DataT>) {
  const meetsSearchCriteria =
    Array.isArray(data) &&
    Array.isArray(columns) &&
    columns.length > 0 &&
    typeof handleFilteredData === "function";

  if (!meetsSearchCriteria) return null;

  // The fields marked with `search: true` in columns
  const searchableFields = columns
    .filter((x) => x.search === true && x.accessor)
    .map((x) => x.accessor);

  if (!searchableFields || searchableFields.length === 0) return null;

  const [searchText, setSearchText] = useState("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value || "";
    setSearchText(text.trim());
  };

  const filter = (unfilteredData: DataT[], query?: string) => {
    const terms = (query || "").split(" ").filter((x) => x.length > 0);

    if (terms.length === 0) {
      return unfilteredData;
    }
    return unfilteredData.filter((row) =>
      terms.every((term: string) =>
        (searchableFields || []).some((field) => {
          const cellData = row[field];
          let text = null;
          if (typeof cellData === "string") {
            text = cellData;
          } else if (typeof cellData === "object" && cellData.text) {
            text = cellData.text;
          }
          return text && text.includes(term);
        })
      )
    );
  };

  useEffect(() => {
    // filter the data whenever searchText or data changes
    const filteredData = filter(data, searchText);

    // pass the filtered data back to the parent component
    handleFilteredData(filteredData);
  }, [data, searchText]);

  if (data.length === 0) {
    return null;
  }

  return (
    <div>
      <SdInput
        name="search-field"
        type="text"
        placeholder="Type to search ..."
        className="bp4-input bp4-fill bp4-medium mb-4"
        onChange={handleChange}
      />
    </div>
  );
}

export default TableSearch;
