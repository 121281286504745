import * as React from "react";
import { useNavigate } from "react-router-dom";
import type { CSSProperties } from "react";
import { usePostHog } from "posthog-js/react";
import type { EventProps } from "../../@types/sd/event";

interface HistoryLinkProperties extends EventProps {
  url: string;
  historyState?: () => unknown;
  className?: string;
  style?: CSSProperties;
  children: React.ReactNode;
}

const HistoryLink: React.FunctionComponent<HistoryLinkProperties> = ({
  url,
  historyState,
  className,
  style,
  eventName,
  children,
}): JSX.Element => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  return (
    <a
      className={className}
      style={style}
      href={url}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (eventName) {
          posthog?.capture(eventName, {
            component: "HistoryLink",
          });
        }
        if (historyState) {
          navigate(url, {
            state: historyState(),
          });
        } else {
          navigate(url);
        }
      }}
    >
      {children}
    </a>
  );
};

export default HistoryLink;
